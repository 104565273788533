import { PossessionLevel } from '@avid/common';

import { TImageNames } from 'components/image';

import { IColorPalette, IShopRedux, ShopPages } from './shop.typings';

export const SHOP_ACTION = <const>{
  ON_OPEN_START_PAGE: '@SHOP/ON_OPEN_START_PAGE',
  CLEAR: '@SHOP/CLEAR',
  SET_PRICE: '@SHOP/SET_PRICE',
  SET_COUNTRY: '@SHOP/SET_COUNTRY',
  SET_LEVEL: '@SHOP/SET_LEVEL',
  GET_NEW_ICON: '@SHOP/GET_NEW_ICON',
  OPEN_SECTOR_PAGE: '@SHOP/OPEN_SECTOR_PAGE',
  SET_LEVEL_PRICE: '@SHOP/SET_LEVEL_PRICE',
  OPEN_MODAL: '@SHOP/OPEN_MODAL',
  MOVE_TO_START_PAGE: '@SHOP/MOVE_TO_START_PAGE',
  BUY_NEW_ICON: '@SHOP/BUY_NEW_ICON',
};

export const INIT_REDUX_SHOP: IShopRedux = {
  country: { value: '' },
  sector: '',
  page: ShopPages.Sector,
  price: { energy: 0, money: 0 },

  level: undefined,
  modal: undefined,
};

export const SHOP_SECTOR_POSSESSION_ITEMS: IItem[] = [
  {
    value: 'car',
    label: 'Car',
  },
  {
    value: 'home',
    label: 'House',
  },
  {
    value: 'mobile',
    label: 'Mobile Phone',
  },
];

export const SHOP_SECTOR_TRAVEL: IItem = {
  value: 'travel',
  label: 'Travel',
};

export const SHOP_SECTOR_ICON: IItem = {
  value: 'icon',
  label: 'Avatar',
};

export const SHOP_SECTOR: IItem[] = [
  ...SHOP_SECTOR_POSSESSION_ITEMS,
  SHOP_SECTOR_TRAVEL,
  SHOP_SECTOR_ICON,
];

export const LEVEL_ICON: Record<PossessionLevel, TImageNames> = {
  [PossessionLevel.Budget]: 'shopBudget',
  [PossessionLevel.Standard]: 'shopStandard',
  [PossessionLevel.Luxury]: 'shopLuxury',
};

export const COLOR_PALETTE: Record<PossessionLevel, IColorPalette> = {
  [PossessionLevel.Budget]: {
    background: 'backgroundBlue',
    selectBackground: 'backgroundSelectBlue',
    border: 'blue',
  },
  [PossessionLevel.Standard]: {
    background: 'backgroundOrange',
    selectBackground: 'backgroundSelectOrange',
    border: 'orange',
  },
  [PossessionLevel.Luxury]: {
    background: 'backgroundYellow',
    selectBackground: 'backgroundSelectYellow',
    border: 'darkYellow',
  },
};
