import { FC } from 'react';

import { ImageBox } from './image-box';
import { Theme } from 'themes';

export interface IPriceInformationProps extends Partial<IPrice> {
  className?: string;
  itemGap?: string;
}

export const PriceInformation: FC<IPriceInformationProps> = (props) => {
  const { className, money, energy, itemGap } = props;

  const RenderMoney = money !== undefined && (
    <ImageBox
      elementTitle={<Theme.Text>{money}</Theme.Text>}
      titlePosition="right"
      sizeImage="30px"
      imageName="money"
      itemGap="4px"
    />
  );

  const RenderEnergy = energy !== undefined && (
    <ImageBox
      elementTitle={<Theme.Text>{energy}</Theme.Text>}
      sizeImage="25px"
      titlePosition="right"
      imageName="power"
      itemGap="4px"
    />
  );

  return (
    <Theme.CentralizeFlexbox
      className={className}
      $fontFamily="bold"
      $itemGap={itemGap}
    >
      {RenderMoney}
      {RenderEnergy}
    </Theme.CentralizeFlexbox>
  );
};
