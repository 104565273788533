import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { H1, Text } from 'components/ui';
import { Image, TImageNames } from 'components/image';
import { typographyCss } from 'themes';

import { CreateCharacterSwitch } from './create-character-switch';

interface IProps {
  title: string;
  description: string;
  image: TImageNames;
  isNextAvailable: boolean;
  onNext: () => void;
  subdescription?: string;
  children?: ReactNode;
}

export const CreateCharacterToolWrapper = styled.div`
  padding-top: 32px;
`;

const Styles = {
  Wrapper: styled.div`
    padding: 8px 0;
    text-align: center;
  `,
  Title: styled(H1)`
    margin-top: 24px;
  `,
  Description: styled(Text)`
    margin-top: 32px;
    ${typographyCss.smallRegular};
  `,
  Subdescription: styled(Text)`
    margin-top: 16px;
    ${typographyCss.smallRegular};
  `,
};

export const CreateCharacterLayout: FC<IProps> = (props) => {
  const {
    description,
    subdescription,
    image,
    title,
    children,
    isNextAvailable,
    onNext,
  } = props;

  const RenderSubdescription = subdescription ? (
    <Styles.Subdescription>{subdescription}</Styles.Subdescription>
  ) : null;

  return (
    <CreateCharacterSwitch isNextAvailable={isNextAvailable} onNext={onNext}>
      <Styles.Wrapper>
        <Image type={image} />
        <Styles.Title>{title}</Styles.Title>

        <Styles.Description>{description}</Styles.Description>
        {RenderSubdescription}

        {children}
      </Styles.Wrapper>
    </CreateCharacterSwitch>
  );
};

export const AnchorTitle = styled(Text)`
  ${typographyCss.RalewayBold16};
  line-height: 24px;
`;
