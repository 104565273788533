import styled, { css } from 'styled-components';

import { ThemePreset } from './component.template';

import { ICheckStyled, ISwitchStyled, IWrapperStyled } from './styles.typings';

import {
  COLORS,
  FONT_SIZES,
  FONT_FAMILIES,
  MEDIA,
  spacing,
  SCALE,
} from 'themes';

const DEFAULT_SIZING = spacing(1);

const Wrapper = styled.div<IWrapperStyled>`
  ${(p) => p.$fontFamily && `font-family: ${FONT_FAMILIES[p.$fontFamily]}`};
  ${(p) => p.$fontSize && `font-size: ${FONT_SIZES[p.$fontSize]}`};

  ${(p) => p.$color && `color: ${COLORS[p.$color]};`}
  ${(p) => p.$textAlign && `text-align: ${p.$textAlign};`}
  ${(p) => p.padding && `padding: ${p.padding}`};

  > * + * {
    margin-top: ${(p) => p.$itemGap?.default ?? DEFAULT_SIZING} !important;
  }

  ${MEDIA.LAPTOP} {
    > * + * {
      margin-top: ${(p) =>
        p.$itemGap?.laptop ?? p.$itemGap?.default ?? DEFAULT_SIZING} !important;
    }
  }
`;

export const Theme = {
  ...ThemePreset,

  Switch: styled.div<ISwitchStyled>`
    width: 100%;
    display: ${(p) => (p.$isOpen ? 'block' : 'none')};
  `,

  Wrapper,

  Checked: styled.div<ICheckStyled>`
    text-align: center;
    padding: ${DEFAULT_SIZING};
    ${(p) => (p.$isChecked ? p.$activeStyled : p.$passiveStyled)}

    &:hover {
      cursor: pointer;
    }
  `,

  ShadowWrapper: styled(Wrapper)<IWrapperStyled>`
    padding: 16px;
    background: #ffffff;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  `,

  PageContainer: styled.div<{ $isFullHeight?: boolean }>`
    padding: 24px 16px;
    height: 100%;

    ${({ $isFullHeight = true }) =>
      $isFullHeight &&
      css`
        & > *:first-child {
          height: 100%;
        }
      `}
  `,

  ShadowHomeWrapper: styled(Wrapper)`
    background-color: ${COLORS.white};
    box-shadow: 2px 6px 16px rgba(45, 45, 55, 0.1);
    border-radius: 10px;
    padding: 32px;
  `,
};

export const Css = {
  Loginwrapper: css`
    height: 100%;
    display: grid;
    justify-items: center;
    grid-template-rows: 1fr auto;
    row-gap: 16px;
  `,
  horizontalScrollBar: css`
    &::-webkit-scrollbar {
      display: block;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: ${COLORS.white};
    }
    &::-webkit-scrollbar-thumb {
      height: 8px;
      background-color: ${COLORS.purple};
      border-radius: 100px;
    }

    ${MEDIA.LAPTOP} {
      &::-webkit-scrollbar {
        height: 6px;
      }
    }
  `,

  mobileOnly: css`
    ${MEDIA.LAPTOP} {
      display: none;
    }
  `,

  responsiveCenterWide: css`
    width: 100%;
    max-width: ${spacing(SCALE.wide)};
    margin: 0 auto;

    ${MEDIA.LAPTOP} {
      width: 25%;
      min-width: ${spacing(SCALE.wide)} !important;
      max-width: 1000px;
    }
  `,
};
