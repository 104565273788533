import { ReactNode } from 'react';

import { Grid } from './grid';

interface IProps {
  rowGap?: string;
  children?: ReactNode | ReactNode[];
}

export const VerticalList = (props: IProps) => {
  const { children, rowGap } = props;

  return <Grid rowGap={rowGap}>{children}</Grid>;
};
