import { FC, ReactElement } from 'react';
import { RuleSet } from 'styled-components';

import { Theme } from 'themes';
import { Image, TImageNames } from 'components/image';

export interface IImageBoxProps {
  className?: string;
  imageName: TImageNames;
  elementTitle: ReactElement;
  onClick?: () => void;
  titlePosition?: 'top' | 'bottom' | 'left' | 'right';
  sizeImage?: string;
  itemGap?: string;
  imageCss?: RuleSet<object>;
}

export const ImageBox: FC<IImageBoxProps> = (props) => {
  const {
    className,
    imageName,
    elementTitle,
    titlePosition = 'bottom',
    sizeImage,
    itemGap = '8px',
    imageCss,
    onClick,
  } = props;

  const isColumn = titlePosition === 'bottom' || titlePosition === 'top';
  const isBeforeImage = titlePosition === 'top' || titlePosition === 'left';

  return (
    <Theme.CentralizeFlexbox
      $isColumn={isColumn}
      onClick={onClick}
      className={className}
      $itemGap={itemGap}
    >
      {isBeforeImage && elementTitle}
      <Image size={sizeImage} type={imageName} $styleCss={imageCss} />
      {!isBeforeImage && elementTitle}
    </Theme.CentralizeFlexbox>
  );
};
