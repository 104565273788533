import { FC } from 'react';
import styled from 'styled-components';
import { IAbility } from '@avid/common';

import { ProgressPercent } from 'components/progress';
import { getGSCLabel } from 'shared';
import { COLORS, TColorNames, Theme } from 'themes';

export interface ISkillDescriptionProps {
  name: string;
  needLevel: number | null;
  type: TSkillType;
  ability?: IAbility;
}

const Wrapper = styled(Theme.Wrapper)`
  padding-bottom: 16px;

  &:not(:last-child) {
    border-bottom: 0.5px solid ${COLORS.ashes};
  }
`;

export const SkillDescription: FC<ISkillDescriptionProps> = (props) => {
  const { name, needLevel, ability, type } = props;

  const nowLevelNumber = ability?.level ?? 0;
  const isTSC = type === 'TSC';

  const nowLevelInformation = isTSC
    ? nowLevelNumber.toFixed(1)
    : getGSCLabel(nowLevelNumber);

  const needLevelInformation = isTSC
    ? needLevel?.toFixed(1)
    : needLevel && getGSCLabel(needLevel);

  const percent = needLevel ? (100 * nowLevelNumber) / needLevel : 100;

  const progressColor: TColorNames = percent < 100 ? 'orange' : 'lightGreen';

  return (
    <Wrapper>
      <Theme.Paragraph $fontFamily="bold" $textAlign="left">
        {name}
      </Theme.Paragraph>
      <Theme.Flexbox $justifyContent="space-between">
        <Theme.Text $fontSize="small">Your level</Theme.Text>
        <Theme.Text $fontSize="small">Required level</Theme.Text>
      </Theme.Flexbox>
      <Theme.Flexbox $justifyContent="space-between">
        <Theme.Text $fontFamily="bold">{nowLevelInformation}</Theme.Text>
        <Theme.Text $fontFamily="bold">{needLevelInformation}</Theme.Text>
      </Theme.Flexbox>
      <ProgressPercent
        percent={percent}
        $progressColor={progressColor}
        $backgroundColor="white"
      />
    </Wrapper>
  );
};
