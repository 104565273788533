import { useMod } from 'services/hooks';
import { getModComponent } from 'services/utils';

interface IProps {
  onClose: () => void;
}

export const DegreeExceededModal = (props: IProps) => {
  const { onClose } = props;

  const ExceededDegreeModalMC = getModComponent(
    useMod('limitedEducation')?.components.ExceededDegreeModal
  );

  return <ExceededDegreeModalMC onClose={onClose} />;
};
