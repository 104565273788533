import { useCallback, useState } from 'react';

export const useUpdateState = <T extends {} | undefined>(initState: T) => {
  const [state, setState] = useState<T>(initState);

  const updateState = useCallback(
    (data: TUpdateStateData<T>) =>
      setState((s) => ({
        ...s,
        ...(typeof data === 'function' ? data(s) : data),
      })),
    []
  );

  const updateField: TUpdateField<T> = (option) => (value) =>
    updateState({ [option]: value } as any);

  const updateFieldCallback: TUpdateFieldCallback<T> = (option) => (
    value
  ) => () => updateState({ [option]: value } as any);

  return { state, updateState, updateField, updateFieldCallback };
};
