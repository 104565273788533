import { useMemo } from 'react';

import { IIntervalJob } from 'typings/interval-jobs.typing';
import { useRoundCountdown } from './round-countdown';

export const useRoundCountdownIntervalJob = () => {
  const handler = useRoundCountdown();

  const job: IIntervalJob = useMemo(
    () => ({ interval: 1, handler }),
    [handler]
  );

  return job;
};
