import { css } from 'styled-components';

import { FONT_FAMILIES, COLORS } from 'themes';

import { CSS } from 'typings';

export interface IThemeSelectorItemParameters {
  default: CSS;
  select: CSS;
  hover: CSS;
}

interface IThemeSelectorParameter {
  selector: CSS;
  menu: CSS;
  item: IThemeSelectorItemParameters;
  header: CSS;
}

export type TThemeSelector = 'orange' | 'blue';

export const DEFAULT_CSS = css``;

export const SELECTOR_THEME: Record<TThemeSelector, IThemeSelectorParameter> = {
  orange: {
    selector: css`
      font-family: ${FONT_FAMILIES.bold};
    `,
    menu: DEFAULT_CSS,
    header: css`
      border-color: ${COLORS.darkYellow};
      padding: 18px;
      text-align: center;
    `,
    item: {
      default: css`
        padding: 5px 15px;
      `,
      hover: css`
        background-color: ${COLORS.lightGray};
      `,
      select: css`
        background-color: ${COLORS.gray};
      `,
    },
  },
  blue: {
    selector: css`
      font-family: ${FONT_FAMILIES.bold};
      text-align: left;
    `,
    menu: css`
      border: none;
    `,
    header: css`
      border-color: ${COLORS.blue};
      padding: 18px 15px;
    `,
    item: {
      default: css`
        padding: 5px 15px;
      `,
      hover: css`
        background-color: ${COLORS.lightGray};
      `,
      select: css`
        background-color: ${COLORS.gray};
      `,
    },
  },
};
