import { AppTutorialController } from 'components/tutorial';
import { useReduxSelector } from 'services/hooks';

import { useMainTutorial } from './main-tutorial.state';

const MainTutorialComponent = () => {
  const { steps, handleCallback } = useMainTutorial();

  return (
    <AppTutorialController
      steps={steps}
      callback={handleCallback}
      isShowWhenTipsDisabled
    />
  );
};

export const MainTutorial = () => {
  const isCompleted = useReduxSelector((redux) => redux.player.tutorials?.main);

  if (isCompleted) {
    return null;
  }

  return <MainTutorialComponent />;
};
