import { useMemo, useState } from 'react';

import { ROUTES } from 'constants/routes';
import { useReduxSelector, useSlider } from 'services/hooks';
import { moveToRouteCallback } from 'services/utils';

import {
  createOnboardingSliderSetting,
  getOnBoardingItems,
} from './onboarding.utils';
import { shallowEqual } from 'react-redux';

export const useOnboarding = () => {
  const [slide, setSlide] = useState(0);

  const version = useReduxSelector((redux) => redux.app.version);
  const gaugesMod = useReduxSelector(
    (redux) => redux.app.gameMods?.gauges,
    shallowEqual
  );

  const { ref, nextSlide } = useSlider();

  const setting = createOnboardingSliderSetting(slide, setSlide);

  const onboardingItems = useMemo(
    () => getOnBoardingItems(!!gaugesMod, version),
    [gaugesMod, version]
  );

  const handleClick =
    slide === onboardingItems.length - 1
      ? moveToRouteCallback(ROUTES.createCharacter)
      : nextSlide;

  return { ref, setting, onboardingItems, handleClick };
};
