import { ISkillInfo, TGameVersion, IAbility, IQuestion } from '@avid/common';

export enum EducationModal {
  NotPass,
  Pass,
  WrongDegree,
  WrongLevel,
  StartTest,
  SelectUniversitySector,
  ExceededDegree,
}

export enum EducationPage {
  Test,
  Level,
  Sector,
  Degree,
}

export interface IEducationState {
  page: EducationPage;
  rating: number;
  isLoading: boolean;
  modal?: EducationModal;
  level?: string;
  sector?: string;
  learnedAbilities?: IAbility[];
  questions?: IQuestion[];
}

export interface IFinishEducationParams {
  level: string;
  rating: number;
  clearEducationState: () => void;
  sector?: string;
  newAbilities?: IAbility[];
}

export interface IEducationRandomUpdateParams {
  educationLevels: TEducationLevels;
  level: string;
  skillInfos: ISkillInfo[];
}

export interface IGetSectorSkillsParams {
  educationLevels: TEducationLevels;
  level: string;
  version: TGameVersion;
  sector: string;
}
