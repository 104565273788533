import { useState } from 'react';

import { useOutsideCall } from 'services/hooks';

export const useTooltip = <T extends HTMLElement>() => {
  const [isOpen, setOpen] = useState(true);
  const { ref } = useOutsideCall<T>(() => setOpen(false));

  return { isOpen, ref };
};
