import { WaitingModal } from 'components/waiting-modal';
import { useReduxSelector } from 'services/hooks';

export const PauseModal = () => {
  const version = useReduxSelector((redux) => redux.app.version);

  const roundNumber = useReduxSelector((redux) => redux.game.round.number);

  if (!roundNumber || version !== 'ntu') {
    return <WaitingModal />;
  }

  return null;
};
