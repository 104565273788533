import { FC } from 'react';
import styled from 'styled-components';

import { Theme } from 'themes';
import { createPrice } from 'services/utils';

import { FIND_JOB_STRINGS } from '../../../find-job.strings';

import { CompanyStyles } from '../company.styles';

interface IMoodInformationProps {
  mood: IMood;
}

interface IWorkInformationProps extends IMoodInformationProps {
  job: string;
  company: string;
  mood: IMood;
  salary?: number;
  cycles?: number;
}

interface IInformationFieldProps {
  title: string;
  information: string;
}

const NoWrapTitle = styled(Theme.Paragraph)`
  white-space: nowrap;
`;

const {
  COMPANY,
  JOB_NAME,
  SALARY,
  MOOD_WORK_INFORMATION,
} = FIND_JOB_STRINGS.JOB_OFFER;

const InformationField = (props: IInformationFieldProps) => {
  const { title, information } = props;

  return (
    <>
      <NoWrapTitle $fontFamily="bold">{title}</NoWrapTitle>
      <Theme.Paragraph>{information || ''}</Theme.Paragraph>
    </>
  );
};

const MoodInformation = (props: IMoodInformationProps) => {
  const { negative, positive } = props.mood;

  return (
    <Theme.Flexbox $itemGap="18px">
      <Theme.Paragraph $fontFamily="bold">
        {MOOD_WORK_INFORMATION}
      </Theme.Paragraph>

      <Theme.Flexbox $itemGap="4px">
        <Theme.Text $color="green">+</Theme.Text>
        <Theme.Text>{positive}</Theme.Text>
      </Theme.Flexbox>

      <Theme.Flexbox $itemGap="4px">
        <Theme.Text $color="red">-</Theme.Text>
        <Theme.Text>{negative}</Theme.Text>
      </Theme.Flexbox>
    </Theme.Flexbox>
  );
};

export const CompanyHead: FC<IWorkInformationProps> = (props) => {
  const { job, company, salary, mood, cycles } = props;

  const RenderCycles = cycles ? (
    <InformationField
      title="Job Duration:"
      information={`Max ${cycles} work cycles`}
    />
  ) : null;

  return (
    <>
      <CompanyStyles.CompanyInformation>
        <InformationField information={company} title={COMPANY} />
        <InformationField information={job} title={JOB_NAME} />
        <InformationField
          information={salary ? createPrice(salary) : ''}
          title={SALARY}
        />
        {RenderCycles}
      </CompanyStyles.CompanyInformation>

      <MoodInformation mood={mood} />
    </>
  );
};
