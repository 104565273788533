import { ICharacterSmart } from '@avid/common';

export const SMART_DESCRIPTIONS: Record<keyof ICharacterSmart, string> = {
  body: 'Good at controlling body movements and handles objects skillfully.',
  logic: 'Good at analyzing problems, reasoning, and math.',
  music: 'Good at sounds, rhythms, melodies and rhymes.',
  nature: 'Good at understanding the natural world and scientific studies.',
  people: 'Good at understanding and relating to others.',
  picture: 'Good at visualizing things in the physical world and in design.',
  self: 'Good at being aware of their strengths, emotions, motivations etc.',
  word: 'Good at words, language, and writing.',
};

export const SMART_LABELS: Record<keyof ICharacterSmart, string> = {
  nature: 'nature-smart ',
  logic: 'logic-smart  ',
  self: 'self-smart ',
  people: 'people-smart',
  music: 'music-smart',
  body: 'body-smart ',
  picture: 'picture-smart',
  word: 'word-smart',
};

export const SMART_TOTAL = 50;

export const SMART_MIN = 1;
export const SMART_MAX = 10;
export const SMART_DIFF = 1;

export const ANCHORS_TOTAL = 2;

export const VALUES_TOTAL = 5;
