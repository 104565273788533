import styled, { css } from 'styled-components';

import { COLORS } from 'themes';

const Css = {
  rectangle: css<{ $isActive: boolean }>`
    flex: 1;
    height: 100%;
    margin: -1px;
    background-color: ${COLORS.purple};

    ${({ $isActive }) => $isActive && 'min-width: 12px'};
  `,
};

export const BotBarStyles = {
  Root: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.white};
  `,
  BorderWrapper: styled.div<{ $isActive: boolean }>`
    height: 100%;
    background-color: ${COLORS.purple};
    padding-bottom: 8px;
    cursor: pointer;

    ${({ $isActive }) => $isActive && 'border-radius: 50% 50% 0 0'};
  `,
  ImageWrapper: styled.div<{ $isActive: boolean }>`
    padding: 8px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent;
    transition: margin-top 0.2s ease-out;

    & > *:only-child {
      width: 24px;
      height: 24px;
    }

    ${({ $isActive }) =>
      $isActive &&
      css`
        margin-top: -4px;
        border-color: ${COLORS.white};
        border-radius: 50%;
      `};
  `,

  LeftRectangle: styled.div<{ $isActive: boolean }>`
    ${Css.rectangle};
    ${({ $isActive }) => $isActive && 'border-radius: 0 12px 0 0'};
  `,
  RightRectangle: styled.div<{ $isActive: boolean }>`
    ${Css.rectangle};
    ${({ $isActive }) => $isActive && 'border-radius: 12px 0 0 0'};
  `,
};
