import { useContext, useEffect } from 'react';

import { useReduxSelector, useUpdateState } from 'services/hooks';
import { VersionsAPI } from 'services/api';

import { WorkContext } from 'screens/work/work.context';

interface IState {
  isLoaded: boolean;
  description?: string;
}

const INIT_STATE: IState = {
  isLoaded: false,
};

export const useWorkDescription = () => {
  const { onCloseModal } = useContext(WorkContext);

  const { state, updateState } = useUpdateState(INIT_STATE);

  const version = useReduxSelector((redux) => redux.app.version);
  const sector = useReduxSelector((redux) => redux.player.work?.sector);
  const upWork = useReduxSelector((redux) => redux.player.work?.careerUp?.job);

  useEffect(() => {
    if (!version) {
      return;
    }

    const request = async () => {
      if (!sector || !upWork) {
        return;
      }

      try {
        const description = await VersionsAPI.sectors.work.job.fetchDescription(
          {
            version,
            sector,
            job: upWork,
          }
        );

        if (!description) {
          updateState({ isLoaded: true });
          return;
        }

        updateState({
          isLoaded: true,
          description,
        });
      } catch {
        updateState({ isLoaded: true });
      }
    };

    request();
  }, [sector, upWork, updateState, version]);

  return { ...state, upWork, onCloseModal };
};
