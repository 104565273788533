import { Theme } from 'themes';

import { ButtonOld } from 'components/button';
import { Loader } from 'components/loader';
import { PriceInformation } from 'components/price-information';

import { FIND_JOB_STRINGS } from '../../find-job.strings';

import { CompanyCard, Modal } from './components';
import { useCompany } from './company.state';
import { ICompanyProps } from './company.typing';

const { CHOOSE_ANOTHER_BUTTON, CHOOSE_ANOTHER_TITLE, TITLE } =
  FIND_JOB_STRINGS.JOB_OFFER;

export const Company = (props: ICompanyProps) => {
  const { onReject, sector, job } = props;

  const {
    modal,
    isLoading,
    changeCompanyMoney,
    activeCompany,
    cycles,
    changeCompanyEnergy = 0,
    onClickTryOther,
    onCloseModal,
    onSetModal,
    onChangeActiveCompanyIndex,
  } = useCompany({ sector, job });

  if (isLoading) {
    return <Loader />;
  }

  if (!activeCompany) {
    return <p>{"Couldn't find the company"}</p>;
  }

  const ModalConditional = modal && (
    <Modal
      modal={modal}
      job={job}
      company={activeCompany.name}
      tryOtherPrice={{ energy: changeCompanyEnergy, money: changeCompanyMoney }}
      onCloseModal={onCloseModal}
      onChangeActiveCompanyIndex={onChangeActiveCompanyIndex}
    />
  );

  return (
    <>
      <Theme.Wrapper $itemGap={{ default: '40px' }}>
        <Theme.Wrapper $itemGap={{ default: '16px', laptop: '24px' }}>
          <Theme.Title $textAlign="center">{TITLE}</Theme.Title>

          <CompanyCard
            sector={sector}
            job={job}
            cycles={cycles}
            companyName={activeCompany.name}
            companyBonus={activeCompany.bonus}
            valuesInfo={activeCompany.values}
            onSetModal={onSetModal}
            onReject={onReject}
          />
        </Theme.Wrapper>

        <Theme.Wrapper $itemGap={{ default: '16px' }}>
          <Theme.Paragraph $textAlign="center">
            {CHOOSE_ANOTHER_TITLE}
          </Theme.Paragraph>

          <ButtonOld
            onClick={onClickTryOther}
            title={CHOOSE_ANOTHER_BUTTON}
            styleTheme="cancelModal"
          >
            <PriceInformation
              energy={changeCompanyEnergy}
              money={changeCompanyMoney}
            />
          </ButtonOld>
        </Theme.Wrapper>
      </Theme.Wrapper>

      {ModalConditional}
    </>
  );
};
