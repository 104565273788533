import { spacing, Theme } from 'themes';

import { WORK_STRINGS } from 'screens/work/work.strings';

import { JobPromotionItem } from './job-promotion-item';
import { useJobPromotion } from './job-promotion.state';
import { JobPromotionStyles as Styles } from './job-promotion.styles';

const { TITLE, SUBTITLE, BUTTON } = WORK_STRINGS.JOB_PROMOTION;

export const JobPromotion = () => {
  const {
    nextLevels,
    selectedJob,
    isLoading,
    confirmJob,
    setWorkData,
    onOpenModal,
  } = useJobPromotion();

  return (
    <Theme.Flexbox $isColumn $justifyContent="space-between">
      <Theme.Wrapper $itemGap={{ default: spacing(3) }} $textAlign="center">
        <Theme.Title>{TITLE}</Theme.Title>

        <Theme.Wrapper $itemGap={{ default: spacing(3) }}>
          <Theme.Paragraph>{SUBTITLE}</Theme.Paragraph>
          <Styles.CenteredContainer>
            {nextLevels?.map((job) => (
              <JobPromotionItem
                key={job}
                job={job}
                selectJob={selectedJob}
                onSelectWork={setWorkData}
                onOpenModal={onOpenModal}
              />
            ))}
          </Styles.CenteredContainer>
        </Theme.Wrapper>
      </Theme.Wrapper>

      <Styles.Button
        title={BUTTON}
        isDisabled={!selectedJob || isLoading}
        onClick={confirmJob}
      />
    </Theme.Flexbox>
  );
};
