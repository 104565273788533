import { FC } from 'react';
import { Theme } from 'themes';

import { TImageNames } from 'components/image';
import { ImageBox } from 'components/image-box';

import { combineWithDot } from 'services/utils';

export interface ICostImageProps {
  image: TImageNames;
  value: number;
}

export const CostImage: FC<ICostImageProps> = (props) => {
  const { image, value } = props;

  return (
    <ImageBox
      elementTitle={<Theme.Text>{combineWithDot(value)}</Theme.Text>}
      sizeImage="16px"
      titlePosition="right"
      imageName={image}
      itemGap="0"
    />
  );
};
