import { useReduxSelector, useTotalSalary } from 'services/hooks';

import { WorkModal } from 'screens/work/work.typings';

export interface IJobPromotionItemProps {
  job: string;

  onSelectWork(job: string): void;
  onOpenModal: (modal: WorkModal) => void;

  selectJob?: string;
}

export const useJobPromotionItem = (props: IJobPromotionItemProps) => {
  const { onOpenModal, job } = props;

  const sector = useReduxSelector((redux) => redux.player.work?.sector);
  const company = useReduxSelector((redux) => redux.player.work?.company);

  const { isLoading, totalSalary } = useTotalSalary({
    sector,
    job,
    companyBonus: company?.bonus,
  });

  const onClickIcon = () => onOpenModal(WorkModal.JobDescription);

  return { totalSalary, isLoading, onClickIcon };
};
