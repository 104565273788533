import { useReduxSelector } from './redux';

export const useShowCareerCentreTutorial = () => {
  const isMainTutorialsCompleted = useReduxSelector(
    (redux) => redux.player.tutorials?.main
  );
  const isCareerCentreTutorialCompleted = useReduxSelector(
    (redux) => redux.player.tutorials?.careerCentre
  );
  const job = useReduxSelector((redux) => redux.player.work?.work);

  return isMainTutorialsCompleted && !isCareerCentreTutorialCompleted && !job;
};
