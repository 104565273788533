import { ChangeEventHandler, memo } from 'react';
import styled from 'styled-components';

import { Field, H2 } from 'components/ui';

interface IProps {
  name: string;
  isValidName: boolean;
  onChangeName: ChangeEventHandler<HTMLInputElement>;
}

const Styles = {
  Root: styled.div`
    padding-top: 32px;
  `,
  Field: styled(Field)`
    margin-top: 16px;
  `,
};

export const NameChanger = memo((props: IProps) => {
  const { name, isValidName, onChangeName } = props;

  return (
    <Styles.Root>
      <H2 $typography="RalewayBold16">Name Your Character</H2>
      <Styles.Field
        isBordered
        rootWidth="100%"
        isValid={isValidName}
        inputProps={{
          type: 'text',
          placeholder: 'Name',
          value: name,
          onChange: onChangeName,
        }}
      />
    </Styles.Root>
  );
});
