import { arrayEqual } from 'services/utils';
import { useReduxSelector } from 'services/hooks';
import { Theme } from 'themes';

import { ProgressList } from './ui';

interface IProps {
  isPreventMaxHeight?: boolean;
}

export const CareerProgressList = (props: IProps) => {
  const { isPreventMaxHeight } = props;

  const career = useReduxSelector(
    (redux) => redux.player.work?.career,
    arrayEqual
  );

  const careerList = career?.map((text, index) => (
    <Theme.Paragraph key={index}>{text.name}</Theme.Paragraph>
  ));

  return (
    <ProgressList
      title="Career"
      isPreventMaxHeight={isPreventMaxHeight}
      items={careerList}
    />
  );
};
