export const Z_INDEXES = {
  MODAL: 95,
  NEGATIVE_BALANCE_MODAL: 200,
  MODAL_BACKGROUND: 90,

  TOOLTIP: 20,

  Z10: 10,
  Z11: 11,
  Z12: 12,
};
