import { checkCanBuy } from '../utils';
import { useReduxSelector } from './redux';

export const useCanBuy = (price?: IPrice) => {
  const minimalMoney = useReduxSelector((redux) => redux.app.minimalMoney);
  const energy = useReduxSelector((redux) => redux.player.main.energy);
  const money = useReduxSelector((redux) => redux.player.main.money);

  if (!price) {
    return true;
  }

  return checkCanBuy({
    maxLoan: minimalMoney,
    playerEnergy: energy,
    playerMoney: money,
    price,
  });
};
