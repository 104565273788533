import { IEducationLevel } from '@avid/common';

import { useEducationInfo } from 'services/hooks';
import { Theme } from 'themes';

interface Iprops {
  education: IEducationLevel;
}

export const EducationProgressItem = (props: Iprops) => {
  const { level, sector } = props.education;

  const { fetchEducationAchievement } = useEducationInfo();

  const achievement = fetchEducationAchievement({ level, sector });

  return (
    <Theme.Flexbox $alignItem="center" $itemGap="8px">
      <Theme.Paragraph>{level}</Theme.Paragraph>
      <Theme.Paragraph $fontSize="px12" $color="green">
        {achievement}
      </Theme.Paragraph>
    </Theme.Flexbox>
  );
};
