import { FC } from 'react';
import styled from 'styled-components';
import { ITravel } from '@avid/common';

import { Theme } from 'themes';
import { Image, TImageNames } from 'components/image';

import { PossessionSector } from './possession-sector';

interface ITravelPossessionProps {
  title: string;
  levelIcon: TImageNames;
  travels?: ITravel[];
}

const Wrapper = styled(Theme.Flexbox)`
  > * {
    width: 24px;
    height: 24px;
    flex: none;
  }
`;

export const TravelPossession: FC<ITravelPossessionProps> = (props) => {
  const { travels = [], levelIcon, title } = props;

  return (
    <PossessionSector
      title={title}
      levelIcon={levelIcon}
      buyPossession={travels.length}
    >
      <Wrapper $itemGap="16px">
        {travels.map(({ image }, index) => (
          <Image size="24px" type={image as TImageNames} key={index} />
        ))}
      </Wrapper>
      <Theme.Flexbox $itemGap="16px">
        {travels.map(({ country }, index) => (
          <Theme.Paragraph key={index}>{country}</Theme.Paragraph>
        ))}
      </Theme.Flexbox>
    </PossessionSector>
  );
};
