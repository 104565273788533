import { useCallback, useState } from 'react';

type TFunction = (...args: any[]) => Promise<any>;

export const useRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendRequest = useCallback(async (callback: () => Promise<void>) => {
    try {
      setIsError(false);
      setIsLoading(true);
      await callback();
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const wrappedRequest = useCallback(
    <F extends TFunction>(request: F) => (...args: Parameters<F>) =>
      sendRequest(() => request(...args)),
    [sendRequest]
  );

  const callbackWrappedRequest = useCallback(
    <F extends TFunction>(request: F) => (...args: Parameters<F>) => () =>
      sendRequest(() => request(...args)),
    [sendRequest]
  );

  const callbackRequest = useCallback(
    (callback: () => Promise<void>) => () => sendRequest(callback),
    [sendRequest]
  );

  return {
    isError,
    isLoading,
    sendRequest,
    wrappedRequest,
    callbackRequest,
    callbackWrappedRequest,
  };
};
