import { db } from '../../firebase';

export const LOGS_LIMIT = 12;

export const logsCollectionRef = (gameCode: string) =>
  db.collection(`games/${gameCode}/logs`);

export const playerLogsQuery = (gameCode: string, userEmail: string) =>
  logsCollectionRef(gameCode)
    .where('playerEmail', '==', userEmail)
    .orderBy('created', 'desc')
    .limit(LOGS_LIMIT);
