import { memo } from 'react';

import { TImageNames } from 'components/image';
import { BASIC_ICONS } from 'constants/character';

import { AvatarIcon } from '../avatar-icon';

import { AvatarsListStyles as Styles } from './avatars-list.styles';
import { H2 } from 'components/ui';

interface IProps {
  onSetAvatar: (icon: TImageNames) => void;
  selectedAvatar?: TImageNames;
}

export const AvatarsList = memo((props: IProps) => {
  const { selectedAvatar, onSetAvatar } = props;

  const RenderIcons = BASIC_ICONS.map((icon) => (
    <AvatarIcon
      key={icon}
      icon={icon}
      isSelect={selectedAvatar === icon}
      selectIcon={onSetAvatar}
    />
  ));

  return (
    <Styles.Root>
      <H2 $typography="RalewayBold16">Select Your Character</H2>
      <Styles.ListWrapper>{RenderIcons}</Styles.ListWrapper>
    </Styles.Root>
  );
});
