import styled from 'styled-components';

import { FONT_FAMILIES, MEDIA, spacing, Theme } from 'themes';

export const SkillInfoPanelStyles = {
  Root: styled.div`
    font-family: ${FONT_FAMILIES.bold};
    display: grid;
    grid-template-columns: 60% auto;
    gap: ${spacing(2)};
    align-items: center;
  `,
  SkillName: styled(Theme.Paragraph)`
    text-align: left;
    word-break: break-word;
  `,
  ProgressContainer: styled.div`
    display: grid;
    align-items: center;
    gap: ${spacing(0.5)};

    ${MEDIA.LAPTOP} {
      grid-template-columns: 1fr ${spacing(13)};
      gap: ${spacing(3)};
    }
  `,
  ProgressBarContainer: styled.div`
    min-width: 100px;

    ${MEDIA.LAPTOP} {
      order: 1;
    }
  `,
  ProgressParagraph: styled(Theme.Paragraph)`
    ${MEDIA.LAPTOP} {
      order: 2;
      text-align: left;
    }
  `,
};
