import { FC } from 'react';

import { ExpandedImage, TImageNames } from 'components/image';

import { PossessionsRowBase } from './possessions-row-base';

interface IProps {
  title: string;
  images: TImageNames[];
}

export const PossessionsRow: FC<IProps> = (props) => {
  const { images, title } = props;

  if (images.length < 1) {
    return null;
  }

  const RenderImages = images.map((image, index) => (
    <ExpandedImage key={index} type={image} />
  ));

  return (
    <>
      <PossessionsRowBase title={`${images.length} ${title}`}>
        {RenderImages}
      </PossessionsRowBase>
    </>
  );
};
