import { FC, ReactElement } from 'react';
import { IAbility } from '@avid/common';

import { Image, TImageNames } from 'components/image';
import { Theme } from 'themes';

import { TForwardSide } from '../life-card.utils';
import { LIFE_CARD_STRING } from '../life-card.const';

import { BonusList } from './bonus-list';
interface IForwardSideCardProps {
  description: string;
  abilities: IAbility[];
  bonuses: string[];
  forwardSide: TForwardSide;
}

const { DISAGREE_CHOOSE_CARD } = LIFE_CARD_STRING;

const SideImage: FC<{ image: TImageNames }> = (props) => (
  <Image width="100px" height="150px" type={props.image} />
);

export const ForwardSideCard = (props: IForwardSideCardProps) => {
  const { abilities, bonuses, forwardSide, description } = props;

  const Description = <Theme.Paragraph>{description}</Theme.Paragraph>;

  let ForwardSideContent: ReactElement | null;

  switch (forwardSide) {
    case 'accept': {
      ForwardSideContent = (
        <>
          <SideImage image="agreeCard" />
          {Description}
          <BonusList abilities={abilities} bonuses={bonuses} />
        </>
      );
      break;
    }

    case 'no-choose': {
      ForwardSideContent = (
        <>
          {Description}
          <BonusList abilities={abilities} bonuses={bonuses} />
        </>
      );
      break;
    }

    case 'decline': {
      ForwardSideContent = (
        <>
          <Theme.Title>{DISAGREE_CHOOSE_CARD}</Theme.Title>
          <SideImage image="disagreeCard" />
        </>
      );
      break;
    }

    default: {
      ForwardSideContent = null;
      break;
    }
  }

  return <>{ForwardSideContent}</>;
};
