import styled from 'styled-components';

import { ButtonOld } from 'components/button';
import { Theme, spacing, Css } from 'themes';

export const JobPromotionStyles = {
  CenteredContainer: styled(Theme.Wrapper)`
    ${Css.responsiveCenterWide};
  `,
  Button: styled(ButtonOld)`
    margin-top: ${spacing(2)};
  `,
};
