import { CompanyModal } from '../company.typing';

import { CongratsModal } from './congrats-modal';
import { OtherCompanyModal } from './other-company-modal';

interface IProps {
  modal: CompanyModal;
  tryOtherPrice: IPrice;

  onChangeActiveCompanyIndex: () => void;
  onCloseModal: () => void;

  job?: string;
  company?: string;
}

export const Modal = (props: IProps) => {
  const {
    modal,
    job,
    company,
    tryOtherPrice,

    onChangeActiveCompanyIndex,
    onCloseModal,
  } = props;

  const Map = {
    [CompanyModal.OtherCompany]: job ? (
      <OtherCompanyModal
        job={job}
        energy={tryOtherPrice.energy}
        money={tryOtherPrice.money}
        onClose={onCloseModal}
        onChangeActiveCompanyIndex={onChangeActiveCompanyIndex}
      />
    ) : null,

    [CompanyModal.Congrats]:
      job && company ? <CongratsModal job={job} company={company} /> : null,
  };

  return Map[modal];
};
