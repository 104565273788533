import { ISkillInfo, ISkill, IAbility } from '@avid/common';

import { useReduxSelector } from 'services/hooks';
import { recordValue } from 'services/utils';
import { transformSkillInfoToSkill } from 'shared';

type TAchievementFormat = 'defaultFormat' | 'pass-test';
interface IGetAchievement {
  level: string;
  sector?: string;
  format?: TAchievementFormat;
}

export type TLevel = number | undefined;

export const useEducationInfo = () => {
  const nowAbilities = useReduxSelector((redux) => redux.player.skills);
  const educationLevels = useReduxSelector(
    (redux) => redux.app.educationLevels
  );

  const fetchEducationAchievement = ({
    level,
    sector,
    format = 'defaultFormat',
  }: IGetAchievement) => {
    if (!level) {
      return;
    }

    const education = recordValue(educationLevels, level);
    const achievement = education?.achievement;

    if (!education?.isSector) {
      return achievement;
    }

    return format === 'defaultFormat'
      ? `${achievement} - ${sector}`
      : `${achievement} in ${sector}`;
  };

  const transformSkillInfosToAbilities = (
    skillInfos: ISkillInfo[]
  ): IAbility[] =>
    skillInfos.map<IAbility>((skill) => {
      const { name, type } = skill;
      const data = nowAbilities?.find((ability) => ability.name === name);
      const ability: IAbility = data ?? {
        level: 0,
        name,
        type,
      };
      return ability;
    });

  const transformSkillInfosToSkills = (skillInfos: ISkillInfo[]): ISkill[] =>
    skillInfos.map<ISkill>((skill) =>
      transformSkillInfoToSkill(skill, nowAbilities)
    );

  return {
    fetchEducationAchievement,
    transformSkillInfosToSkills,
    transformSkillInfosToAbilities,
  };
};
