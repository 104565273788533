import styled from 'styled-components';

import { Grid, P } from 'components/ui';
import { ExpandedImage } from 'components/image';
import { COLORS, Theme, typographyCss } from 'themes';

export const GameReviewStyles = {
  Root: styled(Theme.PageContainer)`
    padding: 40px;
  `,
  GridWrapper: styled(Grid)`
    text-align: center;
  `,
  Image: styled(ExpandedImage)`
    margin-top: 16px;
  `,
  Description: styled(P)`
    margin-top: 16px;
    max-width: 270px;
  `,
  Link: styled.a`
    color: ${COLORS.purple};
    ${typographyCss.RalewayBold16};
  `,
};
