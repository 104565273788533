import styled from 'styled-components';

import { ButtonOld } from 'components/button';
import { Theme, MEDIA, spacing } from 'themes';

const HEIGHT = 'calc(100vh - 200px)';

export const CareerInformationStyles = {
  PageContainer: styled(Theme.PageContainer)`
    ${MEDIA.LAPTOP} {
      padding-bottom: ${spacing(1)};
    }
  `,
  HeightContainer: styled(Theme.Flexbox)`
    height: 100%;

    ${MEDIA.LAPTOP} {
      height: ${HEIGHT};
      max-height: ${HEIGHT};
    }
  `,
  InfoContainer: styled(Theme.Flexbox)`
    ${MEDIA.LAPTOP} {
      height: 100%;
    }
  `,
  Button: styled(ButtonOld)`
    margin-top: ${spacing(1)};
  `,
};
