import { PERFORMANCE_ITEMS } from 'constants/character';

import { PerformanceItem } from './performance-item';
import { ReviewSection } from '../../../components/create-character/review-section';

interface IProps {
  performance: string;
  onEdit: () => void;
}

export const ReviewPerformance = (props: IProps) => {
  const { performance, onEdit } = props;

  const performanceData = PERFORMANCE_ITEMS.find(
    (item) => item.title === performance
  );

  if (!performanceData) {
    return null;
  }

  return (
    <ReviewSection title="Performance Character" onEdit={onEdit}>
      <PerformanceItem
        image={performanceData.image}
        title={performanceData.title}
        titleColor={performanceData.color}
        description={performanceData.description}
        bonuses={performanceData.bonuses}
        isActive={true}
      />
    </ReviewSection>
  );
};
