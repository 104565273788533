import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { MEDIA } from 'themes';

interface IBoxProps extends HTMLAttributes<HTMLDivElement>, ISpaceProps {}

const verticalSpaceCss = ($space: string) => css`
  margin-top: -${$space};

  & > div {
    padding-top: ${$space};
  }
`;

const responsiveVerticalSpaceCss = (props: ISpaceProps) => {
  const { $space, $laptopSpace } = props;

  return css`
    ${$space && verticalSpaceCss($space)};

    ${$laptopSpace &&
    css`
      ${MEDIA.LAPTOP} {
        ${verticalSpaceCss($laptopSpace)}
      }
    `}
  `;
};

const Root = styled.div<ISpaceProps>`
  ${(props) => responsiveVerticalSpaceCss(props)};
`;

export const Box = (props: IBoxProps) => <Root {...props} />;

export const BoxItem = (props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} />
);
