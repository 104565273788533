export enum FindJobPages {
  SearchWork,
  AvailableWork,
  Company,
  UnavailableWork,
}

export enum FindJobModals {
  SelectJob = 'SelectJob',
  ConfirmJobOffer = 'ConfirmJobOffer',
  ChangeCompany = 'ChangeCompany',
}

export interface IFindJobState {
  page: FindJobPages;
  sector?: IItem;
  job?: string;
}
