import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useReduxSelector } from 'services/hooks';
import { callFunctionAction } from 'services/api';
import { PlayerActions } from 'services/redux';

import { WorkPage } from '../../../work.typings';
import { WorkContext } from '../../../work.context';

export const useWorkStartTest = () => {
  const { onOpenPage, onCloseModal } = useContext(WorkContext);

  const money = useReduxSelector(
    (redux) => redux.app.PRICE_WORK_SKILL_SELF_SELECT.money
  );
  const energy = useReduxSelector(
    (redux) => redux.app.PRICE_WORK_SKILL_SELF_SELECT.energy
  );

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onCancel = () => {
    onCloseModal();
  };

  const onConfirm = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await callFunctionAction('@WORK/START_WORK', undefined);
      dispatch(PlayerActions.merge(result.mergePlayer));

      onOpenPage(WorkPage.Test);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      onCloseModal();
    }
  };

  return { money, energy, onConfirm, onCancel };
};
