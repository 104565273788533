import { ICharacterSmart, entriesObject } from '@avid/common';

import { COLORS } from 'themes';
import { SMART_MAX } from 'constants/create-character';

export const getRadarData = (
  smart: ICharacterSmart
): IRadarData<ICharacterSmart>[] => {
  const data = {} as Record<keyof ICharacterSmart, number>;
  entriesObject(smart).forEach(([key, value]) => {
    data[key] = +(+value / SMART_MAX);
  });

  return [
    {
      data,
      meta: { color: COLORS.darkYellow },
    },
  ];
};
