import { FC, ReactNode } from 'react';
import { Theme } from 'themes';

import { ProgressBlockStyles as Styles } from './progress-block.styles';

interface IProgressBlockProps {
  title: string;
  children?: ReactNode;
}

export const ProgressBlock: FC<IProgressBlockProps> = (props) => {
  const { title, children } = props;

  return (
    <Styles.Root>
      <Theme.Paragraph $fontFamily="bold">{title}</Theme.Paragraph>

      <Styles.ListContainer>{children}</Styles.ListContainer>
    </Styles.Root>
  );
};
