import { ICharacterSmart } from '@avid/common';

import { SmartRadar } from 'components/smart-radar';

import { ReviewSection } from '../../../components/create-character/review-section';

interface IProps {
  smart: ICharacterSmart;
  onEdit: () => void;
}

export const ReviewSmarts = (props: IProps) => {
  const { smart, onEdit } = props;

  return (
    <ReviewSection title="Multiple Intelligence or 'Smarts'" onEdit={onEdit}>
      <SmartRadar characterSmart={smart} />
    </ReviewSection>
  );
};
