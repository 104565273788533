import styled from 'styled-components';

import {
  COLORS,
  IAbsolutePositionTemplate,
  TColorNames,
  Z_INDEXES,
} from 'themes';

export interface ITooltipStyled extends IAbsolutePositionTemplate {
  $isHide?: boolean;
  $arrowSize?: string;
  $backgroundColor?: TColorNames;
}

export const TooltipStyles = {
  Wrapper: styled.div<ITooltipStyled>`
    position: absolute;

    z-index: ${Z_INDEXES.TOOLTIP};
    color: ${COLORS.white};
    font-size: 9px;
    padding: 16px;
    border-radius: 16px;

    display: ${(p) => (p.$isHide ? 'none' : 'block')};
    background: ${(p) => COLORS[p.$backgroundColor ?? 'purple']};

    ${(p) => p.$top && `top: ${p.$top};`}
    ${(p) => p.$bottom && `bottom: ${p.$bottom};`}
    ${(p) => p.$left && `left: ${p.$left};`}
    ${(p) => p.$right && `right: ${p.$right};`}

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 15px;
      width: 0;
      height: 0;
      border-left: ${(p) => p.$arrowSize ?? '5px'} solid transparent;
      border-right: ${(p) => p.$arrowSize ?? '5px'} solid transparent;
      border-top: ${(p) => p.$arrowSize ?? '5px'} solid
        ${(p) => COLORS[p.$backgroundColor ?? 'purple']};
    }
  `,
};
