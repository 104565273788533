import { createGlobalStyle } from 'styled-components';

import 'resets.css/resets.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'react-svg-radar-chart/build/css/index.css';

import { COLORS } from './colors';
import { FONT_SIZES } from './font-sizes';
import { FONT_FAMILIES } from './global-fonts';
import { MEDIA } from './media';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: ${FONT_FAMILIES.regular};
    font-size: ${FONT_SIZES.regular};
    color: ${COLORS.black};
  }

  body {
    height: calc(var(--vh, 1vh) * 100);
    user-select: none;

    ${MEDIA.LAPTOP} {
      height: 100vh;
    }

    @media print {
      background-color: ${COLORS.backgroundWhite};
    }
  }

  svg,
  img {
    max-width: 100%;
  }

  #root {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;

    & > *:first-child {
      flex: 1;
      height: 100%;
      overflow: auto;
    }

    & > *:only-child {
      height: 100%;
      overflow: auto;
    }
  }

  *,
  *::after,
  *::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    color: inherit;
    font-family: inherit;
  }

  .slick > * {
    min-width: 0;
    min-height: 0;
  }

  .slick-slider {
    width: 100%;
  }
`;
