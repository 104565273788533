import styled from 'styled-components';

import { Button } from 'components/ui';
import { PurpleBackgroundLayout } from 'components/purple-background-layout';
import { Image } from 'components/image';
import { Css, Theme } from 'themes';

const LoginWrapper = styled.div`
  ${Css.Loginwrapper};
`;

const MiddleWrapper = styled.div<{ gap?: string }>`
  width: 100%;
  display: grid;
  justify-items: center;
  align-content: center;

  gap: ${({ gap }) => gap || '8px'};
`;

export const Login = () => {
  const onClick = () =>
    process.env.REACT_APP_PLATFORM &&
    window.location.replace(`${process.env.REACT_APP_PLATFORM}/game`);

  return (
    <PurpleBackgroundLayout>
      <LoginWrapper>
        <MiddleWrapper>
          <Image type="logo" />
          <Theme.Text $textAlign="center" $color="white" $fontFamily="bold">
            Career Design Life Game
          </Theme.Text>
        </MiddleWrapper>

        <div>
          <Button variant="white" onClick={onClick}>
            Sign in to start playing!
          </Button>
        </div>
      </LoginWrapper>
    </PurpleBackgroundLayout>
  );
};
