import styled from 'styled-components';

import { Button } from 'components/ui';
import { COLORS } from 'themes';

export const CreateCharacterStyles = {
  Root: styled.div`
    padding: 16px;
    padding-bottom: 104px;
    background-color: ${COLORS.backgroundWhite};
  `,
  ButtonWrapper: styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 0;
    background-color: ${COLORS.backgroundWhite};
  `,
  Button: styled(Button)`
    margin: 0 auto;
  `,
};
