import { IEducationLevel } from '@avid/common';

import { entriesObject } from 'services/utils';

export const checkPrerequisites = (educations: IEducationLevel[] = []) => (
  prerequisite: IPrerequisites
) => {
  const passed = educations.filter((ed) => ed.level === prerequisite.level);

  return passed.some(
    (education) => education.rating >= (prerequisite.rating ?? 50)
  );
};

export const checkPreviousEducationLevel = (
  educations?: IEducationLevel[],
  prerequisites?: IPrerequisites[]
): IPrerequisitesRules => {
  if (!prerequisites || !educations) {
    return { isLowRating: false };
  }
  return prerequisites.reduce(
    (
      prerequisitesRules: IPrerequisitesRules,
      prerequisite: IPrerequisites
    ): IPrerequisitesRules => {
      educations.forEach((education: IEducationLevel) => {
        if (education.level === prerequisite.level) {
          prerequisitesRules = {
            isLowRating:
              education.rating >= 50 && education.rating < prerequisite.rating!,
            prerequisitesList: [...prerequisites],
          };
        }
      });
      return prerequisitesRules;
    },
    { isLowRating: false }
  );
};

export const getFirstUniversityLevel = (educationLevels: TEducationLevels) => {
  const sortedUniversityLevels = entriesObject(educationLevels)
    .filter(([, levelParams]) => levelParams.isUniversity)
    .sort(
      ([, levelParamsFirst], [, levelParamsSecond]) =>
        levelParamsFirst.order - levelParamsSecond.order
    );

  return sortedUniversityLevels[0][0] || undefined;
};
