import { ButtonOld } from 'components/button';
import { Image } from 'components/image';
import { spacing } from 'themes';

import { STRINGS } from './many-tabs.constants';
import { ManyTabsStyles as Styles } from './many-tabs.styles';

export const ManyTabs = () => {
  const onReload = () => document.location.reload();

  return (
    <Styles.Root $isColumn $itemGap={spacing(3)}>
      <Image type="womanWaiting" width="260px" height="260px" />
      <Styles.Title>{STRINGS.TITLE}</Styles.Title>

      <Styles.Paragraph $fontFamily="medium" $fontSize="px18">
        {STRINGS.INFO}
      </Styles.Paragraph>

      <ButtonOld title={STRINGS.RELOAD_BUTTON} onClick={onReload} />
    </Styles.Root>
  );
};
