import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Theme } from 'themes';

interface IProps {
  title: string;
  children?: ReactNode;
}

const ChildrenWrapper = styled(Theme.Flexbox)`
  padding: 4px;
`;

export const PossessionsRowBase: FC<IProps> = (props) => {
  const { title, children } = props;

  return (
    <>
      <Theme.Text>{title}</Theme.Text>

      <ChildrenWrapper $justifyContent="flex-start" $itemGap="12px">
        {children}
      </ChildrenWrapper>
    </>
  );
};
