import { useCallback } from 'react';
import { MainActions } from 'screens/main';
import { ShopActions } from 'screens/shop';
import { ModsActions, PlayerActions } from 'services/redux';
import { RewardsActions } from 'services/rewards';

import { useReduxDispatch } from './redux';

export const useClearPlayer = () => {
  const { callAction } = useReduxDispatch();

  const reduxClears = [
    callAction(MainActions.clear),
    callAction(PlayerActions.clear),
    callAction(RewardsActions.clearUserRewards),
    callAction(ShopActions.clear),
    callAction(ModsActions.clear),
  ];

  const clearData = useCallback(
    () => reduxClears.forEach((clear) => clear()),
    []
  );

  return clearData;
};
