import { IQuestion, TGameVersion } from '@avid/common';

import { connectVersion } from './versions.connect';

interface IQuestionsData {
  questions: IQuestion[];
}

interface IQuestionsAmountData {
  questionsAmount: number;
}

interface ILevelsData {
  levels: TEducationLevels;
}

export class EducationAPI {
  private static connectLevels(version: TGameVersion) {
    return connectVersion(version).collection('education').doc('levels');
  }

  public static async fetchLevels(version: TGameVersion) {
    const data = (await this.connectLevels(version).get()).data();
    return (data as ILevelsData | undefined)?.levels;
  }

  public static async fetchQuestions(
    version: TGameVersion,
    levelQuery: string
  ) {
    const data = (
      await this.connectLevels(version)
        .collection('questions')
        .doc(levelQuery)
        .get()
    ).data() as IQuestionsData | undefined;

    return data?.questions;
  }

  public static async fetchQuestionsAmount(
    version: TGameVersion,
    level: string
  ) {
    const data = (
      await this.connectLevels(version)
        .collection('questionsAmount')
        .doc(level)
        .get()
    ).data() as IQuestionsAmountData | undefined;

    return data?.questionsAmount;
  }
}
