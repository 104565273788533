import { Loader } from 'components/loader';
import { FireWork } from 'containers/fire-work';

import { WorkModals, WorkPages } from './components';

import { useWork } from './work.state';
import { WorkContext } from './work.context';

export const Work = () => {
  const state = useWork();

  return (
    <>
      <Loader isLoading={state.isLoading}>
        <WorkContext.Provider value={{ ...state }}>
          <WorkPages />
          {state.modal && <WorkModals />}
        </WorkContext.Provider>

        <FireWork />
      </Loader>
    </>
  );
};
