import {
  TGameVersion,
  PossessionsUtils,
  TPossessionItem,
  PossessionLevel,
} from '@avid/common';

import { useReduxSelector } from 'services/hooks';

import { useShopUtils } from 'screens/shop/shop.utils';
import { SHOP_STRINGS } from 'screens/shop/shop.strings';

const { getVersionPossessionLevelLabel } = PossessionsUtils;
const { POSSESSION_BUY } = SHOP_STRINGS;

interface IGetSubtitleParams {
  sector: string;
  level: PossessionLevel;
  version?: TGameVersion;
}

const getSubtitle = (params: IGetSubtitleParams) => {
  const { version, sector, level } = params;

  const displayPossession =
    (version &&
      getVersionPossessionLevelLabel({
        version,
        possession: sector as TPossessionItem,
        level,
      })) ||
    `${level} ${sector}`;

  return POSSESSION_BUY.SUBTITLE(displayPossession);
};

export const useShopModal = () => {
  const { modal, level, sector, icon, energy, money } = useReduxSelector(
    (redux) => ({
      modal: redux.shop.modal,
      sector: redux.shop.sector,
      level: redux.shop.level,
      energy: redux.shop.price.energy,
      money: redux.shop.price.money,
      icon: redux.player.createCharacter.icon,
    })
  );
  const version = useReduxSelector((redux) => redux.app.version);

  const { moveToStartPage } = useShopUtils();

  const subtitle = level ? getSubtitle({ version, level, sector }) : '';

  return {
    modal,
    level,
    sector,
    subtitle,
    icon,
    energy,
    money,
    version,
    moveToStartPage,
  };
};
