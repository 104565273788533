import {
  IAbility,
  IAuthInfo,
  ICharacter,
  IPlayer,
  IPlayerGauges,
  IPlayerMain,
  IPlayerWork,
  IPlayerPossessions,
} from '@avid/common';

import { action } from 'services/utils';
import { IApplyPlayerBonus } from 'typings/mods';

interface IGetGaugePayload {
  gaugePart: [keyof IPlayerGauges, number];
  mood: number;
}

interface ITickGaugesPayload {
  main: Pick<IPlayerMain, 'mood' | 'money'> & { energy?: number };
  gauges: IPlayerGauges;
  gaugeApplies: number;
}

export const PLAYER_ACTIONS = {
  // Common
  SET: '@PLAYER/SET',
  UPDATE: '@PLAYER/UPDATE',
  CLEAR: '@PLAYER/CLEAR',
  MERGE: '@PLAYER/MERGE',
  UPDATE_ABILITIES: '@PLAYER/UPDATE_ABILITIES',
  APPLY_BONUS: '@PLAYER/APPLY_BONUS',

  // Auth
  SET_AUTH_INFO: '@PLAYER/SET_AUTH_INFO',
  UPDATE_AUTH: '@PLAYER/UPDATE_AUTH',

  // Character
  UPDATE_CHARACTER: '@PLAYER/UPDATE_CHARACTER',

  // Main
  UPDATE_MAIN: '@PLAYER/UPDATE_MAIN',

  // Possessions
  UPDATE_POSSESSION: '@PLAYER/UPDATE_POSSESSION',

  // Work
  SET_WORK: '@PLAYER/SET_WORK',
  UPDATE_WORK: '@PLAYER/UPDATE_WORK',

  // Icons
  UPDATE_ICON: '@PLAYER/UPDATE_ICON',
  BUY_ICON: '@PLAYER/BUY_ICON',

  // Rewards
  SET_REWARDS: '@PLAYER/SET_REWARDS',

  //
  ACCEPT_REFERRALS: 'PLAYER/ACCEPT_REFERRALS',

  // Mods - Gauges
  APPLY_GAUGES: '@PLAYER/APPLY_GAUGES',
  GET_GAUGE: '@PLAYER/GET_GAUGE',
} as const;

export const PlayerActions = {
  set: (payload: IPlayer) => action(PLAYER_ACTIONS.SET, payload),

  update: (payload: Partial<IPlayer>) => action(PLAYER_ACTIONS.UPDATE, payload),

  clear: () => action(PLAYER_ACTIONS.CLEAR),

  merge: (payload: TDeepPartial<IPlayer>) =>
    action(PLAYER_ACTIONS.MERGE, payload),

  updateAbilities: (skills: IAbility[], lastLearnAbilities: IAbility[]) =>
    action(PLAYER_ACTIONS.UPDATE_ABILITIES, { skills, lastLearnAbilities }),

  applyBonus: (bonus: IApplyPlayerBonus) =>
    action(PLAYER_ACTIONS.APPLY_BONUS, bonus),

  // Auth
  setAuth: (payload: IAuthInfo) =>
    action(PLAYER_ACTIONS.SET_AUTH_INFO, payload),

  updateAuth: (payload: Partial<IAuthInfo>) =>
    action(PLAYER_ACTIONS.UPDATE_AUTH, payload),

  // Create Character

  // Character
  updateCharacter: (payload: Partial<ICharacter>) =>
    action(PLAYER_ACTIONS.UPDATE_CHARACTER, payload),

  // Main
  updateMain: (payload: Partial<IPlayerMain>) =>
    action(PLAYER_ACTIONS.UPDATE_MAIN, payload),

  // Possession
  updatePossession: (payload: Partial<IPlayerPossessions>) =>
    action(PLAYER_ACTIONS.UPDATE_POSSESSION, payload),

  // Work
  updateWork: (payload: Partial<IPlayerWork>) =>
    action(PLAYER_ACTIONS.UPDATE_WORK, payload),

  setWork: (payload: IPlayerWork) => action(PLAYER_ACTIONS.SET_WORK, payload),

  //
  updateIcons: (icon: string, icons: string[]) =>
    action(PLAYER_ACTIONS.UPDATE_ICON, { icon, icons }),

  buyIcon: (
    icon: string,
    icons: string[],
    main: Pick<IPlayerMain, 'energy' | 'money'>
  ) => action(PLAYER_ACTIONS.BUY_ICON, { icon, icons, main }),

  // Rewards
  setRewards: (rewards: string[]) =>
    action(PLAYER_ACTIONS.SET_REWARDS, rewards),

  //
  acceptReferrals: (newConfirmedReferrals: number, newMoney: number) =>
    action(PLAYER_ACTIONS.ACCEPT_REFERRALS, {
      newConfirmedReferrals,
      newMoney,
    }),

  // Mods - Gauges
  applyGauges: (payload: ITickGaugesPayload) =>
    action(PLAYER_ACTIONS.APPLY_GAUGES, payload),
  getGauge: (payload: IGetGaugePayload) =>
    action(PLAYER_ACTIONS.GET_GAUGE, payload),
};

export type TPlayerActions = ReturnType<TCombiner<typeof PlayerActions>>;
