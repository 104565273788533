import { VALUES_TOTAL } from 'constants/create-character';
import { useState } from 'react';

export interface IValuesStepProps {
  initValues: string[];
  onNext: (values: string[]) => void;
}

export const useValuesStep = (props: IValuesStepProps) => {
  const { initValues, onNext } = props;

  const [values, setValues] = useState(initValues);

  const isNextAvailable = values.length === VALUES_TOTAL;

  const onSelect = (value: string) =>
    setValues((prev) => {
      if (prev.indexOf(value) === -1) {
        if (prev.length >= VALUES_TOTAL) {
          return prev;
        }

        return [...prev, value];
      }

      return prev.filter((prevValue) => prevValue !== value);
    });

  const handleNext = () => {
    if (values.length !== VALUES_TOTAL) {
      return;
    }

    onNext(values);
  };

  return { values, isNextAvailable, onSelect, handleNext };
};
