import { useCallback } from 'react';

import { useMod } from './mods';

export const usePushApplyRoundLogs = () => {
  const specialJobs = useMod('specialJobs');
  const marriage = useMod('marriage');

  const pushSpecialJobLog = specialJobs?.hooks.usePushSpecialJobOutcomeLog();
  const pushMarriageLogs = marriage?.hooks.usePushMarriageOutcomeLogs();

  const push = useCallback(() => {
    // 1. Special Job outcome
    pushSpecialJobLog?.();

    // 2. Marriage & Cgildren outomes
    pushMarriageLogs?.();
  }, [pushMarriageLogs, pushSpecialJobLog]);

  return push;
};
