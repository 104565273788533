import { InfoImageModal } from 'components/modal';
import { moveToRouteCallback } from 'services/utils';
import { Theme } from 'themes';
import { ROUTES } from 'constants/routes';

import { FIND_JOB_STRINGS } from '../../../find-job.strings';

interface IProps {
  job: string;
  company: string;
}

const { CONFIRM_JOB_OFFER } = FIND_JOB_STRINGS;

export const CongratsModal = (props: IProps) => {
  const { job, company } = props;

  const onConfirm = moveToRouteCallback(ROUTES.work);

  return (
    <InfoImageModal
      image="getNewWork"
      itemGap={{ default: '20px' }}
      buttonText={CONFIRM_JOB_OFFER.BUTTON}
      onCloseModal={onConfirm}
      title={
        <Theme.Paragraph $fontFamily="bold">
          {CONFIRM_JOB_OFFER.TITLE}
        </Theme.Paragraph>
      }
    >
      <Theme.Paragraph $fontFamily="bold">
        {CONFIRM_JOB_OFFER.INFO(job, company)}
      </Theme.Paragraph>
    </InfoImageModal>
  );
};
