import { TooltipRenderProps } from 'react-joyride';

import { ExpandedImage } from 'components/image';

import { TooltipStyles as Styles } from './tooltip.styles';

interface IProps extends TooltipRenderProps {
  labelClose?: string;
}

export const Tooltip = (props: IProps) => {
  const {
    index,
    step,
    size,
    backProps,
    primaryProps,
    tooltipProps,
    labelClose,
  } = props;

  const RenderBack = index > 0 && (
    <Styles.BackText {...backProps} id="back">
      Back
    </Styles.BackText>
  );

  const nextText = index + 1 === size ? labelClose || 'Close' : 'Next';

  return (
    <Styles.Root {...tooltipProps}>
      <Styles.ContentWrapper>
        <ExpandedImage type="tutorialTooltip" size="32px" />
        <Styles.Content>{step.content}</Styles.Content>
      </Styles.ContentWrapper>

      <Styles.ControlsWrapper>
        {RenderBack}

        <Styles.NextButton {...primaryProps} id="next">
          {nextText}
        </Styles.NextButton>
      </Styles.ControlsWrapper>
    </Styles.Root>
  );
};
