import { spacing, Theme } from 'themes';

import { CareerCenterButton } from 'components/career-center-button';
import { moveToRouteCallback } from 'services/utils';
import { ROUTES } from 'constants/routes';

import { FindJobButton } from '../../find-job.styles';
import { FIND_JOB_STRINGS } from '../../find-job.strings';

import { useSearchWork } from './search-work.state';
import { SearchWorkTutorial, SectorSelector, Title } from './components';
import { SearchWorkStyles as Styles } from './search-work.styles';
import { SearchWorkStatus } from './search-work.typing';

interface IProps {
  onSetSector: (sectorItem: IItem) => void;
  onFind: () => void;
  sector?: IItem;
}

export const SearchWork = (props: IProps) => {
  const { sector, onSetSector, onFind } = props;

  const { status, sectorInformation } = useSearchWork();

  const { text, imageType } = sectorInformation;

  return (
    <Theme.Flexbox
      $isColumn
      $justifyContent="space-between"
      $alignItem="center"
      id="search_work-root"
    >
      <Styles.ContentContainer
        $isColumn
        $justifyContent="center"
        $textAlign="center"
        $itemGap={spacing(4)}
      >
        <Theme.Wrapper $itemGap={{ default: spacing(2) }}>
          <Title text={text} />
          <Styles.Image type={imageType} />
          <SectorSelector
            selectedSector={sector}
            onSelectSector={onSetSector}
          />
        </Theme.Wrapper>

        <Styles.ButtonContainer>
          <FindJobButton
            title={FIND_JOB_STRINGS.FIND_JOB}
            onClick={onFind}
            isDisabled={!sector?.value}
          />
          {status === SearchWorkStatus.Employed && (
            <FindJobButton
              title={'Go back'}
              onClick={moveToRouteCallback(ROUTES.work)}
            />
          )}
        </Styles.ButtonContainer>
      </Styles.ContentContainer>

      <CareerCenterButton id="search_work-career_centre" />

      <SearchWorkTutorial />
    </Theme.Flexbox>
  );
};
