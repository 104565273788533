import { useRef, useState } from 'react';
import { IGameMods, IPlayer, TGameModName } from '@avid/common';

import { useReduxSelector } from 'services/hooks';
import { keyofObject, objectEqual } from 'services/utils';
import { ModsService } from 'services/mods';

import { LiveExpensesProxy } from './components';

const getMods = () => {
  const [specialJobsMod, marriageMod] = ModsService.get([
    'specialJobs',
    'marriage',
  ]);

  return {
    MODES_CHECK: ['marriage', 'specialJobs'] as const,

    checkRoundModCb:
      (player: IPlayer, currentRound: number) =>
      (modName: TGameModName, choiceRound?: number) => {
        switch (modName) {
          case 'specialJobs': {
            return specialJobsMod?.utils.checkDisplayIteration(
              player,
              currentRound,
              choiceRound
            );
          }

          case 'marriage': {
            return marriageMod?.utils.checkDisplayIteration(
              player,
              currentRound,
              choiceRound
            );
          }

          default: {
            return true;
          }
        }
      },

    JobOfferIteration: specialJobsMod?.components.JobOfferIteration,
    MarriageIteration: marriageMod?.components.MarriageIteration,
  };
};

const getQueue = (
  gameMods: IGameMods,
  player: IPlayer,
  currentRound: number
) => {
  const order: (keyof IGameMods)[] = [];

  const { MODES_CHECK, checkRoundModCb } = getMods();

  const checkRoundMod = checkRoundModCb(player, currentRound);
  const gameModNames = keyofObject(gameMods);

  for (const mod of MODES_CHECK) {
    if (
      gameModNames.includes(mod) &&
      gameMods[mod] &&
      checkRoundMod(mod, gameMods[mod]?.round)
    ) {
      order.push(mod);
    }
  }

  return order;
};

const LifeIterationWithMods = () => {
  const gameMods = useReduxSelector((redux) => redux.app.gameMods, objectEqual);
  const player = useReduxSelector((redux) => redux.player, objectEqual);
  const roundNumber = useReduxSelector((redux) => redux.game.round.number);

  const [screenIndex, setScreenIndex] = useState(0);

  const queue = useRef(getQueue(gameMods || {}, player, roundNumber));

  const screen = queue.current[screenIndex];

  const moveNext = () => setScreenIndex((prev) => prev + 1);

  const { JobOfferIteration, MarriageIteration } = getMods();

  switch (screen) {
    case 'specialJobs': {
      return JobOfferIteration ? (
        <JobOfferIteration moveNext={moveNext} />
      ) : null;
    }

    case 'marriage': {
      return MarriageIteration ? (
        <MarriageIteration moveNext={moveNext} />
      ) : null;
    }

    default: {
      return <LiveExpensesProxy />;
    }
  }
};

export const LifeIteration = () => {
  const isSpecialJobs = useReduxSelector((redux) =>
    Boolean(redux.app.gameMods?.specialJobs)
  );

  if (isSpecialJobs) {
    return <LifeIterationWithMods />;
  }

  return <LiveExpensesProxy />;
};
