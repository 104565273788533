import { useReduxSelector } from './redux';

export const useShowCourseTutorial = () => {
  const isCourseTutorialCompleted = useReduxSelector(
    (redux) => redux.player.tutorials?.course
  );
  const isMainTutorialsCompleted = useReduxSelector(
    (redux) => redux.player.tutorials?.main
  );

  const isShow = isMainTutorialsCompleted && !isCourseTutorialCompleted;

  return isShow;
};
