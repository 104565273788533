import {
  CreateCharacterLayout,
  CreateCharacterToolWrapper,
} from 'components/create-character';
import { ANCHORS_TOTAL } from 'constants/create-character';

import { AnchorsSelector } from '../anchors-selector';
import { CreateCharacterTrack } from '../create-character-track';

import { IAnchorsStepProps, useAnchorsStep } from './anchors-step.state';

export const AnchorsStep = (props: IAnchorsStepProps) => {
  const { anchors, isNextAvailable, selectAnchor, handleNext } = useAnchorsStep(
    props
  );

  return (
    <CreateCharacterLayout
      title="Career Anchors"
      description="What a person prioritizes or prefers in a job, company or career."
      image="progress3"
      isNextAvailable={isNextAvailable}
      onNext={handleNext}
    >
      <CreateCharacterTrack
        usedPoints={anchors.length}
        maxPoints={ANCHORS_TOTAL}
        labelEnd="selected"
      />

      <CreateCharacterToolWrapper>
        <AnchorsSelector
          selectedAnchors={anchors}
          onSelectAnchor={selectAnchor}
        />
      </CreateCharacterToolWrapper>
    </CreateCharacterLayout>
  );
};
