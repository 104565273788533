import { HTMLAttributes, useMemo } from 'react';
import styled, { RuleSet, css } from 'styled-components';

import { getCssOptionalValue } from 'services/utils/theme';
import { MEDIA, overrideMediaCss, typographyCss } from 'themes';

interface ITypographyProps {
  $typography?: keyof typeof typographyCss;
  $color?: string;
}

interface ITypographyElementProps<T>
  extends ITypographyProps,
    HTMLAttributes<T> {}

const StyledH1 = styled.h1<ITypographyProps>`
  ${({ $typography: typography = 'RalewayBold20' }) =>
    typographyCss[typography]};
  ${({ $color: color }) => color && `color: ${color}`};
  text-align: center;
`;

const StyledH2 = styled.h2<ITypographyProps>`
  ${({ $typography: typography = 'RalewayBold20' }) =>
    typographyCss[typography]};
  ${({ $color: color }) => color && `color: ${color}`};
`;

const StyledH3 = styled.h3<ITypographyProps>`
  ${({ $typography: typography = 'RalewayBold20' }) =>
    typographyCss[typography]};
  ${({ $color: color }) => color && `color: ${color}`};
`;

const StyledP = styled.p<ITypographyProps>`
  ${({ $typography: typography = 'regular' }) => typographyCss[typography]};
  ${({ $color: color }) => color && `color: ${color}`};
`;

export const H1 = (props: ITypographyElementProps<HTMLHeadingElement>) => (
  <StyledH1 {...props} />
);

export const H2 = (props: ITypographyElementProps<HTMLHeadingElement>) => (
  <StyledH2 {...props} />
);

export const H3 = (props: ITypographyElementProps<HTMLHeadingElement>) => (
  <StyledH3 {...props} />
);

export const P = (props: ITypographyElementProps<HTMLParagraphElement>) => (
  <StyledP {...props} />
);

type TTextelement = 'h1' | 'h2' | 'h3' | 'h4' | 'span' | 'p' | 'b';

interface ITextCssProps {
  $typography?: RuleSet;
  $color?: string;
  $align?: string;
}

type TMediaProps = Partial<Record<keyof typeof MEDIA, ITextCssProps>>;

interface IStyledProps {
  $typography?: RuleSet;
  $color?: string;
  $align?: string;
  /**
   * ! Supports static objects only
   */
  $media?: TMediaProps;
}

type TProps<T extends TTextelement> = JSX.IntrinsicElements[T] & {
  as?: T;
  typography?: RuleSet;
  color?: string;
  align?: string;
  /**
   * ! Supports static objects only
   */
  media?: TMediaProps;
};

const getTextCssString = (props: ITextCssProps) => css`
  ${props.$typography ? props.$typography : ''}
  ${getCssOptionalValue('color', props.$color)}
  ${getCssOptionalValue('text-align', props.$align)}
`;

export const StyledText = styled.span<{ $cssRules?: RuleSet }>`
  ${({ $cssRules }) => $cssRules};
`;

export const Text = <T extends TTextelement>(props: TProps<T>) => {
  const { as = 'p', media, typography, align, color, ...otherProps } = props;

  const cssRules = useMemo(() => {
    const cssProps: IStyledProps = {
      $media: media,
      $typography: typography,
      $align: align,
      $color: color,
    };

    return css`
      ${getTextCssString(cssProps)};
      ${overrideMediaCss(cssProps, getTextCssString)}
    `;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [align, color, typography]);

  return <StyledText as={as} $cssRules={cssRules} {...otherProps} />;
};
