import { createStore, applyMiddleware } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension';

import { rewardMiddleware } from './middleware/reward-middleware';
import { rootReducer, IReduxStore, TActions } from './root-reducer';

const middleware = [
  thunk as ThunkMiddleware<IReduxStore, TActions>,
  rewardMiddleware,
];

const rootMiddleware =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware);

export const REDUX_STORE = createStore(rootReducer, rootMiddleware);
