import { TGameVersion } from '@avid/common';

import { connectVersion } from './versions.connect';

export class LifeCardsAPI {
  private static connectLifeCards(version: TGameVersion) {
    return connectVersion(version).collection('life-cards').doc('life-cards');
  }

  public static async fetchLifeCards(version: TGameVersion) {
    const data = (await this.connectLifeCards(version).get()).data();
    return (data as { lifeCards: ILifeCard[] } | undefined)?.lifeCards;
  }
}
