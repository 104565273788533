import { useCallback } from 'react';

import { appAuth } from 'services/firebase';

interface IProps {
  onSuccess?: () => void;
  onFail?: () => void;
}

export const useVerifyToken = (props: IProps) => {
  const { onSuccess, onFail } = props;

  const verify = useCallback(
    async (token: string) => {
      try {
        await appAuth.signInWithCustomToken(token);

        onSuccess?.();
      } catch (error) {
        onFail?.();
        console.error(error);
      }
    },
    [onFail, onSuccess]
  );

  return { verify };
};
