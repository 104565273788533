import { ProgressBar } from 'components/progress-bar';
import { Theme } from 'themes';

import {
  TWhiteWrapperShadowVariant,
  WhiteShadowWrapper,
} from './white-shadow-wrapper';

interface IProps {
  title: string;
  items?: JSX.Element[];
  shadowVariant?: TWhiteWrapperShadowVariant;
  isPreventMaxHeight?: boolean;
}

export const ProgressList = (props: IProps) => (
  <WhiteShadowWrapper
    $itemGap={{ default: '16px' }}
    $shadowVariant={props.shadowVariant}
    $isPreventMaxHeight={props.isPreventMaxHeight}
  >
    <Theme.Paragraph $fontFamily="bold">{props.title}</Theme.Paragraph>
    <ProgressBar itemGap="16px" color="pink" roundSize="8px" lineHeight="1.5px">
      {props.items}
    </ProgressBar>
  </WhiteShadowWrapper>
);
