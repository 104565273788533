import styled from 'styled-components';

import { Button, Text } from 'components/ui';
import { COLORS, MEDIA } from 'themes';

export const TooltipStyles = {
  Root: styled.div`
    max-width: 340px;
    border-radius: 10px;
    padding: 12px;
    background-color: ${COLORS.white};
    ${MEDIA.TABLET} {
      max-width: 400px;
    }
  `,
  ContentWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  Content: styled(Text)`
    margin-left: 12px;
  `,
  ControlsWrapper: styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  BackText: styled(Text)`
    cursor: pointer;
    width: 50px;
  `,
  NextButton: styled(Button)`
    margin-left: 8px;
    width: 50px;
    height: 40px;
  `,
};
