import styled from 'styled-components';

import { Checkbox } from 'components/checkbox';
import { Box, BoxItem } from 'components/ui';
import { spacing, MEDIA } from 'themes';

export const ChoseSkillStyles = {
  Root: styled(Box)`
    width: 100%;

    ${MEDIA.LAPTOP} {
      width: 37.5%;
      min-width: 720px;
      max-width: 1200px;
      margin: 0 auto;
    }
  `,
  CheckBox: styled(Checkbox)`
    ${MEDIA.LAPTOP} {
      padding: ${spacing(2)} ${spacing(4)};
    }
  `,
  TitleBoxItem: styled(BoxItem)`
    text-align: center;
  `,
};
