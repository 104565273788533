import { Text } from 'components/ui';
import { BigAvatar } from 'components/big-avatar';
import { TImageNames } from 'components/image';
import { typographyCss } from 'themes';

import { ReviewSection } from '../../../../components/create-character/review-section';

import { ReviewNameAvatarStyles as Styles } from './review-name-avatar.styles';

interface IProps {
  name: string;
  icon: TImageNames;
  onEdit: () => void;
}

export const ReviewNameAvatar = (props: IProps) => {
  const { icon, name, onEdit } = props;

  return (
    <ReviewSection
      title="Review Your Character"
      titleTypography="RalewayBold20"
      onEdit={onEdit}
    >
      <Styles.FlexWrapper>
        <BigAvatar image={icon} size="158px" />

        <Styles.NameText>
          <Text typography={typographyCss.regularMedium}>Name</Text>
          <Styles.NameValueSpan title={name}>{name}</Styles.NameValueSpan>
        </Styles.NameText>
      </Styles.FlexWrapper>
    </ReviewSection>
  );
};
