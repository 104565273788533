export const LIFE_CARD_STRING = {
  DISAGREE_CHOOSE_CARD: 'You chose to give up this opportunity.',

  CARD: {
    TITLE: 'Life card',
    NEXT_BUTTON: 'Next',
    AGREE_BUTTON: 'Yes',
    DISAGREE_BUTTON: 'No',
  },
};

export const LIFE_CARD_ERRORS = { LIMIT_REACHED: 'limit-reached' } as const;
