import { FC } from 'react';

import { Theme } from 'themes';
import { Image } from 'components/image';

import { NextPageButton } from './components';

import { useWelcomePage } from './welcome-page.state';
import { WelcomePageStyles as Styles } from './welcome-page.styles';

export const WelcomePage: FC = () => {
  const { roundsNumber, moveToGame } = useWelcomePage();

  return (
    <>
      <Styles.PageRoot>
        <Styles.PageContent $textAlign="center" $itemGap={{ default: '48px' }}>
          <Image type="welcomeBackgroundButton" />

          <Theme.Wrapper $itemGap={{ default: '16px' }}>
            <Theme.Wrapper
              $fontSize="header"
              $fontFamily="bold"
              $itemGap={{ default: '8px' }}
            >
              <Theme.Paragraph>You’re all set!</Theme.Paragraph>
              <Theme.Paragraph>Your life will begin soon.</Theme.Paragraph>
            </Theme.Wrapper>

            <Theme.Wrapper $itemGap={{ default: '8px' }}>
              <Theme.Paragraph>
                Please wait for the facilitator to start the round.
              </Theme.Paragraph>
              <Theme.Paragraph>
                This game will last {roundsNumber} rounds
              </Theme.Paragraph>
            </Theme.Wrapper>
          </Theme.Wrapper>

          <Styles.ButtonLaptop title="Let's Go!" onClick={moveToGame} />
        </Styles.PageContent>
      </Styles.PageRoot>

      <NextPageButton
        title="Let's Go!"
        onClick={moveToGame}
        isMobileOnly={true}
      />
    </>
  );
};
