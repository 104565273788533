import { FC } from 'react';

import { Image, TImageNames } from 'components/image';
import { ChooseModal, IChooseModalProps } from './choose-modal';

export interface IChooseImageModalProps extends IChooseModalProps {
  image: TImageNames;
}

export const ChooseImageModal: FC<IChooseImageModalProps> = (props) => {
  const { className, image, children, ...parameters } = props;

  return (
    <ChooseModal className={className} {...parameters}>
      <Image type={image} />
      {children}
    </ChooseModal>
  );
};
