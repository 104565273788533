import { ReactElement } from 'react';

import { CareerCenterModal } from 'screens/career-centre/career-center.typing';
import { SkillInformation } from '../skill-information';
import { JobDescriptionModal } from './job-description-modal';

interface IProps {
  sector: string;
  modal: CareerCenterModal;
  isLoadingDescription: boolean;

  onCloseModal: () => void;
  onOpenModal: (modal: CareerCenterModal) => void;

  job?: string;
  description?: string;
  salary?: number;
}

export const CareerCentreModal = (props: IProps) => {
  const {
    sector,
    job,
    modal,
    description,
    salary,
    isLoadingDescription,
    onOpenModal,
    onCloseModal,
  } = props;

  if (!modal || !job) {
    return null;
  }

  const onConfirm = () => onOpenModal(CareerCenterModal.SkillInformation);

  const Modal: Record<CareerCenterModal, ReactElement> = {
    [CareerCenterModal.Description]: (
      <JobDescriptionModal
        isLoadingDescription={isLoadingDescription}
        job={job}
        onCloseModal={onCloseModal}
        onConfirm={onConfirm}
        description={description}
        salary={salary}
      />
    ),

    [CareerCenterModal.SkillInformation]: (
      <SkillInformation sector={sector} job={job} onCloseModal={onCloseModal} />
    ),
  };

  return Modal[modal];
};
