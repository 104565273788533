import {
  IAbility,
  ICharacterSmart,
  IPlayerPossessions,
  IPossessionProperties,
  ISkillInfo,
  TGameVersion,
  TPossessionLevel,
} from '@avid/common';

import {
  getIncrementedAbilities,
  getSkillsInfoByNames,
  getMergedAbilities,
} from 'shared';

import { getPossessionIcon } from '../character';
import { DEFAULT_USER_POSSESSION } from '../redux';

interface IPopPossessionParams {
  item: keyof IPossessionProperties;
  level: TPossessionLevel;
  version: TGameVersion;
  playerPossessions?: Partial<IPlayerPossessions>;
}

interface IGetNewSkillsFromBonus {
  allSkills: ISkillInfo[];
  bonusSkillNames: string[];
  playerSmart: ICharacterSmart;
  maxSkillLevel: number;
  abilities?: IAbility[];
}

export const popPossession = (
  params: IPopPossessionParams
): Partial<IPlayerPossessions> => {
  const { item, level, version, playerPossessions } = params;

  const possession = playerPossessions?.[item] || DEFAULT_USER_POSSESSION[item];
  const image = getPossessionIcon(version, item, level);

  return {
    ...playerPossessions,
    [item]: {
      ...possession,
      [level]: [image, ...possession[level]],
    },
  };
};

export const getNewSkillsFromBonus = (params: IGetNewSkillsFromBonus) => {
  const {
    abilities,
    allSkills,
    bonusSkillNames,
    maxSkillLevel,
    playerSmart,
  } = params;

  const skillInfos = getSkillsInfoByNames(allSkills, bonusSkillNames);
  const incrementedAbilities = getIncrementedAbilities({
    skillInfos,
    characterSmart: playerSmart,
    maxAbilityLevel: maxSkillLevel,
    characterAbilities: abilities,
  });

  return getMergedAbilities(abilities, incrementedAbilities);
};

export const addStatus = (currentStatusArr: string[], newStatus: string) => {
  const isExist = currentStatusArr.includes(newStatus);

  if (isExist) {
    return currentStatusArr;
  }

  return currentStatusArr.concat(newStatus);
};
