import { IGameMods } from '@avid/common';

import { MOD_FOLDER_MAP } from 'constants/mods';

export interface IModsImportMap {
  bonusCodes: typeof import('mods/bonus-codes').default;
  gauges: typeof import('mods/gauges').default;
  gaugesHard: typeof import('mods/gauges-hard').default;
  popupTips: typeof import('mods/popup-tips').default;
  lifeGoals: typeof import('mods/life-goals').default;
  charityHobby: typeof import('mods/charity-hobby').default;
  gameReport: typeof import('mods/game-report').default;
  specialJobs: typeof import('mods/special-jobs').default;
  marriage: typeof import('mods/marriage').default;
  financial: typeof import('mods/financial').default;
  limitedEducation: typeof import('mods/limited-education').default;
  limitedHighestJobs: typeof import('mods/limited-highest-jobs').default;
}

export type TModName = keyof IGameMods;

type TMappedImports<T extends Array<keyof IModsImportMap>> = {
  [P in keyof T]: T[P] extends keyof IModsImportMap
    ? IModsImportMap[T[P]]
    : [T[P]];
};

export class ModsService {
  private static loaded: Partial<IModsImportMap> = {};

  private static async loadOne<T extends TModName>(modName: T) {
    if (!this.loaded[modName]) {
      const modFolder = MOD_FOLDER_MAP[modName];

      const importDefault: IModsImportMap[T] = (
        await import(`mods/${modFolder}`)
      ).default;

      this.loaded[modName] = importDefault;
    }

    return this.loaded[modName] as IModsImportMap[T];
  }

  public static async load<T extends Array<keyof IModsImportMap>>(
    modNames: [...T]
  ) {
    const promises = await Promise.all(
      modNames.map((modName) => this.loadOne(modName))
    );

    return promises;
  }

  public static unloadAll() {
    this.loaded = {};
  }

  public static getOne<T extends TModName>(modName: T) {
    return this.loaded[modName] as IModsImportMap[T] | undefined;
  }

  public static get<T extends Array<keyof IModsImportMap>>(modNames: [...T]) {
    return modNames.map((modName) => this.getOne(modName)) as Partial<
      TMappedImports<T>
    >;
  }
}
