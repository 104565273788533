import { GreetingLogo } from 'screens/greeting';

import { Authenticator } from './components';

export const Auth = () => {
  const code = new URLSearchParams(location.search).get('code');

  if (!code) {
    return <GreetingLogo />;
  }

  return <Authenticator code={code} />;
};
