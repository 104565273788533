import {
  ICheckEnabledCharacteristicParams,
  checkEnabledCharacteristic,
} from 'services/character';

import { IDisplayCompany, IDisplayCompanyParams } from './company-card.typing';

export const toDisplayCompany = (
  params: IDisplayCompanyParams,
  characteristics: Omit<ICheckEnabledCharacteristicParams, 'characteristic'>
): IDisplayCompany => {
  const { positive, negative } = params;

  const positives: ICompanyParameter[] = positive.map(({ title, info }) => {
    const isEnable = checkEnabledCharacteristic({
      ...characteristics,
      characteristic: title,
    });

    return { title, info, isEnable };
  });

  const negatives: ICompanyParameter[] = negative.map(({ title, info }) => {
    const isEnable = checkEnabledCharacteristic({
      ...characteristics,
      characteristic: title,
    });

    return { title, info, isEnable };
  });

  const [positiveMood, negativeMood] = [
    positives.filter((value) => value.isEnable).length,
    negatives.filter((value) => value.isEnable).length,
  ];

  return {
    values: {
      positive: positives,
      negative: negatives,
    },
    mood: {
      positive: positiveMood,
      negative: negativeMood,
    },
  };
};
