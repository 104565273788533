import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { useSelectorContext } from '../selector.context';

import { getItemText } from 'services/utils';

import {
  IThemeSelectorItemParameters,
  SELECTOR_THEME,
} from '../selector.constants';

interface ISelectorItemProps {
  value: IItem;
  className?: string;
  children?: ReactNode;
}

interface ISelectorItemStyled {
  $isSelect: boolean;
  $variant: IThemeSelectorItemParameters;
}

const Item = styled.div<ISelectorItemStyled>`
  ${(props) => props.$isSelect && props.$variant.select}
  ${(props) => props.$variant.default}

  &:hover {
    ${(props) => props.$variant.hover}
  }
`;

export const SelectorItem: FC<ISelectorItemProps> = (props) => {
  const { className, value, children } = props;
  const {
    setValue,
    setOpen,
    theme = 'orange',
    selectValue,
  } = useSelectorContext();
  const themeStyled = SELECTOR_THEME[theme].item;

  const handleClick = () => {
    setValue(value);
    setOpen(false)();
  };

  return (
    <Item
      className={className}
      onClick={handleClick}
      $isSelect={Object.is(selectValue, value)}
      $variant={themeStyled}
    >
      {getItemText(value)}
      {children}
    </Item>
  );
};
