import { FC, useContext, forwardRef, ReactNode } from 'react';

import { SelectorListContext } from 'services/context';
import { typographyCss } from 'themes';

import { SelectorListStyles as Styles } from './selector-list.styles';

interface ISelectorListItemProps {
  value: string;
  className?: string;
  typography?: keyof typeof typographyCss;
  backgroundColorActive?: string;
  backgroundColor?: string;
  children?: ReactNode;
}

interface IProps<T extends string = string> {
  selectedValues: T[];
  onSelect: (value: T) => void;
  children: React.ReactNode;
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
}

export const SelectorListItem: FC<ISelectorListItemProps> = (props) => {
  const {
    value,
    children,
    className,
    backgroundColor,
    backgroundColorActive,
    typography,
  } = props;

  const listContext = useContext(SelectorListContext);

  const isActive = Boolean(listContext?.selectedValues.includes(value));

  const onClick = () => listContext?.onSelect(value);

  return (
    <Styles.Item
      className={className}
      $isActive={isActive}
      $typography={typography}
      $backgroundColor={backgroundColor}
      $backgroundColorActive={backgroundColorActive}
      onClick={onClick}
    >
      {children}
    </Styles.Item>
  );
};

export const SelectorList = forwardRef<HTMLDivElement, IProps>(
  (props, animationRef) => {
    const { selectedValues, children, className, onSelect } = props;

    return (
      <SelectorListContext.Provider value={{ selectedValues, onSelect }}>
        <Styles.Root className={className}>
          <Styles.MarginWrapper ref={animationRef}>
            {children}
          </Styles.MarginWrapper>
        </Styles.Root>
      </SelectorListContext.Provider>
    );
  }
);
