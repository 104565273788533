export const HOME_STRINGS = {
  BUTTON_TOP_BAR: 'Continue Working',
  CHARACTERISTICS: {
    SMART: 'Multiple Intelligence:',
    ABILITY: 'Performance Character:',
    CAREER: 'Career anchors:',
    VALUES: 'Values:',
  },
  TABS: {
    ACHIEVEMENT: 'Achievements',
    LIFESTYLE: 'Lifestyle',
    PROFILE: 'Your Profile',
    HISTORY: 'History',
    RANKING: 'Ranking',
  },
  POSSESSION: {
    CAR_TITLE: 'Car',
    HOME_TITLE: 'Home',
    TRAVEL_TITLE: 'Travel',
    PHONE_TITLE: 'Phone',
  },
  ACHIEVEMENT: {
    REWARDS: "You've received",
    REWARDS_EMPTY: 'You have no rewards yet. Start collecting rewards!',
    REWARDS_ERROR: 'Error',
    SKILLS: 'Skills',
    TSC: 'Technical Skills and Competencies (TSC)',
    GSC: 'Critical Core Skills (CCS)',
  },
} as const;
