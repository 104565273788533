import dayjs from 'dayjs';
import { IGame, TGameStatus } from '@avid/common';

export const gameCoreUtils = {
  getIntervalFromUNIX(unix: number) {
    const startMoment = dayjs.unix(unix);
    const nowMoment = dayjs().utc(false);
    const diff = nowMoment.diff(startMoment);

    const diffSeconds = dayjs.duration(diff).asSeconds();

    return diffSeconds;
  },

  getActiveRoundParams(game: IGame) {
    const { rounds } = game;

    if (!rounds?.length) {
      return {
        isActive: false,
        isLast: true,
      };
    }

    const lastRoundIndex = rounds.length - 1;

    const lastRound = rounds[lastRoundIndex];

    const startUnix = dayjs(new Date(lastRound.startTime)).unix();
    const duration = this.getIntervalFromUNIX(startUnix);

    return {
      isActive: duration < game.config.roundDuration,
      isLast: lastRoundIndex + 1 >= +game.config.roundsNumber,
    };
  },

  getGameStatus(game: IGame): TGameStatus {
    if (game.status === 'finished' || game.status === 'paused') {
      return game.status;
    }

    const { isActive, isLast } = this.getActiveRoundParams(game);

    if (isActive) {
      return 'in-progress';
    }

    if (isLast) {
      return 'finished';
    }

    return 'paused';
  },
};
