import { FC, ReactNode } from 'react';

import { Text } from 'components/ui';
import { typographyCss } from 'themes';

import { ReviewSectionStyles as Styles } from './review-section.styles';

interface IReviewSectionTitleProps {
  title: string;
  onEdit: () => void;
  typography?: keyof typeof typographyCss;
}

interface IProps {
  title: string;
  onEdit: () => void;
  titleTypography?: keyof typeof typographyCss;
  children?: ReactNode;
}

const ReviewSectionTitle = (props: IReviewSectionTitleProps) => {
  const { title, typography, onEdit } = props;

  return (
    <Styles.TitleWrapper>
      <Text typography={typographyCss[typography || 'regularMedium']}>
        {title}
      </Text>
      <Styles.TitleIcon type="edit" onClick={onEdit} />
    </Styles.TitleWrapper>
  );
};

export const ReviewSection: FC<IProps> = (props) => {
  const { title, children, titleTypography, onEdit } = props;

  return (
    <Styles.Root>
      <ReviewSectionTitle
        title={title}
        typography={titleTypography}
        onEdit={onEdit}
      />

      <Styles.ChildrenWrapper>{children}</Styles.ChildrenWrapper>
    </Styles.Root>
  );
};
