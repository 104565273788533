import { IGameMods } from '@avid/common';

export const MOD_FOLDER_MAP = {
  bonusCodes: 'bonus-codes',
  charityHobby: 'charity-hobby',
  gameReport: 'game-report',
  gauges: 'gauges',
  gaugesHard: 'gauges-hard',
  lifeGoals: 'life-goals',
  marriage: 'marriage',
  popupTips: 'popup-tips',
  specialJobs: 'special-jobs',
  financial: 'financial',
  limitedEducation: 'limited-education',
  limitedHighestJobs: 'limited-highest-jobs',
} as const satisfies Record<keyof IGameMods, string>;
