import { HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { getCssOptionalValue } from 'services/utils';
import { COLORS } from 'themes';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  bgColor?: string;
  size?: string;
}

const Styles = {
  Root: styled.div<{
    $bgColor?: string;
    $size?: string;
  }>`
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    ${(props) => css`
      ${getCssOptionalValue('width', props.$size)};
      ${getCssOptionalValue('height', props.$size)};
      background-color: ${props.$bgColor || COLORS.white};
    `}
  `,
};

export const CircleWrapper = (props: IProps) => {
  const { children, bgColor, size, ...divProps } = props;

  return (
    <Styles.Root $bgColor={bgColor} $size={size} {...divProps}>
      {children}
    </Styles.Root>
  );
};
