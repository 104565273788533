import { FireWork } from 'containers/fire-work';
import { Theme } from 'themes';

import { AvailableWork, Company, SearchWork } from './components';

import { useFindJob } from './find-job.state';
import { FindJobPages } from './find-job.typing';

export const FindJob = () => {
  const {
    page,
    sector,
    job,
    setPage,
    setSector,
    setJob,
    onFind,
    onRejectCompany,
  } = useFindJob();

  let RenderPage: JSX.Element | null = null;

  switch (page) {
    case FindJobPages.SearchWork: {
      RenderPage = (
        <SearchWork sector={sector} onFind={onFind} onSetSector={setSector} />
      );
      break;
    }

    case FindJobPages.AvailableWork: {
      RenderPage = sector ? (
        <AvailableWork
          sector={sector.value}
          selectedJob={job}
          onOpenPage={setPage}
          onSetJob={setJob}
        />
      ) : null;
      break;
    }

    case FindJobPages.Company: {
      RenderPage =
        sector && job ? (
          <Company sector={sector.value} job={job} onReject={onRejectCompany} />
        ) : null;
      break;
    }

    default:
      break;
  }

  return (
    <Theme.PageContainer>
      {RenderPage}

      <FireWork />
    </Theme.PageContainer>
  );
};
