import { ISector } from '@avid/common';

import { Loader } from 'components/loader';
import { moveToRouteCallback } from 'services/utils';
import { Theme } from 'themes';
import { ROUTES } from 'constants/routes';
import { useReduxSelector } from 'services/hooks';
import { getVersionString } from 'services/utils/versions';
import { CAREER_CENTER_STRINGS } from 'screens/career-centre/career-centre.strings';

import { CareerCentreSelector, CareerCentreSlider } from './components';
import { CareerInformationStyles as Styles } from './career-information.styles';

interface IProps {
  isLoading: boolean;
  sectors: ISector[];

  onSetSector: (sector: ISector) => void;
  onOpenDescriptionModal: (job: string) => void;

  playerWork?: string;
  sector?: ISector;
  careerTree?: string[][];
}

const { SUBTITLE, GO_BACK } = CAREER_CENTER_STRINGS;

export const CareerInformation = (props: IProps) => {
  const {
    isLoading,
    sectors,
    sector,
    playerWork,
    careerTree,
    onSetSector,
    onOpenDescriptionModal,
  } = props;

  const workPage = playerWork ? ROUTES.work : ROUTES.findJob;

  const version = useReduxSelector((redux) => redux.app.version);

  return (
    <Styles.PageContainer $isFullHeight={false}>
      <Styles.HeightContainer $justifyContent="space-between" $isColumn>
        <Styles.InfoContainer $isColumn $itemGap="12px">
          <Theme.Wrapper $itemGap={{ default: '12px' }}>
            <Theme.Title $textAlign="center">
              {getVersionString(version, 'CAREER_CENTRE')}
            </Theme.Title>
            <Theme.Paragraph $textAlign="center" $fontFamily="medium">
              {SUBTITLE}
            </Theme.Paragraph>

            <CareerCentreSelector
              isDisabled={isLoading}
              setSector={onSetSector}
              selectedSector={sector}
              sectors={sectors}
            />
          </Theme.Wrapper>

          <Loader isLoading={isLoading}>
            {careerTree && sector && (
              <CareerCentreSlider
                careerTree={careerTree}
                isSectorEducational={sector.isFull}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            )}
          </Loader>
        </Styles.InfoContainer>

        <Styles.Button
          title={GO_BACK}
          onClick={moveToRouteCallback(workPage)}
        />
      </Styles.HeightContainer>
    </Styles.PageContainer>
  );
};
