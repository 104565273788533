import { Redirect } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { useMod } from 'services/hooks';

export const LifeDashboard = () => {
  const Screen = useMod('gauges')?.screens.LifeDashboard;

  if (Screen) {
    return <Screen />;
  }

  return <Redirect to={ROUTES.home} />;
};
