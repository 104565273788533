import styled from 'styled-components';

import { COLORS, typographyCss } from 'themes';

export const SelectorListStyles = {
  Root: styled.div`
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  `,
  MarginWrapper: styled.div`
    margin-top: -8px;
  `,
  Item: styled.div<{
    $isActive: boolean;
    $typography?: keyof typeof typographyCss;
    $backgroundColor?: string;
    $backgroundColorActive?: string;
  }>`
    margin-top: 8px;
    width: 100%;
    padding: 12px 8px;
    border-radius: 10px;
    border: 1px solid ${COLORS.lightGray};
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition:
      background-color 0.15s ease-in-out,
      color 0.2s ease-in-out;

    ${({ $typography }) => $typography && typographyCss[$typography]};

    background-color: ${({
      $isActive,
      $backgroundColor = 'transparent',
      $backgroundColorActive = COLORS.darkYellow,
    }) => ($isActive ? $backgroundColorActive : $backgroundColor)};
    color: ${({ $isActive }) => ($isActive ? COLORS.white : COLORS.black)};
  `,
};
