import styled from 'styled-components';

import { Image } from 'components/image';
import { Theme, MEDIA, spacing, COLORS } from 'themes';

const getBorderColor = (isPositive: boolean, isInclude: boolean) =>
  isInclude ? (isPositive ? COLORS.green : COLORS.solidRed) : COLORS.black;

const getBackgroundColor = (isPositive: boolean, isInclude: boolean) =>
  isInclude
    ? isPositive
      ? COLORS.backgroundGreen
      : COLORS.backgroundRed
    : COLORS.neutralWhite;

export const CompanyAnchorsStyles = {
  Anchor: styled(Theme.Flexbox)<{ isPositive: boolean; isInclude: boolean }>`
    position: relative;
    padding: 8px 12px;
    border: 1px solid
      ${(props) => getBorderColor(props.isPositive, props.isInclude)};
    border-radius: 10px;
    background-color: ${(props) =>
      getBackgroundColor(props.isPositive, props.isInclude)};
  `,
  AnchorIcon: styled(Image)`
    position: absolute;
    right: 0;
    top: -15px;

    ${MEDIA.LAPTOP} {
      right: ${spacing(-2.5)};
      top: ${spacing(1)};
    }
  `,
};
