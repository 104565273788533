import { useEffect, useRef } from 'react';
import Slider from 'react-slick';

import { scrollToTop } from 'services/utils';

export const useCustomRef = <T>() => {
  const ref = useRef<T>(null);

  useEffect(() => {
    return;
  }, [ref]);

  return ref;
};

export const useSlider = () => {
  const ref = useCustomRef<Slider>();

  const nextSlide = () => {
    ref.current && ref.current.slickNext();
  };
  const prevSlide = () => {
    ref.current && ref.current.slickPrev();
  };

  return {
    ref,
    nextSlide,
    prevSlide,
  };
};

export const useScrollTopOnChangePage = (page: any) => {
  useEffect(scrollToTop, [page]);
};
