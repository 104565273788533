import { FIND_JOB_STRINGS } from '../../find-job.strings';

import { IPageContent, SearchWorkStatus } from './search-work.typing';

const { HISTORY_EMPTY, NOT_EMPLOYED, POSITION_ACCOUNTANT } = FIND_JOB_STRINGS;

export const getContent = (job: string): Record<string, IPageContent> => ({
  [SearchWorkStatus.WithoutWorkHistory]: {
    text: HISTORY_EMPTY,
    imageType: 'noWork',
  },
  [SearchWorkStatus.Employed]: {
    text: POSITION_ACCOUNTANT(job),
    imageType: 'planeTable',
  },
  [SearchWorkStatus.NotEmployed]: {
    text: NOT_EMPLOYED,
    imageType: 'planeTableOpen',
  },
});
