import { Image } from 'components/image';
import { ModalWrapper } from 'components/modal';
import { Button, Grid, Text } from 'components/ui';
import { typographyCss } from 'themes';

interface IProps {
  rating: number;
  onClose: () => void;
}

export const NotPassPhdHard = (props: IProps) => {
  const { onClose, rating } = props;

  return (
    <ModalWrapper onCloseModal={onClose}>
      <Grid rowGap="32px" justifyItems="center">
        <Grid
          padding="0 4px"
          rowGap="10px"
          justifyContent="center"
          justifyItems="center"
        >
          <Text typography={typographyCss.RalewayBold18}>
            Sorry you only scored {rating}% in this test. You need to score 100%
            to finish the PhD program.
          </Text>

          <Text>
            You put in a good effort but finishing a PhD is very hard! It
            requires meeting exceptional standards of quality, depth, and
            rigour.
          </Text>

          <Image type="phdHard" />
        </Grid>

        <Button variant="white" onClick={onClose}>
          Continue
        </Button>
      </Grid>
    </ModalWrapper>
  );
};
