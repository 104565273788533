import { useEffect } from 'react';

import { appAuth } from 'services/firebase';
import { history, moveToRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';

export const useAuthenticator = (code: string) => {
  useEffect(() => {
    history.replace({ search: '' });

    const request = async () => {
      try {
        await appAuth.signInWithCustomToken(code);

        moveToRoute(ROUTES.joinGame);
      } catch (error) {
        console.error(error);
        moveToRoute(ROUTES.login);
      }
    };

    request();
  }, [code]);
};
