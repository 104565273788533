import { IAbility } from '@avid/common';

import { PassPhdHard } from './pass-phd-hard';
import { PassDefault } from './pass-default';

import { usePass, IUsePassProps } from './pass.state';

interface IProps extends IUsePassProps {
  onClose: () => void;
  sector?: string;
  abilities?: IAbility[];
}

export const Pass = (props: IProps) => {
  const { level, sector, abilities, onClose } = props;

  const { isPhdHard } = usePass(props);

  const commonProps = { abilities, onClose };

  const RenderContent = isPhdHard ? (
    <PassPhdHard {...commonProps} />
  ) : (
    <PassDefault level={level} sector={sector} {...commonProps} />
  );

  return <>{RenderContent}</>;
};
