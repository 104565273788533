import styled from 'styled-components';

import { Text } from 'components/ui';

export const ReviewNameAvatarStyles = {
  FlexWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  NameText: styled(Text)`
    display: flex;
    align-items: flex-start;
    margin-left: 32px;
  `,
  NameValueSpan: styled(Text)`
    margin-left: 8px;
    max-width: 72px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
};
