import { createFontsComponentAndFamilies } from 'services/utils/theme';
import { IFont } from 'typings/theme.typing';

export type TFontsNames = keyof typeof FONT_FAMILIES;

const getSrc = (fileName: string) => require(`../assets/fonts/${fileName}.ttf`);

export const FONTS = {
  bold: {
    family: 'Raleway-Bold',
    src: getSrc('Raleway-Bold'),
    weight: 700,
  },

  medium: {
    family: 'Quicksand-Medium',
    weight: 500,
    src: getSrc('Quicksand-Medium'),
  },

  regular: {
    family: 'Quicksand-Regular',
    src: getSrc('Quicksand-Regular'),
  },
} as const satisfies Record<string, IFont>;

export const [GlobalFonts, FONT_FAMILIES] =
  createFontsComponentAndFamilies(FONTS);
