import { IRandomCompanyIndex, IToValuesWithInfoParams } from './company.typing';

export const randomNewCompanyIndex = (
  length: number,
  previous: number[]
): IRandomCompanyIndex => {
  const index = Math.ceil(Math.random() * length) - 1;

  if (previous.length === length) {
    if (previous[previous.length - 1] === index) {
      return randomNewCompanyIndex(length, previous);
    }

    return { index, exceed: true };
  }

  if (previous.includes(index)) {
    return randomNewCompanyIndex(length, previous);
  }

  return { index, exceed: false };
};

export const toValuesWithInfo = (params: IToValuesWithInfoParams) => {
  const { allValues, positive, negative } = params;

  const positiveWithDescriptions = positive.map((posValue) => ({
    title: posValue,
    info: allValues[posValue].positive,
  }));
  const negativeWithDescriptions = negative.map((negValue) => ({
    title: negValue,
    info: allValues[negValue].negative,
  }));

  return { positiveWithDescriptions, negativeWithDescriptions };
};
