import { useState, useCallback, ChangeEventHandler } from 'react';
import { ICreateCharacter } from '@avid/common';

import { TImageNames } from 'components/image';
import { checkCensor } from 'services/censor';

export interface INameAvatarProps {
  initNameIcon: Pick<ICreateCharacter, 'icon' | 'name'>;
  onNext: (params: Pick<ICreateCharacter, 'icon' | 'name'>) => void;
}

export const useNameAvatarStep = (props: INameAvatarProps) => {
  const { initNameIcon, onNext } = props;

  const [isValidName, setIsValidName] = useState(true);
  const [avatar, setAvatar] = useState<TImageNames>(
    initNameIcon.icon as TImageNames
  );
  const [name, setName] = useState(initNameIcon.name);

  const onSetAvatar = useCallback((icon: TImageNames) => setAvatar(icon), []);

  const onChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    (ev) => {
      setName(ev.target.value);

      if (!isValidName) {
        setIsValidName(true);
      }
    },
    [isValidName]
  );

  const handleNext = () => {
    const nameTrimed = name.trim();

    if (!avatar || !nameTrimed) {
      return;
    }

    if (nameTrimed.length === 0 || checkCensor(name)) {
      setIsValidName(false);
      return;
    }

    onNext({ name: nameTrimed, icon: avatar });
  };

  const isNextAvailable = Boolean(
    avatar && avatar !== 'characterIcon' && name && isValidName
  );

  return {
    avatar,
    name,
    isValidName,
    isNextAvailable,
    onSetAvatar,
    onChangeName,
    handleNext,
  };
};
