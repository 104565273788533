import { FC } from 'react';

import { SelectSector } from 'components/select-sector';
import { Theme } from 'themes';

import {
  AgreeSelectSector,
  ChooseDegree,
  DefaultEducationModal,
  NotPass,
  Pass,
  SelectEducation,
  StartTest,
  WrongDegree,
} from './components';
import { EDUCATION_STRINGS } from './education.strings';
import { useEducation } from './education.state';
import { EducationModal, EducationPage } from './education.typing';
import { EducationTest } from './components/education-test';
import { DegreeExceededModal } from './components/degree-exceeded-modal';

const { WRONG_LEVEL, SECTOR_TITLE } = EDUCATION_STRINGS;

export const Education: FC = () => {
  const {
    level,
    page,
    sector,
    modal,
    rating,
    isLoading,
    learnedAbilities,
    questions,

    onSetSector,
    onAnswerQuestion,
    onEndTest,
    setPage,
    setModal,
    closeModal,
    setLevel,
    setLevelPage,
    updateState,
    clearState,
    onCloseFail,
    onClosePass,
  } = useEducation();

  let RenderModal: JSX.Element | null = null;
  let RenderPage: JSX.Element | null = null;

  switch (modal) {
    case EducationModal.StartTest: {
      RenderModal = level ? (
        <StartTest
          level={level}
          sector={sector}
          updateEducationState={updateState}
          onCloseModal={closeModal}
        />
      ) : null;
      break;
    }

    case EducationModal.WrongDegree: {
      RenderModal = level ? (
        <WrongDegree level={level} onCloseModal={closeModal} />
      ) : null;
      break;
    }

    case EducationModal.ExceededDegree: {
      RenderModal = <DegreeExceededModal onClose={closeModal} />;
      break;
    }

    case EducationModal.WrongLevel: {
      RenderModal = (
        <DefaultEducationModal
          title={WRONG_LEVEL.TITLE}
          image="notEnjoyLevel"
          buttonText={WRONG_LEVEL.CONTINUE_BUTTON}
          onCloseModal={closeModal}
        />
      );
      break;
    }

    case EducationModal.NotPass: {
      RenderModal = level ? (
        <NotPass level={level} rating={rating} onClose={onCloseFail} />
      ) : null;
      break;
    }

    case EducationModal.Pass: {
      RenderModal = level ? (
        <Pass
          level={level}
          sector={sector}
          abilities={learnedAbilities}
          onClose={onClosePass}
        />
      ) : null;
      break;
    }

    case EducationModal.SelectUniversitySector: {
      RenderModal = sector ? (
        <AgreeSelectSector
          sector={sector}
          onCloseModal={closeModal}
          updateEducationState={updateState}
        />
      ) : null;
      break;
    }

    default:
      break;
  }

  switch (page) {
    case EducationPage.Test:
      RenderPage =
        level && questions ? (
          <EducationTest
            questions={questions}
            isDisabled={isLoading}
            onAnswer={onAnswerQuestion}
            onEndTest={onEndTest}
          />
        ) : null;
      break;

    case EducationPage.Sector:
      RenderPage = (
        <SelectSector
          title={SECTOR_TITLE}
          selectSector={sector}
          onSelectSector={onSetSector}
          onClickGoBack={setLevelPage}
        />
      );
      break;

    case EducationPage.Degree:
      sector
        ? (RenderPage = (
            <ChooseDegree
              sector={sector}
              setModal={setModal}
              setPage={setPage}
              setLevel={setLevel}
            />
          ))
        : null;
      break;

    case EducationPage.Level:
      RenderPage = (
        <SelectEducation
          setLevel={setLevel}
          setModal={setModal}
          setPage={setPage}
          clearEducationState={clearState}
        />
      );
      break;

    default:
      break;
  }

  return (
    <>
      <Theme.PageContainer>{RenderPage}</Theme.PageContainer>
      {RenderModal}
    </>
  );
};
