import { useMod } from 'services/hooks';
import { getModComponent } from 'services/utils';

export const ModNotifications = () => {
  const BadGaugeNotificationMC = getModComponent(
    useMod('gauges')?.components.BadGaugeNotification
  );
  const GaugesEnergyNotificationMC = getModComponent(
    useMod('gaugesHard')?.components.GaugesEnergyNotification
  );
  const LifeGoalNotificationMC = getModComponent(
    useMod('lifeGoals')?.components.LifeGoalNotification
  );
  const DeductionNotificationMC = getModComponent(
    useMod('financial')?.containers.DeductionNotification
  );

  return (
    <>
      <BadGaugeNotificationMC />
      <GaugesEnergyNotificationMC />
      <LifeGoalNotificationMC />
      <DeductionNotificationMC />
    </>
  );
};
