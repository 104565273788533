import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import { IPlayer, TGameStatus } from '@avid/common';

import { callFunction } from 'services/api';
import {
  useClearPlayer,
  useLoadGamePlayer,
  useUpdateState,
} from 'services/hooks';
import { ROUTES } from 'constants/routes';
import { getGameIdentity, moveToRoute } from 'services/utils';

import { CONNECT_GAME_ERRORS } from './connect-game.const';

interface IState {
  isLoading: boolean;
  isError: boolean;
  isExceed: boolean;
}

const INIT_STATE: IState = {
  isLoading: false,
  isError: false,
  isExceed: false,
};

const redirect = (gameStatus: TGameStatus, player: IPlayer) => {
  if (!player.main || !player.createCharacter) {
    moveToRoute(ROUTES.onboarding);
    return;
  }

  moveToRoute(gameStatus === 'paused' ? ROUTES.welcome : ROUTES.home);
};

export const useConnectGame = () => {
  const [gameCode, setGameCode] = useState('');
  const { state, updateState } = useUpdateState(INIT_STATE);

  const clearData = useClearPlayer();
  const loadGamePlayer = useLoadGamePlayer();

  const onChangeCode: ChangeEventHandler<HTMLInputElement> = (ev) => {
    if (state.isLoading) {
      return;
    }

    if (state.isError || state.isExceed) {
      updateState({ isError: false, isExceed: false });
    }

    setGameCode(ev.target.value);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = async (ev) => {
    ev.preventDefault();

    if (state.isLoading || state.isError || !gameCode) {
      return;
    }

    updateState({ isLoading: true });

    try {
      const { game, player } = await callFunction('connectGame')(
        getGameIdentity(gameCode)
      );

      clearData();
      await loadGamePlayer();

      redirect(game.status, player);
    } catch (error) {
      if ((error as Error).message === CONNECT_GAME_ERRORS.LIMIT_REACHED) {
        updateState({ isLoading: false, isExceed: true, isError: true });
        return;
      }

      updateState({ isError: true, isLoading: false });
      console.error(error);
    }
  };

  return { ...state, gameCode, onChangeCode, onSubmit };
};
