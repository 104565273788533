import { useMod } from 'services/hooks';

import { getModComponent } from 'services/utils';

import { BotBarItem } from './bot-bar-item';

export const BotBarFinancialItem = () => {
  const financialMod = useMod('financial');

  const BotBarIconMC = getModComponent(financialMod?.components.BotBarIcon);

  if (!BotBarIconMC || !financialMod) {
    return null;
  }

  const FINANCIAL_ROUTES = financialMod.constants.FINANCIAL_ROUTES;

  return (
    <BotBarItem
      path={FINANCIAL_ROUTES.ROOT + FINANCIAL_ROUTES.BUDGETING}
      additionalPaths={[FINANCIAL_ROUTES.ROOT]}
      Icon={<BotBarIconMC />}
    />
  );
};
