import { memo } from 'react';
import styled from 'styled-components';

import { useReduxSelector } from 'services/hooks';
import { ROUTES } from 'constants/routes';
import { Z_INDEXES } from 'themes';

import { BotBarItem } from '../bot-bar-item';
import { BotBarFinancialItem } from '../bot-bar-financial-item';

const Styles = {
  Root: styled.nav<{ $isOpen: boolean }>`
    position: fixed;
    bottom: ${(p) => (p.$isOpen ? '0' : '-100%')};
    right: 0;
    z-index: ${Z_INDEXES.TOOLTIP};
    margin-top: 20px;
    width: 100%;
    display: flex;
    transition: 0.75s bottom;
  `,
};

export const BotBar = memo(() => {
  const work = useReduxSelector((redux) => redux.player.work?.work);
  const isShowBottomBar = useReduxSelector(
    (redux) => redux.main.isShowBottomBar
  );
  const mods = useReduxSelector((redux) => redux.app.gameMods);

  const RenderLifeDashboardItem = mods?.gauges && (
    <BotBarItem
      image="lifeDashboardBar"
      path={ROUTES.lifeDashboard}
      id="bot_bar-gauges"
    />
  );

  const workPage = work ? ROUTES.work : ROUTES.findJob;

  return (
    <Styles.Root $isOpen={!!isShowBottomBar}>
      <BotBarItem
        image="homeBar"
        path={ROUTES.home}
        additionalPaths={[ROUTES.badgesList]}
      />

      <BotBarItem
        image="educationBar"
        path={ROUTES.education}
        id="bot_bar-education"
      />

      <BotBarItem image="skillBar" path={ROUTES.course} id="bot_bar-skills" />

      <BotBarItem
        image="workBar"
        path={workPage}
        additionalPaths={[ROUTES.work, ROUTES.careerCenter, ROUTES.findJob]}
        id="bot_bar-work"
      />

      {RenderLifeDashboardItem}

      <BotBarItem image="shopBar" path={ROUTES.shop} />

      <BotBarFinancialItem />

      <BotBarItem image="settingBar" path={ROUTES.more} />
    </Styles.Root>
  );
});
