import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { scrollToTop } from 'services/utils';

class Scroll extends Component<any> {
  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      scrollToTop();
    }
  }

  render() {
    return null;
  }
}

export const ScrollToTop = withRouter(Scroll);
