import { COLORS } from 'themes';

import { RewardConstructor } from 'components/reward-constructor';
import { Image } from 'components/image';
import { IRewardOption } from 'services/rewards';
import { recordValue } from 'services/utils';
import { REWARDS_APPEARANCE } from 'constants/rewards-appearance';

import { generateDescription } from './badge.utils';
import { BadgesStyles as Styles } from './badge.styles';

interface IBadgeProps {
  rewardName: string;
  rewardOptions: IRewardOption;
  isActive?: boolean;
}

export const Badge = (props: IBadgeProps) => {
  const { rewardName, rewardOptions, isActive = false } = props;

  const paddingBottom = !rewardOptions.badge && isActive ? '40px' : '24px';

  const RewardImage = isActive ? (
    <RewardConstructor rewardName={rewardName} size="smallSize" />
  ) : (
    <>
      <Image type="greyBadge" width="62px" />
      <Styles.QuestionMark>?</Styles.QuestionMark>
    </>
  );

  const rewardAppearance = recordValue(REWARDS_APPEARANCE, rewardName);

  if (!rewardAppearance) {
    return null;
  }

  return (
    <Styles.Wrapper paddingBottom={paddingBottom}>
      <Styles.RewardImageContainer>{RewardImage}</Styles.RewardImageContainer>
      <Styles.MainContentContainer>
        <Styles.SectorName
          color={isActive ? rewardAppearance.badgeColor : COLORS.grey}
        >
          {rewardAppearance?.title}
        </Styles.SectorName>
        <Styles.RewardType
          color={isActive ? rewardAppearance.badge_text_color : COLORS.grey}
        >
          {rewardOptions.badge && rewardOptions.badge + ' Badge'}
        </Styles.RewardType>
        <Styles.DescriptionWrapper>
          <Styles.AchievementImage
            type={isActive ? 'greenAchievements' : 'greyAchievements'}
          />
          <Styles.Description isActive={isActive}>
            {generateDescription(rewardOptions.requirements)}
          </Styles.Description>
        </Styles.DescriptionWrapper>
      </Styles.MainContentContainer>
    </Styles.Wrapper>
  );
};
