import { IPlayerMain } from '@avid/common';

import { ThunkAction } from 'typings';

import { pushLogThunk } from './logs.thunk';

export interface ILoadGameParams {
  roundNumber: number;
  bonus: Pick<IPlayerMain, 'energy' | 'money' | 'mood'>;
}

export const loadGameLogsThunk =
  (params: ILoadGameParams): ThunkAction =>
  (dispatch, getState) => {
    const { roundNumber, bonus } = params;

    const state = getState();

    const {
      player: {
        createCharacter: { performance },
      },
    } = state;

    dispatch(
      pushLogThunk({
        type: 'round',
        action: 'start',
        params: { round: roundNumber },
      })
    );

    dispatch(
      pushLogThunk({
        type: 'bonus',
        action: 'get',
        params: {
          performance,
          energy: bonus.energy || undefined,
          money: bonus.money || undefined,
          satisfaction: bonus.mood || undefined,
        },
      })
    );
  };
