import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { logsFetcher, LOGS_LIMIT } from 'services/api';
import { useReduxSelector, useUpdateState } from 'services/hooks';
import { IAppLog } from 'services/logs';

export interface IHomeHistoryProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
}
interface IState {
  isLoaded: boolean;
  logs: IAppLog[];
  isHasMore: boolean;
  isUpdating: boolean;
  isNeedButton?: boolean;
}

const INIT_STATE: IState = {
  isLoaded: false,
  logs: [],
  isHasMore: true,
  isUpdating: false,
};

export const useHomeHistory = (props: IHomeHistoryProps) => {
  const { scrollRef } = props;
  const { state, updateState } = useUpdateState(INIT_STATE);

  const gameCode = useReduxSelector((redux) => redux.player.authInfo.code);
  const email = useReduxSelector((redux) => redux.player.authInfo.email);

  const isBlockedRef = useRef(false);

  const { fetchNextLogs } = useMemo(() => {
    if (!gameCode || !email) {
      throw new Error('Unauthorized');
    }

    return logsFetcher(gameCode, email);
  }, [email, gameCode]);

  const loadLogsAsync = useCallback(async () => {
    if (!state.isHasMore) {
      return;
    }

    try {
      const logs = await fetchNextLogs();

      updateState((current) => ({
        isLoaded: true,
        logs: [...current.logs, ...(logs || [])],
        isHasMore: Boolean(logs?.length && logs.length >= LOGS_LIMIT),
      }));
    } catch (ex) {
      updateState({ isLoaded: false });
    }

    const refCurrent = scrollRef.current;
    if (!refCurrent) {
      return;
    }

    const triggerHeight = refCurrent.scrollTop + refCurrent.offsetHeight + 2;

    if (triggerHeight >= refCurrent.scrollHeight) {
      updateState({ isNeedButton: true });
    } else {
      updateState({ isNeedButton: false });
    }
  }, [fetchNextLogs, scrollRef, state.isHasMore, updateState]);

  const updateLogsAsync = useCallback(async () => {
    if (isBlockedRef.current || state.isUpdating) {
      return;
    }

    updateState({ isUpdating: true });
    isBlockedRef.current = true;

    await loadLogsAsync();

    updateState({ isUpdating: false });
    isBlockedRef.current = false;
  }, [loadLogsAsync, state.isUpdating, updateState]);

  const onClickButton = () => {
    updateLogsAsync();
  };

  useEffect(() => {
    loadLogsAsync();
  }, [loadLogsAsync]);

  useEffect(() => {
    const refCurrent = scrollRef.current;
    if (!refCurrent) {
      return;
    }

    const onScrollOut = () => {
      const triggerHeight = refCurrent.scrollTop + refCurrent.offsetHeight + 2;

      if (triggerHeight >= refCurrent.scrollHeight) {
        updateLogsAsync();
      }
    };

    refCurrent.addEventListener('scroll', onScrollOut);

    return () => {
      refCurrent.removeEventListener('scroll', onScrollOut);
    };
  }, [scrollRef, updateLogsAsync, updateState]);

  const isShowButton =
    state.isHasMore && state.isNeedButton && !state.isUpdating;

  return { ...state, isShowButton, onClickButton };
};
