import styled from 'styled-components';

import { MEDIA } from 'themes';

export const ShopPageStyles = {
  WidthContainer: styled.div`
    & > *:first-child {
      height: 100%;
    }

    ${MEDIA.LAPTOP} {
      width: 62%;
      max-width: 1000px;
      margin: 0 auto;
      margin-top: 16px;
    }
  `,
  TwoColumnTemplate: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 16px;

    ${MEDIA.LAPTOP} {
      grid-template-columns: auto auto;
    }
  `,
};
