import { TAppActions } from './app.actions';

import { APP_ACTION, IAppRedux, INIT_REDUX_CONSTANTS } from './app.constants';

const { UPDATE_CONSTANTS, UPDATE_GAME_CONSTANTS, SET_MODAL } = APP_ACTION;

export const reducerApp = (
  state = INIT_REDUX_CONSTANTS,
  action: TAppActions
): IAppRedux => {
  switch (action.type) {
    case UPDATE_CONSTANTS: {
      return { ...state, ...action.payload };
    }

    case UPDATE_GAME_CONSTANTS: {
      return { ...state, ...action.payload };
    }

    case SET_MODAL: {
      return { ...state, isScreenModalOpen: action.payload };
    }

    default:
      return state ?? INIT_REDUX_CONSTANTS;
  }
};
