import styled from 'styled-components';

import { SmartRadarItem } from './components/smart-radar-item';

import { Theme } from 'themes';

export const SmartRadarStyles = {
  Wrapper: styled(Theme.CentralizeFlexbox)`
    .scale {
      fill: white;
    }
    position: relative;
    margin: 0 auto;
    width: 340px;
    height: 330px;
    min-height: 330px;
  `,
  BoxTop: styled(SmartRadarItem)`
    top: 10px;
  `,
  BoxTopRight: styled(SmartRadarItem)`
    top: 40px;
    right: 40px;

    img {
      width: 42px;
      height: 42px;
    }
  `,
  BoxTopLeft: styled(SmartRadarItem)`
    top: 40px;
    left: 40px;
  `,
  BoxLeft: styled(SmartRadarItem)`
    left: 0;
  `,
  BoxRight: styled(SmartRadarItem)`
    right: 8px;
  `,
  BoxBottomLeft: styled(SmartRadarItem)`
    bottom: 40px;
    left: 40px;
  `,
  BoxBottom: styled(SmartRadarItem)`
    bottom: 0;
  `,
  BoxBottomRight: styled(SmartRadarItem)`
    bottom: 40px;
    right: 40px;
  `,
};
