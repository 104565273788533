import { ICompany } from '@avid/common';

import { ICharacteristicItem } from './components';

export const mapCharacteristicCallback = (company?: ICompany) => (
  characteristic: string
): ICharacteristicItem => ({
  label: characteristic,
  match: company?.positive.includes(characteristic)
    ? 'positive'
    : company?.negative.includes(characteristic)
    ? 'negative'
    : 'none',
});
