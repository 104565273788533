import { IRound } from '@avid/common';

export const getPassedRoundsDuration = (rounds?: IRound[]) => {
  const duration = rounds?.reduce((acc, round) => {
    acc += round.duration || 0;
    return acc;
  }, 0);

  return duration || 0;
};
