import { useCallback } from 'react';
import { ICreateCharacter, ICharacterSmart } from '@avid/common';

import { useReduxSelector, useUpdateState } from 'services/hooks';
// import { DEFAULT_SMART_CHARACTER } from 'services/redux';
import {
  TCreateCharacterFields,
  TCreateCharacterStep,
} from 'typings/create-character';

interface IState {
  step: TCreateCharacterStep;
  isPassed: boolean;
  createCharacter: TCreateCharacterFields;
  lifeGoals?: number[];
}

const INIT_STATE: IState = {
  step: 'nameIcon',
  isPassed: false,
  createCharacter: {
    name: '',
    icon: 'characterIcon',
    smart: {
      body: 5,
      picture: 5,
      logic: 5,
      music: 5,
      nature: 5,
      people: 5,
      self: 5,
      word: 5,
    },
    performance: '',
    careerAnchors: [],
    values: [],
  },
};

export const useCreateCharacter = () => {
  const version = useReduxSelector((redux) => redux.app.version);

  const isLifeGoals = useReduxSelector((redux) =>
    Boolean(redux.app.gameMods?.lifeGoals)
  );

  const { state, updateState } = useUpdateState(INIT_STATE);

  const setStep = useCallback(
    (step: TCreateCharacterStep) => updateState({ step }),
    [updateState]
  );

  const passStep = useCallback(
    (
      part: Partial<ICreateCharacter>,
      nextStep: TCreateCharacterStep,
      isPassed?: boolean
    ) =>
      updateState((prev) => ({
        ...prev,
        step: prev.isPassed ? 'review' : nextStep,
        createCharacter: { ...prev.createCharacter, ...part },
        isPassed: isPassed === undefined ? prev.isPassed : isPassed,
      })),
    [updateState]
  );

  const onSetNameIcon = useCallback(
    (nameIcon: Pick<ICreateCharacter, 'name' | 'icon'>) =>
      passStep(nameIcon, 'smarts'),
    [passStep]
  );

  const onSetSmart = useCallback(
    (smart: ICharacterSmart) => passStep({ smart }, 'performance'),
    [passStep]
  );

  const onSetPerformance = useCallback(
    (performance: string) =>
      passStep({ performance }, version === 'ntu' ? 'values' : 'anchors'),
    [passStep, version]
  );

  const onSetAnchors = useCallback(
    (careerAnchors: string[]) => passStep({ careerAnchors }, 'values'),
    [passStep]
  );

  const onSetValues = useCallback(
    (values: string[]) =>
      passStep({ values }, isLifeGoals ? 'goals' : 'review', true),
    [isLifeGoals, passStep]
  );

  const onSetLifeGoals = useCallback(
    (lifeGoals: number[]) => {
      updateState((prev) => ({
        ...prev,
        lifeGoals,
        step: 'review',
        isPassed: true,
      }));
    },
    [updateState]
  );

  return {
    ...state,
    setStep,
    onSetNameIcon,
    onSetSmart,
    onSetPerformance,
    onSetAnchors,
    onSetValues,
    onSetLifeGoals,
  };
};
