import { memo } from 'react';

import { useMod } from 'services/hooks';
import { getModComponent } from 'services/utils';

import { MainBetweenRounds } from '../../main';

import { PauseModal } from './pause-modal';

export const GamePaused = memo(() => {
  const ReferralNotificationModalMC = getModComponent(
    useMod('bonusCodes')?.components.ReferralNotificationModal
  );

  return (
    <>
      <MainBetweenRounds />
      <PauseModal />

      <ReferralNotificationModalMC />
    </>
  );
});
