import { usePlayerSalary, useReduxSelector } from 'services/hooks';

export interface INotCompleteWorkProps {
  onCompleteWork: () => Promise<any> | undefined;
}

export const useNotCompleteWork = () => {
  const { salary, isLoading } = usePlayerSalary();

  const notPassLimit = useReduxSelector(
    (redux) => redux.app.LIMIT_NOT_PASS_WORK_TEST
  );

  return { salary, isLoading, notPassLimit };
};
