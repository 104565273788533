import { TImageNames } from 'components/image';
import { ImageBox } from 'components/image-box';

import { RewardBonusStyles as Styles } from './reward-bonus.styles';

interface IRewardBonusProps {
  value: string | number;
  type?: string;
}

export const RewardBonus = (props: IRewardBonusProps) => {
  const { type, value } = props;

  if (!type) {
    return null;
  }

  const imageNames: Record<string, TImageNames> = {
    money: 'money',
    power: 'power',
    mood: 'smile',
  };

  return (
    <Styles.Wrapper>
      +
      <Styles.BonusWrapper>
        <ImageBox
          sizeImage="15px"
          elementTitle={<span>{value}</span>}
          titlePosition="right"
          imageName={imageNames[type]}
          itemGap="2px"
        />
      </Styles.BonusWrapper>
    </Styles.Wrapper>
  );
};
