import { createContext } from 'react';
import firebase from 'firebase/app';

export interface IAuthContext {
  user: firebase.User | null;
  error: firebase.auth.Error | null;
}

const INIT_VALUE: IAuthContext = {
  user: null,
  error: null,
};

export const AuthContext = createContext(INIT_VALUE);
