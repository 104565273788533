import { FC } from 'react';
import styled from 'styled-components';
import { PossessionLevel } from '@avid/common';

import { useUtils } from 'services/hooks';
import { Css, spacing, Theme } from 'themes';
import { SHOP_STRINGS } from 'screens/shop/shop.strings';
import { ShopButtonBar } from '../shop-button-bar';
import { PossessionItem } from './possession-item';

import { useShopPossessionItem } from './shop-possession-item.state';
import { Loader } from 'components/loader';

const { SECTOR_TITLE } = SHOP_STRINGS;

const CenteredContainer = styled(Theme.Wrapper)`
  ${Css.responsiveCenterWide};
`;

export interface IItemProps {
  level: PossessionLevel;
  label?: string;
}

export const ShopPossessionItem: FC = () => {
  const {
    checkPrice,
    buyPossessionItem,
    onSelectItem,
    moveToStartPage,
    itemLabels,
    isLoading,
    sectorPrises,
    currentLevel,
    currentPrice,
  } = useShopPossessionItem();
  const { checkCannotBuy } = useUtils();

  if (!sectorPrises || isLoading) {
    return <Loader />;
  }

  const Item = ({ level, label }: IItemProps) => (
    <PossessionItem
      isSelect={currentLevel === level}
      isEnable={checkPrice(level)}
      onSelectItem={onSelectItem(level)}
      level={level}
      label={label || level}
      price={sectorPrises[level]}
    />
  );

  const isCannotBuy = () => !currentLevel || checkCannotBuy(currentPrice);

  return (
    <Theme.Flexbox
      $alignItem="center"
      $justifyContent="space-between"
      $isColumn
      $itemGap="20px"
    >
      <Theme.Wrapper $itemGap={{ default: spacing(5) }}>
        <Theme.Title $textAlign="center">{SECTOR_TITLE}</Theme.Title>

        <CenteredContainer $itemGap={{ default: spacing(2) }}>
          <Item
            level={PossessionLevel.Budget}
            label={itemLabels?.Budget || PossessionLevel.Budget}
          />
          <Item
            level={PossessionLevel.Standard}
            label={itemLabels?.Standard || PossessionLevel.Standard}
          />
          <Item
            level={PossessionLevel.Luxury}
            label={itemLabels?.Luxury || PossessionLevel.Luxury}
          />
        </CenteredContainer>
      </Theme.Wrapper>

      <ShopButtonBar
        isDisabledBuy={isCannotBuy()}
        onBuy={buyPossessionItem}
        onGoBack={moveToStartPage}
      />
    </Theme.Flexbox>
  );
};
