import { useContext } from 'react';
import { useReduxSelector } from 'services/hooks';

import { WorkPage } from 'screens/work/work.typings';
import { WorkContext } from 'screens/work/work.context';

export const useCongrats = () => {
  const { onCloseModal, onOpenPage } = useContext(WorkContext);

  const newWork = useReduxSelector((redux) => redux.player.work?.work);

  const onUp = () => {
    onOpenPage(WorkPage.Start);
    onCloseModal();
  };

  return { newWork, onUp };
};
