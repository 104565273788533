import styled from 'styled-components';

import { COLORS, MEDIA } from 'themes';

const BARS = {
  TOP_MOBILE: 32,
  TOP_LAPTOP: 52,
  BOTTOM: 52,
} as const;

export const MainStyles = {
  Wrapper: styled.div`
    background-color: ${COLORS.backgroundWhite};
  `,
  Section: styled.section<{
    $isShowBottomBar?: boolean;
  }>`
    display: grid;
    min-width: 0;
    min-height: 100%;
    padding-top: ${BARS.TOP_MOBILE}px;

    ${({ $isShowBottomBar = true }) =>
      $isShowBottomBar && `padding-bottom: ${BARS.BOTTOM}px;`}

    & > * {
      min-width: 0;
    }

    ${MEDIA.LAPTOP} {
      padding-top: ${BARS.TOP_LAPTOP}px;
      ${({ $isShowBottomBar = true }) =>
        $isShowBottomBar && `padding-bottom: ${BARS.BOTTOM}px;`}
    }
  `,
};
