import { useContext } from 'react';

import { WorkContext } from 'screens/work/work.context';
import { WorkPage } from 'screens/work/work.typings';

export const usePromotion = () => {
  const { onOpenPage, onCloseModal } = useContext(WorkContext);

  const onClose = () => {
    onOpenPage(WorkPage.JobPromotion);
    onCloseModal();
  };

  return { onClose };
};
