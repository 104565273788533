import { useRef } from 'react';
import { Route, Switch } from 'react-router-dom';

import { FireWork } from 'containers/fire-work';
import { BadgesList } from 'screens/badges-list';
import { Home } from 'screens/home';
import { LifeGoals } from 'screens/mods/life-goals';
import { PAGE_ROUTES } from 'constants/routes';

import { TopBar } from './components';

import { MainStyles } from './main.styles';

export const MainBetweenRounds = () => {
  const rootRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <MainStyles.Wrapper ref={rootRef}>
        <TopBar />

        <MainStyles.Section $isShowBottomBar={false}>
          <Switch>
            <Route path={PAGE_ROUTES.badgesList}>
              <BadgesList isWaiting />
            </Route>
            <Route path={PAGE_ROUTES.lifeGoals}>
              <LifeGoals />
            </Route>
            <Route>
              <Home isBetweenRounds scrollRef={rootRef} />
            </Route>
          </Switch>
        </MainStyles.Section>
      </MainStyles.Wrapper>

      <FireWork />
    </>
  );
};
