import { FC, MouseEventHandler, ReactNode } from 'react';

import { CreateCharacterStyles as Styles } from './create-character-switch.styles';

interface IProps {
  isNextAvailable: boolean;
  onNext: MouseEventHandler<HTMLButtonElement>;
  buttonLabel?: string;
  children?: ReactNode;
}

export const CreateCharacterSwitch: FC<IProps> = (props) => {
  const { isNextAvailable, buttonLabel, onNext, children } = props;

  return (
    <Styles.Root>
      <div>{children}</div>

      <Styles.ButtonWrapper>
        <Styles.Button disabled={!isNextAvailable} onClick={onNext}>
          {buttonLabel || 'Next'}
        </Styles.Button>
      </Styles.ButtonWrapper>
    </Styles.Root>
  );
};
