import { P } from 'components/ui';
import { InfoImageModal } from 'components/modal';
import { ROUTES } from 'constants/routes';
import { useReduxSelector } from 'services/hooks';
import { moveToRoute } from 'services/utils';

export const CyclesExceed = () => {
  const isFreelance = useReduxSelector(
    (redux) => redux.player.work?.isFreelance
  );

  const jobType = isFreelance ? 'freelance' : 'internship';

  const onClose = () => moveToRoute(ROUTES.findJob);

  return (
    <InfoImageModal
      buttonText="Find a new job"
      title={
        <P $typography="RalewayBold18">
          Your {jobType} job has ended.
          <br />
          You can find a new job in the work tab.
        </P>
      }
      image="cyclesExceed"
      onCloseModal={onClose}
    />
  );
};
