import { useCallback } from 'react';

import { useReduxSelector } from './redux';
import {
  updateField,
  updateSubfield,
  updateUserParameter,
} from 'services/api/api.utils';

export const useAPI = () => {
  const { code, email } = useReduxSelector((redux) => ({
    code: redux.player.authInfo.code,
    email: redux.player.authInfo.email,
  }));

  return {
    updateUserParameter: useCallback(
      updateUserParameter(code || '', email || ''),
      []
    ),
    updateField: useCallback(updateField(code || '', email || ''), []),
    updateSubfield: updateSubfield(code || '', email || ''),
  };
};
