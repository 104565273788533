import { FC } from 'react';

import { ShopModal, ShopPage } from './components';
import { useMod } from 'services/hooks';

export const Shop: FC = () => {
  const CharityShop = useMod('charityHobby')?.screens.CharityShop;

  if (CharityShop) {
    return <CharityShop />;
  }

  return (
    <>
      <ShopPage />
      <ShopModal />
    </>
  );
};
