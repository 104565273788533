import { Theme } from 'themes';
import { CostModal } from 'components/modal';

import { WORK_STRINGS } from 'screens/work/work.strings';

import { useStartRelevant } from './start-relevant.state';

const { MODAL_WITH_PRICE, START_TEST_RELEVANT_TITLE } = WORK_STRINGS;

const Title = () => (
  <Theme.Paragraph $fontFamily="bold">
    {START_TEST_RELEVANT_TITLE}
  </Theme.Paragraph>
);

export const StartRelevant = () => {
  const { energy = 0, money, onConfirm, onCancel } = useStartRelevant();

  return (
    <CostModal
      title={<Title />}
      money={money}
      energy={energy}
      confirmButtonTitle={MODAL_WITH_PRICE.CONFIRM_BUTTON}
      cancelButtonTitle={MODAL_WITH_PRICE.CANCEL_BUTTON}
      onConfirm={onConfirm}
      onCloseModal={onCancel}
    />
  );
};
