export const entriesObject = <T extends {}>(object: T) =>
  Object.entries(object) as [keyof T, T[keyof T]][];

export const keyofObject = <T extends {}>(object: T) =>
  Object.keys(object) as (keyof T)[];

export const valuesOfObject = <T extends {}>(obj: T) =>
  Object.values(obj) as T[keyof T][];

export const createItems = (items: string[]): IItem[] =>
  items.map<IItem>((item) => ({
    value: item,
  }));

export const recordValue = <T>(object: T, key: keyof T) => {
  const value = object[key];
  return value ? value : undefined;
};

export const getItemText = (item: IItem) => item.label ?? item.value;
export const convertToItemString = (value: string) => ({ value });

export const getKeyByValue = <T extends Object>(object: T, value: T[keyof T]) =>
  keyofObject(object).find((key) => object[key] === value);

export const deepEqual = <T>(obj: T, target: T): boolean => {
  const typeObj = typeof obj;
  const typeTarget = typeof target;

  if (obj === target) {
    return true;
  }

  if (Number.isNaN(obj) && Number.isNaN(target)) {
    return true;
  }

  if (
    typeObj !== 'object' ||
    typeTarget !== 'object' ||
    obj === null ||
    target === null
  ) {
    return false;
  }

  const keysObj = Object.keys(obj as object);
  const keysTarget = Object.keys(target as object);

  if (keysObj.length !== keysTarget.length) {
    return false;
  }

  return keysObj.every((key) =>
    deepEqual((obj as any)[key], (target as any)[key])
  );
};

const isObject = (item: any): item is object => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

export const deepMerge = <T>(target: T, source: TDeepPartial<T>): T => {
  const output = { ...target };

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      const sourceValue = source[key as keyof TDeepPartial<T>];

      if (Array.isArray(sourceValue)) {
        (output as any)[key] = [...sourceValue];
      } else if (isObject(sourceValue)) {
        if (key in target) {
          (output as any)[key] = deepMerge(target[key as keyof T], sourceValue);
        } else {
          (output as any)[key] = sourceValue;
        }
      } else {
        (output as any)[key] = sourceValue;
      }
    });
  }

  return output;
};
