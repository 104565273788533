import { JobDescription } from 'components/job-description';
import { spacing } from 'themes';

import { JobDescriptionModalTutorial } from './job-description-modal-tutorial';

interface IProps {
  job: string;
  isLoadingDescription: boolean;
  onCloseModal: () => void;
  onConfirm: () => void;
  description?: string;
  salary?: number;
}

export const JobDescriptionModal = (props: IProps) => {
  const {
    description,
    isLoadingDescription,
    job,
    salary,
    onCloseModal,
    onConfirm,
  } = props;

  return (
    <>
      <JobDescription
        idButton="career_centre-button_skills"
        job={job}
        buttonText="Check Skills Gap"
        onCloseModal={onCloseModal}
        onConfirm={onConfirm}
        isLoading={isLoadingDescription}
        description={description}
        salary={salary}
        itemGap={{ default: spacing(2), laptop: spacing(3) }}
      />

      <JobDescriptionModalTutorial />
    </>
  );
};
