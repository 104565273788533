import { ImageBox } from 'components/image-box';

import { Title } from '../title';

import { useRoundTimer } from './round-timer.state';

export const RoundTimer = () => {
  const { roundTime } = useRoundTimer();

  return (
    <div>
      <ImageBox
        sizeImage="15px"
        elementTitle={<Title value={roundTime} />}
        titlePosition="right"
        imageName="time"
      />
    </div>
  );
};
