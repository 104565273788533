import { FC } from 'react';
import { IAbility } from '@avid/common';

import { Progress } from 'components/progress';
import { useReduxSelector } from 'services/hooks';
import { getGSCLabel } from 'shared';

import { SkillInfoPanelStyles as Styles } from './skill-info-panel.styles';

interface ISkillInfoPanelProps {
  className?: string;
  ability: IAbility;
}

export const SkillInfoPanel: FC<ISkillInfoPanelProps> = (props) => {
  const { className, ability } = props;
  const { level, name, type } = ability;
  const MAX_LEVEL = useReduxSelector((redux) => redux.app.MAX_SKILL_LEVEL);

  const skillLevel = type === 'GSC' ? getGSCLabel(level) : level.toFixed(2);

  return (
    <Styles.Root className={className}>
      <Styles.SkillName>{name}</Styles.SkillName>

      <Styles.ProgressContainer>
        <Styles.ProgressParagraph>{skillLevel}</Styles.ProgressParagraph>
        <Styles.ProgressBarContainer>
          <Progress
            completePoint={+level}
            maxPoint={MAX_LEVEL}
            $progressColor="orange"
          />
        </Styles.ProgressBarContainer>
      </Styles.ProgressContainer>
    </Styles.Root>
  );
};
