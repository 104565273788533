import { FC, ReactNode } from 'react';

import { LifeCardStyles } from '../life-card.styles';

export interface ILifeCardInformationProps {
  frontSide: ReactNode;
  backSide: ReactNode;
  isFlipped: boolean;
}

export const LifeCardInformation: FC<ILifeCardInformationProps> = (props) => {
  const { frontSide, backSide, isFlipped } = props;

  return (
    <LifeCardStyles.Card
      frontSide={<LifeCardStyles.CardSide>{frontSide}</LifeCardStyles.CardSide>}
      backSide={
        <LifeCardStyles.CardSide $isColumn $itemGap="16px">
          {backSide}
        </LifeCardStyles.CardSide>
      }
      isFlipped={isFlipped}
    />
  );
};
