import { NamedExoticComponent } from 'react';
import { createGlobalStyle, ExecutionProps } from 'styled-components';
import { entriesObject } from '@avid/common';

import { IFont } from 'typings/theme.typing';

type TFontsFamilies<T extends Record<string, IFont>> = {
  [P in keyof T]: T[P]['family'];
};

const getFontFamily = (font: IFont) => font.family;

export const createFontFace = (
  family: string,
  src: string,
  weight = 400,
  style = 'normal'
): string => `
    @font-face {
      font-family: "${family}";
      src: url(${src}) format("truetype");
      font-weight: ${weight};
      font-style: ${style};
    }
  `;

const createFontsComponent = <T extends Record<string, IFont>>(fontsMap: T) => {
  const fontsStylesString = entriesObject(fontsMap).reduce(
    (style, [, { family, src, weight }]) => {
      style = style + `${createFontFace(family, src, weight)}`;
      return style;
    },
    ``
  );

  return createGlobalStyle`${fontsStylesString}`;
};

const createFontsFamilies = <T extends Record<string, IFont>>(fontsMap: T) =>
  entriesObject(fontsMap).reduce((fontFamiliesObj, [name, font]) => {
    fontFamiliesObj[name] = getFontFamily(font);
    return fontFamiliesObj;
  }, {} as TFontsFamilies<T>);

export const createFontsComponentAndFamilies = <
  T extends Record<string, IFont>,
>(
  fontsMap: T
): [NamedExoticComponent<ExecutionProps & object>, TFontsFamilies<T>] => [
  createFontsComponent(fontsMap),
  createFontsFamilies(fontsMap),
];

export const getCssOptionalValue = (prop: string, value?: string) => {
  if (value === undefined) {
    return '';
  }

  return `${prop}: ${value};`;
};
