import { TSmart } from '@avid/common';
import { TImageNames } from 'components/image';

const reg = /^(\w|\.)+@(\w|\.)+\.[a-zA-Z]{2,6}$/;

const WORD_ARTICLE_REGEX = /^([aeiou])/i;

export const combineWithDot = (number: number) =>
  `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const createPrice = (currency?: number | null) => {
  if (currency === undefined || currency === null) {
    return '...';
  }

  return `${currency < 0 ? '-' : ''}$${combineWithDot(Math.abs(currency))}`;
};

export const checkIsValidEmail = (text: string) => reg.test(text);

export const encodeEmail = (email: string) =>
  encodeURIComponent(email).replace(/\./g, '%2E');

export const decodeEmail = (encodedEmail: string) =>
  decodeURIComponent(encodedEmail.replace('%2E', '.'));

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getElementTargetById = (elementId: string) => `#${elementId}`;

export const SMART_IMAGE_MAP: Record<TSmart, TImageNames> = {
  body: 'bodySmart',
  logic: 'logicSmart',
  music: 'musicSmart',
  nature: 'natureSmart',
  people: 'peopleSmart',
  picture: 'imageSmart',
  self: 'selfSmart',
  word: 'wordsSmart',
};

export const getWordWithArticle = (word: string) => {
  const article = WORD_ARTICLE_REGEX.test(word) ? 'an' : 'a';

  return article + ' ' + word;
};
