import styled from 'styled-components';

import { MEDIA } from 'themes';

export const HomeStyles = {
  WidthWrapper: styled.div`
    ${MEDIA.LAPTOP} {
      width: 60%;
      margin: 0 auto;
      max-width: 1020px;
    }
  `,

  CodesWrapper: styled.div`
    ${MEDIA.CHROMEBOOK} {
      display: flex;
      justify-content: space-between;
    }
  `,
};
