import { IEducationLevel } from '@avid/common';

import { entriesObject } from 'services/utils';
import { IDegree } from 'typings/education.typing';

interface IGetDegreesParams {
  sector: string;
  educationLevels: TEducationLevels;
  educations?: IEducationLevel[];
}

export const universityLevelsEntries = (educationLevels: TEducationLevels) =>
  entriesObject(educationLevels).filter(
    ([, levelParams]) => levelParams.isUniversity
  );

export const checkOpenDegreeCallback =
  (achieved: IEducationLevel[]) => (params: IStudy) => {
    try {
      const preRequisites = params.preRequisites;

      const prerequisite = preRequisites && preRequisites[0];
      const answer = preRequisites && preRequisites[0]?.rating;
      const study = achieved.filter(
        (achievedEducation) =>
          achievedEducation.level &&
          achievedEducation.level === prerequisite?.level
      );
      const isOpen = !!answer && study.some((st) => st.rating >= answer);
      return isOpen;
    } catch (e) {
      console.error(e);
    }
  };

export const checkCompleteDegreeCallback =
  (achieved: IEducationLevel[]) => (level: string) =>
    !!achieved.filter((ed) => ed.level === level).length;

export const getDegrees = (params: IGetDegreesParams): IDegree[] => {
  const { educationLevels, educations, sector } = params;

  const sectorStudy =
    educations?.filter(
      (education) =>
        universityLevelsEntries(educationLevels)
          .map(([levelName]) => levelName)
          .includes(education.level) && education.sector === sector
    ) || [];

  const checkOpenDegree = checkOpenDegreeCallback(sectorStudy);
  const checkCompleteDegree = checkCompleteDegreeCallback(sectorStudy);

  return universityLevelsEntries(educationLevels)
    .sort((first, second) => first[1].order - second[1].order)
    .map(([levelName, levelParams], index) => ({
      name: levelName,
      achievement: levelParams.achievement,
      isOpen: index === 0 ? true : Boolean(checkOpenDegree(levelParams)),
      isCompleted: checkCompleteDegree(levelName),
    }));
};
