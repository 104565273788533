import { FC, ReactElement } from 'react';

import { spacing, Theme } from 'themes';
import { Checkboxes } from 'components/checkbox';
import { SHOP_STRINGS } from 'screens/shop/shop.strings';
import { ShopPossessionItem } from '../shop-possession-item';
import { TravelShop } from '../travel-shop';

import { useShopPage } from './shop-page.state';

import { SHOP_SECTOR } from 'screens/shop/shop.constants';

import { ShopPages } from 'screens/shop/shop.typings';

import { ShopPageStyles as Styles } from './shop-page.styled';
import { IconShop } from '../icon-shop';

const { SECTOR_TITLE } = SHOP_STRINGS;

export const ShopPage: FC = () => {
  const { page, sector, setSector } = useShopPage();

  const Page: Record<ShopPages, ReactElement> = {
    [ShopPages.Sector]: (
      <Theme.Wrapper $textAlign="center" $itemGap={{ default: spacing(3) }}>
        <Theme.Title>{SECTOR_TITLE}</Theme.Title>
        <Styles.TwoColumnTemplate>
          <Checkboxes
            items={SHOP_SECTOR}
            setValue={setSector}
            value={sector}
            variant="pink"
          />
        </Styles.TwoColumnTemplate>
      </Theme.Wrapper>
    ),
    [ShopPages.BuyItem]: <ShopPossessionItem />,
    [ShopPages.BuyTravel]: <TravelShop />,
    [ShopPages.Icon]: <IconShop />,
  };

  return (
    <Theme.PageContainer>
      <Styles.WidthContainer>{Page[page]}</Styles.WidthContainer>
    </Theme.PageContainer>
  );
};
