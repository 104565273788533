import { memo } from 'react';

import { useMod } from 'services/hooks';
import { getModComponent } from 'services/utils';

export const TipToastsDisplay = memo(() => {
  const popupTipsModComponents = useMod('popupTips')?.components;

  const TopToastStackMC = getModComponent(
    popupTipsModComponents?.TopToastStack
  );
  const MessagePopupMC = getModComponent(popupTipsModComponents?.MessagePopup);

  return (
    <>
      <TopToastStackMC />
      <MessagePopupMC />
    </>
  );
});
