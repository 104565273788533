import { FC, MouseEventHandler } from 'react';
import styled, { css, RuleSet } from 'styled-components';

import { Theme } from 'themes';

const getIcon = (name: string) => require(`../assets/icons/${name}`);
const getImage = (name: string) => require(`../assets/images/${name}`);

export const IMAGES = {
  // --- Background --- //
  backgroundLogo: getImage('background-logo.svg'),
  backgroundLogoLaptop: getImage('background-logo-laptop.svg'),

  // --- Greeting page --- //
  logo: getImage('logo.png'),

  // --- Log in --- //
  lock: getIcon('log-in/lock.svg'),
  errorLock: getIcon('log-in/error-lock.svg'),

  // --- Main --- //
  facilitatorStopped: getIcon('main/facilitator-stopped.svg'),
  endGame: getIcon('main/end-game.svg'),

  // --- Onboarding --- //
  onboarding1: getImage('onboarding/onboarding_1.svg'),
  onboardingEnergy: getImage('onboarding/energy.svg'),
  onboarding3: getImage('onboarding/onboarding_3.svg'),
  onboarding4: getImage('onboarding/onboarding_4.svg'),
  onboarding5: getImage('onboarding/onboarding_5.svg'),
  onboarding6: getImage('onboarding/onboarding_6.svg'),

  // --- Create chapter --- //
  remove: getIcon('create-character/close_modal.svg'),
  close: getIcon('create-character/close_modal_black.svg'),
  man: getIcon('create-character/man.svg'),
  progress1: getIcon('create-character/progress1.svg'),
  progress2: getIcon('create-character/progress2.svg'),
  progress3: getIcon('create-character/progress3.svg'),
  progress4: getIcon('create-character/progress4.svg'),
  edit: getIcon('create-character/edit.svg'),

  // --- Character icons --- //
  characterIcon: getIcon('character/avatars/character-icon.svg'),
  avatar1: getIcon('character/avatars/avatar-1.svg'),
  avatar2: getIcon('character/avatars/avatar-2.svg'),
  avatar3: getIcon('character/avatars/avatar-3.svg'),
  avatar4: getIcon('character/avatars/avatar-4.svg'),
  avatar5: getIcon('character/avatars/avatar-5.svg'),
  avatar6: getIcon('character/avatars/avatar-6.svg'),
  avatar7: getIcon('character/avatars/avatar-7.svg'),
  avatar8: getIcon('character/avatars/avatar-8.svg'),
  avatar9: getIcon('character/avatars/avatar-9.svg'),
  avatar10: getIcon('character/avatars/avatar-10.svg'),
  avatar11: getIcon('character/avatars/avatar-11.svg'),
  avatar12: getIcon('character/avatars/avatar-12.svg'),
  avatar13: getIcon('character/avatars/avatar-13.svg'),
  avatar14: getIcon('character/avatars/avatar-14.svg'),
  avatar15: getIcon('character/avatars/avatar-15.svg'),
  avatar16: getIcon('character/avatars/avatar-16.svg'),
  avatar17: getIcon('character/avatars/avatar-17.svg'),
  avatar18: getIcon('character/avatars/avatar-18.svg'),

  // --- Smart --- //
  natureSmart: getIcon('create-character/smart/nature.svg'),
  logicSmart: getIcon('create-character/smart/logic.svg'),
  selfSmart: getIcon('create-character/smart/self.svg'),
  peopleSmart: getIcon('create-character/smart/people.svg'),
  musicSmart: getIcon('create-character/smart/music.svg'),
  bodySmart: getIcon('create-character/smart/body.svg'),
  imageSmart: getIcon('create-character/smart/image.svg'),
  wordsSmart: getIcon('create-character/smart/words.svg'),

  // --- Performances --- //
  selfControl: getIcon('create-character/performance/self-control.svg'),
  zest: getIcon('create-character/performance/zest.svg'),
  socialIntelligence: getIcon(
    'create-character/performance/social-intelligence.svg'
  ),
  curiosity: getIcon('create-character/performance/curiosity.svg'),
  grit: getIcon('create-character/performance/grit.svg'),
  optimism: getIcon('create-character/performance/optimism.svg'),
  gratitude: getIcon('create-character/performance/gratitude.svg'),

  // --- Welcome page --- //
  welcomeBackgroundButton: getImage('background-image.svg'),
  actionPointButton1: getIcon('character/button/active-point-background-1.svg'),
  actionPointButton2: getIcon('character/button/active-point-background-2.svg'),
  bankAccountButton: getIcon('character/button/bank-background.svg'),

  // --- Top bar --- //
  money: getIcon('character/top-bar/money.svg'),
  power: getIcon('character/top-bar/power.svg'),
  smile: getIcon('character/top-bar/smile.svg'),
  time: getIcon('character/top-bar/time.svg'),

  // --- Bottom bar --- //
  // * Page icon * //
  homeBar: getIcon('character/bottom-bar/home.svg'),
  educationBar: getIcon('character/bottom-bar/education.svg'),
  settingBar: getIcon('character/bottom-bar/setting.svg'),
  shopBar: getIcon('character/bottom-bar/shop.svg'),
  skillBar: getIcon('character/bottom-bar/skill.svg'),
  lifeDashboardBar: getIcon('character/bottom-bar/life-dashboard.svg'),
  workBar: getIcon('character/bottom-bar/work.svg'),
  // * Bar * //
  selectHomeBar: getIcon('character/bottom-bar/select-home.svg'),
  selectEducationBar: getIcon('character/bottom-bar/select-education.svg'),
  selectSkillBar: getIcon('character/bottom-bar/select-skill.svg'),
  selectWorkBar: getIcon('character/bottom-bar/select-work.svg'),
  selectShopBar: getIcon('character/bottom-bar/select-shop.svg'),
  selectSettingBar: getIcon('character/bottom-bar/select-setting.svg'),

  // --- Possession --- //
  money1: getImage('possessions/money 1.svg'),
  money2: getImage('possessions/money 2.svg'),
  money3: getImage('possessions/money 3.svg'),
  // * Car * //
  car: getImage('possessions/car.svg'),
  car1: getImage('possessions/car-1.svg'),
  car2: getImage('possessions/car-2.svg'),
  car3: getImage('possessions/car-3.svg'),
  carUS1: getImage('possessions/car-us-1.svg'),
  carUS2: getImage('possessions/car-us-2.svg'),
  carUS3: getImage('possessions/car-us-3.svg'),
  // * Home * //
  home: getImage('possessions/home.svg'),
  home1: getImage('possessions/home-1.svg'),
  home2: getImage('possessions/home-2.svg'),
  home3: getImage('possessions/home-3.svg'),

  homeUS1: getImage('possessions/home-us-1.svg'),
  homeUS2: getImage('possessions/home-us-2.svg'),
  homeUS3: getImage('possessions/home-us-3.svg'),
  // * Travel * //
  travel: getImage('possessions/travel.svg'),
  travel1: getImage('possessions/travel-level-1.svg'),
  travel2: getImage('possessions/travel-level-2.svg'),
  travel3: getImage('possessions/travel-level-3.svg'),
  // * Phone * //
  phone: getImage('possessions/phone.svg'),
  phone1: getImage('possessions/phone-level-1.svg'),
  phone2: getImage('possessions/phone-level-2.svg'),
  phone3: getImage('possessions/phone-level-3.svg'),

  // --- Education --- //
  completeEducation: getIcon('education/complete-education.svg'),
  primarySchool: getImage('education/primary.svg'),
  secondarySchool: getImage('education/second.svg'),
  juniorCollage: getImage('education/junior-collage.svg'),
  polytechnic: getImage('education/polytechnic.svg'),
  ite: getImage('education/ite.svg'),
  university: getImage('education/university.svg'),
  exitTest: getImage('education/exit-test.svg'),
  passTest: getImage('education/pass-test.svg'),
  notPassTest: getImage('education/not-pass-test.svg'),
  closeModal: getIcon('education/close-modal.svg'),
  notEnjoyLevel: getImage('education/not-enjoy-level.svg'),
  notEnjoyDegree: getImage('education/not-enjoy-degree.svg'),
  degreeAccess: getIcon('education/degree-access.svg'),
  rightAnswer: getIcon('education/right-answer.svg'),
  wrongAnswer: getIcon('education/wrong-answer.svg'),
  skillUp: getIcon('education/skill-up.svg'),
  skillDown: getIcon('education/skill-down.svg'),
  skillEqual: getIcon('education/skill-equal.svg'),
  phdHard: getIcon('education/phd-hard.svg'),
  phdHardPass: getIcon('education/phd-hard-pass.svg'),

  // --- Work --- //
  noWork: getIcon('work/no-work.svg'),
  planeTableOpen: getIcon('work/plane-table-open.svg'),
  planeTable: getIcon('work/plane-table.svg'),
  house: getIcon('work/house.svg'),
  arrowDown: getIcon('work/arrow-down.svg'),
  notCompleteWork: getImage('work/not-complete-work.svg'),
  completeWork: getImage('work/complete-work.svg'),
  negativeCompanyAnchor: getIcon('work/negative-company-anchor.svg'),
  positiveCompanyAnchor: getIcon('work/positive-company-anchor.svg'),
  careerTreeArrow: getIcon('work/career-tree-arrow.svg'),
  getNewWork: getImage('work/get-new-work.svg'),
  quitWork: getImage('work/quit-work.svg'),
  cyclesExceed: getImage('work/cycles-exceed.svg'),
  informationCareer: getIcon('work/information-career.svg'),

  // --- Course --- //
  completeCourse: getImage('course/complete-course.svg'),

  // --- Waiting --- //
  manWaiting: getImage('waiting/man-waiting.svg'),
  womanWaiting: getImage('waiting/woman-waiting.svg'),

  // --- Life card --- //
  agreeCard: getImage('life-card/agree-card.svg'),
  disagreeCard: getImage('life-card/disagree-card.svg'),
  startLifeCard: getImage('life-card/start-life-card.svg'),

  // --- Tutorial --- //
  tutorialTooltip: getIcon('tutorial/tooltip.svg'),
  // --- Shop --- //
  shopBudget: getIcon('shop/budget.svg'),
  shopStandard: getIcon('shop/economical.svg'),
  shopLuxury: getIcon('shop/luxury.svg'),

  // --- Slider --- //
  nextArrow: getIcon('slider/next-arrow.svg'),
  beforeArrow: getIcon('slider/before-arrow.svg'),

  // --- Project --- //
  backPageArrow: getIcon('back-page-arrow.svg'),

  // --- Live expenses --- //
  liveExpenses: getImage('live-expenses.svg'),

  // --- Mood --- //
  badMood: getIcon('main/bad-mood.svg'),
  neutralMood: getIcon('main/neutral-mood.svg'),
  goodMood: getIcon('main/good-mood.svg'),

  // --- Rewards modal --- //
  backgroundImage: getImage('rewards-modal/modal-background.svg'),

  // --- Game review --- //
  gameReview: getImage('game-review.svg'),

  // --- Other --- //
  warn: getIcon('warn.svg'),

  // --- Rewards --- //
  careerMainGold: getImage('rewards/main-body/career/gold.svg'),
  careerMainSilver: getImage('rewards/main-body/career/silver.svg'),
  careerMainBronze: getImage('rewards/main-body/career/bronze.svg'),

  educationMainGold: getImage('rewards/main-body/education/gold.svg'),
  educationMainSilver: getImage('rewards/main-body/education/silver.svg'),
  educationMainBronze: getImage('rewards/main-body/education/bronze.svg'),
  educationMainPink: getImage('rewards/main-body/education/pink.svg'),

  happinessMainGold: getImage('rewards/main-body/happiness/gold.svg'),
  happinessMainSilver: getImage('rewards/main-body/happiness/silver.svg'),
  happinessMainBronze: getImage('rewards/main-body/happiness/bronze.svg'),
  happinessMainPink: getImage('rewards/main-body/happiness/pink.svg'),

  moneyMainGold: getImage('rewards/main-body/money/gold.svg'),
  moneyMainSilver: getImage('rewards/main-body/money/silver.svg'),
  moneyMainBronze: getImage('rewards/main-body/money/bronze.svg'),
  moneyMainPink: getImage('rewards/main-body/money/pink.svg'),

  skillsMainGold: getImage('rewards/main-body/skills/gold.svg'),
  skillsMainSilver: getImage('rewards/main-body/skills/silver.svg'),
  skillsMainBronze: getImage('rewards/main-body/skills/bronze.svg'),

  careerSector: getImage('rewards/rewards-sectors/career.svg'),
  educationSector: getImage('rewards/rewards-sectors/education.svg'),
  happinessSector: getImage('rewards/rewards-sectors/happiness.svg'),
  moneySector: getImage('rewards/rewards-sectors/money.svg'),
  skillsSector: getImage('rewards/rewards-sectors/skills.svg'),
  masterSector: getImage('rewards/rewards-sectors/master.svg'),
  loyalEmployeeSector: getImage('rewards/rewards-sectors/loyal-employee.svg'),
  securitySector: getImage('rewards/rewards-sectors/security.svg'),
  driverSector: getImage('rewards/rewards-sectors/driver.svg'),
  wellConnectedSector: getImage('rewards/rewards-sectors/well-connected.svg'),
  stylishSector: getImage('rewards/rewards-sectors/stylish.svg'),
  worldTravellerSector: getImage('rewards/rewards-sectors/world-traveller.svg'),
  avidTravellerSector: getImage('rewards/rewards-sectors/avid-traveller.svg'),
  midCareerChangeSector: getImage(
    'rewards/rewards-sectors/mid-career-change.svg'
  ),
  jobHopperSector: getImage('rewards/rewards-sectors/job-hopper.svg'),

  ribbonLargeSlopingBlue: getImage('rewards/ribbons/large/sloping/blue.svg'),
  ribbonLargeSlopingDarkBlue: getImage(
    'rewards/ribbons/large/sloping/dark-blue.svg'
  ),
  ribbonLargeSlopingGreen: getImage('rewards/ribbons/large/sloping/green.svg'),
  ribbonLargeSlopingOrange: getImage(
    'rewards/ribbons/large/sloping/orange.svg'
  ),
  ribbonLargeSlopingViolet: getImage(
    'rewards/ribbons/large/sloping/violet.svg'
  ),

  ribbonLargeStraightBlue: getImage('rewards/ribbons/large/straight/blue.svg'),
  ribbonLargeStraightDarkBlue: getImage(
    'rewards/ribbons/large/straight/dark-blue.svg'
  ),
  ribbonLargeStraightGreen: getImage(
    'rewards/ribbons/large/straight/green.svg'
  ),
  ribbonLargeStraightOrange: getImage(
    'rewards/ribbons/large/straight/orange.svg'
  ),
  ribbonLargeStraightViolet: getImage(
    'rewards/ribbons/large/straight/violet.svg'
  ),

  ribbonSmallSlopingBlue: getImage('rewards/ribbons/small/sloping/blue.svg'),
  ribbonSmallSlopingDarkBlue: getImage(
    'rewards/ribbons/small/sloping/dark-blue.svg'
  ),
  ribbonSmallSlopingGreen: getImage('rewards/ribbons/small/sloping/green.svg'),
  ribbonSmallSlopingOrange: getImage(
    'rewards/ribbons/small/sloping/orange.svg'
  ),
  ribbonSmallSlopingViolet: getImage(
    'rewards/ribbons/small/sloping/violet.svg'
  ),

  ribbonSmallStraightBlue: getImage('rewards/ribbons/small/straight/blue.svg'),
  ribbonSmallStraightDarkBlue: getImage(
    'rewards/ribbons/small/straight/dark-blue.svg'
  ),
  ribbonSmallStraightGreen: getImage(
    'rewards/ribbons/small/straight/green.svg'
  ),
  ribbonSmallStraightOrange: getImage(
    'rewards/ribbons/small/straight/orange.svg'
  ),
  ribbonSmallStraightViolet: getImage(
    'rewards/ribbons/small/straight/violet.svg'
  ),

  greyBadge: getImage('rewards/grey-badge.svg'),
  // --- Rewards achievements --- //
  greenAchievements: getImage('reward-achievements/green.svg'),
  greyAchievements: getImage('reward-achievements/grey.svg'),
} as const;

export type TImageNames = keyof typeof IMAGES;

interface IImageStyled {
  className?: string;
  height?: string;
  width?: string;
  size?: string;
  $margin?: string;
  $styleCss?: RuleSet;
}

export interface IImageProps extends IImageStyled {
  type: TImageNames | '';
  onClick?: MouseEventHandler<HTMLDivElement>;
  $margin?: string;
}

const sizeCss = css<IImageStyled>`
  ${(p) => (p.height || p.size) && `height: ${p.height ?? p.size};`}
  ${(p) => (p.width || p.size) && `width: ${p.width ?? p.size};`}
`;

const Wrapper = styled(Theme.Flexbox)<IImageStyled>`
  ${sizeCss}
  margin: ${(p) => (p.$margin ? p.$margin : '0 auto')};
  overflow: auto;
  ${({ $styleCss }) => $styleCss && $styleCss}
  padding: 0;
`;

const ImageImg = styled.img<IImageStyled>`
  user-select: none;
  pointer-events: none;
  max-width: 100%;
  max-height: 100%;

  ${sizeCss}
`;

export const Image: FC<IImageProps> = (props) => {
  const { type, height, width, size, className, onClick, $margin, $styleCss } =
    props;

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event && event.preventDefault();
    onClick && onClick(event);
  };

  const src = type === '' ? undefined : IMAGES[type];

  return (
    <Wrapper
      $justifyContent="center"
      className={className}
      onClick={handleClick}
      height={height}
      width={width}
      size={size}
      $margin={$margin}
      $styleCss={$styleCss}
    >
      <ImageImg src={src} alt="img" height={height} width={width} size={size} />
    </Wrapper>
  );
};

export const ExpandedImage = styled(Image)`
  margin: 0;
  flex-shrink: 0;
`;
