import { FC, useMemo } from 'react';
import { IAbility, ISkillInfo } from '@avid/common';

import { Theme } from 'themes';
import { useEducationInfo, useReduxSelector } from 'services/hooks';
import { isIncludeInArray, sortAlphabeticalObjectArray } from 'services/utils';

import { SkillInfoPanel } from './skill-info-panel';
import { ChoseSkillStyles as Styles } from './choose-skill.styles';
import { Box, BoxItem } from 'components/ui';

export interface IChooseSkillProps {
  title: string;

  limit: number;
  skillInfos: ISkillInfo[];
  selectAbilities: string[];
  setAbilities(ability: string): void;
  id?: string;
  className?: string;
  subtitle?: string;
  isSelectMax?: boolean;
}

interface ISkillInformationProps {
  ability: IAbility;
  isLimit: boolean;
  selectAbilities: string[];

  setAbilities(ability: string): void;
}

const SkillInformation = (props: ISkillInformationProps) => {
  const { ability, isLimit, selectAbilities, setAbilities } = props;
  const { name } = ability;

  const isSelect = isIncludeInArray(selectAbilities, name);

  return (
    <Styles.CheckBox
      isChecked={isSelect}
      item={{ value: name }}
      setValue={setAbilities}
      isDisabled={isLimit && !isSelect}
    >
      <SkillInfoPanel ability={ability} />
    </Styles.CheckBox>
  );
};

export const ChooseSkill: FC<IChooseSkillProps> = (props) => {
  const {
    id,
    className,
    subtitle,
    title,
    skillInfos,
    selectAbilities,
    limit,
    isSelectMax = true,
    setAbilities,
  } = props;

  const maxSkillLevel = useReduxSelector((redux) => redux.app.MAX_SKILL_LEVEL);

  const isLimit = selectAbilities.length >= limit;
  const { transformSkillInfosToAbilities } = useEducationInfo();

  const skills = useMemo(
    () =>
      sortAlphabeticalObjectArray(
        transformSkillInfosToAbilities(skillInfos),
        'name'
      ),
    [skillInfos]
  );

  const onSetAbility = (name: string) => {
    const selectedAbility = skills.find((ability) => ability.name === name);

    if ((selectedAbility?.level || 0) >= maxSkillLevel && !isSelectMax) {
      return;
    }

    setAbilities(name);
  };

  return (
    <Styles.Root className={className} id={id}>
      <Box>
        <Styles.TitleBoxItem>
          <Theme.Text $fontFamily="bold">{title}</Theme.Text>

          {subtitle && (
            <Theme.Text $color="lightGray">{` ${subtitle}`}</Theme.Text>
          )}
        </Styles.TitleBoxItem>
      </Box>

      <Box $space="8px">
        {skills.map((ability, index) => (
          <BoxItem key={index}>
            <SkillInformation
              selectAbilities={selectAbilities}
              ability={ability}
              isLimit={isLimit}
              setAbilities={onSetAbility}
            />
          </BoxItem>
        ))}
      </Box>
    </Styles.Root>
  );
};
