import { ISector, TGameVersion } from '@avid/common';

import { EducationAPI } from './education.api';
import { LifeCardsAPI } from './life-cards.api';
import { SectorsAPIPart } from './sectors';
import { AllSkillsAPI } from './skills.api';
import { connectVersion } from './versions.connect';

interface ISectorsData {
  sectors: ISector[];
}

export class VersionsAPI {
  public static sectors = SectorsAPIPart;
  public static education = EducationAPI;
  public static lifeCards = LifeCardsAPI;
  public static allSkills = AllSkillsAPI;

  public static async fetchSectors(version: TGameVersion) {
    const data = (await connectVersion(version).get()).data();
    return (data as ISectorsData | undefined)?.sectors;
  }
}
