import styled from 'styled-components';

import { Grid } from 'components/ui';

export const PossessionsListStyles = {
  ContentWrapper: styled.div`
    display: grid;
    row-gap: 40px;
  `,
  SectionWrapper: styled.div`
    display: grid;
    row-gap: 16px;
  `,
  ItemsWrapper: styled(Grid)`
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
    align-items: center;

    > * {
      overflow: auto;
    }
  `,
};
