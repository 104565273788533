export const CAREER_CENTER_STRINGS = {
  SUBTITLE: 'Select the sector and click on your desired job to find out more.',
  GO_BACK: 'Go back',

  DESCRIPTION_BUTTON: 'Check Skills Gap',

  JOB_SKILL_INFORMATION: {
    TITLE: 'Skills Gap Analysis',
  },

  JOB_DESCRIPTION_BUTTON: 'Check Skills Gap',
} as const;
