import { InfoImageModal } from 'components/modal';
import { Theme } from 'themes';

import { WORK_STRINGS } from 'screens/work/work.strings';

import { usePromotion } from './promotion.state';

const { CAREER_UP } = WORK_STRINGS;

export const Promotion = () => {
  const { onClose } = usePromotion();

  const Title = <Theme.Title>{CAREER_UP.TITLE}</Theme.Title>;
  const Subtitle = <Theme.Paragraph>{CAREER_UP.SUBTITLE}</Theme.Paragraph>;

  return (
    <InfoImageModal
      title={Title}
      subtitle={Subtitle}
      image="getNewWork"
      buttonText={CAREER_UP.BUTTON}
      onCloseModal={onClose}
    />
  );
};
