import { ReactNode, useContext } from 'react';

import { SelectorDataListContext } from 'services/context';
import styled from 'styled-components';

import { SelectorListStyles as Styles } from './selector-list.styles';

const Item = styled(Styles.Item)`
  cursor: default;
`;

interface ISelectorListItemProps {
  value: string;
  className?: string;
  children?: ReactNode;
}

interface IProps {
  selectedValues: string[];
  className?: string;
  children?: ReactNode;
}

export const SelectorDataListItem = (props: ISelectorListItemProps) => {
  const { value, children, className } = props;

  const listContext = useContext(SelectorDataListContext);

  const isActive = Boolean(listContext?.selectedValues.includes(value));

  return (
    <Item className={className} $isActive={isActive}>
      {children}
    </Item>
  );
};

export const SelectorDataList = (props: IProps) => {
  const { selectedValues, children, className } = props;

  return (
    <SelectorDataListContext.Provider value={{ selectedValues }}>
      <Styles.Root className={className}>
        <Styles.MarginWrapper>{children}</Styles.MarginWrapper>
      </Styles.Root>
    </SelectorDataListContext.Provider>
  );
};
