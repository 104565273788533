import { InfoModal } from 'components/modal';
import { createPrice } from 'services/utils';
import { useReduxSelector } from 'services/hooks';
import { Z_INDEXES, Theme } from 'themes';

interface IProps {
  onClose: () => void;
}

const createText = (money: number) =>
  `Your rich uncle will give you an interest-free loan. You can only use up till ${createPrice(
    money
  )}`;

export const LoanModal = (props: IProps) => {
  const { onClose } = props;

  const minimalMoney = useReduxSelector((redux) => redux.app.minimalMoney);

  return (
    <InfoModal
      zIndex={Z_INDEXES.NEGATIVE_BALANCE_MODAL}
      isScreenModal={false}
      buttonText="OK"
      onCloseModal={onClose}
      title={<Theme.Text>{createText(minimalMoney)}</Theme.Text>}
      isMultiClickButton
    />
  );
};
