import { useState } from 'react';
import { ICharacterSmart } from '@avid/common';

import { getUsedSmartPoints } from 'services/utils';
import { SMART_TOTAL } from 'constants/create-character';
import {
  CreateCharacterLayout,
  CreateCharacterToolWrapper,
} from 'components/create-character';
import { getVersionString } from 'services/utils/versions';
import { useReduxSelector } from 'services/hooks';

import { SmartsEditor } from '../smarts-editor';
import { CreateCharacterTrack } from '../create-character-track';

interface IProps {
  initSmarts: ICharacterSmart;
  onNext: (characterSmart: ICharacterSmart) => void;
}

export const SmartsStep = (props: IProps) => {
  const { initSmarts, onNext } = props;

  const version = useReduxSelector((redux) => redux.app.version);

  const [characterSmart, setCharacterSmart] = useState(initSmarts);

  const usedSmartPoints = getUsedSmartPoints(characterSmart);

  const handleNext = () => onNext(characterSmart);

  return (
    <CreateCharacterLayout
      title="Multiple Intelligence or 'Smarts'"
      description={getVersionString(version, 'SMARTS_DESCRIPTION')}
      image="progress1"
      isNextAvailable={usedSmartPoints === SMART_TOTAL}
      onNext={handleNext}
    >
      <CreateCharacterTrack
        usedPoints={usedSmartPoints}
        maxPoints={SMART_TOTAL}
        labelEnd="assigned"
      />

      <CreateCharacterToolWrapper>
        <SmartsEditor
          characterSmart={characterSmart}
          onSetSmart={setCharacterSmart}
        />
      </CreateCharacterToolWrapper>
    </CreateCharacterLayout>
  );
};
