import { useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { IGameMods, IPlayer, TGameModName } from '@avid/common';

import { useReduxSelector } from 'services/hooks';
import { ModsService } from 'services/mods';
import { keyofObject, moveToRoute, objectEqual } from 'services/utils';
import { ROUTES } from 'constants/routes';

import { LifeCard } from './life-card';

type TModScreen =
  | keyof Pick<IGameMods, 'specialJobs' | 'marriage'>
  | 'lifeCard';

const getMods = () => {
  const [specialJobsMod, marriageMod] = ModsService.get([
    'specialJobs',
    'marriage',
  ]);

  return {
    JobOffer: specialJobsMod?.components.JobOffer,
    LifeChoice: marriageMod?.screens.LifeChoice,

    checkMod: (modName: TGameModName, player: IPlayer) => {
      switch (modName) {
        case 'marriage': {
          return marriageMod?.utils.checkDisplayCard(player);
        }

        case 'specialJobs': {
          return specialJobsMod?.utils.checkDisplayCard(player);
        }

        default: {
          return true;
        }
      }
    },
  };
};

const getQueue = (gameMods: IGameMods, round: number, player: IPlayer) => {
  const CHECK_QUEUE = ['marriage', 'specialJobs'] as const;

  const queue: TModScreen[] = [];

  const gameModNames = keyofObject(gameMods);

  const { checkMod } = getMods();

  CHECK_QUEUE.forEach((mod) => {
    if (
      gameModNames.includes(mod) &&
      gameMods[mod]?.round === round &&
      checkMod(mod, player)
    ) {
      queue.push(mod);
    }
  });

  queue.push('lifeCard');

  return queue;
};

const ModLifeCards = () => {
  const round = useReduxSelector((redux) => redux.game.round.number);
  const player = useReduxSelector((redux) => redux.player, objectEqual);

  const gameMods =
    useReduxSelector((redux) => redux.app.gameMods, objectEqual) || {};

  const queueRef = useRef(getQueue(gameMods, round, player));

  const [index, setIndex] = useState(0);

  const moveNext = () => {
    const max = queueRef.current.length - 1;

    if (index >= max) {
      moveToRoute(ROUTES.lifeIteration);
      return;
    }

    setIndex((prev) => prev + 1);
  };

  const screen = queueRef.current[index];
  const mods = getMods();

  switch (screen) {
    case 'specialJobs': {
      const { JobOffer } = mods;
      return JobOffer ? <JobOffer moveNext={moveNext} /> : null;
    }

    case 'marriage': {
      const { LifeChoice } = mods;
      return LifeChoice ? <LifeChoice moveNext={moveNext} /> : null;
    }

    case 'lifeCard': {
      return <LifeCard />;
    }

    default: {
      return <Redirect to={ROUTES.lifeIteration} />;
    }
  }
};

export const LifeCardGate = () => {
  const roundOffer = useReduxSelector(
    (redux) => redux.app.gameMods?.specialJobs?.round
  );
  const roundMarriage = useReduxSelector(
    (redux) => redux.app.gameMods?.marriage?.round
  );

  if (!roundOffer && !roundMarriage) {
    return <LifeCard />;
  }

  return <ModLifeCards />;
};
