import { RewardConstructor } from 'components/reward-constructor';
import { recordValue } from 'services/utils';
import { IRewardOption } from 'services/rewards';
import { REWARDS_APPEARANCE } from 'constants/rewards-appearance';

import { REWARD_MODAL_STRINGS } from '../../reward-modal.constants';
import { RewardBonus } from '../reward-bonus';

import { RewardAppearanceStyles as Styles } from './reward-appearance.styles';

interface IRewardAppearanceProps {
  rewardName: string;
  rewardOptions: IRewardOption;
}

const { title, subtitle } = REWARD_MODAL_STRINGS;

export const RewardAppearance = (props: IRewardAppearanceProps) => {
  const { rewardName, rewardOptions } = props;

  const rewardAppearance = recordValue(REWARDS_APPEARANCE, rewardName);

  if (!rewardAppearance) {
    return null;
  }

  return (
    <Styles.ModalContent>
      <Styles.ModalTitle color={rewardAppearance.badgeColor}>
        {title}
      </Styles.ModalTitle>
      <Styles.ModalSubtitle>{subtitle}</Styles.ModalSubtitle>
      <Styles.RewardSectorTitle>
        {`${rewardAppearance.title} ${rewardOptions.badge ? '' : 'Badge'}`}
      </Styles.RewardSectorTitle>
      <Styles.RewardTypeTitle color={rewardAppearance.badge_text_color}>
        {rewardOptions.badge && `${rewardOptions.badge} Badge`}
      </Styles.RewardTypeTitle>
      <Styles.RewardContainer>
        <RewardConstructor rewardName={rewardName} size="defaultSize" />
      </Styles.RewardContainer>
      <Styles.BonusesContainer $isProgressBar={!!rewardOptions.nextReward}>
        {rewardOptions.bonuses.map((bonus) => (
          <RewardBonus
            key={bonus.valueType}
            type={bonus.valueType}
            value={bonus.bonus}
          />
        ))}
      </Styles.BonusesContainer>
    </Styles.ModalContent>
  );
};
