import styled from 'styled-components';

import { Image, TImageNames } from 'components/image';
import { TColorNames, Theme } from 'themes';

interface IProps {
  multi: number;
}

const WideText = styled(Theme.Text)`
  width: 25px;
  text-align: left;
`;

export const SkillMultiplier = (props: IProps) => {
  const { multi } = props;

  const imageType: TImageNames =
    multi > 1 ? 'skillUp' : multi === 1 ? 'skillEqual' : 'skillDown';
  const color: TColorNames =
    multi > 1 ? 'lightGreen' : multi === 1 ? 'blackGray' : 'pink';

  const formatNumber = Number(multi.toFixed(1).toString());

  return (
    <Theme.Flexbox $alignItem="center" $itemGap="5px">
      <Image width="12px" type={imageType} />
      <WideText $fontFamily="regular" $fontSize="small" $color={color}>
        {formatNumber}x
      </WideText>
    </Theme.Flexbox>
  );
};
