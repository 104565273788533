import { useCallback, useState } from 'react';
import { ICreateCharacter } from '@avid/common';

export type TPerformanceParams = Pick<
  ICreateCharacter,
  'performance' | 'performanceBonus'
>;

export interface IPerformanceStepProps {
  initPerformance: string;
  onNext: (performance: string) => void;
}

export const usePerformanceStep = (props: IPerformanceStepProps) => {
  const { initPerformance, onNext } = props;

  const [performance, setPerformance] = useState<string>(initPerformance);

  const selectPerformance = useCallback(
    (value: string) => setPerformance(value),
    []
  );

  const handleNext = () => {
    if (!performance) {
      return;
    }

    onNext(performance);
  };

  const usedPoints = performance ? 1 : 0;

  return { performance, usedPoints, handleNext, selectPerformance };
};
