import { TGameVersion } from '@avid/common';

import { db } from '../../firebase';

export const connectVersion = (version: TGameVersion) =>
  db.collection('versions').doc(version);

export const connectSector = (version: TGameVersion, sector: string) =>
  connectVersion(version).collection('sectors').doc(sector);

export const connectWork = (version: TGameVersion, sector: string) =>
  connectSector(version, sector).collection('work');
