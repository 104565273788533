import { FC } from 'react';
import styled, { css } from 'styled-components';
import { PossessionLevel } from '@avid/common';

import { COLORS, Theme } from 'themes';
import { Image } from 'components/image';
import { COLOR_PALETTE, IColorPalette, LEVEL_ICON } from 'screens/shop';
import { CostImage } from '../cost-image';

export interface ITravelShopItemProps {
  level: PossessionLevel;
  price: IPrice;
  isEnable: boolean;
  isSelect: boolean;

  onSelectItem(): void;
}

interface IWrapperParameters {
  $colors: IColorPalette;
  $isEnable: boolean;
  $isSelect: boolean;
}

const Wrapper = styled(Theme.CentralizeFlexbox)<IWrapperParameters>`
  background: ${(props) =>
    COLORS[
      props.$isSelect
        ? props.$colors.selectBackground
        : props.$colors.background
    ]};
  border: 1px solid ${(props) => COLORS[props.$colors.border]};
  padding: 16px;
  border-radius: 10px;
  cursor: pointer;

  ${(props) =>
    !props.$isEnable &&
    css`
      filter: contrast(110%) brightness(110%) sepia(30%) grayscale(100%);
      cursor: not-allowed;
    `}
`;

export const TravelShopItem: FC<ITravelShopItemProps> = (itemProps) => {
  const { isEnable, isSelect, level, onSelectItem, price } = itemProps;

  const { energy, money } = price;

  const handleClick = () => {
    if (isEnable) {
      onSelectItem();
    }
  };

  return (
    <Wrapper
      $isColumn
      $colors={COLOR_PALETTE[level]}
      $isEnable={isEnable}
      $isSelect={isSelect}
      onClick={handleClick}
      $itemGap="8px"
    >
      <Image type={LEVEL_ICON[level]} size="32px" />
      <Theme.Paragraph $fontFamily="bold">{level}</Theme.Paragraph>
      <CostImage image="money" value={money ?? 0} />
      <CostImage image="power" value={energy} />
    </Wrapper>
  );
};
