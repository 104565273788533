import { ISkill } from '@avid/common';

import { updateObjectArray } from 'services/utils';
import { transformSkillInfoToSkillCallback } from 'shared';
import { VersionsAPI } from 'services/api';

import { getAllNextLevelsAsync } from '../../work.utils';

import {
  ICheckCanFoundRelevantSkillParams,
  ICheckIsAllSkillSectorMaxLevel,
  IUpdateSkillsArrayParams,
} from './work-choose-skill.typing';

const isMaxLevelSkill = (MAX_SKILL_LEVEL: number) => (skill: ISkill) =>
  skill.level >= MAX_SKILL_LEVEL;

export const updateSkillsArray = (params: IUpdateSkillsArrayParams) => {
  const { skills, allSkills, skillName, limit } = params;

  if (!skills) {
    return;
  }

  const skill = allSkills.find(({ name }) => skillName === name);

  if (!skill) {
    return;
  }

  return updateObjectArray({
    array: skills,
    newValue: skill,
    field: 'name',
    limit: limit,
  });
};

const checkIsAllSkillSectorMaxLevelAsync = async (
  params: ICheckIsAllSkillSectorMaxLevel
): Promise<boolean> => {
  const { version, abilities = [], sector, maxSkillLevel } = params;

  const sectorSkill = await VersionsAPI.sectors.education.fetchSkills(
    version,
    sector
  );

  return !!sectorSkill
    ?.map(transformSkillInfoToSkillCallback(abilities))
    .every(isMaxLevelSkill(maxSkillLevel));
};

export const checkCanFoundRelevantSkillAsync = async (
  params: ICheckCanFoundRelevantSkillParams
) => {
  const { version, abilities, maxSkillLevel, sector, work } = params;

  const [levels, isAllSkillMaxLevel] = await Promise.all([
    getAllNextLevelsAsync({ version, sector, work }),
    checkIsAllSkillSectorMaxLevelAsync({
      version,
      sector,
      maxSkillLevel,
      abilities,
    }),
  ]);

  return levels && !isAllSkillMaxLevel;
};
