import { usePlayerSalary } from 'services/hooks';
import { createPrice } from 'services/utils';

import { CareerText } from './career-text';

export const CareerSalary = () => {
  const { isLoading, salary } = usePlayerSalary();

  return (
    <CareerText isBold title="Salary">
      {isLoading ? '...' : createPrice(salary)}
    </CareerText>
  );
};
