import { memo } from 'react';

import { RankingItemStyles as Styles } from './positions-list.styles';

interface IStatItemProps {
  title: string;
  positions: Record<number, number>;
}

const ROUND = 'Round';
const CURRENT_POSITION = 'Current Position';

export const PositionsList = memo((props: IStatItemProps) => {
  const { title, positions } = props;

  const RoundsMap = Object.entries(positions)
    .reverse()
    .map(([round, position], index) => {
      const isFirst = index === 0;
      const Component = isFirst ? Styles.FirstItem : Styles.Item;

      return (
        <Component key={`${title}-${round}`}>
          <dt>{isFirst ? CURRENT_POSITION : `${ROUND} ${round}`}</dt>
          <Styles.Value>{position || '-'}</Styles.Value>
        </Component>
      );
    });

  return (
    <Styles.Root>
      <Styles.Title>{title}</Styles.Title>
      <dl>{RoundsMap}</dl>
    </Styles.Root>
  );
});
