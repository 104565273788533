import firebase from 'firebase/app';
import { IAuth, IPlayer } from '@avid/common';

import { gameAPI } from './game-api';

export interface IUpdateFieldParameters<T extends keyof IPlayer> {
  field: T;
  value: IPlayer[T] | firebase.firestore.FieldValue;
}

export interface IMergeFieldParameters<T extends keyof IPlayer> {
  field: T;
  value: Partial<IPlayer[T]> | firebase.firestore.FieldValue;
}

export interface IUpdateSubfieldParameters<
  T extends keyof IPlayer,
  S extends keyof IPlayer[T]
> {
  field: T;
  subfield: S;
  value: IPlayer[T][S];
}

export type THighestPositions = {
  data: Record<string, string[]>;
};

export const updateUserParameter = (gameCode: string, userCode: string) => <
  S extends keyof IPlayer['main']
>(
  subfield: S,
  value: IPlayer['main'][S]
) =>
  gameAPI.updateSubfield({
    code: gameCode,
    userCode,
    field: 'main',
    subfield,
    value,
  });

export const mergePlayer = (auth: IAuth, fields: RecursivePartial<IPlayer>) =>
  gameAPI.mergePlayer(auth, fields);

export const updateField = (gameCode: string, userCode: string) => <
  T extends keyof IPlayer
>({
  field,
  value,
}: IUpdateFieldParameters<T>) =>
  gameAPI.updateFiled({ code: gameCode, userCode, field, value });

export const mergeField = (gameCode: string, userCode: string) => <
  T extends keyof IPlayer
>({
  field,
  value,
}: IMergeFieldParameters<T>) =>
  gameAPI.mergeField({ code: gameCode, userCode, field, value });

export const updateSubfield = (gameCode: string, userCode: string) => <
  T extends keyof IPlayer,
  S extends keyof IPlayer[T]
>({
  field,
  value,
  subfield,
}: IUpdateSubfieldParameters<T, S>) =>
  gameAPI.updateSubfield({
    code: gameCode,
    userCode,
    field,
    value,
    subfield,
  });
