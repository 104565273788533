import { PossessionLevel } from '@avid/common';

import { action } from 'services/utils';
import { IItemPrice } from 'typings';

import { ShopModals, ShopPages } from './shop.typings';
import { SHOP_ACTION } from './shop.constants';

const {
  ON_OPEN_START_PAGE,
  CLEAR,
  SET_PRICE,
  OPEN_SECTOR_PAGE,
  SET_LEVEL_PRICE,
  OPEN_MODAL,
  SET_COUNTRY,
  SET_LEVEL,
  MOVE_TO_START_PAGE,
} = SHOP_ACTION;

export const ShopActions = {
  onOpenStartPage: () => action(ON_OPEN_START_PAGE),
  setPrice: (price: IPrice | IItemPrice) => action(SET_PRICE, price),
  setLevelPrice: (price: IPrice | IItemPrice, level: PossessionLevel) =>
    action(SET_LEVEL_PRICE, { price, level }),
  setLevel: (level: PossessionLevel) => action(SET_LEVEL, level),
  moveToStartPage: () => action(MOVE_TO_START_PAGE),
  setCountry: (country: IItem) => action(SET_COUNTRY, country),
  openModal: (modal?: ShopModals) => action(OPEN_MODAL, modal),

  openSectorPage: (sector: string, page: ShopPages) =>
    action(OPEN_SECTOR_PAGE, { sector, page }),

  clear: () => action(CLEAR),
};

export type TShopActions = ReturnType<TActionCombiner<typeof ShopActions>>;
