import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TGameStatus } from '@avid/common';

import { GameActions } from 'services/redux';
import { getRoundSecondsLeft } from 'services/utils';

import { useReduxSelector } from './redux';

export const useRoundCountdown = () => {
  const dispatch = useDispatch();

  const roundDuration = useReduxSelector(
    (redux) => redux.game.config?.roundDuration || 0
  );
  const roundsNumber = useReduxSelector(
    (redux) => redux.game.config?.roundsNumber || 0
  );
  const roundNumber = useReduxSelector((redux) => redux.game.round.number);

  const updateRound = useCallback(
    (secondsPassed: number) => {
      const secondsLeft = getRoundSecondsLeft(roundDuration, secondsPassed);

      let status: TGameStatus | undefined = undefined;

      if (secondsLeft <= 0) {
        status = roundNumber >= roundsNumber ? 'finished' : 'paused';

        dispatch(GameActions.merge({ status }));
      }
    },
    [dispatch, roundDuration, roundNumber, roundsNumber]
  );

  return updateRound;
};
