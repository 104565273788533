import { ReactElement, useContext } from 'react';

import { Theme } from 'themes';

import { WorkPage } from 'screens/work/work.typings';
import { WorkContext } from 'screens/work/work.context';

import { JobPromotion } from '../job-promotion';
import { Career } from '../career';
import { WorkChooseSkill } from '../work-choose-skill';
import { WorkTest } from '../work-test';

export const WorkPages = () => {
  const { page } = useContext(WorkContext);

  const Page: Record<WorkPage, ReactElement> = {
    [WorkPage.Start]: <Career />,
    [WorkPage.ChooseSkill]: <WorkChooseSkill />,
    [WorkPage.Test]: <WorkTest />,
    [WorkPage.JobPromotion]: <JobPromotion />,
  };

  return <Theme.PageContainer>{Page[page]}</Theme.PageContainer>;
};
