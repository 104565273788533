import { memo } from 'react';
import styled from 'styled-components';
import { ITravel, versionsPossessionsLevels } from '@avid/common';

import { Grid, Text, WhiteShadowWrapper } from '../../ui';
import { PossessionsRow, TravelsRow } from '../../possessions-list';
import { TImageNames } from '../../image';
import { useMod, useReduxSelector } from 'services/hooks';
import { entriesObject, objectEqual } from 'services/utils';
import { typographyCss } from 'themes';

const Styles = {
  ContentWrapper: styled.div`
    display: grid;
    row-gap: 40px;
  `,
  SectionWrapper: styled.div`
    display: grid;
    row-gap: 16px;
  `,
  ItemsWrapper: styled(Grid)`
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
    align-items: center;

    > * {
      overflow: auto;
    }
  `,
};

export const PossessionsSection = memo(() => {
  const playerPossessions = useReduxSelector(
    (redux) => redux.player.possession,
    objectEqual
  );

  const version = useReduxSelector((redux) => redux.app.version);

  const charityHobbyMod = useMod('charityHobby');

  const Charities = charityHobbyMod?.components.Charities;
  const Hobbies = charityHobbyMod?.components.Hobbies;

  const RenderCharities = Charities ? (
    <Styles.SectionWrapper>
      <Text typography={typographyCss.RalewayBold16}>Charity</Text>

      <Styles.ItemsWrapper>
        <Charities />
      </Styles.ItemsWrapper>
    </Styles.SectionWrapper>
  ) : null;

  const RenderHobbies = Hobbies ? (
    <Styles.SectionWrapper>
      <Text typography={typographyCss.RalewayBold16}>Hobby</Text>
      <Styles.ItemsWrapper>
        <Hobbies />
      </Styles.ItemsWrapper>
    </Styles.SectionWrapper>
  ) : null;

  const RenderPossessions = entriesObject(playerPossessions || {}).map(
    ([item, possessions]) => {
      if (!possessions) {
        return;
      }

      return entriesObject(possessions).map(([level, items]) => {
        const title = version
          ? versionsPossessionsLevels[version]?.[item]?.[level] || level
          : level;

        if (item === 'travel') {
          if (Hobbies) {
            return null;
          }

          return (
            <TravelsRow
              key={item + level}
              title={level}
              travels={items as ITravel[]}
            />
          );
        }

        return (
          <PossessionsRow
            key={item + level}
            title={title}
            images={items as TImageNames[]}
          />
        );
      });
    }
  );

  return (
    <WhiteShadowWrapper $isPreventMaxHeight>
      <Styles.ContentWrapper>
        <Styles.SectionWrapper>
          <Text typography={typographyCss.RalewayBold16}>Possessions</Text>
          <Styles.ItemsWrapper>{RenderPossessions}</Styles.ItemsWrapper>
        </Styles.SectionWrapper>

        {RenderCharities}

        {RenderHobbies}
      </Styles.ContentWrapper>
    </WhiteShadowWrapper>
  );
});
