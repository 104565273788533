import { FC, memo } from 'react';
import styled from 'styled-components';

import { COLORS, Css } from 'themes';
import { ButtonOld } from 'components/button';

interface INextPageButton {
  onClick: () => void;
  isDisabled?: boolean;
  title?: string;
  isMobileOnly?: boolean;
}

const Wrapper = styled.div<{ isMobileOnly?: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 8px;
  background-color: ${COLORS.white};

  ${({ isMobileOnly = false }) => isMobileOnly && Css.mobileOnly};
`;

export const NextPageButton: FC<INextPageButton> = memo((props) => {
  const { onClick, title, isDisabled, isMobileOnly } = props;

  return (
    <Wrapper isMobileOnly={isMobileOnly}>
      <ButtonOld
        onClick={onClick}
        title={title ?? 'Next'}
        isDisabled={isDisabled}
      />
    </Wrapper>
  );
});
