export const SHOP_STRINGS = {
  SECTOR_TITLE: 'What would you like to purchase?',
  POSSESSION_ITEM: 'Which type of home would you like to purchase?',
  BUY_BUTTON: 'Buy',

  POSSESSION_BUY: {
    TITLE: 'Congratulations!',
    SUBTITLE: (possession: string) => `You bought the ${possession}!`,
    BUTTON: 'Continue',
  },

  TRAVEL: {
    TITLE: 'Select the country you are travelling to',
    SELECTOR_PLACEHOLDER: 'Select Country',
    BUY_TITLE: 'How would you like to travel?',
    BUTTON: 'Buy',
  },

  CHARACTER_MODAL: {
    TITLE: 'Congratulations',
    SUBTITLE: 'Enjoy your new character!',
    BUTTON: 'Continue',
  },

  BOTTOM_BAR: {
    GO_BACK: 'Go back',
    BUY: 'Buy',
  },
};
