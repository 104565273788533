import React, { useEffect } from 'react';

import { useReduxSelector } from 'services/hooks';

import { EducationModal, EducationPage } from '../../education.typing';
import { EducationLevel } from '../education-level';

import { SelectEducationTutorial } from './select-education-tutorial';
import { SelectEducationLevelStyles as Styles } from './select-education.styles';
import { getRenderedLevelsProps } from './select-education.utils';
import { SELECT_EDUCATION_IDS } from './select-education.const';

interface IProps {
  setLevel: (level: string) => void;
  setModal: (modal?: EducationModal) => void;
  setPage: (page: EducationPage) => void;
  clearEducationState: () => void;
}

export const SelectEducation = React.memo((props: IProps) => {
  const { clearEducationState, ...other } = props;

  const educationLevels = useReduxSelector(
    (redux) => redux.app.educationLevels
  );

  useEffect(() => {
    clearEducationState();
  }, [clearEducationState]);

  const LevelsMap = getRenderedLevelsProps(educationLevels).map(
    (levelProps, index) =>
      levelProps && (
        <EducationLevel
          key={levelProps.level}
          id={index === 0 ? SELECT_EDUCATION_IDS.LEVEL : undefined}
          {...levelProps}
          {...other}
        />
      )
  );

  return (
    <Styles.Root>
      <SelectEducationTutorial />

      <Styles.ResponsiveContainer id={SELECT_EDUCATION_IDS.LEVELS}>
        {LevelsMap}
      </Styles.ResponsiveContainer>
    </Styles.Root>
  );
});
