import { ISector } from '@avid/common';
import styled, { css } from 'styled-components';
import { Step } from 'react-joyride';

import { SelectorThemed } from 'components/selector';
import { AppTutorialController } from 'components/tutorial';
import { Css } from 'themes';
import {
  useShowCareerCentreTutorial,
  useWindowDimensions,
} from 'services/hooks';

interface ICareerCentreSelectorProps {
  sectors: IItem[];
  setSector: (value: ISector) => void;
  isDisabled: boolean;
  selectedSector?: IItem;
}

const PLACEHOLDER = 'Select sector';

const STEPS: Step[] = [
  {
    target: '#career_centre-selector',
    content: 'Choose any sector.',
    disableBeacon: true,
  },
];

const Root = styled.div`
  ${Css.responsiveCenterWide};
`;

export const SelectorMenuCss = css<{ maxHeight: number }>`
  max-height: ${({ maxHeight }) => maxHeight}px;
`;

const CareerCentreSelectorTutorialComponent = () => {
  return <AppTutorialController steps={STEPS} labelClose="Got it!" />;
};

const CareerCentreSelectorTutorial = () => {
  const isShow = useShowCareerCentreTutorial();

  if (!isShow) {
    return null;
  }

  return <CareerCentreSelectorTutorialComponent />;
};

export const CareerCentreSelector = (props: ICareerCentreSelectorProps) => {
  const { isDisabled, sectors, selectedSector, setSector } = props;

  const { height } = useWindowDimensions();

  const selectorMaxHeight = height - 300;

  return (
    <>
      <Root id="career_centre-selector">
        <SelectorThemed
          placeholder={PLACEHOLDER}
          isDisabled={isDisabled}
          selectValue={selectedSector}
          menuMaxHeight={`${selectorMaxHeight}px`}
          items={sectors}
          setValue={setSector}
        />
      </Root>

      <CareerCentreSelectorTutorial />
    </>
  );
};
