import { ANCHORS_TOTAL } from 'constants/create-character';
import { useCallback, useState } from 'react';

export interface IAnchorsStepProps {
  initAnchors: string[];
  onNext: (anchors: string[]) => void;
}

export const useAnchorsStep = (props: IAnchorsStepProps) => {
  const { initAnchors, onNext } = props;

  const [anchors, setAnchors] = useState(initAnchors);

  const isNextAvailable = anchors.length === ANCHORS_TOTAL;

  const selectAnchor = useCallback(
    (value: string) =>
      setAnchors((prev) => {
        // previously unselected value
        if (prev.indexOf(value) === -1) {
          if (prev.length >= ANCHORS_TOTAL) {
            return prev;
          }

          return [...prev, value];
        }

        // previously selected value -> remove
        return prev.filter((anchor) => anchor !== value);
      }),
    []
  );

  const handleNext = () => {
    if (anchors.length !== ANCHORS_TOTAL) {
      return;
    }

    onNext(anchors);
  };

  return { anchors, isNextAvailable, selectAnchor, handleNext };
};
