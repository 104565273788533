import { FC } from 'react';
import styled from 'styled-components';
import { IVideoParameter } from '@avid/common';

import { spacing, Theme } from 'themes';
import { Loader } from 'components/loader';
import { MORE_STRINGS } from '../more.strings';
import { MoreWrapper } from './more-wrapper';

import { useFetch, useGameApi } from 'services/hooks';

const { TUTORIAL_TITLE } = MORE_STRINGS;

const VideosContainer = styled(Theme.Flexbox)`
  display: flex;
  align-items: center;
`;

const YouTubeVideo = ({ title, video }: IVideoParameter) => (
  <>
    <Theme.Paragraph>{title}</Theme.Paragraph>
    <iframe width="340" height="315" src={video} title="video"></iframe>
  </>
);

export const VideoTutorial: FC = () => {
  const { fetchTutorialVideos } = useGameApi();
  const [videos, isLoading] = useFetch(fetchTutorialVideos);

  if (!videos || isLoading) {
    return <Loader />;
  }

  return (
    <MoreWrapper>
      <Theme.Title $textAlign="center">{TUTORIAL_TITLE}</Theme.Title>

      <VideosContainer $isColumn $itemGap={spacing(2)}>
        {videos.map((video, key) => (
          <YouTubeVideo key={key} {...video} />
        ))}
      </VideosContainer>
    </MoreWrapper>
  );
};
