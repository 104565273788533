import { CreateCharacterSwitch } from 'components/create-character';

import { IReviewStepFormProps, ReviewStepForm } from '../review-step-form';

import { useReviewStep } from './review-step.state';

export const ReviewStep = (props: IReviewStepFormProps) => {
  const { createCharacter, lifeGoals, onSetStep } = props;

  const { isLoading, onConfirm } = useReviewStep(createCharacter, lifeGoals);

  return (
    <CreateCharacterSwitch
      isNextAvailable={!isLoading}
      buttonLabel="Confirm"
      onNext={onConfirm}
    >
      <ReviewStepForm
        createCharacter={createCharacter}
        lifeGoals={lifeGoals}
        onSetStep={onSetStep}
      />
    </CreateCharacterSwitch>
  );
};
