import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CallBackProps, STATUS, Step } from 'react-joyride';

import { AppTutorialController } from 'components/tutorial';
import { useShowCareerCentreTutorial } from 'services/hooks';
import { callFunctionAction } from 'services/api';
import { PlayerActions } from 'services/redux';

const STEPS: Step[] = [
  {
    target: '#career_centre-button_skills',
    content: 'Click “Check Skills Gap”.',
    disableBeacon: true,
  },
];

export const JobDescriptionModalTutorialComponent = () => {
  const passedRef = useRef(false);

  const dispatch = useDispatch();

  const completeTutorial = async () => {
    const result = await callFunctionAction('@TUTORIALS/COMPLETE', {
      tutorialKey: 'careerCentre',
    });

    dispatch(PlayerActions.merge(result.mergePlayer));
  };

  const handleCallback = (data: CallBackProps) => {
    const { status } = data;

    if (status === STATUS.FINISHED && !passedRef.current) {
      passedRef.current = true;
      completeTutorial();
    }
  };

  return (
    <AppTutorialController
      steps={STEPS}
      callback={handleCallback}
      labelClose="Got it!"
    />
  );
};

export const JobDescriptionModalTutorial = () => {
  const isShow = useShowCareerCentreTutorial();

  if (!isShow) {
    return null;
  }

  return <JobDescriptionModalTutorialComponent />;
};
