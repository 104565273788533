import {
  CreateCharacterLayout,
  CreateCharacterToolWrapper,
} from 'components/create-character';

import { CreateCharacterTrack } from '../create-character-track';
import { PerformanceEditor } from '../performance-editor';

import {
  IPerformanceStepProps,
  usePerformanceStep,
} from './performance-step.state';

export const PerformanceStep = (props: IPerformanceStepProps) => {
  const {
    performance,
    usedPoints,

    selectPerformance,
    handleNext,
  } = usePerformanceStep(props);

  return (
    <CreateCharacterLayout
      title="Performance Character"
      description="What is your special ability? You'll get the relevant bonus throughout the game."
      image="progress2"
      isNextAvailable={Boolean(performance)}
      onNext={handleNext}
    >
      <CreateCharacterTrack
        usedPoints={usedPoints}
        maxPoints={1}
        labelEnd="selected"
      />

      <CreateCharacterToolWrapper>
        <PerformanceEditor
          selectedPerformance={performance}
          onSelectPerformance={selectPerformance}
        />
      </CreateCharacterToolWrapper>
    </CreateCharacterLayout>
  );
};
