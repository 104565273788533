import { FC } from 'react';

import { TImageNames } from 'components/image';

import { useReduxSelector } from 'services/hooks';
import { BigAvatar } from './big-avatar';

export interface ICharacterIconProps {
  className?: string;
  sizeIcon?: string;
}

export const CharacterIcon: FC<ICharacterIconProps> = (props) => {
  const { className, sizeIcon } = props;

  const image = useReduxSelector((redux) => redux.player.createCharacter.icon);

  return (
    <BigAvatar
      image={image as TImageNames}
      size={sizeIcon}
      className={className}
    />
  );
};
