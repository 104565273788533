import { ISector } from '@avid/common';

import { VersionsAPI } from 'services/api';
import { useReduxSelector, useUpdateState } from 'services/hooks';
import { getSectorCareerTreeAsync } from 'shared';
import { useSectorsNoWork } from 'services/hooks';

import { CareerCenterModal } from './career-center.typing';

interface ICareerCenterState {
  isLoadingSector: boolean;
  isLoadingDescription: boolean;
  sector?: ISector;
  careerTree?: string[][];
  modal?: CareerCenterModal;
  job?: string;
  jobDescription?: string;
  jobSalary?: number;
}

const getFlattenCareerTree = (newTree: string[][], isSectorFull: boolean) =>
  isSectorFull ? newTree : [newTree.flat().filter((job) => job)];

const INIT_STATE: ICareerCenterState = {
  isLoadingSector: false,
  isLoadingDescription: false,
};

export const useCareerCenter = () => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  const version = useReduxSelector((redux) => redux.app.version);
  const noWorkSectors = useSectorsNoWork();
  const playerJob = useReduxSelector((redux) => redux.player.work?.work);

  const updateJobDescriptionAsync = async (job: string) => {
    if (!version || !state.sector) {
      return;
    }

    updateState({ isLoadingDescription: true });
    try {
      const [description, salary = 0] = await Promise.all([
        VersionsAPI.sectors.work.job.fetchDescription({
          version,
          sector: state.sector.value,
          job,
        }),
        VersionsAPI.sectors.work.job.fetchSalary({
          version,
          sector: state.sector.value,
          job,
        }),
      ]);

      if (!description) {
        updateState({ isLoadingDescription: false });
        return;
      }

      updateState({
        jobDescription: description,
        jobSalary: salary,
        isLoadingDescription: false,
      });
    } catch {
      updateState({ isLoadingDescription: false });
    }
  };

  const onSetSector = async (sector: ISector) => {
    if (!version || state.isLoadingSector) {
      return;
    }

    try {
      updateState({ sector, isLoadingSector: true });

      const fetchedTree = await getSectorCareerTreeAsync(version, sector.value);

      if (!fetchedTree) {
        updateState({ isLoadingSector: false });
        return;
      }

      updateState({
        careerTree: getFlattenCareerTree(fetchedTree, sector.isFull),
        isLoadingSector: false,
      });
    } catch {
      updateState({ isLoadingSector: false });
    }
  };

  const onCloseModal = () => updateState({ modal: undefined });

  const onOpenModal = (modal: CareerCenterModal) => updateState({ modal });

  const openDescriptionModal = (job: string) => {
    updateState({
      job,
      modal: CareerCenterModal.Description,
    });

    updateJobDescriptionAsync(job);
  };

  return {
    ...state,
    sectors: noWorkSectors,
    playerJob,

    onSetSector,
    onOpenModal,
    onCloseModal,
    openDescriptionModal,
  };
};
