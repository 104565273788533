import { css } from 'styled-components';

import { FONT_SIZES, FONT_FAMILIES, COLORS, MEDIA } from 'themes';

const selectedCss = css`
  background-color: ${COLORS.white};
  font-family: ${FONT_FAMILIES.medium};
  border-bottom-color: ${COLORS.purple};
`;

export const homeTabCss = css<{ isActiveByDefault?: boolean }>`
  padding: 8px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  line-height: ${FONT_SIZES.px18};
  text-align: center;
  white-space: nowrap;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  font-family: ${FONT_FAMILIES.regular};
  transition: all 0.1s ease-out;

  ${MEDIA.LAPTOP} {
    flex: 1;
  }

  &.react-tabs__tab--selected {
    ${selectedCss}
  }

  ${({ isActiveByDefault }) => isActiveByDefault && selectedCss}
`;
