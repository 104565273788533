import { ReactNode } from 'react';
import styled from 'styled-components';

import {
  BUTTON_THEME,
  CSS_BUTTON_PRESET,
  TButtonTheme,
} from './button.constants';

export interface IButtonOldProps {
  title: string;

  onClick?: () => void;

  id?: string;
  className?: string;
  isDisabled?: boolean;
  children?: ReactNode;
  styleTheme?: TButtonTheme;
}

const Wrapper = styled.button<{ $styleTheme?: TButtonTheme }>`
  ${CSS_BUTTON_PRESET}
  ${(props) => BUTTON_THEME[props.$styleTheme ?? 'default']}
`;

export const ButtonOld = (props: IButtonOldProps) => {
  const { className, id, onClick, title, isDisabled, styleTheme, children } =
    props;

  return (
    <Wrapper
      id={id}
      disabled={isDisabled}
      onClick={onClick}
      className={className}
      $styleTheme={styleTheme}
    >
      {title}
      {children}
    </Wrapper>
  );
};
