import styled from 'styled-components';

import { Theme, spacing, MEDIA } from 'themes';

export const ManyTabsStyles = {
  Root: styled(Theme.Flexbox)`
    width: 90%;
    height: 100%;
    margin: 0 auto;
    padding: ${spacing(2)};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    ${MEDIA.TABLET} {
      max-width: 600px;
    }
  `,
  Title: styled(Theme.Title)`
    margin: 0;
  `,
  Paragraph: styled(Theme.Paragraph)`
    margin: 0;

    &:not(first-child) {
      margin-top: ${spacing(1)};
    }
  `,
};
