import { Image, TImageNames } from 'components/image';
import { COLORS, typographyCss } from 'themes';
import { PerformanceItemBonus } from '../performance-item-bonus';

import { PerformanceItemStyles as Styles } from './performance-item.styles';
import { memo } from 'react';

interface IPerformanceItemProps {
  title: string;
  description: string;
  titleColor: string;
  image: TImageNames;
  bonuses: string[];
  isActive: boolean;
  onSelect?: (performance: string) => void;
  className?: string;
}

export const PerformanceItem = memo((props: IPerformanceItemProps) => {
  const {
    title,
    description,
    titleColor,
    image,
    bonuses,
    isActive,
    className,
    onSelect,
  } = props;

  const onClick = onSelect && (() => onSelect(title));

  return (
    <Styles.Root isActive={isActive} onClick={onClick} className={className}>
      <Image type={image} />

      <Styles.Title
        typography={typographyCss.px12Bold}
        color={isActive ? COLORS.white : titleColor}
      >
        {title}
      </Styles.Title>

      <Styles.Description
        typography={typographyCss.littleRegular}
        color={isActive ? COLORS.white : COLORS.black}
      >
        {description}
      </Styles.Description>

      <PerformanceItemBonus bonuses={bonuses} />
    </Styles.Root>
  );
});
