import { ISkillInfo, ISkill, ICharacterSmart, IAbility } from '@avid/common';

import { keyofObject, recordValue } from 'services/utils';
import { GSC_MAP } from 'constants/skills-gsc';

const minGsc = Math.min(...keyofObject(GSC_MAP));
const maxGsc = Math.max(...keyofObject(GSC_MAP));

import {
  IGetAbilitiesByName,
  IGetIncrementedAbilitiesParams,
  IGetLearnedAbilityLevelParams,
} from './skills.shared.typing';

export const getAbilitiesByName = (params: IGetAbilitiesByName) => {
  const { name, abilities = [] } = params;

  return abilities.find((ability) => ability.name === name);
};

export const getGSCLabel = (level: number) => {
  const CASTED_MAP = GSC_MAP as Record<number, string>;

  if (level <= minGsc) {
    return CASTED_MAP[minGsc];
  }
  if (level >= maxGsc) {
    return CASTED_MAP[maxGsc];
  }

  const some = Math.max(
    ...keyofObject(CASTED_MAP).filter((value) => +value <= level)
  );
  return CASTED_MAP[some];
};

export const getLevelAbility = (name: string, abilities: IAbility[]) => {
  const level = getAbilitiesByName({ name, abilities })?.level;
  return level ? +level : 0;
};

export const transformSkillInfoToSkill = (
  info: ISkillInfo,
  abilities: IAbility[] = []
): ISkill => ({
  ...info,
  level: getLevelAbility(info.name, abilities),
});

export const transformSkillInfoToSkillCallback = (abilities: IAbility[]) => (
  info: ISkillInfo
): ISkill => ({
  ...info,
  level: getLevelAbility(info.name, abilities),
});

export const getSmartMultiple = (
  characterSmarts: ICharacterSmart,
  skillSmart: keyof ICharacterSmart
) => {
  const smartValue = recordValue(characterSmarts, skillSmart);
  return 1 + (smartValue ? (smartValue - 5) / 10 : 0);
};

export const getLearnedAbilityLevel = (
  params: IGetLearnedAbilityLevelParams
) => {
  const { currentLevel, learnLevel, multiple, maxLevel } = params;

  const increaseBy = learnLevel - currentLevel;
  const newLevel = currentLevel + increaseBy * multiple;

  if (newLevel > maxLevel) {
    return maxLevel;
  }

  return newLevel <= currentLevel ? currentLevel : newLevel;
};

export const getMergedAbilities = (
  characterAbilities: IAbility[] = [],
  learnedAbilities: IAbility[]
) => {
  const copy = [...characterAbilities];

  learnedAbilities.forEach((learnedAbility) => {
    const characterAbilityIndex = copy.findIndex(
      (characterAbility) => characterAbility.name === learnedAbility.name
    );

    if (characterAbilityIndex !== -1) {
      copy.splice(characterAbilityIndex, 1, learnedAbility);
      return;
    }

    copy.push(learnedAbility);
  });

  return copy;
};

export const getIncrementedAbilities = (
  params: IGetIncrementedAbilitiesParams
) => {
  const {
    skillInfos,
    characterAbilities = [],
    characterSmart,
    maxAbilityLevel,
  } = params;
  const newAbilities: IAbility[] = [];

  skillInfos.forEach((skillInfo) => {
    const smartMultiple = getSmartMultiple(characterSmart, skillInfo.smart);

    const characterAbility = characterAbilities.find(
      (ability) => ability.name === skillInfo.name
    );

    const abilityLevelParams = {
      currentLevel: characterAbility?.level || 0,
      learnLevel: (characterAbility?.level || 0) + 1,
      multiple: smartMultiple,
    };

    const abilityLevel = getLearnedAbilityLevel({
      ...abilityLevelParams,
      maxLevel: maxAbilityLevel,
    });

    newAbilities.push({
      name: skillInfo.name,
      type: skillInfo.type,
      level: abilityLevel,
    });
  });

  return newAbilities;
};
