import { useReduxSelector } from 'services/hooks';
import { arrayEqual } from 'services/utils';

import { EducationProgressItem } from './education-progress-item';
import { ProgressList } from './ui';

interface IProps {
  isPreventMaxHeight?: boolean;
}

export const EducationProgressList = (props: IProps) => {
  const { isPreventMaxHeight } = props;

  const educations = useReduxSelector(
    (redux) => redux.player.educations || [],
    arrayEqual
  );

  const educationList = [...educations]
    .reverse()
    .map((study, index) => (
      <EducationProgressItem key={index} education={study} />
    ));

  return (
    <ProgressList
      title="Education"
      items={educationList}
      isPreventMaxHeight={isPreventMaxHeight}
    />
  );
};
