export const EDUCATION_STRINGS = {
  TEST: {
    TITLE: 'Test',
    NEXT_TEST_BUTTON: 'Next',

    PASS_BUTTON: 'Continue',
    NOT_PASS_BUTTON: 'Close',
  },
  EXIT_TEST: {
    TITLE:
      'Exit quiz Are you sure to leave the game? You will lose your Energy and $',
    EXIT_BUTTON: 'Exit',
    STAY_BUTTON: 'Stay',
  },
  SECTOR_TITLE: 'Pick your major',
  DEGREE: {
    TITLE: 'Choose your degree',
  },
  START_TEST: {
    TITLE: 'Score above 50% to pass',
    START_BUTTON: 'Start Test',
    STOP_BUTTON: 'Go Back',
  },
  WRONG_LEVEL: {
    TITLE:
      'You are not yet qualified for this education level. Please attempt previous levels first',
    CONTINUE_BUTTON: 'Continue',
  },
  WRONG_DEGREE: {
    BUTTON: 'Continue',
  },
  AGREE_SECTOR: {
    CONFIRM_BUTTON: 'Yes',
    CANCEL_BUTTON: 'Go back',
  },
};
