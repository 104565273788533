import { Route, Switch } from 'react-router-dom';

import { RewardDisplay } from 'components/reward-modal';
import { MainTutorial } from 'components/main-tutorial';
import { BadgesList } from 'screens/badges-list';
import { CareerCenter } from 'screens/career-centre';
import { Courses } from 'screens/courses';
import { Education } from 'screens/education';
import { FindJob } from 'screens/find-job';
import { Home } from 'screens/home';
import { More } from 'screens/more';
import { Shop } from 'screens/shop';
import { Work } from 'screens/work';
import { LifeDashboard, LifeGoals } from 'screens/mods';
import { combineModsRoutes, getModComponent } from 'services/utils';
import { useMod } from 'services/hooks';
import { PAGE_ROUTES } from 'constants/routes';
import { CSS_CLASSES } from 'constants/css';

import { BotBar, MainModal, TipToastsDisplay, TopBar } from './components';
import {
  MainInterval,
  ApplyRoundCountdown,
  ModNotifications,
} from './containers';

import { useMain } from './main.state';
import { MainStyles } from './main.styles';

export const Main = () => {
  const { isShowBottomBar, wrapperRef } = useMain();

  const FinancialMC = getModComponent(useMod('financial')?.screens.Financial);

  return (
    <Route path={combineModsRoutes(PAGE_ROUTES)}>
      <MainStyles.Wrapper ref={wrapperRef} className={CSS_CLASSES.MAIN_WRAPPER}>
        <TopBar />

        <MainStyles.Section $isShowBottomBar={isShowBottomBar}>
          <Switch>
            <Route path={PAGE_ROUTES.shop} component={Shop} />
            <Route path={PAGE_ROUTES.work} component={Work} />
            <Route path={PAGE_ROUTES.course} component={Courses} />
            <Route path={PAGE_ROUTES.education} component={Education} />
            <Route path={PAGE_ROUTES.more} component={More} />
            <Route path={PAGE_ROUTES.findJob} component={FindJob} />
            <Route path={PAGE_ROUTES.lifeDashboard} component={LifeDashboard} />
            <Route path={PAGE_ROUTES.lifeGoals} component={LifeGoals} />
            <Route path={PAGE_ROUTES.badgesList} component={BadgesList} />
            <Route path={PAGE_ROUTES.careerCenter} component={CareerCenter} />
            <Route path={PAGE_ROUTES.home}>
              <Home scrollRef={wrapperRef} />
            </Route>

            <FinancialMC />
          </Switch>
        </MainStyles.Section>

        <BotBar />

        <MainTutorial />

        <TipToastsDisplay />

        <RewardDisplay />
      </MainStyles.Wrapper>

      <MainModal />
      <ModNotifications />

      <MainInterval />
      <ApplyRoundCountdown />
    </Route>
  );
};
