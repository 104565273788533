import styled from 'styled-components';

import { Image } from 'components/image';

export const ReviewSectionStyles = {
  Root: styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  TitleWrapper: styled.div`
    display: flex;
    justify-content: center;
  `,
  TitleIcon: styled(Image)`
    cursor: pointer;
    margin: 0;
    margin-left: 8px;
  `,
  ChildrenWrapper: styled.div`
    padding-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
};
