import { CareerInformation, CareerCentreModal } from './components';
import { useCareerCenter } from './career-centre.state';

export const CareerCenter = () => {
  const {
    sectors,
    isLoadingSector,
    sector,
    job,
    playerJob,
    careerTree,
    modal,
    isLoadingDescription,
    jobDescription,
    jobSalary,

    onSetSector,
    onOpenModal,
    onCloseModal,
    openDescriptionModal,
  } = useCareerCenter();

  return (
    <>
      <CareerInformation
        isLoading={isLoadingSector}
        sector={sector}
        sectors={sectors}
        careerTree={careerTree}
        playerWork={playerJob}
        onSetSector={onSetSector}
        onOpenDescriptionModal={openDescriptionModal}
      />

      {modal && sector && (
        <CareerCentreModal
          modal={modal}
          job={job}
          sector={sector.value}
          isLoadingDescription={isLoadingDescription}
          description={jobDescription}
          salary={jobSalary}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
        />
      )}
    </>
  );
};
