import { useState, useCallback, useEffect } from 'react';

import { useReduxSelector } from 'services/hooks';
import {
  formatTime,
  getRoundSecondsPassed,
  getRoundSecondsLeft,
} from 'services/utils';

export const useRoundTimer = () => {
  const [secondsLeft, setSecondsLeft] = useState(0);

  const roundStartUnix = useReduxSelector(
    (redux) => redux.game.round.startUnix
  );
  const roundDuration = useReduxSelector(
    (redux) => redux.game.config?.roundDuration || 0
  );
  const status = useReduxSelector((redux) => redux.game.status);

  const roundTime =
    secondsLeft <= 0 || status !== 'in-progress'
      ? 'Waiting...'
      : formatTime(secondsLeft);

  const updateTimer = useCallback(() => {
    const secondsPassed = getRoundSecondsPassed(roundStartUnix);

    const newSecondsLeft = getRoundSecondsLeft(roundDuration, secondsPassed);

    setSecondsLeft(newSecondsLeft);
  }, [roundDuration, roundStartUnix]);

  useEffect(() => {
    if (!roundDuration || !roundStartUnix || status !== 'in-progress') {
      return;
    }

    updateTimer();

    const interval = setInterval(updateTimer, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [roundDuration, roundStartUnix, status, updateTimer]);

  return { roundTime };
};
