import { IGameConstants, IPlayer } from '@avid/common';

import { gameAPI } from '../api';
import { db } from '../firebase';

import { useReduxSelector } from './redux';
import { useAPI } from './api.utils';

export const useGameApi = () => {
  const { code, email } = useReduxSelector((redux) => ({
    code: redux.player.authInfo.code,
    email: redux.player.authInfo.email,
  }));

  // --- Utils --- //
  const { updateField, updateSubfield } = useAPI();

  // *** Log in *** //

  const savePlayerInfo = (
    player: Pick<IPlayer, 'authInfo' | 'createCharacter' | 'main'>
  ) =>
    gameAPI.post(
      { document: code || '', subCollection: 'users', subDocument: email },
      player
    );

  const fetchGameCollection = async () =>
    (
      await db.collection('constants').doc('game').get()
    ).data() as Promise<IGameConstants>;

  const fetchTutorialVideos = async () =>
    (await fetchGameCollection()).tutorialVideo;

  return {
    updateField,
    updateSubfield,

    savePlayerInfo,

    fetchTutorialVideos,
  };
};
