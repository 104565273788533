import { FC } from 'react';
import { PossessionLevel } from '@avid/common';

import { spacing, Theme } from 'themes';
import { SelectorThemed } from 'components/selector';
import { useShopUtils } from 'screens/shop/shop.utils';
import { useUtils } from 'services/hooks';
import { convertToItemString } from 'services/utils';
import { Loader } from 'components/loader';
import { COUNTRIES } from 'constants/countries';
import { SHOP_STRINGS } from 'screens/shop/shop.strings';

import { ShopButtonBar } from '../shop-button-bar';
import { IItemProps } from '../shop-possession-item';
import { TravelShopItem } from './travel-shop-item';

import { useTravelShop } from './travel-shop.state';
import { TravelShopStyles } from './travel-shop.styles';

const { BUY_TITLE, SELECTOR_PLACEHOLDER, TITLE } = SHOP_STRINGS.TRAVEL;

export const TravelShop: FC = () => {
  const {
    country,
    level,

    prices,

    isLoadingPrice,

    setLevel,
    setTravelSector,
    buyTravel,
    checkPrice,
  } = useTravelShop();
  const { checkCannotBuy } = useUtils();
  const { moveToStartPage } = useShopUtils();

  if (!prices || isLoadingPrice) {
    return <Loader />;
  }

  const Item = (props: IItemProps) => (
    <TravelShopItem
      isSelect={level === props.level}
      isEnable={checkPrice(props.level)}
      onSelectItem={setLevel(props.level)}
      level={props.level}
      price={prices[props.level]}
    />
  );

  const isCannotBuy = () => {
    if (!level || !country.value) {
      return true;
    }

    const { energy, money } = prices[level];
    return checkCannotBuy({ energy, money: money });
  };

  return (
    <>
      <Theme.Flexbox
        $isColumn
        $justifyContent="space-between"
        $textAlign="center"
        $itemGap="20px"
      >
        <Theme.Wrapper $itemGap={{ default: spacing(4) }}>
          <Theme.Wrapper $itemGap={{ default: spacing(2) }}>
            <Theme.Title>{TITLE}</Theme.Title>

            <SelectorThemed
              selectValue={country}
              setValue={setTravelSector}
              theme="blue"
              placeholder={SELECTOR_PLACEHOLDER}
              items={COUNTRIES.map(convertToItemString)}
            />
          </Theme.Wrapper>

          <Theme.Wrapper $itemGap={{ default: spacing(2) }}>
            <Theme.Title>{BUY_TITLE}</Theme.Title>

            <TravelShopStyles.Wrapper>
              <Item level={PossessionLevel.Budget} />
              <Item level={PossessionLevel.Standard} />
              <Item level={PossessionLevel.Luxury} />
            </TravelShopStyles.Wrapper>
          </Theme.Wrapper>
        </Theme.Wrapper>

        <ShopButtonBar
          isDisabledBuy={isCannotBuy()}
          onGoBack={moveToStartPage}
          onBuy={buyTravel}
        />
      </Theme.Flexbox>
    </>
  );
};
