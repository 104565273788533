import { MiddlewareAPI } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IReduxStore, PlayerThunk, TActions } from 'services/redux';
import { entriesObject } from 'services/utils';

import { rewardsCheckers } from '../checkers';
import { INDICATORS } from '../rewards.constants';
import { TRewardsIndicator } from '../rewards.typing';

import { careerGoldRewardType } from './handler-service.constants';

export type TStoreAPI = MiddlewareAPI<
  ThunkDispatch<IReduxStore, {}, TActions>,
  IReduxStore
>;

export class RewardsService {
  private static _state: IReduxStore | null = null;
  private static _dispatch: TStoreAPI['dispatch'];

  private static _getNotAchievedRewards() {
    const state = this._state;

    if (!state) {
      return;
    }

    const { options } = state.rewards;
    const { rewards } = state.player;

    if (!options) {
      return;
    }

    return entriesObject(options).filter(
      ([rewardName]) => !rewards?.includes(rewardName)
    );
  }

  private static _getAchievedRewardTypes(indicators: TRewardsIndicator[]) {
    const state = this._state;
    if (!state) {
      return;
    }

    const notAchievedRewards = this._getNotAchievedRewards();
    if (!notAchievedRewards || !notAchievedRewards.length) {
      return;
    }

    let achievedRewardTypes: string[] = [];
    let achievedIndicatorTypes: string[] = [];

    indicators.forEach((indicator) => {
      const checkedRewards = notAchievedRewards.filter(
        ([, rewardOptions]) => rewardOptions.indicator === indicator
      );

      if (!checkedRewards || !checkedRewards.length) {
        return;
      }

      switch (indicator) {
        case INDICATORS.educationLevel: {
          const completedEducationLevels = state.player.educations?.map(
            (education) => education.level
          );

          achievedIndicatorTypes = rewardsCheckers.education(
            checkedRewards,
            completedEducationLevels
          );

          break;
        }

        case INDICATORS.career: {
          achievedIndicatorTypes = rewardsCheckers.career(
            checkedRewards,
            state.player.work
          );

          break;
        }

        case INDICATORS.money: {
          achievedIndicatorTypes = rewardsCheckers.rich(
            checkedRewards,
            state.player.main.money
          );

          break;
        }

        case INDICATORS.happiness: {
          achievedIndicatorTypes = rewardsCheckers.happiness(
            checkedRewards,
            state.player.main.mood
          );

          break;
        }

        case INDICATORS.skills: {
          achievedIndicatorTypes = rewardsCheckers.skills(
            checkedRewards,
            state.player.skills
          );

          break;
        }

        case INDICATORS.possession: {
          achievedIndicatorTypes = rewardsCheckers.possession(
            checkedRewards,
            state.player.possession
          );

          break;
        }

        case INDICATORS.travels: {
          achievedIndicatorTypes = rewardsCheckers.travels(
            checkedRewards,
            state.player.possession?.travel
          );

          break;
        }

        case INDICATORS.outfit: {
          achievedIndicatorTypes = rewardsCheckers.outfit(state.player.icons);

          break;
        }

        default:
          break;
      }

      achievedRewardTypes = achievedRewardTypes.concat(achievedIndicatorTypes);
    });

    return achievedRewardTypes;
  }

  public static initializeStoreAPI(storeAPI: TStoreAPI) {
    this._state = storeAPI.getState();
    this._dispatch = storeAPI.dispatch;
  }

  public static handleRewards(indicators: TRewardsIndicator[]) {
    const achievedTypes = this._getAchievedRewardTypes(indicators);
    if (!achievedTypes || !achievedTypes.length) {
      return;
    }

    achievedTypes.forEach(async (rewardType) => {
      await this._dispatch(PlayerThunk.presentReward(rewardType));
    });
  }

  public static handleAllRewards() {
    this.handleRewards(Object.values(INDICATORS));
  }

  public static async handleCareerGoldReward() {
    const state = this._state;
    const notAchievedRewards = this._getNotAchievedRewards();

    if (!notAchievedRewards || !notAchievedRewards.length || !state) {
      return;
    }

    const careerGoldReward = notAchievedRewards.filter(
      ([rewardName]) => rewardName === careerGoldRewardType
    )[0];
    if (!careerGoldReward || !state.app.version) {
      return;
    }

    const achieved = await rewardsCheckers.careerGold(
      state.app.version,
      state.player.work
    );

    if (!achieved?.length) {
      return;
    }

    await this._dispatch(PlayerThunk.presentReward(achieved[0]));
  }
}
