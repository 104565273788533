import { useState } from 'react';

import { ButtonOld, IButtonOldProps } from './button';

import { CustomComponent } from 'typings';

export const OnetimeClickButton: CustomComponent<IButtonOldProps> = (props) => {
  const {
    onClick,
    isDisabled,
    title,
    className,
    id,
    styleTheme: $styleTheme,
  } = props;

  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleClick = () => {
    if (!isClicked && onClick) {
      onClick();
    }
    setIsClicked(true);
  };

  return (
    <ButtonOld
      id={id}
      className={className}
      styleTheme={$styleTheme}
      title={title}
      isDisabled={isDisabled || isClicked}
      onClick={handleClick}
    />
  );
};
