import styled from 'styled-components';

import { Image } from 'components/image';
import { ButtonOld } from 'components/button';
import { COLORS } from 'themes';

export const CareerCenterButtonStyles = {
  Root: styled.div`
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
  `,
  Button: styled(ButtonOld)`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${COLORS.blue};
    border-radius: 10px;
    margin-top: 32px;
    max-width: 346px;
    min-width: 100%;
    height: 88px;
  `,
  Icon: styled(Image)`
    flex-shrink: 1;
    margin: 0;
    margin-right: 16px;
  `,
};
