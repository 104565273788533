import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { Css, Theme } from 'themes';

import { MorePage } from 'screens/more';
import { MoreStartPage } from '../more-start-page';
import { VideoTutorial } from '../video-tutorial';

import { useMorePages } from './more-pages.state';

const CenteredContainer = styled(Theme.Wrapper)`
  ${Css.responsiveCenterWide};
`;

export const MorePages: FC = () => {
  const { page } = useMorePages();

  const Pages: Record<MorePage, ReactElement> = {
    [MorePage.Start]: <MoreStartPage />,
    [MorePage.Tutorial]: <VideoTutorial />,
  };

  return (
    <Theme.PageContainer>
      <CenteredContainer>{Pages[page]}</CenteredContainer>
    </Theme.PageContainer>
  );
};
