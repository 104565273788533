import { IAbility, TGameVersion, TJobSkills } from '@avid/common';

import { VersionsAPI } from 'services/api';
import { entriesObject } from 'services/utils/object';

import { getAbilitiesByName } from '../skills';

import {
  ICalculateBonusParams,
  ICalculateExperienceParams,
  ICheckLevelUpperParams,
  ICheckMinLevel,
  ICheckNeedWorkExperienceParams,
  IGetRelevantSkillsParams,
  IGetTotalSalaryParams,
} from './work.shared.typing';

export const checkNumberLevel = (level: string) => !Number.isNaN(+level);

const checkIsMinLevel = (params: ICheckMinLevel) => {
  const { needLevel, isMinLevel, currentLevel } = params;
  return isMinLevel ? currentLevel >= needLevel : currentLevel > needLevel;
};

export const checkLevelUpper = (params: ICheckLevelUpperParams) => {
  const { currentLevel, needLevel, isMinLevel = false } = params;

  if (needLevel === null) {
    return true;
  }

  return checkIsMinLevel({
    needLevel,
    currentLevel,
    isMinLevel,
  });
};

export const getPostiveNegative = (
  companyValues: Record<string, ICompanyValue>,
  valueOrAnchor: string,
  isPositive: boolean
) => {
  const data = companyValues[valueOrAnchor];
  return isPositive ? data.positive : data.negative;
};

export const calculateBonus = (params: ICalculateBonusParams) => {
  const {
    jobSkills,
    oneSkillSalaryBonus,
    maxSkillSalaryBonus,
    characterAbilities,
  } = params;

  let bonus = 0;
  const works = entriesObject(jobSkills);

  for (const [skillName, skillData] of works) {
    if (bonus >= maxSkillSalaryBonus) {
      break;
    }

    const ability = getAbilitiesByName({
      name: skillName,
      abilities: characterAbilities,
    });

    if (!ability) {
      continue;
    }

    const isLevelUpper = checkLevelUpper({
      currentLevel: ability.level,
      needLevel: skillData.level,
    });
    if (isLevelUpper) {
      bonus += oneSkillSalaryBonus;
    }
  }

  return bonus;
};

const getLevel = (level: string) => +level.replace('Level', '');

const sortCareerTreeByLevel = (careerTree: Record<string, string>) =>
  Object.entries(careerTree)
    .sort((a, b) => getLevel(a[0]) - getLevel(b[0]))
    .map(([, career]) => career);

export const getTotalSalary = (params: IGetTotalSalaryParams) => {
  const {
    jobSalary,
    jobSkills,
    sectorMultiplier = 1,
    bonusData: {
      companyBonus,
      characterAbilities,
      oneSkillSalaryBonus,
      maxSkillSalaryBonus,
      performanceSalaryBonus,
    },
  } = params;

  const bonus = calculateBonus({
    jobSkills,
    maxSkillSalaryBonus,
    oneSkillSalaryBonus,
    characterAbilities,
  });

  const bonusPercent =
    (bonus + (companyBonus ?? 0) + performanceSalaryBonus) / 100;

  const salaryMultiplied = sectorMultiplier * jobSalary;

  return Math.round(salaryMultiplied * (1 + bonusPercent));
};

export const getSectorCareerTreeAsync = async (
  version: TGameVersion,
  sector: string
) => {
  if (!sector) {
    return;
  }

  const treesResponse = await VersionsAPI.sectors.work.fetchCareerTree({
    version,
    sector,
  });

  if (treesResponse) {
    return treesResponse.map((career) => sortCareerTreeByLevel(career));
  }
};

export const removeWorkExperienceFromSkillList = (
  careerUpSkills: TJobSkills
) => {
  const skills = entriesObject(careerUpSkills);
  const index = skills.findIndex(([name]) => {
    return name === 'Work Experience';
  });
  if (index !== -1) {
    skills.splice(index, 1);
  }
  return skills;
};

const calculateExperience = (params: ICalculateExperienceParams) => {
  const { currentSector, sector, experience } = params;

  return currentSector === sector ? experience : experience / 2;
};

export const checkEnoughExperience = (
  params: ICheckNeedWorkExperienceParams
) => {
  const {
    sector: currentSector,
    needWorkExperience,
    workExperience = {},
  } = params;

  const currentExperience = entriesObject(workExperience).reduce<number>(
    (acc, [sector, experience]) =>
      acc + calculateExperience({ currentSector, sector, experience }),
    0
  );

  return currentExperience >= needWorkExperience;
};

export const updateFirst = <T>(array: T[], item: T) => [
  item,
  ...array.slice(1),
];

const getNextJobsFromCareerTree = (
  careerTee: Record<string, string>[],
  job: string
) =>
  careerTee
    .map((branch) => sortCareerTreeByLevel(branch))
    .map((jobsArray) => {
      const jobIndex = jobsArray.findIndex((jobName) => jobName === job);

      if (jobIndex === -1) {
        return undefined;
      }

      return jobIndex + 1 < jobsArray.length
        ? jobsArray[jobIndex + 1]
        : undefined;
    })
    .filter((nextJob) => nextJob) as string[];

const transformJobSkillsToRequiredLevels = (jobSkills: TJobSkills[]) => {
  const skillLevels: Record<string, number> = {};

  jobSkills.forEach((skills) =>
    entriesObject(skills)
      .filter(([skillName]) => skillName !== 'Work Experience')
      .forEach(([skillName, skillParams]) => {
        if (skillLevels[skillName]) {
          skillLevels[skillName] = skillParams.level
            ? Math.max(skillLevels[skillName], skillParams.level)
            : skillLevels[skillName];

          return;
        }

        skillLevels[skillName] = skillParams.level || 0;
      })
  );

  return skillLevels;
};

const getNotMaxSkill = (playerSkills: IAbility[], maxSkillLevel: number) =>
  playerSkills.find((ability) => ability.level < maxSkillLevel)?.name;

export const getRelevantSkillAsync = async (
  params: IGetRelevantSkillsParams
) => {
  const { job, playerSkills = [], sector, maxSkillLevel, version } = params;

  // 1. Fetch career tree from Firestore
  const careerBranches = await VersionsAPI.sectors.work.fetchCareerTree({
    version,
    sector,
  });

  if (careerBranches) {
    // 2. Get names (titles) of the next level jobs
    const nextJobs = getNextJobsFromCareerTree(careerBranches, job);

    if (nextJobs.length > 0) {
      // 3. Get all needed skills for each job
      const neededSkills = (
        await Promise.all(
          nextJobs.map((nextJob) =>
            VersionsAPI.sectors.work.job.fetchSkills({
              version,
              sector,
              job: nextJob,
            })
          )
        )
      ).filter((jobSkills) => jobSkills !== undefined) as TJobSkills[];

      // 4. Transform needed skills to common skill - level map
      const requiredSkillLevels =
        transformJobSkillsToRequiredLevels(neededSkills);

      // 5. Get skill(s?) with not enough level
      const relevantSkill = entriesObject(requiredSkillLevels).find(
        ([skillName, skillLevel]) =>
          skillLevel >
          (playerSkills?.find((ability) => ability.name === skillName)?.level ||
            0)
      );

      if (relevantSkill) {
        return relevantSkill[0];
      }
    }
  }

  // 6. If all skills are at the required level, then return any not max skill
  return getNotMaxSkill(playerSkills, maxSkillLevel);
};
