import { FC } from 'react';
import styled from 'styled-components';

import { Image, TImageNames } from 'components/image';
import { Theme } from 'themes';

import { ImageCss } from './onboarding.styles';

export interface IOnboardingItemProps {
  imagePath: TImageNames;
  title: string;
  text: string | string[];
}

const Item = styled(Theme.CentralizeFlexbox)`
  max-width: 700px;
`;

const Text = (itemText: string | string[]) =>
  Array.isArray(itemText) ? (
    itemText.map((text, index) => (
      <Theme.Paragraph key={index}>{text}</Theme.Paragraph>
    ))
  ) : (
    <Theme.Paragraph>{itemText}</Theme.Paragraph>
  );

export const OnboardingItem: FC<IOnboardingItemProps> = (props) => {
  const { imagePath, title, text } = props;

  return (
    <Item
      $isColumn
      $color="white"
      $textAlign="center"
      $fontSize="small"
      $itemGap="24px"
    >
      <Image type={imagePath} width="100%" $styleCss={ImageCss} />
      <Theme.Paragraph $fontFamily="bold" $fontSize="big">
        {title}
      </Theme.Paragraph>
      {Text(text)}
    </Item>
  );
};
