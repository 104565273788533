import { FC, ReactNode, memo } from 'react';
import styled from 'styled-components';

import { Theme } from 'themes';
import { CSS } from 'typings';

import { CHECKBOX_STYLE_THEMES, TCheckboxTheme } from './checkbox.constants';

export interface ICheckboxComponentProps {
  id?: string;
  className?: string;
  variant?: TCheckboxTheme;
  isDisabled?: boolean;
  children?: ReactNode;
  setValue: (value: string) => void;
}

interface ICheckboxProps extends ICheckboxComponentProps {
  isChecked: boolean;
  item: IItem;
}

interface ICheckboxStyled {
  $css?: CSS;
  $identical?: CSS;
}

const Wrapper = styled(Theme.Wrapper)<ICheckboxStyled>`
  padding: 16px;
  cursor: pointer;

  ${(p) => p.$identical}
  ${(p) => p.$css}
`;

export const Checkbox: FC<ICheckboxProps> = (props) => {
  const {
    setValue,
    item,
    className,
    isChecked,
    variant,
    isDisabled,
    children,
  } = props;

  const { label, value } = item;

  const { active, disable, identical, notAllowed } =
    CHECKBOX_STYLE_THEMES[variant ?? 'default'];

  const css = isDisabled ? notAllowed : isChecked ? active : disable;

  const handleClick = () => {
    if (!isDisabled) {
      setValue(value);
    }
  };

  return (
    <Wrapper
      className={className}
      onClick={handleClick}
      $css={css}
      $identical={identical}
      $fontFamily="bold"
    >
      {children ?? (label || value)}
    </Wrapper>
  );
};

export const CheckboxMemo = memo(Checkbox);
