import { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { Image, TImageNames } from 'components/image';
import { IInfoModalProps, InfoModal } from './info-modal';

export interface IInfoImageModalProps extends IInfoModalProps {
  image: TImageNames;
  imageSize?: string;
  subtitle?: ReactElement;
  isRoundImage?: boolean;
}

const roundImage = css`
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Picture = styled(Image)<{ isRoundImage: boolean }>`
  ${(props) => props.isRoundImage && roundImage}
`;

export const InfoImageModal: FC<IInfoImageModalProps> = (props) => {
  const {
    image,

    children,
    isRoundImage,
    imageSize,
    subtitle,

    ...modalProps
  } = props;

  return (
    <InfoModal {...modalProps}>
      <Picture type={image} size={imageSize} isRoundImage={!!isRoundImage} />
      {subtitle}
      {children}
    </InfoModal>
  );
};
