import { FC } from 'react';
import { spacing, Theme } from 'themes';

import { ButtonOld } from 'components/button';

import { SHOP_STRINGS } from '../shop.strings';

interface IShopButtonBarProps {
  onBuy: () => void;
  onGoBack: () => void;

  isDisabledBuy: boolean;

  buyButtonText?: string;
}

const { BUY, GO_BACK } = SHOP_STRINGS.BOTTOM_BAR;

export const ShopButtonBar: FC<IShopButtonBarProps> = (props) => {
  const { onBuy, onGoBack, isDisabledBuy, buyButtonText = BUY } = props;

  return (
    <Theme.Wrapper $itemGap={{ default: spacing(2) }}>
      <ButtonOld
        isDisabled={isDisabledBuy}
        title={buyButtonText}
        onClick={onBuy}
      />

      <ButtonOld onClick={onGoBack} title={GO_BACK} styleTheme="cancelModal" />
    </Theme.Wrapper>
  );
};
