import { useReduxSelector } from 'services/hooks';

import { EDUCATION_STRINGS } from '../../education.strings';

import { DefaultEducationModal } from './default-modal';

interface IProps {
  rating: number;
  onClose: () => void;
}

const { NOT_PASS_BUTTON } = EDUCATION_STRINGS.TEST;

export const NotPassDefault = (props: IProps) => {
  const { rating, onClose } = props;

  const passTestRating = useReduxSelector(
    (redux) => redux.app.PASS_TEST_RATING
  );

  const title = `Sorry, you only scored ${rating}% in this test.  You need at least ${passTestRating}% to graduate.  Try again? `;

  return (
    <DefaultEducationModal
      title={title}
      image="notPassTest"
      buttonText={NOT_PASS_BUTTON}
      onCloseModal={onClose}
    />
  );
};
