import { ButtonOld } from 'components/button';
import { TImageNames } from 'components/image';
import { ICON_PRICE } from 'constants/prices';

import { IconModal } from '../icon-modal';
import { IconsList } from '../icons-list';

import { useIconShop } from './icon-shop.state';
import { IconShopStyles as Styles } from './icon-shop.styles';

const { energy, money } = ICON_PRICE;

export const IconShop = () => {
  const {
    selected,
    isShowModal,
    characterIcon,
    playerIcons,
    buyIcons,
    isDisabled,
    isBuy,

    onSelectIcon,
    onClick,
    onGoBack,
    onCloseModal,
  } = useIconShop();

  const RenderModal = isShowModal && (
    <IconModal icon={selected} onClose={onCloseModal} />
  );

  const RenderBuyIconsList = Boolean(buyIcons.length) && (
    <IconsList
      title="Buy More Avatars"
      icons={buyIcons}
      selected={selected}
      onSelectIcon={onSelectIcon}
    />
  );

  return (
    <>
      <Styles.Wrapper>
        <Styles.ContentWrapper>
          <div>
            <Styles.Title>What would you like to purchase?</Styles.Title>

            <Styles.SelectedAvatar image={selected} size="150px" />
            <Styles.Price energy={energy} money={money} $isShow={isBuy} />
          </div>

          <Styles.SelectorWrapper>
            <IconsList
              title="Your Avatars"
              icons={playerIcons as TImageNames[]}
              characterIcon={characterIcon}
              selected={selected}
              onSelectIcon={onSelectIcon}
            />
            {RenderBuyIconsList}
          </Styles.SelectorWrapper>
        </Styles.ContentWrapper>

        <Styles.ButtonsWrapper>
          <ButtonOld
            title={isBuy ? 'Buy Avatar' : 'Select Avatar'}
            isDisabled={isDisabled}
            onClick={onClick}
          />
          <ButtonOld title="Go back" styleTheme="cancelModal" onClick={onGoBack} />
        </Styles.ButtonsWrapper>
      </Styles.Wrapper>

      {RenderModal}
    </>
  );
};
