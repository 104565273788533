import styled from 'styled-components';

import { H1 } from 'components/ui';
import { CreateCharacterSwitch } from 'components/create-character';

import { AvatarSelector } from '../avatar-selector';
import { NameChanger } from '../name-changer';

import { INameAvatarProps, useNameAvatarStep } from './name-avatar-step.state';

const WidthWrapper = styled.div`
  max-width: 504px;
  margin: 0 auto;
`;

export const NameAvatarStep = (props: INameAvatarProps) => {
  const {
    avatar,
    name,
    isValidName,
    isNextAvailable,
    onSetAvatar,
    onChangeName,
    handleNext,
  } = useNameAvatarStep(props);

  return (
    <CreateCharacterSwitch
      isNextAvailable={isNextAvailable}
      onNext={handleNext}
    >
      <WidthWrapper>
        <H1>Create Your Character</H1>

        <AvatarSelector selectedAvatar={avatar} onSetAvatar={onSetAvatar} />

        <NameChanger
          name={name}
          onChangeName={onChangeName}
          isValidName={isValidName}
        />
      </WidthWrapper>
    </CreateCharacterSwitch>
  );
};
