import { IAbility, ISkillInfo } from '@avid/common';

import {
  useFetch,
  usePushLog,
  useReduxSelector,
  useScrollTopOnChangePage,
  useUpdateState,
} from 'services/hooks';
import { VersionsAPI, callFunctionAction } from 'services/api';
import { updateObjectArray } from 'services/utils';

import { LIMIT_SKILL, SINGLE_COURSE_PRICE } from './course.constants';
import { useDispatch } from 'react-redux';
import { PlayerActions } from 'services/redux';

export enum CourseModal {
  ABOUT = 'ABOUT',
  START_COURSE = 'START_COURSE',
  COMPLETE = 'COMPLETE',
  CHOOSE_SECTOR = 'CHOOSE_SECTOR',
  CLICK_SKILL = 'CLICK_SKILL',
}

export enum CoursePage {
  CHOOSE_SECTOR = 'CHOOSE_SECTOR',
  CHOOSE_SKILL = 'CHOOSE_SKILL',
  LEVEL = 'LEVEL',
}

interface ICoursesStateData {
  page: CoursePage;
  isLoadingSkills: boolean;
  modal?: CourseModal;

  sector: string;
  skillInfos: ISkillInfo[];

  learntAbilities?: IAbility[];
}

export type TUpdateCourseField = TUpdateFieldCallback<ICoursesStateData>;

const INIT_STATE: ICoursesStateData = {
  page: CoursePage.LEVEL,
  isLoadingSkills: false,
  skillInfos: [],
  sector: '',
};

export const useCourse = () => {
  const {
    state,
    updateFieldCallback,
    updateField,
    updateState,
  } = useUpdateState(INIT_STATE);

  const nowAbilities = useReduxSelector((redux) => redux.player.skills);
  const version = useReduxSelector((redux) => redux.app.version);

  const pushLog = usePushLog();

  const dispatch = useDispatch();

  useScrollTopOnChangePage(state.page);

  const closeModal = updateFieldCallback('modal')(undefined);

  const [skills, isLoading] = useFetch(
    () => {
      if (!version) {
        return;
      }
      return VersionsAPI.sectors.education.fetchSkills(version, state.sector);
    },
    {
      isStartLoad: state.page === CoursePage.CHOOSE_SKILL,
    }
  );

  const isSkillsChosen = state.skillInfos.length !== 0;

  const onFinishCourse = async () => {
    updateState({ isLoadingSkills: true });

    try {
      const result = await callFunctionAction('@COURSE/BUY_SKILLS', {
        skillNames: state.skillInfos.map((skill) => skill.name),
      });

      updateState({
        isLoadingSkills: false,
        learntAbilities: result.incrementedSkills,
        modal: CourseModal.COMPLETE,
        sector: undefined,
        page: CoursePage.LEVEL,
        skillInfos: [],
      });

      dispatch(PlayerActions.merge(result.mergePlayer));

      const skillNumber = state.skillInfos.length;
      const { energy, money } = SINGLE_COURSE_PRICE;
      const totalEnergy = energy * skillNumber;
      const totalPrice = money * skillNumber;

      pushLog({
        type: 'skills',
        action: 'increase',
        params: {
          skills: state.skillInfos.map((ability) => ability.name),
          sector: state.sector,
          energy: totalEnergy,
          money: totalPrice,
        },
      });

      return result.incrementedSkills;
    } catch (error) {
      updateState(INIT_STATE);
      console.error(error);
    }
  };

  const setSkill = (skillName: string) => {
    if (!skills) {
      return;
    }

    const skill = skills.find(({ name }) => skillName === name);

    if (!skill) {
      return;
    }

    updateField('skillInfos')(
      updateObjectArray({
        array: state.skillInfos,
        newValue: skill,
        field: 'name',
        limit: LIMIT_SKILL,
      })
    );
  };

  const chooseSector = (sector: string) =>
    updateState({ modal: CourseModal.CHOOSE_SECTOR, sector });

  const openPage = (page: CoursePage) => () => {
    closeModal();
    updateField('page')(page);
  };

  const onContinue = () =>
    updateField('modal')(
      isSkillsChosen ? CourseModal.START_COURSE : CourseModal.CLICK_SKILL
    );

  return {
    ...state,
    updateField: updateFieldCallback,
    closeModal,
    setSkill,
    openPage,
    chooseSector,
    onContinue,

    nowAbilities,
    skills,
    isLoading,
    onFinishCourse,

    isSkillsChosen,
  };
};
