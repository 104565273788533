import { HTMLAttributes, useMemo } from 'react';
import styled, { RuleSet, css } from 'styled-components';

import { MEDIA, overrideMediaCss } from 'themes';
import { getCssOptionalValue } from 'services/utils';

interface IGridLayoutProps {
  $rowGap?: string;
  $columnGap?: string;
  $gap?: string;
  $justifyContent?: string;
  $justifyItems?: string;
  $alignItems?: string;
  $alignContent?: string;
  $columns?: number;
  $border?: string;
  $borderRadius?: string;
  $boxShadow?: string;
  $backgroundColor?: string;
  $padding?: string;
  $isFlexible?: boolean;
  $color?: string;
  $textAlign?: string;

  /**
   * ! Supports static objects only
   */
  $typography?: string | RuleSet;
}

type TMediaGridProps = Partial<Record<keyof typeof MEDIA, IGridLayoutProps>>;

interface IGridLayoutMediaProps extends IGridLayoutProps {
  /**
   * ! Supports static objects only
   */
  $media?: TMediaGridProps;
}

export interface IGridProps extends HTMLAttributes<HTMLDivElement> {
  rowGap?: string;
  columnGap?: string;
  gap?: string;
  justifyContent?: string;
  justifyItems?: string;
  alignItems?: string;
  alignContent?: string;
  columns?: number;
  isFlexible?: boolean;
  border?: string;
  borderRadius?: string;
  backgroundColor?: string;
  boxShadow?: string;
  padding?: string;
  color?: string;
  textAlign?: string;

  /**
   * ! Supports static objects only
   */
  typography?: string | RuleSet;

  /**
   * ! Supports static objects only
   */
  media?: TMediaGridProps;
}

const GRID_CONSTANTS = {
  DEFAULT_GAP: '8px',
} as const;

const getGap = (props: IGridLayoutProps) => {
  const { $rowGap, $columnGap, $gap } = props;

  if ($gap) {
    return `gap: ${$gap};`;
  }

  if (!$rowGap && !$columnGap) {
    return `gap: ${GRID_CONSTANTS.DEFAULT_GAP};`;
  }

  return '';
};

const getGridLayoutCssString = (
  props: IGridLayoutProps,
  isOverriding: boolean
) => css`
  ${isOverriding
    ? ''
    : `
    align-content: ${props.$alignContent || 'start'};
    ${getGap(props)}
  `}
  ${getCssOptionalValue('row-gap', props.$rowGap)}
  ${getCssOptionalValue('column-gap', props.$columnGap)}
  ${getCssOptionalValue('justify-content', props.$justifyContent)}
  ${getCssOptionalValue('justify-items', props.$justifyItems)}
  ${getCssOptionalValue('align-items', props.$alignItems)}
  ${getCssOptionalValue('border', props.$border)}
  ${getCssOptionalValue('border-radius', props.$borderRadius)}
  ${getCssOptionalValue('box-shadow', props.$boxShadow)}
  ${getCssOptionalValue('background-color', props.$backgroundColor)}
  ${getCssOptionalValue('padding', props.$padding)}
  ${getCssOptionalValue('color', props.$color)}
  ${getCssOptionalValue('text-align', props.$textAlign)}
  ${props.$typography ? props.$typography : ''};
  ${props.$columns ? `grid-auto-flow: column;` : ''}
`;

const StyledGrid = styled.div<{ $cssRules?: RuleSet }>`
  display: grid;
  ${({ $cssRules }) => $cssRules}
`;

export const Grid = (props: IGridProps) => {
  const {
    rowGap,
    columnGap,
    gap,
    justifyContent,
    justifyItems,
    alignItems,
    alignContent,
    columns,
    backgroundColor,
    boxShadow,
    borderRadius,
    border,
    padding,
    isFlexible,
    media,
    color,
    typography,
    textAlign,

    ...divProps
  } = props;

  const cssRules = useMemo(() => {
    const cssProps: IGridLayoutMediaProps = {
      $rowGap: rowGap,
      $columnGap: columnGap,
      $gap: gap,
      $justifyContent: justifyContent,
      $justifyItems: justifyItems,
      $alignItems: alignItems,
      $alignContent: alignContent,
      $columns: columns,
      $isFlexible: isFlexible,
      $media: media,
      $backgroundColor: backgroundColor,
      $boxShadow: boxShadow,
      $borderRadius: borderRadius,
      $border: border,
      $padding: padding,
      $color: color,
      $typography: typography,
      $textAlign: textAlign,
    };

    return css`
      ${getGridLayoutCssString(cssProps, false)};
      ${overrideMediaCss(cssProps, getGridLayoutCssString)}
    `;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alignContent,
    alignItems,
    backgroundColor,
    border,
    borderRadius,
    boxShadow,
    color,
    columnGap,
    columns,
    gap,
    isFlexible,
    justifyContent,
    justifyItems,
    padding,
    rowGap,
    textAlign,
  ]);

  return <StyledGrid $cssRules={cssRules} {...divProps} />;
};
