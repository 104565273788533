import { Step } from 'react-joyride';

import { AppTutorialController } from 'components/tutorial';
import { getElementTargetById } from 'services/utils';
import { useReduxSelector } from 'services/hooks';

import { SELECT_EDUCATION_IDS } from '../select-education.const';
import { useEducationTutorial } from './select-education-tutorial.state';

const steps: Step[] = [
  {
    target: '#bot_bar-education',
    content: 'Welcome to the Education tab!',
    disableBeacon: true,
  },
  {
    target: getElementTargetById(SELECT_EDUCATION_IDS.LEVELS),
    content:
      'You have already been passed the education levels in green, and can pursue those in blue.',
  },
  {
    target: getElementTargetById(SELECT_EDUCATION_IDS.LEVEL),
    content:
      'Click on the level, take a short quiz and graduate to get your certificate.',
  },
  {
    target: getElementTargetById(SELECT_EDUCATION_IDS.LEVELS),
    content:
      'Pro-tip: You can choose to graduate with a degree to get your first set of skills to find a job.',
  },
];

const SelectEducationTutorialComponent = () => {
  const { handleCallback } = useEducationTutorial();

  return <AppTutorialController steps={steps} callback={handleCallback} />;
};

export const SelectEducationTutorial = () => {
  const isMainTutorialCompleted = useReduxSelector(
    (redux) => redux.player.tutorials?.main
  );
  const isEducationTutorialCompleted = useReduxSelector(
    (redux) => redux.player.tutorials?.education
  );

  const isShow = isMainTutorialCompleted && !isEducationTutorialCompleted;

  if (!isShow) {
    return null;
  }

  return <SelectEducationTutorialComponent />;
};
