import { FC, MutableRefObject, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { MainActions } from 'screens/main';
import { useMod } from 'services/hooks';
import { getModComponent } from 'services/utils';
import { Theme } from 'themes';

import { HomeTabs, TopBar } from './components';

import { HomeStyles as Styles } from './home.styles';

interface IHomeProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
  isBetweenRounds?: boolean;
}

export const Home: FC<IHomeProps> = (props) => {
  const { isBetweenRounds, scrollRef } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MainActions.setShowBottomBar(true));
  }, [dispatch]);

  const BonusCodesMC = getModComponent(
    useMod('bonusCodes')?.components.BonusCodes
  );

  return (
    <Theme.PageContainer $isFullHeight={false}>
      <Styles.WidthWrapper>
        <TopBar isWorkDisabled={isBetweenRounds} />

        <BonusCodesMC />

        <HomeTabs scrollRef={scrollRef} />
      </Styles.WidthWrapper>
    </Theme.PageContainer>
  );
};
