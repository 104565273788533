import { useEffect } from 'react';

import { useUpdateState } from 'services/hooks';

interface IStatItemState {
  displayValue: number;
  isInitialized: boolean;
  isAnimating: boolean;
}

const INIT_STATE: IStatItemState = {
  displayValue: 0,
  isAnimating: false,
  isInitialized: false,
};

export const useStatItem = (value: number) => {
  const { state, updateState } = useUpdateState({
    ...INIT_STATE,
    displayValue: value,
  });

  useEffect(() => {
    updateState(
      state.isInitialized ? { isAnimating: true } : { isInitialized: true }
    );
  }, [value]);

  const onAnimationEnd = () =>
    updateState({ isAnimating: false, displayValue: value });

  return { ...state, onAnimationEnd };
};
