import styled from 'styled-components';

export const AvatarsListStyles = {
  Root: styled.div`
    padding-top: 48px;
  `,

  ListWrapper: styled.div`
    padding-top: 16px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    > * div {
      flex: 1;
    }
  `,
};
