import styled from 'styled-components';

import { ButtonOld } from 'components/button';
import { Loader } from 'components/loader';
import { Grid } from 'components/ui';
import { spacing, Theme, typographyCss } from 'themes';

import { EducationModal } from '../../education.typing';
import { EDUCATION_STRINGS } from '../../education.strings';

import { Choose } from './choose';
import {
  useChooseDegree,
  IChooseDegreeStateProps,
} from './choose-degree.state';

interface IChooseDegreeProps extends IChooseDegreeStateProps {
  setModal: (modal?: EducationModal) => void;
}

const { TITLE } = EDUCATION_STRINGS.DEGREE;

const MarginButton = styled(ButtonOld)`
  margin-top: ${spacing(2)};
`;

export const ChooseDegree = (props: IChooseDegreeProps) => {
  const { setModal, ...stateProps } = props;

  const { degrees, isLoading, setDegree, onClickGoBack } =
    useChooseDegree(stateProps);

  const RenderDegrees = degrees.map(
    ({ name, achievement, isOpen, isCompleted, isExceed }) => (
      <Choose
        key={name}
        title={achievement}
        isOpen={Boolean(isOpen)}
        isComplete={isCompleted}
        isExceed={isExceed}
        setDegree={setDegree(name)}
        setModal={setModal}
      />
    )
  );

  const RenderContent = isLoading ? (
    <Loader />
  ) : (
    <Theme.Flexbox $isColumn $itemGap="16px">
      {RenderDegrees}
    </Theme.Flexbox>
  );

  return (
    <Grid
      typography={typographyCss.RalewayBold16}
      textAlign="center"
      alignContent="space-between"
    >
      <Theme.Wrapper $itemGap={{ laptop: spacing(3) }}>
        <Theme.Paragraph>{TITLE}</Theme.Paragraph>

        {RenderContent}
      </Theme.Wrapper>

      <MarginButton onClick={onClickGoBack} title="Go back" />
    </Grid>
  );
};
