import { FC } from 'react';

import { Theme } from 'themes';

import {
  CareerTreeItemStyles as Styles,
  ARROW_CLASS_NAME,
} from './career-tree-item.styles';

export interface ICareerTreeItemProps {
  job: string;
  isWithArrow: boolean;
  onOpenDescriptionModal: (job: string) => void;
}

export const CareerTreeItem: FC<ICareerTreeItemProps> = (props) => {
  const { job, onOpenDescriptionModal, isWithArrow } = props;

  const onClick = () => onOpenDescriptionModal(job.trim());

  return (
    <Styles.Item onClick={onClick}>
      <Styles.Information>
        <Theme.Paragraph>{job}</Theme.Paragraph>
        <Styles.Icon type="informationCareer" />
      </Styles.Information>
      {isWithArrow && (
        <Styles.Arrow type="careerTreeArrow" className={ARROW_CLASS_NAME} />
      )}
    </Styles.Item>
  );
};
