import styled, { css } from 'styled-components';

import { IPerformanceItemView, PERFORMANCE_ITEMS } from 'constants/character';
import { MEDIA } from 'themes';

import { PerformanceItem } from '../performance-item';

interface IProps {
  onSelectPerformance: (performance: string) => void;
  selectedPerformance?: string;
}

const Styles = {
  Root: styled.div`
    ${MEDIA.TABLET} {
      margin-top: -16px;
      margin-left: -16px;
    }
  `,
  FlexWrapper: styled.div`
    position: relative;
    height: 450px;

    ${MEDIA.TABLET} {
      position: static;

      max-width: 840px;
      margin: 0 auto;
      height: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      > * {
        margin-top: 16px;
        margin-left: 16px;
      }
    }
  `,
  PerformanceItem: styled(PerformanceItem)<{ $view: IPerformanceItemView }>`
    position: absolute;
    cursor: pointer;

    ${MEDIA.TABLET} {
      position: static;
      width: 150px;
      height: 150px;
    }

    ${({ $view }) => css`
      width: ${$view.px}px;
      height: ${$view.px}px;
      top: ${$view.top}px;
      left: ${$view.left}px;
    `}
  `,
};

export const PerformanceEditor = (props: IProps) => {
  const { selectedPerformance, onSelectPerformance } = props;

  const RenderItems = PERFORMANCE_ITEMS.map((performance) => {
    const { title, image, description, color, bonuses, view } = performance;

    return (
      <Styles.PerformanceItem
        $view={view}
        key={title}
        title={title}
        description={description}
        image={image}
        titleColor={color}
        isActive={selectedPerformance === title}
        bonuses={bonuses}
        onSelect={onSelectPerformance}
      />
    );
  });

  return (
    <Styles.Root>
      <Styles.FlexWrapper>{RenderItems}</Styles.FlexWrapper>
    </Styles.Root>
  );
};
