import { FC, ReactElement } from 'react';
import { spacing, Theme } from 'themes';

import { Loader } from 'components/loader';
import { ChooseModal, CostModal } from 'components/modal';
import { CompleteCourse } from './complete-course';
import { ChooseSector, CourseChooseSkill, CourseStartPage } from './components';
import { COURSE_STRINGS } from './course.strings';

import { CourseModal, CoursePage, useCourse } from './course.state';

import { SINGLE_COURSE_PRICE } from './course.constants';

import { ClickSkillModal } from 'components/modal/click-skill-modal';

const { ABOUT, CHOOSE_SECTOR_MODAL, START_COURSE } = COURSE_STRINGS;
const { energy, money } = SINGLE_COURSE_PRICE;

export const Courses: FC = () => {
  const {
    skillInfos,
    learntAbilities,
    isLoadingSkills,
    page,
    sector,
    modal,
    isLoading,
    skills,
    nowAbilities,
    isSkillsChosen,

    setSkill,
    onFinishCourse,
    openPage,
    chooseSector,
    onContinue,
    closeModal,
  } = useCourse();

  const coursePrice: IPrice = {
    energy: energy * skillInfos.length,
    money: money * skillInfos.length,
  };

  const Modal: Record<CourseModal, ReactElement> = {
    [CourseModal.ABOUT]: (
      <CostModal
        cancelButtonTitle={ABOUT.CANCEL_BUTTON}
        confirmButtonTitle={ABOUT.CONFIRM_BUTTON}
        energy={energy}
        money={money}
        onCancel={closeModal}
        onCloseModal={closeModal}
        onConfirm={openPage(CoursePage.CHOOSE_SECTOR)}
        title={<Theme.Paragraph>{ABOUT.TITLE_1}</Theme.Paragraph>}
        subtitle={<Theme.Paragraph>{ABOUT.TITLE_2}</Theme.Paragraph>}
      />
    ),
    [CourseModal.CHOOSE_SECTOR]: (
      <ChooseModal
        itemGap={{ default: spacing(2) }}
        cancelButtonTitle={CHOOSE_SECTOR_MODAL.DISAGREE_BUTTON}
        confirmButtonTitle={CHOOSE_SECTOR_MODAL.AGREE_BUTTON}
        onCloseModal={closeModal}
        onConfirm={openPage(CoursePage.CHOOSE_SKILL)}
        title={<Theme.Paragraph>{`Select ${sector} sector?`}</Theme.Paragraph>}
      />
    ),
    [CourseModal.START_COURSE]: (
      <CostModal
        cancelButtonTitle={ABOUT.CANCEL_BUTTON}
        confirmButtonTitle={ABOUT.CONFIRM_BUTTON}
        energy={coursePrice.energy}
        money={coursePrice.money}
        onCloseModal={closeModal}
        onConfirm={onFinishCourse}
        title={
          <Theme.Paragraph>
            {START_COURSE.TITLE(skillInfos.length)}
          </Theme.Paragraph>
        }
        subtitle={<Theme.Paragraph>{START_COURSE.SUBTITLE}</Theme.Paragraph>}
      />
    ),
    [CourseModal.COMPLETE]: (
      <CompleteCourse
        isLoading={isLoadingSkills}
        abilities={learntAbilities}
        onCompleteCourse={openPage(CoursePage.LEVEL)}
      />
    ),
    [CourseModal.CLICK_SKILL]: <ClickSkillModal onCloseModal={closeModal} />,
  };

  const Pages: Record<CoursePage, ReactElement> = {
    [CoursePage.CHOOSE_SECTOR]: (
      <ChooseSector sector={sector} onSelectSector={chooseSector} />
    ),
    [CoursePage.CHOOSE_SKILL]:
      isLoading || !skills ? (
        <Loader />
      ) : (
        <CourseChooseSkill
          skills={skills}
          skillInfos={skillInfos}
          isSkillsChosen={isSkillsChosen}
          setSkill={setSkill}
          onContinue={onContinue}
        />
      ),
    [CoursePage.LEVEL]: (
      <CourseStartPage
        nowAbilities={nowAbilities}
        onClickButton={openPage(CoursePage.CHOOSE_SECTOR)}
      />
    ),
  };

  return (
    <Theme.Wrapper>
      <Theme.PageContainer $isFullHeight={false}>
        {Pages[page]}
      </Theme.PageContainer>

      {modal && Modal[modal]}
    </Theme.Wrapper>
  );
};
