import { useCallback, useEffect, useRef, useState } from 'react';

import { useReduxSelector } from 'services/hooks';

export const useNegativeMoneyTracker = () => {
  const [isOpen, setIsOpen] = useState(false);

  const money = useReduxSelector((redux) => redux.player.main.money);

  const prevMoney = useRef(money);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (money < 0 && prevMoney.current > money) {
      setIsOpen(true);
    }

    prevMoney.current = money;
  }, [money]);

  return { isOpen, onClose };
};
