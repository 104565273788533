import { useMemo } from 'react';

import { useReduxSelector } from 'services/hooks';
import { arrayEqual, objectEqual } from 'services/utils';

import { mapCharacteristicCallback } from './profile.utils';

export const useProfile = () => {
  const version = useReduxSelector((redux) => redux.app.version);

  const smart = useReduxSelector(
    (redux) => redux.player.createCharacter.smart,
    objectEqual
  );
  const ability = useReduxSelector(
    (redux) => redux.player.createCharacter.performance
  );
  const playerAnchors = useReduxSelector(
    (redux) => redux.player.createCharacter.careerAnchors,
    arrayEqual
  );
  const playerValues = useReduxSelector(
    (redux) => redux.player.createCharacter.values,
    arrayEqual
  );
  const company = useReduxSelector(
    (redux) => redux.player.work?.company,
    objectEqual
  );

  const { anchors, values } = useMemo(
    () => ({
      anchors: playerAnchors.map(mapCharacteristicCallback(company)),
      values: playerValues.map(mapCharacteristicCallback(company)),
    }),
    [company, playerAnchors, playerValues]
  );

  return {
    ability,
    version,
    anchors,
    values,
    company,
    smart,
  };
};
