import { SmartRadar } from 'components/smart-radar';
import { Theme } from 'themes';

import { CharacteristicList } from './components';
import { useProfile } from './profile.state';
import { ProfileStyles as Styles } from './profile.styles';

interface IProps {
  className?: string;
}

export const Profile = (props: IProps) => {
  const { className } = props;

  const { version, ability, anchors, values, smart } = useProfile();

  const RenderAnchors =
    version === 'ntu' ? null : (
      <Theme.Wrapper>
        <Theme.Paragraph $fontFamily="bold">Career anchors:</Theme.Paragraph>
        <CharacteristicList characteristics={anchors} />
      </Theme.Wrapper>
    );

  return (
    <Styles.Root className={className}>
      <Styles.SmartsContainer $itemGap={{ default: '8px' }}>
        <Theme.Paragraph $fontFamily="bold">
          Multiple Intelligence:
        </Theme.Paragraph>

        <SmartRadar characterSmart={smart} />
      </Styles.SmartsContainer>

      <Styles.CharacteristicsContainer
        $itemGap={{ default: '8px', laptop: '32px' }}
      >
        <Theme.Wrapper>
          <Theme.Paragraph $fontFamily="bold">
            Performance Character:
          </Theme.Paragraph>

          <Styles.Ability>{ability}</Styles.Ability>
        </Theme.Wrapper>

        {RenderAnchors}

        <Theme.Wrapper>
          <Theme.Paragraph $fontFamily="bold">Values:</Theme.Paragraph>

          <CharacteristicList characteristics={values} />
        </Theme.Wrapper>
      </Styles.CharacteristicsContainer>
    </Styles.Root>
  );
};
