import { useMod } from 'services/hooks';
import { getModComponent } from 'services/utils';

export const BudgetingPropose = () => {
  const BudgetingProposeMC = getModComponent(
    useMod('financial')?.containers.BudgetingProposeModalProxy
  );

  return <BudgetingProposeMC />;
};
