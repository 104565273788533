import Slider from 'react-slick';
import styled, { css } from 'styled-components';

import { ButtonOld } from 'components/button';
import { IMAGES } from 'components/image';

import { COLORS, FONT_FAMILIES, MEDIA, Theme } from 'themes';

interface IDotStyled {
  isActive: boolean;
}

export const OnboardingStyles = {
  Wrapper: styled(Theme.Flexbox)`
    background-image: url(${IMAGES.backgroundLogo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    padding: 1em 1em 2em;
    height: 100%;

    ${MEDIA.LAPTOP} {
      background-image: url(${IMAGES.backgroundLogoLaptop});
    }
  `,
  Button: styled(ButtonOld)`
    font-family: ${FONT_FAMILIES.medium};
  `,
  Dot: styled.div<IDotStyled>`
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: ${(p) => COLORS[p.isActive ? 'darkYellow' : 'white']};
  `,
  DotBar: styled.ul`
    bottom: -10px;
  `,
  Slider: styled(Slider)`
    display: flex;
    flex: 1;
    padding: 0.5em 1em 1.5em;

    .slick-track {
      height: 100%;
    }

    .slick-list {
      flex: 1;
    }

    .slick-slide > div {
      display: flex;
      justify-content: center;
      align-content: center;
      height: 100%;
    }
  `,
};

export const ImageCss = css`
  width: 100%;

  ${MEDIA.LAPTOP} {
    width: auto;
  }
`;
