import { FC } from 'react';
import styled from 'styled-components';
import { COLORS, TColorNames } from 'themes';

interface IProgress {
  $progressColor?: TColorNames;
  $backgroundColor?: TColorNames;
}

interface IProgressStyled extends IProgress {
  percent: number;
}

interface IProgressPercentProps extends IProgressStyled {
  className?: string;
}

export interface IProgressProps extends IProgress {
  className?: string;
  completePoint: number;
  maxPoint: number;
}

const ProgressBar = styled.div<IProgressStyled>`
  position: relative;
  border-radius: 5px;
  width: 100%;
  height: 10px;
  background-color: ${(p) => COLORS[p.$backgroundColor ?? 'darkAches']};
  overflow: hidden;
  border: 1px solid ${(p) => COLORS[p.$progressColor ?? 'darkYellow']};

  &::after {
    content: '';
    left: 0;
    height: 10px;
    position: absolute;
    border-radius: 5px;
    background-color: ${(p) => COLORS[p.$progressColor ?? 'darkYellow']};
    width: ${(p) => p.percent}%;
  }
`;

export const Progress: FC<IProgressProps> = (props) => {
  const {
    className,
    completePoint,
    maxPoint,
    $progressColor,
    $backgroundColor,
  } = props;

  const percent = (100 * completePoint) / maxPoint;

  return (
    <ProgressBar
      percent={percent}
      $progressColor={$progressColor}
      $backgroundColor={$backgroundColor}
      className={className}
    />
  );
};

export const ProgressPercent: FC<IProgressPercentProps> = (props) => {
  const {
    className,
    percent,
    $backgroundColor: backgroundColor,
    $progressColor: progressColor,
  } = props;

  return (
    <ProgressBar
      percent={percent}
      $progressColor={progressColor}
      $backgroundColor={backgroundColor}
      className={className}
    />
  );
};
