import { ComponentType, FC, ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { useAuth, useReduxSelector } from 'services/hooks';

interface IAuthRouteProps {
  path: string | string[];
  isCharacter?: boolean;
  component?: ComponentType<any>;
  children?: ReactNode;
}

export const AuthRoute: FC<IAuthRouteProps> = (props) => {
  const { path, children, component, isCharacter = false } = props;

  const { isAuthenticated } = useAuth();

  const isCharacterCreated = useReduxSelector(
    (redux) => redux.player.createCharacter?.isCreate
  );

  if (!isAuthenticated) {
    return <Redirect to={ROUTES.login} />;
  }

  if (isCharacter && !isCharacterCreated) {
    return <Redirect to={ROUTES.createCharacter} />;
  }

  return (
    <Route path={path} component={component}>
      {children}
    </Route>
  );
};
