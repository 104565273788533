import { useCallback, useMemo, useState } from 'react';

import { TImageNames } from 'components/image';
import { useReduxDispatch, useReduxSelector } from 'services/hooks';
import { checkCanBuy } from 'services/utils';
import { ALL_ICONS } from 'constants/character';
import { ICON_PRICE } from 'constants/prices';
import { PlayerActions } from 'services/redux';

import { ShopActions } from '../../shop.actions';
import { callFunctionAction } from 'services/api';
import { useDispatch } from 'react-redux';

export const useIconShop = () => {
  const characterIcon = useReduxSelector(
    (redux) => redux.player.createCharacter.icon
  );
  const playerIcons = useReduxSelector((redux) => redux.player.icons);
  const playerEnergy = useReduxSelector((redux) => redux.player.main.energy);
  const playerMoney = useReduxSelector((redux) => redux.player.main.money);
  const maxLoan = useReduxSelector((redux) => redux.app.minimalMoney);

  const [selected, setSelected] = useState(characterIcon as TImageNames);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { callAction } = useReduxDispatch();
  const dispatch = useDispatch();

  const moveToStart = callAction(ShopActions.moveToStartPage);

  const checkCanBuyIcon = useCallback(
    () =>
      checkCanBuy({
        playerEnergy,
        playerMoney,
        maxLoan,
        price: ICON_PRICE,
      }),
    [maxLoan, playerEnergy, playerMoney]
  );

  const buyIcons = useMemo(
    () => ALL_ICONS.filter((icon) => !playerIcons?.includes(icon)),
    [playerIcons]
  );

  const isBuy = useMemo(() => !playerIcons?.includes(selected), [
    playerIcons,
    selected,
  ]);

  const isDisabled = useMemo(
    () =>
      (isBuy && !checkCanBuyIcon()) || (!isBuy && characterIcon === selected),
    [characterIcon, checkCanBuyIcon, isBuy, selected]
  );

  const onSelectIcon = useCallback(
    (icon: TImageNames) => setSelected(icon),
    []
  );

  const onClick = useCallback(async () => {
    if (isDisabled || selected === characterIcon || isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await callFunctionAction('@SHOP/CHANGE_AVATAR', {
        icon: selected,
      });

      if (result?.mergePlayer) {
        dispatch(PlayerActions.merge(result?.mergePlayer));
      }

      if (result?.isBuy) {
        setIsShowModal(true);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }, [isDisabled, selected, characterIcon, isLoading, dispatch]);

  const onGoBack = () => moveToStart();

  const onCloseModal = () => setIsShowModal(false);

  return {
    selected,
    isShowModal,
    characterIcon,
    playerIcons,
    buyIcons,
    isBuy,
    isDisabled,
    onSelectIcon,
    onClick,
    onGoBack,
    onCloseModal,
  };
};
