import styled from 'styled-components';
import { IAbility } from '@avid/common';

import { Image } from 'components/image';
import { Button, Grid, Text } from 'components/ui';
import { useReduxSelector } from 'services/hooks';
import { ModalWrapper } from 'components/modal';
import { typographyCss } from 'themes';

import { EducationSkills } from './education-skills';

interface IProps {
  onClose: () => void;
  abilities?: IAbility[];
}

const Styles = {
  Label: styled(Text)`
    padding: 0 12px;
  `,
};

export const PassPhdHard = (props: IProps) => {
  const { abilities, onClose } = props;

  const characterName = useReduxSelector(
    (redux) => redux.player.createCharacter.name
  );

  return (
    <ModalWrapper onCloseModal={onClose}>
      <Grid rowGap="24px" justifyItems="center">
        <Grid padding="0 12px" rowGap="20px" textAlign="center">
          <Text typography={typographyCss.RalewayBold18}>
            Congratulations! You have successfully scored 100% and graduated
            with a PhD.
          </Text>

          <Styles.Label>
            You’ve successfully earned the salutation of Doctor!
          </Styles.Label>

          <Text typography={typographyCss.RalewayBold16}>{characterName}</Text>
        </Grid>

        <Image type="phdHardPass" />

        <EducationSkills abilities={abilities} />

        <Button variant="white" onClick={onClose}>
          Continue
        </Button>
      </Grid>
    </ModalWrapper>
  );
};
