import { ButtonOld } from 'components/button';

import { OnboardingItem } from './onboarding-item';
import { BUTTON_TEXT } from './onboarding.utils';
import { OnboardingStyles } from './onboarding.styles';
import { useOnboarding } from './onboarding.state';

export const Onboarding = () => {
  const { ref, setting, onboardingItems, handleClick } = useOnboarding();

  return (
    <OnboardingStyles.Wrapper $isColumn $itemGap="2em">
      <OnboardingStyles.Slider ref={ref} {...setting}>
        {onboardingItems.map((item) => (
          <OnboardingItem key={item.title} {...item} />
        ))}
      </OnboardingStyles.Slider>

      <ButtonOld onClick={handleClick} title={BUTTON_TEXT} styleTheme="white" />
    </OnboardingStyles.Wrapper>
  );
};
