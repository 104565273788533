import { action } from 'services/utils';

import { IMoreRedux, MORE_ACTION, MorePage } from './more.constants';

const { UPDATE_FIELDS, CLEAR, SET_PAGE } = MORE_ACTION;

export const MoreActions = {
  updateFields: (state: Partial<IMoreRedux>) => action(UPDATE_FIELDS, state),
  clear: () => action(CLEAR),
  setPage: (page: MorePage) => action(SET_PAGE, page),
};

export type TMoreActions = ReturnType<TActionCombiner<typeof MoreActions>>;
