import { memo } from 'react';
import styled from 'styled-components';

import { TImageNames } from 'components/image';
import { ImageBox } from 'components/image-box';
import { StatItemClone } from '../stat-item-clone';
import { Title } from '../title';

import { combineWithDot } from 'services/utils';
import { useStatItem } from './stat-item.state';

interface IStatItemProps {
  image: TImageNames;
  value: number;
  id?: string;
}

const Wrapper = styled.div`
  position: relative;
`;

export const StatItem = memo((props: IStatItemProps) => {
  const { image, value, id } = props;

  const { displayValue, isAnimating, onAnimationEnd } = useStatItem(value);

  return (
    <Wrapper id={id}>
      <ImageBox
        sizeImage="15px"
        elementTitle={<Title value={combineWithDot(displayValue)} />}
        titlePosition="right"
        imageName={image}
      />
      {isAnimating && (
        <StatItemClone
          value={value - displayValue}
          image={image}
          onAnimationEnd={onAnimationEnd}
        />
      )}
    </Wrapper>
  );
});
