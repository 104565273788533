import { createContext, FC, ReactNode, useContext, useState } from 'react';
import styled from 'styled-components';

import { useOutsideCall } from 'services/hooks';

import { SELECTOR_THEME, TThemeSelector } from './selector.constants';

import { CSS } from 'typings';

export interface ISelectorContextParameters {
  setValue(value: IItem): void;
  isDisabled?: boolean;
  selectValue?: IItem;
  theme?: TThemeSelector;
}

interface ISelectorContext extends ISelectorContextParameters {
  isOpen: boolean;
  setOpen(isOpen: boolean): () => void;
}

interface ISelectorProps extends ISelectorContextParameters {
  className?: string;
  children?: ReactNode;
}

const SelectorContext = createContext<ISelectorContext>({
  theme: 'orange',
  setValue: () => {
    return;
  },
  selectValue: { value: '' },
  isOpen: false,
  setOpen: () => () => {
    return;
  },
});

export const useSelectorContext = () => useContext(SelectorContext);

const SelectorStyled = styled.div<{ $variant: CSS }>`
  position: relative;
  ${(props) => props.$variant}
  cursor: pointer;
`;

export const SelectorProvider: FC<ISelectorProps> = (props) => {
  SelectorContext.displayName = 'Selector';

  const {
    children,
    className,
    theme = 'orange',
    isDisabled,
    selectValue,
    setValue,
  } = props;
  const themeStyled = SELECTOR_THEME[theme].selector;

  const [isOpen, setOpen] = useState(false);
  const onSetOpen = (isNewOpen: boolean) => () => {
    if (isDisabled) {
      return;
    }

    setOpen(isNewOpen);
  };

  const { ref } = useOutsideCall<HTMLDivElement>(() => setOpen(false));

  const onSetValue = (item: IItem) => {
    if (item.value === selectValue?.value) {
      return;
    }

    setValue(item);
  };

  return (
    <SelectorContext.Provider
      value={{
        theme,
        isOpen,
        selectValue,
        setOpen: onSetOpen,
        setValue: onSetValue,
      }}
    >
      <SelectorStyled ref={ref} className={className} $variant={themeStyled}>
        {children}
      </SelectorStyled>
    </SelectorContext.Provider>
  );
};
