type TPrimitive = number | string;

interface IUpdateParameter<T> {
  array: T[];
  newValue: T;
  limit?: number;
}
interface IUpdateListParameter<T> extends IUpdateParameter<T> {
  index: number;
}
interface IUpdateObjectArrayParameters<T extends {}, K extends keyof T>
  extends IUpdateParameter<T> {
  field: K;
}

interface IIncludeInObjectArrayParameters<T extends {}, K extends keyof T> {
  array: T[];
  filed: keyof T;
  value: T[K];
}

// *** Utils *** //

export const removeArrayItemByIndex = <T>(array: T[], index: number): T[] => {
  const clone = [...array];
  clone.splice(index, 1);
  return clone;
};

const updateArray = <T>(params: IUpdateListParameter<T>) => {
  const { array, index, newValue, limit } = params;
  if (typeof limit !== 'number') {
    return index === -1
      ? [...array, newValue]
      : removeArrayItemByIndex(array, index);
  }
  if (limit >= array.length && index === -1) {
    return [...array, newValue];
  }
  return removeArrayItemByIndex(array, index);
};

// *** Includes *** //

export const isIncludeInArray = <T extends TPrimitive>(
  array: T[] | T,
  item: T
) => (Array.isArray(array) ? array.indexOf(item) !== -1 : array === item);

export const includeInObjectArray = <T extends {}, K extends keyof T>(
  parameter: IIncludeInObjectArrayParameters<T, K>
) => {
  const { array, value, filed } = parameter;
  return array.some((item) => Object.is(item[filed], value));
};

export const isEmptyArray = <T>(array: T[]) => !array.length;

export const updateObjectArray = <T extends {}, K extends keyof T>(
  params: IUpdateObjectArrayParameters<T, K>
) => {
  const { array, field, newValue, limit } = params;
  if (!newValue) {
    return array;
  }
  const index = array.findIndex((item) => item[field] === newValue[field]);
  return updateArray({ array, newValue, index, limit });
};

export const uniqueItemArray = <T>(array: T[]): T[] =>
  Array.from(new Set(array));

export const sortAlphabeticalObjectArray = <T extends object>(
  array: T[],
  field: keyof T
) => array.sort((a, b) => (a[field] < b[field] ? -1 : 1));

export const sortAlphabeticalPrimitiveArray = <T extends TPrimitive>(
  array: T[]
) => array.sort((a, b) => (a < b ? -1 : 1));

// *** Random *** //

export const getRandomItem = <T>(array: T[]): T =>
  array[Math.ceil(Math.random() * array.length) - 1];

export const getRandomIndexInArray = (array: any[]): number =>
  Math.ceil(Math.random() * array.length) - 1;

// *** Async *** //

export const asyncMap = async <T, V>(
  array: T[],
  asyncAction: (item: T) => Promise<V>
) => {
  const asyncItems: V[] = [];
  for await (const item of array) {
    const response = await asyncAction(item);
    asyncItems.push(response);
  }
  return asyncItems;
};

export const accumulateNumbersArray = (array: number[]) =>
  array.reduce((accumulate, currentValue) => accumulate + currentValue, 0);
