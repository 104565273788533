import { useReduxSelector } from 'services/hooks';
import { Theme } from 'themes';

export const GameCode = () => {
  const gameCode = useReduxSelector((redux) => redux.player.authInfo.code);

  return (
    <Theme.Paragraph>
      Game code: <Theme.Text $fontFamily="bold">{gameCode}</Theme.Text>
    </Theme.Paragraph>
  );
};
