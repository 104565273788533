import { Theme } from 'themes';

interface IProps {
  text: string;
  subtitle?: string;
}

export const Title = (props: IProps) => {
  return (
    <Theme.Flexbox $itemGap="16px" $isColumn>
      <Theme.Title>{props.text}</Theme.Title>
      {props.subtitle && <Theme.Title>{props.subtitle}</Theme.Title>}
    </Theme.Flexbox>
  );
};
