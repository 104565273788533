import { FC, ReactNode } from 'react';
import styled, { RuleSet } from 'styled-components';

import { COLORS, IWrapperStyled, Theme, Z_INDEXES } from 'themes';
import { CSS } from 'typings';

import { useSelectorContext } from '../selector.context';
import { SELECTOR_THEME } from '../selector.constants';

interface ISelectorMenuProps extends IWrapperStyled {
  topGap: string;
  className?: string;
  styles?: RuleSet;
  children?: ReactNode;
}

interface ISelectorMenuStyled extends IWrapperStyled {
  $isOpen: boolean;
  $topGap: string;
  $themeStyled: CSS;
  styles?: RuleSet;
}

const Menu = styled(Theme.Wrapper)<ISelectorMenuStyled>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  margin-top: ${(props) => props.$topGap};
  min-height: 16px;
  border: 1px solid black;
  border-radius: 8px;

  position: absolute;
  width: 100%;
  z-index: ${Z_INDEXES.TOOLTIP};
  background-color: ${COLORS.white};

  max-height: 500px;
  overflow: auto;

  ${(props) => props.$themeStyled};
  ${({ styles: $styles }) => $styles};
`;

export const SelectorMenu: FC<ISelectorMenuProps> = (props) => {
  const { className, children, topGap, styles, ...wrapperProps } = props;
  const { isOpen, theme = 'orange' } = useSelectorContext();
  const themeStyled = SELECTOR_THEME[theme].menu;

  return (
    <Menu
      className={className}
      $isOpen={isOpen}
      $topGap={topGap}
      $themeStyled={themeStyled}
      styles={styles}
      {...wrapperProps}
    >
      {children}
    </Menu>
  );
};
