import { useEffect, useState } from 'react';

import { sectorRef } from '../api';
import { useReduxSelector } from './redux';

export const useRealtimeJobLimits = (sector: string) => {
  const [isLimited, setIsLimited] = useState(false);

  const gameCode = useReduxSelector((redux) => redux.player.authInfo.code);

  useEffect(() => {
    if (!gameCode) {
      return;
    }

    const ref = sectorRef(gameCode, sector);

    ref.on('value', (snapshot) => {
      const data = snapshot.val();

      const limit = data?.limit || 0;
      const numPlayers = data?.players ? Object.keys(data.players).length : 0;

      setIsLimited(limit <= numPlayers);
    });

    return () => ref.off();
  }, [gameCode, sector]);

  return isLimited;
};
