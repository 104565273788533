import { IPlayerPossessions } from '@avid/common';

import { db } from 'services/firebase';

import { IShopConstants } from 'typings';

type TPossessionKey = keyof IPlayerPossessions | 'icon';

export const useShopAPI = () => {
  const fetchShopCollection = async () =>
    (
      await db.collection('constants').doc('shop').get()
    ).data() as Promise<IShopConstants>;

  const fetchPossessionSector = (possession: TPossessionKey) => async () =>
    (await fetchShopCollection()).possessionPrice[possession];
  const fetchIconPrice = async () => (await fetchShopCollection()).icons;

  return {
    fetchPossessionSector,
    fetchIconPrice,
  };
};
