import { memo } from 'react';

import { HOME_STRINGS } from 'screens/home/home.strings';

import { arrayEqual, moveToRouteCallback, objectEqual } from 'services/utils';
import { PAGE_ROUTES } from 'constants/routes';

import { RewardsList } from './components';
import { REWARDS_COLLECTION_STRINGS } from './rewards-collection.constants';
import { RewardsCollectionStyles as Styles } from './rewards-collection.styles';
import { useReduxSelector } from 'services/hooks';

interface IRewardsListProps {
  rewardsNames: string[];
}

const { REWARDS, REWARDS_EMPTY } = HOME_STRINGS.ACHIEVEMENT;
const { buttonBadgesList } = REWARDS_COLLECTION_STRINGS;

export const moveToBadgeListCallback = moveToRouteCallback(
  PAGE_ROUTES.badgesList
);

const RewardsListDisplayed = (props: IRewardsListProps) => {
  const { rewardsNames } = props;

  return (
    <>
      <Styles.Title>{REWARDS}</Styles.Title>
      <RewardsList rewardsNames={rewardsNames} />
    </>
  );
};

const RewardsEmptyDisplayed = () => <Styles.Info>{REWARDS_EMPTY}</Styles.Info>;

export const RewardsCollection = memo(() => {
  const rewardsOptions = useReduxSelector(
    (redux) => redux.rewards.options,
    objectEqual
  );

  const userRewardsTypes =
    useReduxSelector((redux) => redux.player.rewards, arrayEqual) || [];

  if (!rewardsOptions) {
    return null;
  }

  const rewards = [...userRewardsTypes].reverse();

  return (
    <Styles.Wrapper>
      {rewards && rewards.length >= 1 ? (
        <RewardsListDisplayed rewardsNames={rewards} />
      ) : (
        <RewardsEmptyDisplayed />
      )}
      <Styles.GoToBadgesButton
        title={buttonBadgesList}
        onClick={moveToBadgeListCallback}
      />
    </Styles.Wrapper>
  );
});
