import { CostModal } from 'components/modal';
import { Theme } from 'themes';

import { EDUCATION_STRINGS } from '../../../education.strings';

import { StartPhdHard } from './start-phd-hard';

import { useStartTest, IStartTestProps } from './start-test.state';

const { START_BUTTON, STOP_BUTTON, TITLE } = EDUCATION_STRINGS.START_TEST;

export const StartTest = (props: IStartTestProps) => {
  const { onCloseModal } = props;

  const state = useStartTest(props);

  if (!state) {
    return null;
  }

  const { energy, money, isLoading, isPhdHard, onStartTest } = state;

  if (isPhdHard) {
    return (
      <StartPhdHard
        energy={energy}
        money={money}
        isLoading={isLoading}
        onNext={onStartTest}
        onClose={onCloseModal}
      />
    );
  }

  return (
    <CostModal
      cancelButtonTitle={STOP_BUTTON}
      confirmButtonTitle={START_BUTTON}
      energy={energy}
      money={money}
      onConfirm={onStartTest}
      onCloseModal={onCloseModal}
      onCancel={onCloseModal}
      title={<Theme.Paragraph>{TITLE}</Theme.Paragraph>}
    />
  );
};
