import { useState, Fragment } from 'react';

import { Text } from './ui';
import { InfoImageModal } from './modal';
import { useReduxSelector } from 'services/hooks';
import { typographyCss } from 'themes';

const TEXT = {
  ROUND: [
    'The round has ended.',
    'Please wait for the',
    'next round to begin.',
  ],
  READY: ['Get ready!', 'The first round will be', 'starting very soon.'],
} as const;

export const WaitingModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const round = useReduxSelector((redux) => redux.game.round.number);

  if (!isOpen) {
    return null;
  }

  const text = TEXT[round ? 'ROUND' : 'READY'];

  const RenderText = text.map((line, index) => (
    <Fragment key={index}>
      {index ? <br /> : null}
      {line}
    </Fragment>
  ));

  const onClose = () => setIsOpen(false);

  return (
    <InfoImageModal
      image="manWaiting"
      title={<Text typography={typographyCss.RalewayBold16}>{RenderText}</Text>}
      buttonText="OK"
      onCloseModal={onClose}
    />
  );
};
