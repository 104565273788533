import { HTMLAttributes, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { COLORS } from 'themes';

interface IRootProps {
  rootWidth?: string;
  isValid?: boolean;
  isBordered?: boolean;
}

interface IProps extends IRootProps, HTMLAttributes<HTMLDivElement> {
  Icon?: JSX.Element;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

const Styles = {
  Root: styled.div<IRootProps>`
    display: flex;
    padding: 14px 16px;
    border-radius: 10px;
    background-color: ${COLORS.white};
    box-shadow: 2px 6px 16px rgba(45, 45, 55, 0.1);
    max-width: 480px;
    transition: all 0.2s ease-in-out;

    border: 1px solid
      ${({ isValid, isBordered }) =>
        isValid ? (isBordered ? COLORS.black : 'transparent') : COLORS.red};
    color: ${({ isValid }) => (isValid ? COLORS.black : COLORS.red)};

    ${({ rootWidth }) => rootWidth && `width: ${rootWidth}`};
  `,
  IconWrapper: styled.div`
    padding-right: 12px;
  `,
  Input: styled.input`
    flex: 1;

    ::placeholder {
      color: ${COLORS.black};
    }
  `,
};

export const Field = (props: IProps) => {
  const { Icon, isValid = true, inputProps } = props;

  const RenderIcon = Icon && <Styles.IconWrapper>{Icon}</Styles.IconWrapper>;

  return (
    <Styles.Root isValid={isValid} {...props}>
      {RenderIcon}
      <Styles.Input {...inputProps} />
    </Styles.Root>
  );
};
