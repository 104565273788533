import { IPlayer } from '@avid/common';

import { PlayerActions } from 'services/redux';

import { useReduxDispatch, useReduxSelector } from './redux';

interface IGetPrice {
  money?: number;
  price?: number;
}
interface ICheckPrice extends IGetPrice {
  energy: number;
}

export const useUtils = () => {
  const { energy, money, minimalMoney } = useReduxSelector((redux) => ({
    energy: redux.player.main.energy,
    money: redux.player.main.money,

    minimalMoney: redux.app.minimalMoney,
  }));

  const { callAction } = useReduxDispatch();

  const setPlayer = callAction(PlayerActions.set);

  const checkNeedMoney = (price: IGetPrice) => !!price.money || !!price.price;
  const getEndPayMoney = (price: IGetPrice) =>
    money - (price.money ?? price.price ?? 0);

  const checkCanBuy = (price: ICheckPrice) => {
    const isNeedEnergy = energy >= price.energy;
    const endPayMoney = getEndPayMoney(price);

    const isCanBuy = checkNeedMoney(price)
      ? endPayMoney >= minimalMoney && isNeedEnergy
      : isNeedEnergy;

    return isCanBuy;
  };

  const checkCannotBuy = (price?: ICheckPrice) => {
    if (!price) {
      return false;
    }

    const isNeedEnergy = energy < price.energy;
    const endPayMoney = getEndPayMoney(price);

    const isCannotBuy = checkNeedMoney(price)
      ? minimalMoney > endPayMoney || isNeedEnergy
      : isNeedEnergy;

    return isCannotBuy;
  };

  const setUserData = (user: IPlayer) => {
    setPlayer(user);
  };

  return {
    checkCannotBuy,
    checkCanBuy,
    setUserData,
  };
};
