import { IPlayerPossessions, PossessionLevel } from '@avid/common';

import { TImageNames } from 'components/image';

export const POSSESSION_ICONS: Record<
  keyof IPlayerPossessions,
  Record<PossessionLevel, Record<'standard' | 'us', TImageNames>>
> = {
  car: {
    [PossessionLevel.Budget]: { standard: 'car1', us: 'carUS1' },
    [PossessionLevel.Standard]: { standard: 'car2', us: 'carUS2' },
    [PossessionLevel.Luxury]: { standard: 'car3', us: 'carUS3' },
  },
  home: {
    [PossessionLevel.Budget]: { standard: 'home1', us: 'homeUS1' },
    [PossessionLevel.Standard]: { standard: 'home2', us: 'homeUS2' },
    [PossessionLevel.Luxury]: { standard: 'home3', us: 'homeUS3' },
  },
  mobile: {
    [PossessionLevel.Budget]: { standard: 'phone1', us: 'phone1' },
    [PossessionLevel.Standard]: { standard: 'phone2', us: 'phone2' },
    [PossessionLevel.Luxury]: { standard: 'phone3', us: 'phone3' },
  },
  travel: {
    [PossessionLevel.Budget]: { standard: 'travel1', us: 'travel1' },
    [PossessionLevel.Standard]: { standard: 'travel2', us: 'travel2' },
    [PossessionLevel.Luxury]: { standard: 'travel3', us: 'travel3' },
  },
};
