import { useReduxSelector } from 'services/hooks';

import { Title } from './title';

export const RoundsCounter = () => {
  const roundsNumber = useReduxSelector(
    (redux) => redux.game.config?.roundsNumber
  );
  const roundNumber = useReduxSelector((redux) => redux.game.round.number);

  const displayRound = `${roundNumber}|${roundsNumber}`;

  return <Title value={displayRound} />;
};
