import { useCallback, useState } from 'react';
import { ICharacterSmart } from '@avid/common';

import {
  SMART_DIFF,
  SMART_MAX,
  SMART_MIN,
  SMART_TOTAL,
} from 'constants/create-character';
import { getUsedSmartPoints } from 'services/utils';

export interface ISmartsEditorProps {
  characterSmart: ICharacterSmart;
  onSetSmart: (characterSmart: ICharacterSmart) => void;
}

export const useSmartsEditor = (props: ISmartsEditorProps) => {
  const { characterSmart, onSetSmart } = props;

  const [selectedSmart, setSelectedSmart] = useState<keyof ICharacterSmart>();

  const onOpenModal = useCallback(
    (smartKey: keyof ICharacterSmart) => setSelectedSmart(smartKey),
    []
  );

  const onClosePopup = () => setSelectedSmart(undefined);

  const onIncrease = () => {
    if (!selectedSmart) {
      return;
    }

    const current = characterSmart[selectedSmart];

    if (
      current >= SMART_MAX ||
      getUsedSmartPoints(characterSmart) >= SMART_TOTAL
    ) {
      return;
    }

    onSetSmart({ ...characterSmart, [selectedSmart]: current + SMART_DIFF });
  };

  const onDecrease = () => {
    if (!selectedSmart) {
      return;
    }

    const current = characterSmart[selectedSmart];

    if (current <= SMART_MIN) {
      return;
    }

    onSetSmart({ ...characterSmart, [selectedSmart]: current - SMART_DIFF });
  };

  return { selectedSmart, onOpenModal, onClosePopup, onIncrease, onDecrease };
};
