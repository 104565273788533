import { useCanBuy } from 'services/hooks';

interface IProps {
  energy: number;
  money?: number;
}

export const useStartPhfHard = (props: IProps) => {
  const { energy, money } = props;

  const isCanBuy = useCanBuy({ energy, money });

  return { isCanBuy };
};
