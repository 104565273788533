import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useReduxSelector } from 'services/hooks';
import { PlayerActions } from 'services/redux';
import { callFunctionAction } from 'services/api';
import { getRelevantSkillAsync } from 'shared';

import { WorkContext } from '../../../work.context';

export const useStartRelevant = () => {
  const { onSetSkills, onCloseModal, onSetSelfSelect } = useContext(
    WorkContext
  );

  const [isLoading, setIsLoading] = useState(false);

  const abilities = useReduxSelector((redux) => redux.player.skills);
  const version = useReduxSelector((redux) => redux.app.version);
  const sector = useReduxSelector((redux) => redux.player.work?.sector);
  const work = useReduxSelector((redux) => redux.player.work?.work);
  const money = useReduxSelector(
    (redux) => redux.app.PRICE_WORK_SKILL_CENTER_SELECT.money
  );
  const energy = useReduxSelector(
    (redux) => redux.app.PRICE_WORK_SKILL_CENTER_SELECT.energy
  );
  const maxSkillLevel = useReduxSelector((redux) => redux.app.MAX_SKILL_LEVEL);

  const gameSkills = useReduxSelector((redux) => redux.app.skills);

  const dispatch = useDispatch();

  const onCancel = () => {
    onCloseModal();
  };

  const onConfirm = async () => {
    if (isLoading) {
      return;
    }

    if (!version) {
      onCloseModal();
      return;
    }

    setIsLoading(true);

    const relevantSkillName =
      sector &&
      work &&
      (await getRelevantSkillAsync({
        sector,
        job: work,
        playerSkills: abilities,
        version,
        maxSkillLevel,
      }));

    if (!relevantSkillName) {
      onCloseModal();
      return;
    }

    const relevantSkill = gameSkills.find(
      (gameSkill) => gameSkill.name === relevantSkillName
    );

    if (!relevantSkill) {
      onCloseModal();
      return;
    }

    try {
      const result = await callFunctionAction(
        '@WORK/CHOOSE_RELEVANT_SKILLS',
        undefined
      );
      dispatch(PlayerActions.merge(result.mergePlayer));

      onSetSkills([relevantSkill]);
      onSetSelfSelect();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      onCloseModal();
    }
  };

  return { money, energy, onConfirm, onCancel };
};
