import { IStudy, TGameVersion } from '@avid/common';

import { entriesObject } from './object';

interface ICheckNtuHighestLevelParams {
  level: string;
  educationLevels: Record<string, IStudy>;
  version?: TGameVersion;
}

export const getHighestLevel = (educationLevels: Record<string, IStudy>) => {
  const sortedEntries = entriesObject(educationLevels).sort(
    (first, second) => second[1].order - first[1].order
  );

  return sortedEntries;
};

export const checkHighestLevel = (
  educationLevels: Record<string, IStudy>,
  levelName: string
) => {
  const highestLevel = getHighestLevel(educationLevels);

  return highestLevel[0][0] === levelName;
};

export const checkNtuHighestLevel = (params: ICheckNtuHighestLevelParams) => {
  const { educationLevels, level: levelName, version } = params;

  return version === 'ntu' && checkHighestLevel(educationLevels, levelName);
};
