import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MainActions } from 'screens/main';
import { useReduxSelector, useReduxDispatch, usePushLog } from 'services/hooks';
import { checkNtuHighestLevel, objectEqual, recordValue } from 'services/utils';
import { PlayerActions } from 'services/redux';
import { callFunctionAction } from 'services/api';

import {
  IEducationState,
  EducationPage,
  EducationModal,
} from '../../../education.typing';

export interface IStartTestProps {
  level: string;
  sector?: string;
  onCloseModal: () => void;
  updateEducationState: (partialState: Partial<IEducationState>) => void;
}

export const useStartTest = (props: IStartTestProps) => {
  const { level, sector, updateEducationState } = props;

  const [isLoading, setIsLoading] = useState(false);

  const version = useReduxSelector((redux) => redux.app.version);
  const educationLevels = useReduxSelector(
    (redux) => redux.app.educationLevels,
    objectEqual
  );

  const isPhdHard = useMemo(
    () => checkNtuHighestLevel({ educationLevels, level, version }),
    [educationLevels, level, version]
  );

  const { callAction } = useReduxDispatch();

  const dispatch = useDispatch();
  const pushLog = usePushLog();

  const setShowBottomBar = callAction(MainActions.setShowBottomBar);

  const cost = recordValue(educationLevels, level)?.price;

  if (!cost) {
    return null;
  }

  const { energy, money } = cost;

  const onStartTest = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await callFunctionAction('@EDUCATION/START', {
        level,
        sector,
      });

      if (result.isExceeded) {
        updateEducationState({ modal: EducationModal.ExceededDegree });
        return;
      }

      if (!result.questions?.length) {
        throw new Error('No questions');
      }

      setShowBottomBar(false);

      updateEducationState({
        questions: result.questions,
        page: EducationPage.Test,
        modal: undefined,
      });

      dispatch(PlayerActions.merge(result.mergePlayer));
    } catch (error) {
      updateEducationState({
        page: EducationPage.Level,
        modal: undefined,
      });

      console.error(error);
    } finally {
      setIsLoading(false);
    }

    pushLog({
      type: 'education',
      action: 'start',
      params: {
        level,
        sector,
        energy: -energy,
        money: money && -money,
      },
    });
  };

  return { isLoading, energy, money, isPhdHard, onStartTest };
};
