import styled from 'styled-components';

import { ButtonOld } from 'components/button';
import { MEDIA, spacing } from 'themes';

export const CareerStyles = {
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${spacing(2)};
    }

    ${MEDIA.LAPTOP} {
      flex-direction: row;
      justify-content: center;

      & > * + * {
        margin-top: 0;
        margin-left: ${spacing(6)};
      }
    }
  `,

  ProgressesContainer: styled.div`
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${spacing(2)};
    }

    ${MEDIA.LAPTOP} {
      flex-direction: row;
      justify-content: center;

      & > * + * {
        margin-top: 0;
        margin-left: ${spacing(5)};
      }
    }
  `,
  Button: styled(ButtonOld)`
    width: 280px;
    height: 62px;
  `,
};
