import { createPrice } from 'services/utils';

export const WORK_STRINGS = {
  CAREER: {
    POSITION: 'Your current position',
    EXPERIENCE: (year: number) => `Work experience ${year} cycle`,
    SKILL: 'Recently Developed Skills',
    CONTINUE_BUTTON: 'Continue Working',
    CHANGE_BUTTON: 'Change Job',
    QUIT_BUTTON: 'Quit Job',
    COMPANY_NAME: 'Company: ',
  },

  MODAL_WITH_PRICE: {
    CONFIRM_BUTTON: 'Continue',
    CANCEL_BUTTON: 'Go Back',
  },

  START_TEST_TITLE: 'To work at your job',
  START_TEST_RELEVANT_TITLE: 'Chose skills relevant to your next level job',

  NOT_PASS_MODAL: {
    TITLE: 'Sorry, you didn’t do well in your job.',
    WARNING: (limitNotPassWork: number) =>
      `Warning! If you fail ${limitNotPassWork} times in the same job, you will get fired.`,
    SKILL: 'No skills gained.',
    EXPERIENCE: 'No work experience gained.',
    BUTTON: 'Continue',
  },

  COMPLETE_WORK: {
    TITLE: (experience: number) =>
      `Done with work! Overall work experience: ${experience} ${
        experience > 1 ? 'cycles' : 'cycle'
      }`,
    BUTTON: 'Continue',
    SKILL: 'New Skill Levels:',
  },

  CAREER_UP: {
    TITLE: 'Congratulations, you are up for a promotion!',
    SUBTITLE:
      'You are eligible for a promotion based on your skills and work experience.',
    BUTTON: 'View job offers',
  },

  CHOOSE_SKILL: {
    TITLE: (needSkillSelect: number) =>
      `Select ${needSkillSelect} skill to develop`,
    BUTTON: 'Continue',
    CHOOSE_RELEVANT_BUTTON: 'Choose Relevant Skills',
  },

  FAIL_WORK: {
    TITLE: 'Sorry, you lost your job!',
    SUBTITLE: 'You failed 3 times in your job and got fired. ',
    BUTTON: 'Find a new job',
  },

  QUIT_WORK: {
    TITLE: 'Are you sure you want to quit your job?',
    CONFIRM_BUTTON: 'Yes',
    CANCEL_BUTTON: 'Go Back',
  },

  JOB_PROMOTION: {
    TITLE: 'Job Promotion',
    SUBTITLE: 'Select your new job from the offers below',
    SALARY: (salary: number) => `Salary: ${createPrice(salary)}`,
    BUTTON: 'Confirm new job',
  },

  JOB_CONGRATULATION: {
    TITLE: 'Congrats on the promotion!',
    SUBTITLE: 'Your position is now:',
    BUTTON: 'Start working',
  },
};
