import { FC, ReactElement } from 'react';
import { spacing, Theme } from 'themes';

import { OnetimeClickButton } from 'components/onetime-click-button';
import { ButtonOld } from 'components/button';

import { IModalWrapperProps, ModalWrapper } from './modal-wrapper';

export interface IChooseModalProps extends IModalWrapperProps {
  confirmButtonTitle: string;
  cancelButtonTitle: string;
  title?: ReactElement;

  onConfirm(): void;
  onCancel?: () => void;
}

export const ChooseModal: FC<IChooseModalProps> = (props) => {
  const {
    cancelButtonTitle,
    confirmButtonTitle,

    title,
    children,

    onCloseModal,
    onCancel = onCloseModal,
    onConfirm,

    ...modalProps
  } = props;

  return (
    <ModalWrapper onCloseModal={onCloseModal} {...modalProps}>
      {title}
      {children}
      <Theme.Wrapper $itemGap={{ default: spacing(3) }}>
        <OnetimeClickButton onClick={onConfirm} title={confirmButtonTitle} />
        <ButtonOld
          onClick={onCancel}
          title={cancelButtonTitle}
          styleTheme="cancelModal"
        />
      </Theme.Wrapper>
    </ModalWrapper>
  );
};
