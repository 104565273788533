import { useMemo } from 'react';

import { useMod, useReduxSelector } from 'services/hooks';
import { arrayEqual, objectEqual } from 'services/utils';

import { getDegrees } from './choose-degree.utils';
import { EducationPage } from '../../education.typing';

export interface IChooseDegreeStateProps {
  sector: string;
  setPage: (page: EducationPage) => void;
  setLevel: (level: string) => void;
}

export const useChooseDegree = (props: IChooseDegreeStateProps) => {
  const { sector, setPage, setLevel } = props;

  const educations = useReduxSelector(
    (redux) => redux.player.educations,
    arrayEqual
  );
  const educationLevels = useReduxSelector(
    (redux) => redux.app.educationLevels,
    objectEqual
  );

  const degrees = useMemo(
    () => getDegrees({ educationLevels, educations, sector }),
    [educationLevels, educations, sector]
  );

  const limited = useMod('limitedEducation')?.hooks.useLimitedDegrees({
    sector,
    sortedDegrees: degrees,
  });

  const setDegree = (degree: string) => () => setLevel(degree);

  const onClickGoBack = () => setPage(EducationPage.Sector);

  return {
    isLoading: limited?.isLoading ?? false,
    degrees: limited?.degrees ?? degrees,
    setDegree,
    onClickGoBack,
  };
};
