import { IAbility } from '@avid/common';

import { ButtonOld } from 'components/button';
import { Image } from 'components/image';
import { useEducationInfo } from 'services/hooks';
import { Theme } from 'themes';

import { EducationModalWrapper } from '../education-modal-wrapper';

import { EducationSkills } from './education-skills';

interface IProps {
  level: string;
  onClose: () => void;
  sector?: string;
  abilities?: IAbility[];
}

export const PassDefault = (props: IProps) => {
  const { level, sector, abilities, onClose } = props;

  const { fetchEducationAchievement } = useEducationInfo();

  const achievement = fetchEducationAchievement({
    level,
    sector,
    format: 'pass-test',
  });

  const subtitle = `You’ve successfully graduated. ${achievement} achieved`;

  return (
    <EducationModalWrapper onCloseModal={onClose}>
      <Theme.Wrapper>
        <Theme.Paragraph>Congratulations</Theme.Paragraph>
        <Theme.Paragraph>{subtitle}</Theme.Paragraph>
      </Theme.Wrapper>

      <Image type="passTest" />

      <EducationSkills abilities={abilities} />

      <ButtonOld title="Continue" onClick={onClose} />
    </EducationModalWrapper>
  );
};
