import styled from 'styled-components';

import { Theme } from 'themes';
import { ButtonOld, buttonDisabledStyles } from 'components/button';
import { ChooseSkill } from 'components/choose-skill';
import { Loader } from 'components/loader';

import { WORK_STRINGS } from '../../work.strings';

import { useWorkChooseSkill } from './work-choose-skill.state';
import { getVersionString } from 'services/utils/versions';

const { BUTTON, TITLE, CHOOSE_RELEVANT_BUTTON } = WORK_STRINGS.CHOOSE_SKILL;

const ContinueButton = styled(ButtonOld)<{ isSkillsChosen: boolean }>`
  ${({ isSkillsChosen }) => !isSkillsChosen && buttonDisabledStyles.default}
`;

export const WorkChooseSkill = () => {
  const {
    isLoading,
    version,
    fetchedSkills,
    isCanRelevant,
    isNeedSkillNumber,
    isSelfSelect,
    skills,
    LIMIT_SELECT_SKILL_BEFORE_START_WORK,

    openWorkStartPage,
    onContinue,
    onSetSkill,
    onChooseRelevantSkill,
  } = useWorkChooseSkill();

  if (isLoading) {
    return <Loader />;
  }

  const choseSkillButton = getVersionString(version, 'CONSULT_SKILLS');

  return (
    <Theme.Flexbox
      $isColumn
      $alignItem="center"
      $itemGap="16px"
      $textAlign="center"
    >
      <ChooseSkill
        skillInfos={fetchedSkills}
        limit={LIMIT_SELECT_SKILL_BEFORE_START_WORK}
        title={TITLE(LIMIT_SELECT_SKILL_BEFORE_START_WORK)}
        setAbilities={onSetSkill}
        selectAbilities={skills.map(({ name }) => name)}
      />
      <ContinueButton
        onClick={onContinue}
        isSkillsChosen={isNeedSkillNumber}
        title={BUTTON}
      />

      {isSelfSelect && isCanRelevant && (
        <>
          <Theme.Paragraph>{choseSkillButton}</Theme.Paragraph>
          <ButtonOld
            styleTheme="blue"
            onClick={onChooseRelevantSkill}
            title={CHOOSE_RELEVANT_BUTTON}
          />
        </>
      )}

      <ButtonOld
        title="Go back"
        onClick={openWorkStartPage}
        styleTheme="cancelModal"
      />
    </Theme.Flexbox>
  );
};
