import { ReactNode, forwardRef } from 'react';

import { Z_INDEXES } from 'themes';

import { ModalStyles as Styles } from './modal.styles';

export interface IModalProps {
  isOpen?: boolean;
  className?: string;
  zIndex?: number;
  children?: ReactNode;
}

export const Modal = forwardRef<HTMLDivElement, IModalProps>((props, ref) => {
  const { className, children, isOpen, zIndex = Z_INDEXES.MODAL } = props;

  return (
    <Styles.Root $isOpen={isOpen} $zIndex={zIndex} className={className}>
      <Styles.ContentContainer ref={ref}>{children}</Styles.ContentContainer>
    </Styles.Root>
  );
});
