import { CostModal } from 'components/modal';
import { Theme } from 'themes';

import { FIND_JOB_STRINGS } from '../../../../find-job.strings';

import {
  IOtherCompanyModalProps,
  useOtherCompanyModal,
} from './other-company-modal.state';

const { CHANGE_COMPANY } = FIND_JOB_STRINGS;

export const OtherCompanyModal = (props: IOtherCompanyModalProps) => {
  const { job, energy, money, onClose, onChangeActiveCompanyIndex } = props;

  const { onConfirm } = useOtherCompanyModal({
    job,
    energy,
    money,
    onChangeActiveCompanyIndex,
    onClose,
  });

  return (
    <CostModal
      energy={energy}
      money={money}
      cancelButtonTitle={CHANGE_COMPANY.CANCEL_BUTTON}
      confirmButtonTitle={CHANGE_COMPANY.CONFIRM_BUTTON}
      title={<Theme.Paragraph>{CHANGE_COMPANY.TITLE}</Theme.Paragraph>}
      onCloseModal={onClose}
      onConfirm={onConfirm}
    />
  );
};
