import styled from 'styled-components';

import { Box, Text } from 'components/ui';
import { Image } from 'components/image';
import { COLORS, Z_INDEXES } from 'themes';

const Control = styled.div`
  width: 58px;
  height: 58px;
  background-color: ${COLORS.white};
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 5px;
    background-color: ${COLORS.purple};
    border-radius: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const SmartPopupStyles = {
  Root: styled(Box)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 240px;
    display: grid;
    justify-items: center;
    row-gap: 16px;
    padding: 32px;
    border-radius: 24px;
    z-index: ${Z_INDEXES.MODAL};
    background-color: ${COLORS.purple};
    color: ${COLORS.white};
  `,
  CloseIcon: styled(Image)`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  `,
  ControlsWrapper: styled.div`
    display: grid;
    grid-template-columns: auto minmax(36px, 1fr) auto;
    justify-items: center;
    align-items: center;
    column-gap: 32px;
  `,
  ValueSpan: styled(Text)`
    width: 40px;
  `,
  MinusControl: Control,
  PlusControl: styled(Control)`
    &::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 30px;
      background-color: ${COLORS.purple};
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
};
