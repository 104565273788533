import { deepEqual } from './object';

// ! Redux action creator generator
export function action<T extends string, P>(
  type: T,
  payload: P
): { type: T; payload: P };

export function action<T extends string>(type: T): { type: T };

export function action<T extends string, P = undefined>(type: T, payload?: P) {
  return { type, payload } as any;
}

export const objectEqual = <T>(obj1: T, obj2: T) => deepEqual(obj1, obj2);

export const arrayEqual = <T>(arr1: T[] = [], arr2: T[] = []) => {
  let result = true;

  if (arr1.length !== arr2.length) {
    return false;
  }

  arr1.forEach((_, index) => {
    if (!deepEqual(arr1[index], arr2[index])) {
      result = false;
      return;
    }
  });

  return result;
};
