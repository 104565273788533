import { typographyCss } from 'themes';

import { P } from './typography';
import { VerticalList } from './vertical-list';

interface IProps {
  items: string[];
  rowGap?: string;
  typograpghy?: keyof typeof typographyCss;
}

export const VerticalTextList = (props: IProps) => {
  const { items, rowGap, typograpghy } = props;

  const RenderItems = items.map((item, index) => (
    <P key={index} $typography={typograpghy}>
      {item}
    </P>
  ));

  return <VerticalList rowGap={rowGap}>{RenderItems}</VerticalList>;
};
