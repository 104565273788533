import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CallBackProps, STATUS } from 'react-joyride';

import { callFunctionAction } from 'services/api';
import { PlayerActions } from 'services/redux';

export const useChooseSkillsTutorial = () => {
  const passedRef = useRef(false);

  const dispatch = useDispatch();

  const handleCallback = async (data: CallBackProps) => {
    const { status } = data;

    if (status === STATUS.FINISHED && !passedRef.current) {
      passedRef.current = true;

      const result = await callFunctionAction('@TUTORIALS/COMPLETE', {
        tutorialKey: 'course',
      });

      dispatch(PlayerActions.merge(result.mergePlayer));
    }
  };

  return { handleCallback };
};
