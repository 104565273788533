import { Button } from './button';
import { Grid, IGridProps } from './grid';

interface IProps {
  nextLabel: string;
  backLabel: string;
  onNext: () => void;
  onBack: () => void;
  className?: string;
  isNextDisabled?: boolean;
  borderRadius?: string;
  justifyContent?: string;
  gridProps?: IGridProps;
}

export const ButtonBar = (props: IProps) => {
  const {
    backLabel,
    nextLabel,
    className,
    isNextDisabled,
    onBack,
    onNext,
    justifyContent,
    borderRadius = '20px',
    gridProps,
  } = props;

  return (
    <Grid
      rowGap="16px"
      className={className}
      justifyContent={justifyContent}
      {...gridProps}
    >
      <Button
        borderRadius={borderRadius}
        disabled={isNextDisabled}
        onClick={onNext}
      >
        {nextLabel}
      </Button>

      <Button variant="white" borderRadius={borderRadius} onClick={onBack}>
        {backLabel}
      </Button>
    </Grid>
  );
};
