import styled from 'styled-components';

import { Css } from 'themes';

export const RewardsListStyles = {
  Wrapper: styled.ul`
    margin-top: 22px;
    margin-left: 8px;
    margin-right: 8px;
    padding-top: 5px;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: auto;

    ${Css.horizontalScrollBar}
  `,
  Item: styled.li`
    height: 102px;
  `,
};
