import styled from 'styled-components';

import { Image } from 'components/image';
import { Theme, MEDIA, SCALE, spacing } from 'themes';

export const SearchWorkStyles = {
  ContentContainer: styled(Theme.Flexbox)`
    ${MEDIA.LAPTOP} {
      width: 50%;
      max-width: 1000px;
    }
  `,
  ButtonContainer: styled.div`
    width: 100%;
    max-width: ${spacing(SCALE.wide)};
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-top: ${spacing(2)};
    }

    ${MEDIA.LAPTOP} {
      flex-direction: row;
      justify-content: center;

      & > * + * {
        margin-top: 0;
        margin-left: ${spacing(2)};
      }
    }
  `,
  Image: styled(Image)`
    height: 139px;
    margin-top: 32px !important;
  `,
};
