export const COLORS = {
  nwdPrimary: '#DD3AE3',
  nwdBlack: '#0D0D0D',
  white: '#fff',
  backgroundWhite: '#fbfbff',
  lightGray: '#E4E4E5',
  gray: '#EBEBEB',
  grey: '#BBB7B6',
  darkGray: '#7C879D',
  blackGray: '#888281',
  lighterGray: '#EBE9F7',
  ashes: '#C0C0C4',
  darkAches: '#C4C4C4',
  neutralWhite: 'rgba(255, 255, 255, 0.1)',
  disableButton: '#666666',

  red: '#F05858',
  darkRed: '#D64C44',
  orangeRed: '#FF9900',
  pink: '#DC4F89',
  solidRed: '#EB5757',
  pinkRed: '#DE3058',
  backgroundRed: ' rgba(235, 87, 87, 0.55)',
  lightPinkBackground: 'rgba(220, 79, 137, 0.1)',
  pinkBackground: 'rgba(220, 79, 137, 0.6)',
  papayaWhip: '#FEEFDC',

  yellow: '#FFD530',
  darkYellow: '#F9C13F',
  backgroundYellow: 'rgba(249, 193, 63, 0.1)',
  backgroundSelectYellow: 'rgba(249, 193, 63, 0.25)',
  orange: '#F48A02',
  orange2: '#FBB54C',
  pastelOrange: '#F9B050',
  backgroundOrange: 'rgba(244, 138, 2, 0.1)',
  backgroundSelectOrange: 'rgba(244, 138, 2, 0.25)',

  black: '#0B1836',
  maxBlack: '#000000',
  shadow: '#2D2D3726',
  shadowLite: '#2d2d371a',

  purple: '#5748AF',
  lightPurple: '#ef60a3',
  darkPurple: '#5448A9',
  violet: '#7F3F98',

  blue: '#0F7FF1',
  lightBlue: '#00ACC5',
  solidBlue: '#007eff',
  purpleBlue: '#544DEB',
  grayBlue: '#4B6FAC',
  backgroundBlue: 'rgba(15, 127, 241, 0.1)',
  backgroundSelectBlue: 'rgba(15, 127, 241, 0.25)',
  scrollbarTrack: 'rgba(11, 24, 54, 0.15)',

  green: '#27AE60',
  lightGreen: '#61B183',
  darkGreen: '#00A651',
  darkerGreen: '#239E44',
  highlineGreen: '#98FB98',
  backgroundGreen: 'rgba(97, 177, 131, 0.55)',

  modalBackground: '#2D2D3728',

  transparent: 'initial',
};

export type TColorNames = keyof typeof COLORS;
