import { FC, ReactNode } from 'react';

import { useTooltip } from './tooltip.state';

import { ITooltipStyled, TooltipStyles } from './tooltip.styles';

export interface ITooltipProps extends ITooltipStyled {
  className?: string;
  children?: ReactNode;
}

export const Tooltip: FC<ITooltipProps> = (props) => {
  const { className, children, $isHide: isHide, ...styled } = props;
  const { ref, isOpen } = useTooltip<HTMLDivElement>();

  return (
    <TooltipStyles.Wrapper
      ref={ref}
      $isHide={isHide || !isOpen}
      className={className}
      {...styled}
    >
      {children}
    </TooltipStyles.Wrapper>
  );
};
