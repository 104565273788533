export const COURSE_STRINGS = {
  SKILL: {
    TITLE: 'Your Skills and Skill Levels:',
    BUTTON: 'Level Up My Skills',
  },
  ABOUT: {
    TITLE_1: 'Level up in 1 skill',
    TITLE_2: 'You may select up to 5 skills to develop',
    CONFIRM_BUTTON: 'Next',
    CANCEL_BUTTON: 'Go Back',
  },
  START_COURSE: {
    CONFIRM_BUTTON: 'Submit',
    CANCEL_BUTTON: 'Cancel',
    SUBTITLE: 'You may select up to 5 skills to develop',
    TITLE: (skillNumber: number) => `Level up in ${skillNumber} skills`,
  },
  END_COURSE: {
    TITLE: 'These skills were developed. Your new skill levels:',
    SKILL_SECTION: 'Here are your new skill levels:',
    CLOSE_BUTTON: 'Close',
  },
  CHOOSE_SKILL: {
    TITLE: 'Choose skills',
    BUTTON: 'Continue',
  },
  CHOOSE_SECTOR: {
    TITLE: 'Choose the sector',
  },
  CHOOSE_SECTOR_MODAL: {
    AGREE_BUTTON: 'Confirm',
    DISAGREE_BUTTON: 'Go back',
  },
};
