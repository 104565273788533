import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ModsStyles } from 'containers/mods-styles';
import { GreetingLogo } from 'screens/greeting';
import { useReduxSelector } from 'services/hooks';
import { ModsService } from 'services/mods';
import { RewardsActions } from 'services/rewards';
import { objectEqual, keyofObject } from 'services/utils';

import { AppActions } from '../app.actions';
import { fetchVersionConstants } from '../app.api';
import { loadVersionRewards } from '../app.utils';

import { AppRouter } from './app-router';

export const GameConstantsLoader = () => {
  const [isModsLoaded, setIsModsLoaded] = useState(false);
  const [isConstantsLoaded, setIsConstantsLoaded] = useState(false);

  const gameMods = useReduxSelector((redux) => redux.app.gameMods, objectEqual);
  const gameVersion =
    useReduxSelector((redux) => redux.app.version) || 'standard';

  const dispatch = useDispatch();

  const loadVersionConstants = useCallback(async () => {
    const fetchedConstants = await fetchVersionConstants(gameVersion);

    dispatch(AppActions.updateConstants(fetchedConstants));
  }, [dispatch, gameVersion]);

  const loadRewards = useCallback(async () => {
    const rewards = await loadVersionRewards(gameVersion);

    dispatch(RewardsActions.setRewardsOptions(rewards));
  }, [dispatch, gameVersion]);

  const loadAllConstants = useCallback(async () => {
    await Promise.all([loadVersionConstants(), loadRewards()]);

    setIsConstantsLoaded(true);
  }, [loadRewards, loadVersionConstants]);

  // Load version rewards & constants
  useEffect(() => {
    loadAllConstants();
  }, [loadAllConstants]);

  // Load game mods
  useEffect(() => {
    const loadMods = async () => {
      setIsModsLoaded(false);

      ModsService.unloadAll();

      const modsToLoad = gameMods && keyofObject(gameMods);

      if (modsToLoad?.length) {
        try {
          await ModsService.load(modsToLoad);
        } catch (error) {
          console.error(error);
        }
      }

      setIsModsLoaded(true);
    };

    loadMods();
  }, [gameMods]);

  if (isModsLoaded && isConstantsLoaded) {
    return (
      <>
        <AppRouter />
        <ModsStyles />
      </>
    );
  }

  return <GreetingLogo />;
};
