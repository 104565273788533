import { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { Loader } from 'components/loader';
import { useMod } from 'services/hooks';
import { ModsService } from 'services/mods';
import { getModComponent } from 'services/utils';

interface IMatchParams {
  gameCode: string;
}

export const GameReport = (props: RouteChildrenProps<IMatchParams>) => {
  const GameReportScreenMC = getModComponent(
    useMod('gameReport')?.screens.GameReport
  );

  return <GameReportScreenMC {...props} />;
};

export const GameReportModLoader = (
  props: RouteChildrenProps<IMatchParams>
) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      await ModsService.load(['gameReport']);

      setIsLoading(false);
    };

    load();
  }, []);

  const GameReportTokenScreenMC = getModComponent(
    useMod('gameReport')?.screens.GameReportToken
  );

  if (isLoading) {
    return <Loader />;
  }

  return <GameReportTokenScreenMC {...props} />;
};

export const GameReportToken = (props: RouteChildrenProps<IMatchParams>) => {
  const GameReportTokenScreen = useMod('gameReport')?.screens.GameReportToken;

  if (GameReportTokenScreen) {
    return <GameReportTokenScreen {...props} />;
  }

  return <GameReportModLoader {...props} />;
};
