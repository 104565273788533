import { FC, ReactElement } from 'react';

import { IFlexboxStyled, Theme } from 'themes';
import { isIncludeInArray } from 'services/utils';

import { CheckboxMemo, ICheckboxComponentProps } from './checkbox';

interface ICheckboxListProps extends ICheckboxComponentProps, IFlexboxStyled {
  items: IItem[];
  value?: string | string[];
  isDisabled?: boolean;
}

export const CheckboxList: FC<ICheckboxListProps> = (props) => {
  const { setValue, items, className, variant, value, isDisabled, ...flexbox } =
    props;

  const isChecked = (el: string) =>
    value ? isIncludeInArray(value, el) : false;

  const Items = items.map<ReactElement>((item: IItem, index: number) => {
    const isSelect = isChecked(item.value);
    const isItemDisabled = item.isDisabled || (!isSelect && isDisabled);

    return (
      <CheckboxMemo
        key={index}
        setValue={setValue}
        item={item}
        variant={variant}
        isDisabled={isItemDisabled}
        isChecked={isSelect}
      />
    );
  });

  return (
    <Theme.Flexbox className={className} {...flexbox}>
      {Items}
    </Theme.Flexbox>
  );
};
