export const VERSION_STRINGS = {
  standard: {
    CAREER_CENTRE: 'Career Centre',
    LAND: 'Singaland',
    ONBOARDING_CAREER_CENTRE:
      'All you need to know about jobs and careers in Singaland is in the Career Centre',
    CONSULT_SKILLS:
      'Consult the Career Centre to choose relevant skills for your next promotion',
    SMARTS_DESCRIPTION:
      "Each person has different strengths - or smarts - in various areas. 10 points means you are awesome in that 'smart'.",
    VALUES_DESCRIPTION:
      'These are your priorities which guide the way you choose to live and work.',
  },
  us: {
    CAREER_CENTRE: 'Career Centre',
    LAND: 'Empowerland',
    ONBOARDING_CAREER_CENTRE:
      'All you need to know about jobs and careers in Empowerland is in the Career Centre',
    CONSULT_SKILLS:
      'Consult the Career Centre to choose relevant skills for your next promotion',
  },
  ntu: {
    CAREER_CENTRE: 'Career & Attachment Office (CAO)',
    LAND: 'NTUniverse',
    ONBOARDING_CAREER_CENTRE:
      'The NTU Career & Attachment Office (CAO) develops and empowers students to reach their potential and fulfill career aspirations through personalised career coaching, industry-specific consultations, employability skills workshops, and career experiential programmes.',
    CONSULT_SKILLS:
      'Click Choose Relevant Skills button below for the CAO to recommend a skill for your next promotion.',
    SMARTS_DESCRIPTION:
      'Your ‘smarts’ affect how quickly you gain certain skills',
    VALUES_DESCRIPTION:
      'Your work satisfaction depends on how well your job aligns with your Values, which are priorities which guide the way you live.',
  },
} as const;
