import { useReduxSelector } from 'services/hooks';

import { getSplitRewards } from './badges-list.utils';

export const useBadgesList = () => {
  const rewardsOptions = useReduxSelector((redux) => redux.rewards.options);
  const userRewardsTypes = useReduxSelector((redux) => redux.player.rewards);

  if (!rewardsOptions) {
    return;
  }

  const splitRewards = getSplitRewards(rewardsOptions, userRewardsTypes || []);

  return { splitRewards };
};
