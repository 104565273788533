import { shallowEqual } from 'react-redux';

import { useReduxSelector } from 'services/hooks';

export const useWorkSelectors = () => {
  const positiveValues = useReduxSelector(
    (redux) => redux.player.work?.company.positive,
    shallowEqual
  );
  const negativeValues = useReduxSelector(
    (redux) => redux.player.work?.company.negative,
    shallowEqual
  );

  const chAnchors = useReduxSelector(
    (redux) => redux.player.createCharacter.careerAnchors,
    shallowEqual
  );
  const chValues = useReduxSelector(
    (redux) => redux.player.createCharacter.values,
    shallowEqual
  );

  const version = useReduxSelector((redux) => redux.app.version);
  const constAnchors = useReduxSelector(
    (redux) => redux.app.anchors,
    shallowEqual
  );
  const constValues = useReduxSelector(
    (redux) => redux.app.values,
    shallowEqual
  );

  return {
    version,

    positiveValues,
    negativeValues,

    chAnchors,
    chValues,

    constAnchors,
    constValues,
  };
};
