import { FC } from 'react';
import { IAbility } from '@avid/common';

import { Image } from 'components/image';
import { Loader } from 'components/loader';
import { InfoModal } from 'components/modal';
import { SkillList } from 'components/skills';
import { Theme } from 'themes';

import { COURSE_STRINGS } from './course.strings';

export interface ICompleteCourseProps {
  isLoading: boolean;
  onCompleteCourse(): void;
  abilities?: IAbility[];
}

const { CLOSE_BUTTON, SKILL_SECTION, TITLE } = COURSE_STRINGS.END_COURSE;

export const CompleteCourse: FC<ICompleteCourseProps> = (props) => {
  const { onCompleteCourse, abilities, isLoading } = props;

  return (
    <InfoModal
      onCloseModal={onCompleteCourse}
      onConfirm={onCompleteCourse}
      buttonText={CLOSE_BUTTON}
    >
      <Theme.Title>{TITLE}</Theme.Title>
      <Image type="completeCourse" />
      <Theme.Paragraph>{SKILL_SECTION}</Theme.Paragraph>
      <Loader isLoading={isLoading}>
        {abilities && <SkillList abilities={abilities} />}
      </Loader>
    </InfoModal>
  );
};
