import styled, { css } from 'styled-components';

import { COLORS } from 'themes';
import { Image, TImageNames } from './image';

interface IProps {
  icon: TImageNames;
  isSelect?: boolean;
  isMargin?: boolean;
  className?: string;
  borderColor?: string;
  size?: string;
  onSelect?: (icon: TImageNames) => void;
}

type TWrapperProps = Pick<
  IProps,
  'isSelect' | 'isMargin' | 'borderColor' | 'size'
>;

const Wrapper = styled(Image)<TWrapperProps>`
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  background: ${COLORS.white};
  cursor: pointer;

  ${({ size }) =>
    size &&
    css`
      width: ${size};
      height: ${size};
    `}

  ${({ isMargin }) => isMargin && 'margin: 7px 10px;'}

  ${({ isSelect, borderColor }) =>
    isSelect &&
    css`
      transition: all 0.12s ease-in-out;
      border-color: ${borderColor || COLORS.darkYellow};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    `};

  & > img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const SmallAvatar = (props: IProps) => {
  const {
    icon,
    className,
    isSelect,
    borderColor,
    size = '64px',
    isMargin = true,
    onSelect,
  } = props;

  const onClick = onSelect && (() => onSelect(icon));

  return (
    <Wrapper
      type={icon}
      size={size}
      borderColor={borderColor}
      className={className}
      isMargin={isMargin}
      isSelect={isSelect}
      onClick={onClick}
    />
  );
};
