import { ISkillInfo, TGameVersion } from '@avid/common';

import { VersionsAPI } from 'services/api';

export const getSkillsInfoByNames = (
  skillInfoList: ISkillInfo[],
  skillNames: string[]
) => skillInfoList.filter((skill) => skillNames.includes(skill.name));

export const getEducationQuestionsAsync = (
  version: TGameVersion,
  doc: string
) => VersionsAPI.education.fetchQuestions(version, doc);

export const getEducationQuestionsAmountAsync = (
  version: TGameVersion,
  level: string
) => VersionsAPI.education.fetchQuestionsAmount(version, level);
