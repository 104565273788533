import styled, { css } from 'styled-components';

import { createItemGap } from './style.utils';

import {
  IFlexboxStyled,
  IFlexboxWrapperStyled,
  ITextTemplate,
  ITitleTextTemplate,
} from './styles.typings';

import { COLORS } from 'themes';
import { FONT_SIZES } from 'themes/font-sizes';
import { FONT_FAMILIES } from 'themes/global-fonts';
import { MEDIA } from 'themes/media';

const text = css<ITextTemplate>`
  ${(p) => p.$fontFamily && `font-family: ${FONT_FAMILIES[p.$fontFamily]}`};
  ${(p) => p.$fontSize && `font-size: ${FONT_SIZES[p.$fontSize]}`};

  ${(p) => p.$color && `color: ${COLORS[p.$color]};`}
  ${(p) => p.$textAlign && `text-align: ${p.$textAlign};`}
`;

const cssSafariFlexboxSupport = css`
  flex: 0 1 auto;
`;

const FlexboxWrapper = styled.div<IFlexboxWrapperStyled>`
  display: flex;

  ${(p) => createItemGap(p.$isColumn, p.$itemGap)}
  ${(p) => p.$fontFamily && `font-family: ${FONT_FAMILIES[p.$fontFamily]}`};
  ${(p) => p.$fontSize && `font-size: ${FONT_SIZES[p.$fontSize]}`};

  ${(p) => p.$color && `color: ${COLORS[p.$color]};`}
  ${(p) => p.$textAlign && `text-align: ${p.$textAlign};`}

  ${(p) => p.$isColumn && 'flex-direction: column;'}

  ${MEDIA.SAFARI_ONLY(cssSafariFlexboxSupport)}
`;

const CentralizeFlexboxWrapper = styled(FlexboxWrapper)`
  align-items: center;
  justify-content: center;
`;

const Flexbox = styled(FlexboxWrapper)<IFlexboxStyled>`
  ${(p) => p.$alignItem && `align-items: ${p.$alignItem};`};
  ${(p) => p.$justifyContent && `justify-content: ${p.$justifyContent};`};
`;

export const ThemePreset = {
  Paragraph: styled.p<ITextTemplate>`
    ${text};
  `,
  Text: styled.span<ITextTemplate>`
    ${text}
  `,
  Title: styled.p<ITitleTextTemplate>`
    min-width: fit-content;
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.header};

    ${(p) => p.$color && `color: ${COLORS[p.$color]};`}
    ${(p) => p.$textAlign && `text-align: ${p.$textAlign};`}
  `,

  Flexbox,
  CentralizeFlexbox: CentralizeFlexboxWrapper,

  FullSizeFlexbox: styled(Flexbox)`
    flex: 1;
  `,

  FullSizeCentralizeFlexbox: styled(CentralizeFlexboxWrapper)`
    flex: 1 0 auto;
  `,
};
