import { Step } from 'react-joyride';

import { SelectSector } from 'components/select-sector';
import { AppTutorialController } from 'components/tutorial';
import { useShowCourseTutorial } from 'services/hooks';

import { COURSE_STRINGS } from '../course.strings';

interface IProps {
  sector: string;
  onSelectSector: (sector: string) => void;
}

const STEPS: Step[] = [
  {
    target: '#course-choose_sector',
    content: 'Choose any sector and click "Confirm".',
    disableBeacon: true,
  },
];

const ChooseSectorTutorialComponent = () => {
  return <AppTutorialController steps={STEPS} labelClose="Got it!" />;
};

const ChooseSectorTutorial = () => {
  const isShow = useShowCourseTutorial();

  if (!isShow) {
    return null;
  }

  return <ChooseSectorTutorialComponent />;
};

export const ChooseSector = (props: IProps) => {
  const { onSelectSector, sector } = props;

  return (
    <>
      <SelectSector
        listId="course-choose_sector"
        title={COURSE_STRINGS.CHOOSE_SECTOR.TITLE}
        selectSector={sector}
        isFullSectors={false}
        onSelectSector={onSelectSector}
      />

      <ChooseSectorTutorial />
    </>
  );
};
