import { ButtonOld } from 'components/button';
import { Theme } from 'themes';

import { EDUCATION_STRINGS } from '../../education.strings';
import { EducationPage, IEducationState } from '../../education.typing';

import { EducationModalWrapper } from './education-modal-wrapper';

const { CANCEL_BUTTON, CONFIRM_BUTTON } = EDUCATION_STRINGS.AGREE_SECTOR;

interface IProps {
  sector: string;
  onCloseModal: () => void;
  updateEducationState: (partialState: Partial<IEducationState>) => void;
}

export const AgreeSelectSector = (props: IProps) => {
  const { sector, onCloseModal, updateEducationState } = props;

  const onConfirm = () =>
    updateEducationState({ page: EducationPage.Degree, modal: undefined });

  return (
    <EducationModalWrapper onCloseModal={onCloseModal}>
      <Theme.Paragraph>{`You have selected ${sector} major. Confirm?`}</Theme.Paragraph>
      <ButtonOld onClick={onConfirm} title={CONFIRM_BUTTON} />
      <ButtonOld onClick={onCloseModal} title={CANCEL_BUTTON} styleTheme="white" />
    </EducationModalWrapper>
  );
};
