import { Redirect } from 'react-router-dom';

import { ButtonOld } from 'components/button';
import { Image } from 'components/image';
import { createPrice } from 'services/utils';
import { useReduxSelector } from 'services/hooks';
import { Theme } from 'themes';
import { ROUTES } from 'constants/routes';

import { useLiveExpenses } from './live-expenses.state';
import { LiveExpensesStyles as Styles, ImageCss } from './live-expenses.styles';

const LiveExpenses = () => {
  const { isLoading, getLiveExpenses, payLiveExpenses } = useLiveExpenses();

  const expMoney = createPrice(getLiveExpenses());
  const title = `${expMoney} will be deducted as living expenses`;

  return (
    <Theme.PageContainer>
      <Styles.Root $isColumn $alignItem="center" $textAlign="center">
        <Theme.Title>{title}</Theme.Title>

        <Styles.ImageContainer>
          <Image type="liveExpenses" $styleCss={ImageCss} />
        </Styles.ImageContainer>

        <div>
          <ButtonOld
            title="Next"
            isDisabled={isLoading}
            onClick={payLiveExpenses}
          />
        </div>
      </Styles.Root>
    </Theme.PageContainer>
  );
};

export const LiveExpensesProxy = () => {
  const isExpensesDeducted = useReduxSelector(
    (redux) => redux.player.main.isLiveExpensesDeducted
  );

  if (isExpensesDeducted) {
    return <Redirect to={ROUTES.home} />;
  }

  return <LiveExpenses />;
};
