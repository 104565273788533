import styled from 'styled-components';
import { COLORS } from 'themes';

import { Image, TImageNames } from './image';

interface IProps {
  image: TImageNames;
  size?: string;
  className?: string;
}

const Wrapper = styled(Image)`
  background: ${COLORS.white};
  margin: 0 auto;
  width: 100%;
  height: 100%;

  flex: 0 0 auto;

  &,
  & > img {
    border-radius: 50%;
    max-width: ${(props) => props.size};
  }
`;

export const BigAvatar = (props: IProps) => {
  const { image, className, size } = props;

  return <Wrapper type={image} size={size} className={className} />;
};
