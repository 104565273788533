import { useRef } from 'react';
import { useReduxSelector } from 'services/hooks';

export const useMain = () => {
  const isShowBottomBar = useReduxSelector(
    (redux) => redux.main.isShowBottomBar
  );

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return {
    isShowBottomBar,
    wrapperRef,
  };
};
