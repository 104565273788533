import { IRewardsReduxState } from './rewards.typing';

export const REWARDS_ACTIONS = {
  SET_REWARDS_OPTIONS: '@REWARDS/SET_OPTIONS',
  SET_USER_REWARDS: '@REWARDS/SET_USER_REWARDS',
  SET_USER_PRESENTED_REWARDS: '@REWARDS/SET_USER_PRESENTED_REWARDS',
  PRESENT_REWARD: '@REWARDS/PRESENT_REWARD',
  CLEAR_USER_REWARDS: '@REWARDS/CLEAR_USER_REWARDS',
} as const;

export const INITIAL_REDUX_STATE: IRewardsReduxState = {
  userPresentedRewards: 0,
};

export const INDICATORS = {
  educationLevel: 'education-level',
  money: 'money',
  happiness: 'happiness',
  skills: 'skills',
  career: 'career',
  possession: 'possession',
  outfit: 'outfit',
  travels: 'travels',
} as const;

export const SPECIFIC_REWARD_TYPES = {
  career: { careerGold: 'career-gold' },
  outfit: { stylish: 'stylish' },
} as const;

export const BONUS_TYPES = {
  money: 'money',
  mood: 'mood',
  power: 'power',
} as const;
