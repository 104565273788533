import { TImageNames } from 'components/image';
import { COLORS } from 'themes';

export interface IPerformanceItemView {
  top: number;
  left: number;
  px: number;
}

export interface IPerformanceItem {
  image: TImageNames;
  title: string;
  color: string;
  description: string;
  bonuses: string[];
  view: IPerformanceItemView;
}

// Lots of non-breaking spaces (&nbsp;)

export const PERFORMANCE_ITEMS: IPerformanceItem[] = [
  {
    image: 'selfControl',
    title: 'Self-Control',
    color: COLORS.pinkRed,
    description: 'Being able to regulate behaviors.',
    bonuses: ['10% Bank Account per round'],
    view: { left: 22, top: 7, px: 134 },
  },
  {
    image: 'zest',
    title: 'Zest',
    color: COLORS.yellow,
    description: 'Being enthusiastic about life.',
    bonuses: ['2 Energy per round', '5% Salary'],
    view: { left: 166, top: 0, px: 139 },
  },
  {
    image: 'socialIntelligence',
    title: 'Social Intelligence',
    color: COLORS.orange2,
    description: 'Managing relationships and social situations.',
    bonuses: ['10% Salary'],
    view: { left: 5, top: 152, px: 129 },
  },
  {
    image: 'curiosity',
    title: 'Curiosity',
    color: COLORS.darkGreen,
    description: 'Being open to new things. ',
    bonuses: ['1 Life Card per round'],
    view: { left: 224, top: 144, px: 131 },
  },
  {
    image: 'grit',
    title: 'Grit',
    color: COLORS.grayBlue,
    description: 'Perseverance to achieve your goals.',
    bonuses: ['5 Energy per round'],
    view: { left: 112, top: 247, px: 130 },
  },
  {
    image: 'gratitude',
    title: 'Gratitude',
    color: COLORS.lightBlue,
    description: 'Being thankful and appreciative.',
    bonuses: ['10% Satisfaction per round'],
    view: { left: 212, top: 346, px: 125 },
  },
  {
    image: 'optimism',
    title: 'Optimism',
    color: COLORS.violet,
    description: 'Having hope for the future.',
    bonuses: ['5% Bank Account per round', '5% Satisfaction per round'],
    view: { left: -5, top: 336, px: 142 },
  },
];
