import { recordValue } from 'services/utils';
import { FONT_SIZES } from 'themes';
import { REWARDS_APPEARANCE } from 'constants/rewards-appearance';

import { CommonComponents, Styles } from './reward-constructor-styles';

interface IRewardConstructorProps {
  rewardName: string;
  size: string;
  withDropShadow?: boolean;
}

export const RewardConstructor = (props: IRewardConstructorProps) => {
  const { rewardName, size } = props;

  const rewardAppearance = recordValue(REWARDS_APPEARANCE, rewardName);

  if (!rewardAppearance) {
    return null;
  }

  const { title, badge_text_color, rewardImage } = rewardAppearance;
  const {
    background,
    mainImage,
    mainTopOffset,
    styleKit,
    ribbon,
    ribbonType,
    fontSize,
  } = rewardImage;

  const rewardStyle = `${size}${styleKit}${ribbonType}`;
  const RewardsConstructorStyles = Styles[rewardStyle];

  if (!RewardsConstructorStyles) {
    return null;
  }

  let titleFontSize: string;
  if (fontSize) {
    titleFontSize = fontSize + 'px';
  } else {
    titleFontSize = title.length >= 9 ? FONT_SIZES[11] : FONT_SIZES.px15;
  }

  return (
    <RewardsConstructorStyles.Container>
      <CommonComponents.MainBody type={background} />
      <CommonComponents.SectorWrapper $topOffset={mainTopOffset}>
        <CommonComponents.RewardsSector type={mainImage} />
        <CommonComponents.Title
          fontSize={titleFontSize}
          color={badge_text_color}
        >
          {title}
        </CommonComponents.Title>
      </CommonComponents.SectorWrapper>
      <RewardsConstructorStyles.Ribbon type={ribbon} />
    </RewardsConstructorStyles.Container>
  );
};
