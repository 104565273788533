import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { callFunctionAction } from 'services/api';
import { usePushLog } from 'services/hooks';
import { PlayerActions } from 'services/redux';
import { scrollToMainTop } from 'services/utils';

export interface IOtherCompanyModalProps {
  job: string;
  energy: number;
  onChangeActiveCompanyIndex: () => void;
  onClose: () => void;
  money?: number;
}

export const useOtherCompanyModal = (props: IOtherCompanyModalProps) => {
  const { job, energy, money, onChangeActiveCompanyIndex, onClose } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const pushLog = usePushLog();

  const onConfirm = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await callFunctionAction('@WORK/TRY_OTHER', undefined);

      dispatch(PlayerActions.merge(result.mergePlayer));

      onChangeActiveCompanyIndex();
      onClose();

      scrollToMainTop('smooth');

      pushLog({
        type: 'work',
        action: 'tryOther',
        params: {
          position: job,
          energy: -energy,
          money: money && -money,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, onConfirm };
};
