import styled from 'styled-components';

import { VALUES_TOTAL } from 'constants/create-character';
import {
  CreateCharacterLayout,
  CreateCharacterToolWrapper,
} from 'components/create-character';
import { getVersionString } from 'services/utils/versions';
import { useReduxSelector } from 'services/hooks';

import { CreateCharacterTrack } from '../create-character-track';

import { IValuesStepProps, useValuesStep } from './values-step.state';
import { ValuesPicker } from '../values-picker';

const ToolWrapper = styled(CreateCharacterToolWrapper)`
  padding-top: 16px;
`;

export const ValuesStep = (props: IValuesStepProps) => {
  const { values, isNextAvailable, handleNext, onSelect } = useValuesStep(
    props
  );

  const version = useReduxSelector((redux) => redux.app.version);

  const image = version === 'ntu' ? 'progress3' : 'progress4';

  return (
    <CreateCharacterLayout
      image={image}
      title="Values"
      description={getVersionString(version, 'VALUES_DESCRIPTION')}
      subdescription="Select those you Always Value"
      isNextAvailable={isNextAvailable}
      onNext={handleNext}
    >
      <CreateCharacterTrack
        usedPoints={values.length}
        maxPoints={VALUES_TOTAL}
        labelEnd="selected"
      />

      <ToolWrapper>
        <ValuesPicker selectedValues={values} onSelectValue={onSelect} />
      </ToolWrapper>
    </CreateCharacterLayout>
  );
};
