import { ICharacterSmart } from '@avid/common';

import { SMART_DESCRIPTIONS } from 'constants/create-character';
import { capitalize } from 'services/utils';

import { SmartPopup } from '../smart-popup';

interface IPopupProps {
  selectedSmart: keyof ICharacterSmart;
  characterSmart: ICharacterSmart;
  onClose: () => void;
  onIncrease: () => void;
  onDecrease: () => void;
}

export const SmartsEditorPopup = (props: IPopupProps) => {
  const {
    selectedSmart,
    characterSmart,
    onClose,
    onDecrease,
    onIncrease,
  } = props;

  return (
    <SmartPopup
      title={`${capitalize(selectedSmart)} Smart`}
      description={SMART_DESCRIPTIONS[selectedSmart]}
      value={characterSmart[selectedSmart]}
      onClose={onClose}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
    />
  );
};
