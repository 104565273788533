import styled from 'styled-components';

import { ButtonOld } from 'components/button';
import { initialBoxShadowValue } from 'components/ui';
import { COLORS, FONT_FAMILIES, FONT_SIZES } from 'themes';

export const RewardsCollectionStyles = {
  Wrapper: styled.div`
    background-color: ${COLORS.white};
    padding-top: 10px;
    padding-bottom: 32px;
    border-radius: 10px;
    box-shadow: ${initialBoxShadowValue};
  `,
  Title: styled.p`
    font-family: ${FONT_FAMILIES.regular};
    font-size: ${FONT_SIZES.small};
    text-align: center;
  `,
  Info: styled.p`
    margin-top: 50px;
    padding: 0 40px;
    font-family: ${FONT_FAMILIES.medium};
    font-size: ${FONT_SIZES.regular};
    text-align: center;
  `,
  GoToBadgesButton: styled(ButtonOld)`
    margin-top: 25px;
    width: 230px;
    height: 34px;
    min-height: 34px;
    padding: 5px;
  `,
};
