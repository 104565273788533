import { memo } from 'react';

import { TImageNames } from 'components/image';
import { SmallAvatar } from 'components/small-avatar';

interface IProps {
  icon: TImageNames;
  isSelect: boolean;
  selectIcon: (name: TImageNames) => void;
}

export const AvatarIcon = memo((props: IProps) => {
  const { icon, isSelect, selectIcon } = props;

  const onClick = () => selectIcon(icon);

  return <SmallAvatar icon={icon} isSelect={isSelect} onSelect={onClick} />;
});
