import styled from 'styled-components';

import { MEDIA } from 'themes';

export const SelectEducationLevelStyles = {
  Root: styled.div`
    ${MEDIA.LAPTOP} {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `,

  ResponsiveContainer: styled.div`
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
    gap: 24px;

    ${MEDIA.LAPTOP} {
      margin: 0 auto;
      grid-template-columns: auto auto auto;
      gap: 32px;
    }
  `,
};
