import { IWorkCollection, TGameVersion } from '@avid/common';

import { connectWork } from '../../versions.connect';

import { JobAPI } from './job.api';

export interface IParams {
  version: TGameVersion;
  sector: string;
}

export class WorkAPI {
  public static job = JobAPI;

  private static async fetchWorkData<TDocument extends keyof IWorkCollection>(
    workParams: IParams,
    workDocument: TDocument
  ) {
    const { version, sector } = workParams;
    const documentData = (
      await connectWork(version, sector).doc(workDocument).get()
    ).data() as
      | {
          [TKey in TDocument]: IWorkCollection[TKey];
        }
      | undefined;

    if (documentData) {
      return documentData[workDocument];
    }
  }

  public static fetchCareerTree(params: IParams) {
    return WorkAPI.fetchWorkData(params, 'careerTree');
  }

  public static fetchHighestPositions(params: IParams) {
    return WorkAPI.fetchWorkData(params, 'highestPositions');
  }

  public static fetchCompanies(params: IParams) {
    return WorkAPI.fetchWorkData(params, 'companies');
  }
}
