import styled from 'styled-components';

import { MEDIA, spacing, Theme } from 'themes';
import { FIND_JOB_STRINGS } from 'screens/find-job/find-job.strings';

import { CompanyAnchor } from './components';

const { COMPANY_VALUES } = FIND_JOB_STRINGS.JOB_OFFER;

export interface ICompanyParametersProps {
  positiveParameters: ICompanyParameter[];
  negativeParameters: ICompanyParameter[];
}

const ListContainer = styled(Theme.Flexbox)`
  margin: 0 auto;
  display: flex;

  ${MEDIA.LAPTOP} {
    width: 625px;
  }
`;

export const CompanyParameters = (props: ICompanyParametersProps) => {
  const { positiveParameters, negativeParameters } = props;

  return (
    <Theme.Wrapper $itemGap={{ default: spacing(2.5) }}>
      <Theme.Paragraph $fontFamily="bold" $textAlign="center">
        {COMPANY_VALUES}
      </Theme.Paragraph>

      <ListContainer $isColumn $itemGap={spacing(1.5)}>
        {positiveParameters.map((parameter) => (
          <CompanyAnchor
            key={parameter.title}
            anchor={parameter.title}
            anchorInformation={parameter.info}
            isPositive
            isEnable={parameter.isEnable}
          />
        ))}
        {negativeParameters.map((parameter) => (
          <CompanyAnchor
            key={parameter.title}
            anchor={parameter.title}
            anchorInformation={parameter.info}
            isEnable={parameter.isEnable}
          />
        ))}
      </ListContainer>
    </Theme.Wrapper>
  );
};
