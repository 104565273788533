import { INIT_REDUX_MORE, IMoreRedux, MORE_ACTION } from './more.constants';

import { TMoreActions } from './more.actions';

const { CLEAR, UPDATE_FIELDS, SET_PAGE } = MORE_ACTION;

export const reducerMore = (
  state = INIT_REDUX_MORE,
  action: TMoreActions
): IMoreRedux => {
  switch (action.type) {
    case CLEAR: {
      return INIT_REDUX_MORE;
    }

    case UPDATE_FIELDS: {
      return { ...state, ...action.payload };
    }

    case SET_PAGE: {
      return { ...state, page: action.payload };
    }

    default:
      return state ?? INIT_REDUX_MORE;
  }
};
