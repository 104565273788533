import styled from 'styled-components';
import { COLORS, Theme } from 'themes';

import { Checkbox } from 'components/checkbox';
import { Image } from 'components/image';
import { Loader } from 'components/loader';
import { WORK_STRINGS } from 'screens/work/work.strings';

import {
  IJobPromotionItemProps,
  useJobPromotionItem,
} from './job-promotion-item.state';

const Item = styled(Checkbox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Icon = styled(Image)`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 10px;
  border: 1px solid ${COLORS.darkYellow};
`;

const { SALARY } = WORK_STRINGS.JOB_PROMOTION;

export const JobPromotionItem = (props: IJobPromotionItemProps) => {
  const { job, selectJob, onSelectWork } = props;

  const { totalSalary, isLoading, onClickIcon } = useJobPromotionItem(props);

  if (!totalSalary || isLoading) {
    return <Loader />;
  }

  const isChecked = selectJob === job;

  return (
    <Item isChecked={isChecked} setValue={onSelectWork} item={{ value: job }}>
      <Theme.Paragraph $fontFamily="bold">{job}</Theme.Paragraph>
      <Icon type="informationCareer" size="14px" onClick={onClickIcon} />
      <Theme.Paragraph $fontFamily="regular">
        {SALARY(totalSalary)}
      </Theme.Paragraph>
    </Item>
  );
};
