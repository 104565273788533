import { action } from 'services/utils';

import { MAIN_ACTION } from './main.constants';

const { CLEAR, SET_SHOW_BOTTOM_BAR, SET_IS_TESTED, OPEN_TEST, CLOSE_TEST } =
  MAIN_ACTION;

export const MainActions = {
  clear: () => action(CLEAR),

  openTest: () => action(OPEN_TEST),

  closeTest: () => action(CLOSE_TEST),

  setShowBottomBar: (isShowBottomBar: boolean) =>
    action(SET_SHOW_BOTTOM_BAR, isShowBottomBar),

  setIsTested: (status: boolean) => action(SET_IS_TESTED, status),
};

export type TMainActions = ReturnType<TActionCombiner<typeof MainActions>>;
