import { TPlayerLog } from '@avid/common';

import { pushLogAsync } from '../../logs';
import { ThunkAction } from 'typings';

export const pushLogThunk =
  (playerLog: TPlayerLog): ThunkAction =>
  (_dispatch, getState) => {
    const {
      player: {
        authInfo,
        createCharacter: { name },
        main: { round, money },
        skills,
        confirmedReferrals,
      },
      game: { round: gameRound },
    } = getState();

    const { email, code } = authInfo;

    // Rewrite "mood" to "satisfaction"
    if ((playerLog.params as any).mood !== undefined) {
      if (!playerLog.params.satisfaction) {
        playerLog.params.satisfaction = (playerLog.params as any).mood;
      }

      delete (playerLog.params as any).mood;
    }

    return pushLogAsync({
      auth: { code, email },
      playerName: authInfo.name,
      characterName: name,
      playerMoney: money,
      playerSkills: skills?.length || 0,
      playerReferrals: confirmedReferrals,
      round,
      log: playerLog,
      roundStart: gameRound?.startUnix,
    });
  };
