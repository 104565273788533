import { Middleware } from 'redux';

import { PLAYER_ACTIONS } from 'services/redux/reducers';
import { INDICATORS, RewardsService } from 'services/rewards';

import { IReduxStore, TActions } from '../../root-reducer';

export const rewardMiddleware: Middleware<{}, IReduxStore> = (storeAPI) => (
  next
) => (action: TActions) => {
  const result = next(action);

  const isCompletedTutorial = storeAPI.getState().player.tutorials?.main;

  if (isCompletedTutorial) {
    RewardsService.initializeStoreAPI(storeAPI);

    switch (action.type) {
      case PLAYER_ACTIONS.MERGE: {
        RewardsService.handleAllRewards();
        break;
      }

      case PLAYER_ACTIONS.UPDATE:
        RewardsService.handleRewards([INDICATORS.educationLevel]);
        break;

      case PLAYER_ACTIONS.APPLY_BONUS: {
        RewardsService.handleRewards([
          INDICATORS.money,
          INDICATORS.possession,
          INDICATORS.skills,
        ]);
        break;
      }

      case PLAYER_ACTIONS.UPDATE_MAIN: {
        RewardsService.handleRewards([
          INDICATORS.money,
          INDICATORS.happiness,
          INDICATORS.career,
        ]);
        break;
      }

      case PLAYER_ACTIONS.GET_GAUGE: {
        RewardsService.handleRewards([INDICATORS.happiness]);
        break;
      }

      case PLAYER_ACTIONS.UPDATE_WORK:
        RewardsService.handleRewards([INDICATORS.career]);
        RewardsService.handleCareerGoldReward();
        break;

      case PLAYER_ACTIONS.UPDATE_POSSESSION:
        RewardsService.handleRewards([
          INDICATORS.possession,
          INDICATORS.travels,
        ]);
        break;

      case PLAYER_ACTIONS.UPDATE_ABILITIES:
        RewardsService.handleRewards([INDICATORS.skills]);
        break;

      case PLAYER_ACTIONS.BUY_ICON:
        RewardsService.handleRewards([INDICATORS.outfit]);
        break;

      default:
        break;
    }
  }
  return result;
};
