import { TImageNames } from 'components/image';
import { UNIVERSITY } from 'constants/education';
import { entriesObject, valuesOfObject } from 'services/utils';

import { IEducationLevelBase } from '../education-level';

const imageMap: Record<number, TImageNames> = {
  1: 'primarySchool',
  2: 'secondarySchool',
  3: 'juniorCollage',
  4: 'ite',
  5: 'university',
};

const getEducationLevelProps = (
  level: string,
  levelParams: IStudy
): IEducationLevelBase | undefined => {
  const { preRequisites, achievement, image } = levelParams;

  if (preRequisites && achievement) {
    return {
      prerequisites: preRequisites,
      image: image ? imageMap[image] : 'primarySchool',
      level,
    };
  }
};

export const getUniversityProps = (educationLevels: TEducationLevels) => {
  const universityFirstParams = valuesOfObject(educationLevels)
    .filter((levelParams) => levelParams.isUniversity)
    .sort((first, second) => first.order - second.order)[0] as
    | IStudy
    | undefined;

  const universityProps:
    | IEducationLevelBase
    | undefined = universityFirstParams && {
    level: UNIVERSITY,
    image: 'university',
    prerequisites: universityFirstParams.preRequisites,
  };

  return universityProps;
};

export const getRenderedLevelsProps = (educationLevels: TEducationLevels) => {
  const renderedLevelProps = entriesObject(educationLevels)
    .filter(([, levelParams]) => !levelParams.isUniversity)
    .sort((first, second) => first[1].order - second[1].order)
    .map(([level, levelParams]) => getEducationLevelProps(level, levelParams));

  const universityProps = getUniversityProps(educationLevels);
  renderedLevelProps.push(universityProps);

  return renderedLevelProps;
};
