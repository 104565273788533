import { memo } from 'react';

import { TImageNames } from 'components/image';
import { COLORS, Theme } from 'themes';

import { IconsListStyles as Styles } from './icons-list.styles';

interface IProps {
  title: string;
  selected: TImageNames;
  icons: TImageNames[];
  onSelectIcon: (icon: TImageNames) => void;
  characterIcon?: string;
  iconBorderColor?: string;
}

export const IconsList = memo((props: IProps) => {
  const { title, icons, selected, characterIcon, onSelectIcon } = props;

  const RenderIcons = icons.map((icon) => {
    const isCharacterIcon = characterIcon === icon;

    return (
      <Styles.Avatar
        key={icon}
        icon={icon}
        borderColor={isCharacterIcon ? COLORS.darkYellow : COLORS.darkPurple}
        isSelect={selected === icon || isCharacterIcon}
        onSelect={onSelectIcon}
      />
    );
  });

  return (
    <Styles.Wrapper>
      <Theme.Text>{title}</Theme.Text>
      <Styles.IconsContainer>{RenderIcons}</Styles.IconsContainer>
    </Styles.Wrapper>
  );
});
