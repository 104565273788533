import { REWARDS_APPEARANCE } from 'constants/rewards-appearance';
import {
  IRewardOption,
  IRewardRequirements,
  TRewardsMap,
} from 'services/rewards';
import { entriesObject, recordValue } from 'services/utils';

const displayOrder = [
  'Education',
  'Skills',
  'Career',
  'Happiness',
  'Wealth',
  'Bonus Challenge',
];

export const categorizeRewards = (rewards: TRewardsMap) => {
  const categorizedRewards: Record<string, [string, IRewardOption][]> = {
    'Bonus Challenge': [],
  };

  entriesObject(rewards).forEach((reward) => {
    const [rewardName, rewardOptions] = reward;

    const rewardTitle = recordValue(REWARDS_APPEARANCE, rewardName)?.title;
    if (!rewardTitle) {
      return;
    }

    if (rewardOptions.badge) {
      if (!categorizedRewards[rewardTitle]) {
        categorizedRewards[rewardTitle] = [];
      }
      categorizedRewards[rewardTitle].push(reward);
      return;
    }
    categorizedRewards['Bonus Challenge'].push(reward);
  });

  return categorizedRewards;
};

export const splitRewards = (
  rewards: [string, IRewardOption][],
  rewardTypes: string[]
) => {
  const includedRewards = rewards.filter(([rewardName]) =>
    rewardTypes.includes(rewardName)
  );

  const notIncludedRewards = rewards.filter(
    ([rewardName]) => !rewardTypes.includes(rewardName)
  );

  return { includedRewards, notIncludedRewards };
};

export const compareFn = (first: string, second: string) =>
  displayOrder.indexOf(first) - displayOrder.indexOf(second);

export const generateDescription = (
  requirements: IRewardRequirements<unknown>
) =>
  requirements.strictRequirements?.title ||
  requirements.notStrictRequirements?.title ||
  'Coming soon...';

export const getSplitRewards = (
  rewardsOptions: TRewardsMap,
  userRewardsTypes: string[]
) => {
  const categorizedRewards = categorizeRewards(rewardsOptions);
  const sorted = Object.keys(categorizedRewards).sort(compareFn);
  const rewardsSplit = sorted.map((category) => ({
    title: category,
    rewards: splitRewards(categorizedRewards[category], userRewardsTypes),
  }));

  return rewardsSplit;
};
