import { IGameRedux } from 'typings/game.typing';
import { action } from '../../../utils';

export const GAME_ACTIONS = {
  MERGE: '@GAME/MERGE',
} as const;

export const GameActions = {
  merge: (payload: TDeepPartial<IGameRedux>) =>
    action(GAME_ACTIONS.MERGE, payload),
};

export type TGameActions = ReturnType<TCombiner<typeof GameActions>>;
