import styled from 'styled-components';

import { COLORS, Z_INDEXES, MEDIA, Theme } from 'themes';

export const TopBarStyles = {
  Root: styled.div`
    position: fixed;
    width: 100%;
    padding: 8px;
    top: 0;
    right: 0;
    z-index: ${Z_INDEXES.TOOLTIP};
    background-color: ${COLORS.white};

    ${MEDIA.LAPTOP} {
      padding-top: 24px;
      padding-bottom: 9px;
      border-bottom: 2px solid ${COLORS.darkPurple};
    }
  `,
  ResponsiveContainer: styled(Theme.Flexbox)`
    ${MEDIA.LAPTOP} {
      margin: 0 auto;
      width: 40%;
    }
  `,
};
