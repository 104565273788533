import styled from 'styled-components';
import { COLORS, Theme } from 'themes';

import { ICharacteristicListProps, TMatch } from './characteristic-list.typing';

const getBackgroundColor = (match: TMatch) => {
  switch (match) {
    case 'positive':
      return COLORS.backgroundGreen;
    case 'negative':
      return COLORS.backgroundRed;
    default:
      return;
  }
};

const ColorParagraph = styled(Theme.Paragraph)<{ match: TMatch }>`
  padding: 0 8px;
  background-color: ${({ match }) => getBackgroundColor(match)};
`;

export const CharacteristicList = (props: ICharacteristicListProps) => {
  const { characteristics } = props;

  return (
    <Theme.Wrapper>
      {characteristics.map((characteristic) => (
        <ColorParagraph key={characteristic.label} match={characteristic.match}>
          {characteristic.label}
        </ColorParagraph>
      ))}
    </Theme.Wrapper>
  );
};
