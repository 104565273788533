import { useReduxSelector } from 'services/hooks';
import { recordValue } from 'services/utils';

import { EDUCATION_STRINGS } from '../../education.strings';

import { DefaultEducationModal } from './default-modal';

interface IProps {
  level: string;
  onCloseModal: () => void;
}

const { WRONG_DEGREE } = EDUCATION_STRINGS;
const { BUTTON } = WRONG_DEGREE;

export const WrongDegree = (props: IProps) => {
  const { level, onCloseModal } = props;

  const educationLevels = useReduxSelector(
    (redux) => redux.app.educationLevels
  );

  const levelParams = recordValue(educationLevels, level);
  const preRequisite = levelParams?.preRequisites[0];
  const requiredLevel = recordValue(educationLevels, preRequisite?.level || '');
  const rating = preRequisite?.rating;

  const text = `To qualify for 
  ${level}, you will need to have scored at least 
  ${rating}% in your 
  ${requiredLevel?.achievement}. Please attempt 
  ${requiredLevel?.achievement} again to improve your score.`;

  return (
    <DefaultEducationModal
      buttonText={BUTTON}
      title={text}
      image="notEnjoyDegree"
      onCloseModal={onCloseModal}
    />
  );
};
