import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Image } from 'components/image';
import { useWrappedAction } from 'services/hooks';
import { Theme } from 'themes';

import { MoreActions } from '../more.actions';
import { MorePage } from '../more.constants';

export interface IMoreWrapperProps {
  className?: string;
  children?: ReactNode;
}

const Wrapper = styled(Theme.Wrapper)`
  position: relative;
`;

const Icon = styled(Image)`
  position: absolute;
  top: 3px;
  left: 15px;
  cursor: pointer;
`;

export const MoreWrapper: FC<IMoreWrapperProps> = (props) => {
  const { className, children } = props;

  const setPage = useWrappedAction(MoreActions.setPage);

  return (
    <Wrapper className={className}>
      <Icon type="backPageArrow" onClick={setPage(MorePage.Start)} />
      {children}
    </Wrapper>
  );
};
