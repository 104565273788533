import styled, { keyframes } from 'styled-components';

import { Theme, COLORS } from 'themes';

interface IModalContainerStyled {
  $zIndex: number;
  $isOpen?: boolean;
}

const expand = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

export const ModalStyles = {
  Root: styled(Theme.CentralizeFlexbox)<IModalContainerStyled>`
    display: ${(props) => (props.$isOpen ? 'flex' : 'none')};

    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index: ${(props) => props.$zIndex};
    background: ${COLORS.modalBackground};
    margin: 0 !important;
  `,

  ContentContainer: styled.div`
    max-height: 97%;
    overflow-y: auto;
    animation: ${expand} 0.2s;
    transform-style: preserve-3d;
  `,
};
