import styled from 'styled-components';
import { Tab, TabList } from 'react-tabs';

import { MEDIA, homeTabCss } from 'themes';

export const HomeTabsStyles = {
  Root: styled.div`
    margin-top: 24px;

    ${MEDIA.LAPTOP} {
      margin-top: 48px;
    }
  `,

  PanelsWrapper: styled.div`
    margin-top: 8px;
  `,
};

export const HomeTabList = styled(TabList)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${MEDIA.LAPTOP} {
    justify-content: space-between;
  }
`;

export const HomeTab = styled(Tab)`
  ${homeTabCss as any}
`;
