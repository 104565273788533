import styled from 'styled-components';

import { ButtonOld } from 'components/button';

export const FindJobButton = styled(ButtonOld)`
  margin: 0;
  border-radius: 10px;
  width: 280px;
  height: 56px;
`;
