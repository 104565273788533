import styled from 'styled-components';
import { IAbility } from '@avid/common';

import { SkillList } from 'components/skills';
import { Theme } from 'themes';

interface IProps {
  abilities?: IAbility[];
}

export const AbilitiesList = styled(SkillList)`
  overflow: auto;
  max-height: 180px;
  padding-right: '16px';
`;

export const EducationSkills = (props: IProps) => {
  const { abilities } = props;

  if (!abilities) {
    return null;
  }

  return (
    <>
      <Theme.Paragraph>Skills gained:</Theme.Paragraph>
      {abilities && <AbilitiesList abilities={abilities} />}
    </>
  );
};
