import styled from 'styled-components';

import { Image } from 'components/image';
import { ModalWrapper } from 'components/modal';
import { PriceInformation } from 'components/price-information';
import { ButtonBar, Grid, Text } from 'components/ui';
import { typographyCss } from 'themes';

import { useStartPhfHard } from './start-phd-hard.state';

interface IProps {
  energy: number;
  isLoading: boolean;
  onNext: () => void;
  onClose: () => void;
  money?: number;
}

const Styles = {
  Title: styled(Text)`
    padding: 0 12px;
  `,
};

export const StartPhdHard = (props: IProps) => {
  const { energy, money, isLoading, onNext, onClose } = props;

  const { isCanBuy } = useStartPhfHard({ energy, money });

  return (
    <ModalWrapper onCloseModal={onClose}>
      <Grid rowGap="32px" justifyItems="center" textAlign="center">
        <Grid padding="0 12px" rowGap="20px">
          <Grid rowGap="6px">
            <Image type="warn" />

            <Text>Are you sure you want to continue?</Text>
          </Grid>

          <Grid rowGap="16px">
            <Styles.Title typography={typographyCss.RalewayBold18}>
              You must score 100% to graduate with a PhD.
            </Styles.Title>

            <Text>
              Please be aware that it is hard to complete a PhD programme.
            </Text>

            <Text>All the best!</Text>

            <PriceInformation energy={energy} money={money} />
          </Grid>
        </Grid>

        <ButtonBar
          nextLabel="Start Test"
          backLabel="Go Back"
          isNextDisabled={isLoading || !isCanBuy}
          onNext={onNext}
          onBack={onClose}
        />
      </Grid>
    </ModalWrapper>
  );
};
