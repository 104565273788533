interface ICheckCanBuyParams {
  playerEnergy: number;
  playerMoney: number;
  maxLoan: number;
  price: IPrice;
}

export const checkCanBuy = (params: ICheckCanBuyParams) => {
  const { playerEnergy, playerMoney, maxLoan, price } = params;

  return (
    (price.energy ? (playerEnergy || 0) >= price.energy : true) &&
    (price.money ? (playerMoney || 0) - maxLoan >= price.money : true)
  );
};
