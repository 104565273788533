import { LoanModal } from '../loan-modal';

import { useNegativeMoneyTracker } from './negative-money-tracker.state';

export const NegativeMoneyTracker = () => {
  const { isOpen, onClose } = useNegativeMoneyTracker();

  if (!isOpen) {
    return null;
  }

  return <LoanModal onClose={onClose} />;
};
