import { Step } from 'react-joyride';

import { AppTutorialController } from 'components/tutorial';
import { useShowCourseTutorial } from 'services/hooks';

import { useStartCourseTutorial } from './start-scourse-tutorial.state';

const STEPS: Step[] = [
  {
    target: '#bot_bar-skills',
    content: 'Welcome to the Upskilling tab!',
    disableBeacon: true,
  },
  {
    target: '#course-button_up',
    content: 'Let’s try to upskill in 1 skill together.',
  },
  {
    target: '#course-button_up',
    content: 'Click on the “Level Up My Skills” button.',
  },
];

export const StartCourseTutorialComponent = () => {
  const { handleCallback } = useStartCourseTutorial();

  return (
    <AppTutorialController
      steps={STEPS}
      callback={handleCallback}
      labelClose="Got it!"
    />
  );
};

export const StartCourseTutorial = () => {
  const isShow = useShowCourseTutorial();

  if (!isShow) {
    return null;
  }

  return <StartCourseTutorialComponent />;
};
