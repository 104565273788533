import styled from 'styled-components';

import { ButtonOld } from 'components/button';
import { MEDIA, Theme } from 'themes';

export const CompanyCardStyles = {
  Root: styled(Theme.ShadowWrapper)`
    ${MEDIA.LAPTOP} {
      width: 60%;
      min-width: 1000px;
      max-width: 1600px;
      margin: 0 auto;
    }
  `,
  ButtonsContainer: styled(Theme.Flexbox)`
    margin-top: 24px !important;

    ${MEDIA.LAPTOP} {
      margin-top: 48px !important;
    }
  `,
  Button: styled(ButtonOld)`
    margin: 0;
  `,
};
