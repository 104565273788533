import { ISkillInfo } from '@avid/common';

import { ChooseSkill } from 'components/choose-skill';
import { useUtils } from 'services/hooks';

import { LIMIT_SKILL, SINGLE_COURSE_PRICE } from '../course.constants';
import { COURSE_STRINGS } from '../course.strings';
import { CourseStyles } from '../course.styles';

import { ChooseSkillsTutorial } from './choose-skills-tutorial';

import { CourseChooseSkillsStyles as Styles } from './choose-skills.styles';

interface IProps {
  skills: ISkillInfo[];
  skillInfos: ISkillInfo[];
  isSkillsChosen: boolean;
  setSkill: (key: string) => void;
  onContinue: () => void;
}

const getMaxSkillsString = (skillsCount: number, limit: number) =>
  skillsCount > limit ? `(max ${limit})` : '';

export const CourseChooseSkill = (props: IProps) => {
  const { skillInfos, skills, isSkillsChosen, setSkill, onContinue } = props;

  const { checkCannotBuy } = useUtils();

  const coursePrice: IPrice = {
    energy: SINGLE_COURSE_PRICE.energy * skillInfos.length,
    money: SINGLE_COURSE_PRICE.money * skillInfos.length,
  };

  return (
    <>
      <Styles.MarginContainer>
        <ChooseSkill
          id="course-choose_skills"
          skillInfos={skills}
          limit={LIMIT_SKILL}
          selectAbilities={skillInfos.map(({ name }) => name)}
          setAbilities={setSkill}
          subtitle={getMaxSkillsString(skills.length, LIMIT_SKILL)}
          title={COURSE_STRINGS.CHOOSE_SKILL.TITLE}
          isSelectMax={false}
        />
      </Styles.MarginContainer>

      <CourseStyles.Button
        title={COURSE_STRINGS.CHOOSE_SKILL.BUTTON}
        onClick={onContinue}
        isDisabled={checkCannotBuy(coursePrice)}
        isSkillsChosen={isSkillsChosen}
      />

      <ChooseSkillsTutorial />
    </>
  );
};
