import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { IGame } from '@avid/common';

import { ROUTES } from 'constants/routes';
import { callFunction } from 'services/api';
import { clientFirebase } from 'services/firebase';
import {
  usePushApplyRoundLogs,
  useReduxSelector,
  useThunkDispatch,
} from 'services/hooks';
import { getPassedRoundsDuration, moveToRoute } from 'services/utils';
import { GameActions, PlayerActions, loadGameLogsThunk } from 'services/redux';
import { CLIENT_ID } from 'constants/settings';

import { gameCoreUtils } from './game-core.utils';

export const useGameCoreLoader = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const gameCode = useReduxSelector((redux) => redux.player.authInfo.code);

  const dispatch = useThunkDispatch();

  const pushApplyRoundLogs = usePushApplyRoundLogs();

  // Fetch the actual game status from Firestore and update redux
  useEffect(() => {
    const off = clientFirebase.firestore.games.onGame(
      gameCode,
      async (snapshot) => {
        const gameData = snapshot.data() as IGame | undefined;

        if (!gameData) {
          return moveToRoute(ROUTES.joinGame);
        }

        try {
          const result = await callFunction('applyRound')({
            clientId: CLIENT_ID,
          });

          const gameStatus = gameCoreUtils.getGameStatus(gameData);

          const roundNumber = gameData.rounds?.length || 0;

          const roundStartedAt =
            gameData.rounds?.[(roundNumber || 1) - 1]?.startTime;

          const startUnix = roundStartedAt
            ? dayjs(roundStartedAt).utc(false).unix()
            : undefined;

          dispatch(
            GameActions.merge({
              status: gameStatus,
              passedSeconds: getPassedRoundsDuration(gameData.rounds),
              round: { number: roundNumber, startUnix },
            })
          );

          if (!result?.mergePlayer) {
            return;
          }

          dispatch(PlayerActions.merge(result.mergePlayer));

          dispatch(
            loadGameLogsThunk({
              roundNumber: roundNumber || 1,
              bonus: result.bonus,
            })
          );
          pushApplyRoundLogs();

          moveToRoute(ROUTES.lifeCard);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoaded(true);
        }
      }
    );

    return off;
  }, [gameCode, dispatch, pushApplyRoundLogs]);

  return { isLoaded };
};
