import { FC } from 'react';

import { Theme } from 'themes';
import { useReduxSelector } from 'services/hooks';
import { moveToRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';

import { HOME_STRINGS } from '../../home.strings';

import { TopBarStyles as Styles } from './top-bar.styles';
import { CharacterIcon } from 'components/character-icon';

interface ITopBarProps {
  isWorkDisabled?: boolean;
}

export const TopBar: FC<ITopBarProps> = (props) => {
  const { isWorkDisabled } = props;

  const name = useReduxSelector((redux) => redux.player.createCharacter.name);
  const work = useReduxSelector((redux) => redux.player.work?.work);

  const openContinueWorkingPage = () =>
    moveToRoute(work ? ROUTES.work : ROUTES.findJob);

  const ButtonElement = (
    <Styles.Button
      title={HOME_STRINGS.BUTTON_TOP_BAR}
      onClick={openContinueWorkingPage}
      isHidden={isWorkDisabled}
      isWork={!!work}
    />
  );

  return (
    <Styles.Root>
      <Styles.IconNameWrapper>
        <Styles.CharacterIconWrapper>
          <CharacterIcon />
        </Styles.CharacterIconWrapper>

        <Styles.InfoWrapper>
          <Styles.CharacterName name={name} />
          <Theme.Paragraph>{work}</Theme.Paragraph>
        </Styles.InfoWrapper>
      </Styles.IconNameWrapper>

      {ButtonElement}
    </Styles.Root>
  );
};
