import styled from 'styled-components';
import { IAbility } from '@avid/common';

import { MEDIA, SCALE, spacing, Theme } from 'themes';
import { SkillList } from 'components/skills';
import { COURSE_STRINGS } from 'screens/courses/course.strings';

import { TMouseEvent } from 'typings';

import { CourseStyles } from 'screens/courses/course.styles';
import { StartCourseTutorial } from '../start-course-tutorial';

interface ICourseStartPageProps {
  onClickButton: TMouseEvent;
  nowAbilities?: IAbility[];
}

const wide = spacing(SCALE.wide);

const { SKILL } = COURSE_STRINGS;

const Root = styled(Theme.Flexbox)`
  max-width: ${wide};
  margin: 0 auto;
  padding-bottom: 80px;

  ${MEDIA.LAPTOP} {
    min-width: ${wide};
    max-width: ${spacing(100)};
    width: 25%;
    margin: 0 auto;
  }
`;

export const CourseStartPage = (props: ICourseStartPageProps) => {
  const { nowAbilities = [], onClickButton } = props;

  return (
    <Root $isColumn $justifyContent="space-between">
      <Theme.Wrapper $textAlign="center" $itemGap={{ default: spacing(2) }}>
        <Theme.Title>{SKILL.TITLE}</Theme.Title>
        <SkillList abilities={nowAbilities} />
      </Theme.Wrapper>

      <CourseStyles.Button
        id="course-button_up"
        onClick={onClickButton}
        title={SKILL.BUTTON}
      />

      <StartCourseTutorial />
    </Root>
  );
};
