import { useEffect } from 'react';

import {
  useReduxDispatch,
  useReduxSelector,
  useScrollTopOnChangePage,
} from 'services/hooks';

import { SHOP_SECTOR_ICON, SHOP_SECTOR_TRAVEL } from '../../shop.constants';
import { ShopActions } from '../../shop.actions';
import { ShopPages } from '../../shop.typings';

const getPage = (sector: string): ShopPages => {
  if (sector === SHOP_SECTOR_TRAVEL.value) {
    return ShopPages.BuyTravel;
  }

  if (sector === SHOP_SECTOR_ICON.value) {
    return ShopPages.Icon;
  }

  return ShopPages.BuyItem;
};

export const useShopPage = () => {
  const page = useReduxSelector((redux) => redux.shop.page);
  const sector = useReduxSelector((redux) => redux.shop.sector);

  const { callAction } = useReduxDispatch();
  const openSectorPage = callAction(ShopActions.openSectorPage);
  const onOpenStartPage = callAction(ShopActions.onOpenStartPage);

  useEffect(onOpenStartPage, []);
  useScrollTopOnChangePage(page);

  const onSetSector = (newSector: string) => {
    const newPage = getPage(newSector);
    openSectorPage(newSector, newPage);
  };

  return { page, sector, setSector: onSetSector };
};
