import { Theme } from 'themes';

import { CostModal } from 'components/modal';

import { WORK_STRINGS } from 'screens/work/work.strings';

import { useWorkStartTest } from './work-start-test.state';

const { MODAL_WITH_PRICE, START_TEST_TITLE } = WORK_STRINGS;

const Title = () => (
  <Theme.Paragraph $fontFamily="bold">{START_TEST_TITLE}</Theme.Paragraph>
);

export const WorkStartTest = () => {
  const { energy = 0, money, onConfirm, onCancel } = useWorkStartTest();

  return (
    <CostModal
      title={<Title />}
      money={money}
      energy={energy}
      confirmButtonTitle={MODAL_WITH_PRICE.CONFIRM_BUTTON}
      cancelButtonTitle={MODAL_WITH_PRICE.CANCEL_BUTTON}
      onConfirm={onConfirm}
      onCloseModal={onCancel}
    />
  );
};
