import styled from 'styled-components';
import Slider from 'react-slick';

import { MEDIA, spacing } from 'themes';

export const CareerCentreSliderStyles = {
  WidthContainer: styled.div`
    width: 100%;

    ${MEDIA.LAPTOP} {
      width: 62.5%;
      min-width: 1000px;
      max-width: 1600px;
      margin: 0 auto;
      overflow-y: auto;
    }
  `,
  Slider: styled(Slider)`
    padding: 0 ${spacing(2)};
    text-align: center;

    .slick-track {
      height: 100%;
    }

    .slick-slide > div {
      height: 100%;
    }

    .slick-arrow {
      &.slick-next {
        right: 1px;
      }

      &.slick-prev {
        left: 1px;
      }

      &:before {
        color: black;
      }
    }
  `,
};
