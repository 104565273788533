import { TGameVersion } from '@avid/common';

import { VERSION_STRINGS } from 'constants/versions';

export const getVersionString = (
  version: TGameVersion = 'standard',
  key: keyof typeof VERSION_STRINGS['standard']
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
) => VERSION_STRINGS[version][key] || VERSION_STRINGS['standard'][key];
