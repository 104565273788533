import styled, { css } from 'styled-components';

import { Image } from 'components/image';

import { COLORS, FONT_FAMILIES, FONT_SIZES, Z_INDEXES } from 'themes';

export const RewardsStyleTypes = {
  defaultSizeCareerStraightRibbon: 'defaultSizeCareerStraightRibbon',
  defaultSizeCareerSmallStraightRibbon: 'defaultSizeCareerSmallStraightRibbon',
  defaultSizeCareerSlopingRibbon: 'defaultSizeCareerSlopingRibbon',
  defaultSizeCareerSmallSlopingRibbon: 'defaultSizeCareerSmallSlopingRibbon',
  smallSizeCareerStraightRibbon: 'smallSizeCareerStraightRibbon',
  smallSizeCareerSmallStraightRibbon: 'smallSizeCareerSmallStraightRibbon',
  smallSizeCareerSlopingRibbon: 'smallSizeCareerSlopingRibbon',
  smallSizeCareerSmallSlopingRibbon: 'smallSizeCareerSmallSlopingRibbon',

  defaultSizeEducationStraightRibbon: 'defaultSizeEducationStraightRibbon',
  defaultSizeEducationSmallStraightRibbon:
    'defaultSizeEducationSmallStraightRibbon',
  defaultSizeEducationSlopingRibbon: 'defaultSizeEducationSlopingRibbon',
  defaultSizeEducationSmallSlopingRibbon:
    'defaultSizeEducationSmallSlopingRibbon',
  smallSizeEducationStraightRibbon: 'smallSizeEducationStraightRibbon',
  smallSizeEducationSmallStraightRibbon:
    'smallSizeEducationSmallStraightRibbon',
  smallSizeEducationSlopingRibbon: 'smallSizeEducationSlopingRibbon',
  smallSizeEducationSmallSlopingRibbon: 'smallSizeEducationSmallSlopingRibbon',

  defaultSizeHappinessStraightRibbon: 'defaultSizeHappinessStraightRibbon',
  defaultSizeHappinessSmallStraightRibbon:
    'defaultSizeHappinessSmallStraightRibbon',
  defaultSizeHappinessSlopingRibbon: 'defaultSizeHappinessSlopingRibbon',
  defaultSizeHappinessSmallSlopingRibbon:
    'defaultSizeHappinessSmallSlopingRibbon',
  smallSizeHappinessStraightRibbon: 'smallSizeHappinessStraightRibbon',
  smallSizeHappinessSmallStraightRibbon:
    'smallSizeHappinessSmallStraightRibbon',
  smallSizeHappinessSlopingRibbon: 'smallSizeHappinessSlopingRibbon',
  smallSizeHappinessSmallSlopingRibbon: 'smallSizeHappinessSmallSlopingRibbon',

  defaultSizeRichStraightRibbon: 'defaultSizeRichStraightRibbon',
  defaultSizeRichSmallStraightRibbon: 'defaultSizeRichSmallStraightRibbon',
  defaultSizeRichSlopingRibbon: 'defaultSizeRichSlopingRibbon',
  defaultSizeRichSmallSlopingRibbon: 'defaultSizeRichSmallSlopingRibbon',
  smallSizeRichStraightRibbon: 'smallSizeRichStraightRibbon',
  smallSizeRichSmallStraightRibbon: 'smallSizeRichSmallStraightRibbon',
  smallSizeRichSlopingRibbon: 'smallSizeRichSlopingRibbon',
  smallSizeRichSmallSlopingRibbon: 'smallSizeRichSmallSlopingRibbon',

  defaultSizeSkillsStraightRibbon: 'defaultSizeSkillsStraightRibbon',
  defaultSizeSkillsSmallStraightRibbon: 'defaultSizeSkillsSmallStraightRibbon',
  defaultSizeSkillsSlopingRibbon: 'defaultSizeSkillsSlopingRibbon',
  defaultSizeSkillsSmallSlopingRibbon: 'defaultSizeSkillsSmallSlopingRibbon',
  smallSizeSkillsStraightRibbon: 'smallSizeSkillsStraightRibbon',
  smallSizeSkillsSmallStraightRibbon: 'smallSizeSkillsSmallStraightRibbon',
  smallSizeSkillsSlopingRibbon: 'smallSizeSkillsSlopingRibbon',
  smallSizeSkillsSmallSlopingRibbon: 'smallSizeSkillsSmallSlopingRibbon',
};

const commonCss = {
  container: css`
    display: block;
    position: relative;
    width: 180px;
  `,
  sectorWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 90px;
    left: 35px;
    z-index: ${Z_INDEXES.Z12};
  `,
  ribbon: css`
    position: absolute;
    z-index: ${Z_INDEXES.Z10};
  `,
};

const {
  container: containerCss,
  sectorWrapper: sectorWrapperCss,
  ribbon: ribbonCss,
} = commonCss;

export const CommonComponents = {
  MainBody: styled(Image)`
    position: absolute;
    z-index: ${Z_INDEXES.Z11};
  `,
  RewardsSector: styled(Image)`
    width: 100%;
  `,
  SectorWrapper: styled.div<{ $topOffset: number }>`
    ${sectorWrapperCss}
    top: ${({ $topOffset }) => $topOffset + 'px'};
  `,
  Title: styled.span<{ color: string; fontSize?: string }>`
    margin-top: 3px;
    text-transform: uppercase;
    font-family: ${FONT_FAMILIES.bold};
    font-weight: bold;
    text-align: center;
    color: ${({ color }) => color || COLORS.black};
    font-size: ${({ fontSize }) => fontSize ?? FONT_SIZES.px15};
  `,
};

const DefaultRewardComponents = {
  Container: styled.div`
    ${containerCss}
  `,
};

const SmallRewardComponents = {
  Container: styled.div`
    ${containerCss}
    transform: scale(0.4);
    margin: 0 -54px;
  `,
};

const { Container: DefaultContainer } = DefaultRewardComponents;
const { Container: SmallContainer } = SmallRewardComponents;

const HappinessTitle = styled(CommonComponents.Title)`
  font-size: ${FONT_SIZES[11]};
`;

export const Styles = {
  [RewardsStyleTypes.defaultSizeCareerStraightRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 75px;
      left: 5px;
    `,
  },
  [RewardsStyleTypes.defaultSizeCareerSmallStraightRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 100px;
      left: 20px;
    `,
  },
  [RewardsStyleTypes.defaultSizeCareerSlopingRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 85px;
      left: 12px;
    `,
  },
  [RewardsStyleTypes.defaultSizeCareerSmallSlopingRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 125px;
      left: 45px;
    `,
  },
  [RewardsStyleTypes.smallSizeCareerStraightRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 75px;
      left: 5px;
    `,
  },
  [RewardsStyleTypes.smallSizeCareerSmallStraightRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 100px;
      left: 20px;
    `,
  },
  [RewardsStyleTypes.smallSizeCareerSlopingRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 85px;
      left: 12px;
    `,
  },
  [RewardsStyleTypes.smallSizeCareerSmallSlopingRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 125px;
      left: 45px;
    `,
  },

  [RewardsStyleTypes.defaultSizeEducationStraightRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 60px;
    `,
  },
  [RewardsStyleTypes.defaultSizeEducationSmallStraightRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 100px;
      left: 20px;
    `,
  },
  [RewardsStyleTypes.defaultSizeEducationSlopingRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 80px;
      left: 10px;
    `,
  },
  [RewardsStyleTypes.defaultSizeEducationSmallSlopingRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 115px;
      left: 41px;
    `,
  },
  [RewardsStyleTypes.smallSizeEducationStraightRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 60px;
    `,
  },
  [RewardsStyleTypes.smallSizeEducationSmallStraightRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 100px;
      left: 20px;
    `,
  },
  [RewardsStyleTypes.smallSizeEducationSlopingRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 80px;
      left: 10px;
    `,
  },
  [RewardsStyleTypes.smallSizeEducationSmallSlopingRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 115px;
      left: 41px;
    `,
  },

  [RewardsStyleTypes.defaultSizeHappinessStraightRibbon]: {
    Container: DefaultContainer,
    Title: HappinessTitle,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 60px;
      left: 2px;
    `,
  },
  [RewardsStyleTypes.defaultSizeHappinessSmallStraightRibbon]: {
    Container: DefaultContainer,
    Title: HappinessTitle,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 93px;
      left: 21px;
    `,
  },
  [RewardsStyleTypes.defaultSizeHappinessSlopingRibbon]: {
    Container: DefaultContainer,
    Title: HappinessTitle,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 80px;
      left: 10px;
    `,
  },
  [RewardsStyleTypes.defaultSizeHappinessSmallSlopingRibbon]: {
    Container: DefaultContainer,
    Title: HappinessTitle,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 100px;
      left: 42px;
    `,
  },
  [RewardsStyleTypes.smallSizeHappinessStraightRibbon]: {
    Container: SmallContainer,
    Title: HappinessTitle,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 60px;
      left: 2px;
    `,
  },
  [RewardsStyleTypes.smallSizeHappinessSmallStraightRibbon]: {
    Container: SmallContainer,
    Title: HappinessTitle,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 93px;
      left: 21px;
    `,
  },
  [RewardsStyleTypes.smallSizeHappinessSlopingRibbon]: {
    Container: SmallContainer,
    Title: HappinessTitle,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 80px;
      left: 10px;
    `,
  },
  [RewardsStyleTypes.smallSizeHappinessSmallSlopingRibbon]: {
    Container: SmallContainer,
    Title: HappinessTitle,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 100px;
      left: 42px;
    `,
  },

  [RewardsStyleTypes.defaultSizeRichStraightRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 75px;
      left: 5px;
    `,
  },
  [RewardsStyleTypes.defaultSizeRichSmallStraightRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 100px;
      left: 20px;
    `,
  },
  [RewardsStyleTypes.defaultSizeRichSlopingRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 85px;
      left: 12px;
    `,
  },
  [RewardsStyleTypes.defaultSizeRichSmallSlopingRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 122px;
      left: 45px;
    `,
  },
  [RewardsStyleTypes.smallSizeRichStraightRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 75px;
      left: 5px;
    `,
  },
  [RewardsStyleTypes.smallSizeRichSmallStraightRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 100px;
      left: 20px;
    `,
  },
  [RewardsStyleTypes.smallSizeRichSlopingRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 85px;
      left: 12px;
    `,
  },
  [RewardsStyleTypes.smallSizeRichSmallSlopingRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 122px;
      left: 45px;
    `,
  },

  [RewardsStyleTypes.defaultSizeSkillsStraightRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 75px;
      left: 5px;
    `,
  },
  [RewardsStyleTypes.defaultSizeSkillsSmallStraightRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 100px;
      left: 20px;
    `,
  },
  [RewardsStyleTypes.defaultSizeSkillsSlopingRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 85px;
      left: 12px;
    `,
  },
  [RewardsStyleTypes.defaultSizeSkillsSmallSlopingRibbon]: {
    Container: DefaultContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 122px;
      left: 45px;
    `,
  },
  [RewardsStyleTypes.smallSizeSkillsStraightRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 75px;
      left: 5px;
    `,
  },
  [RewardsStyleTypes.smallSizeSkillsSmallStraightRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 100px;
      left: 20px;
    `,
  },
  [RewardsStyleTypes.smallSizeSkillsSlopingRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 85px;
      left: 12px;
    `,
  },
  [RewardsStyleTypes.smallSizeSkillsSmallSlopingRibbon]: {
    Container: SmallContainer,
    Ribbon: styled(Image)`
      ${ribbonCss}top: 122px;
      left: 45px;
    `,
  },
};
