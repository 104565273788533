import { Step } from 'react-joyride';
import { TGameVersion } from '@avid/common';

import { AppTutorialController } from 'components/tutorial';
import { useReduxSelector, useShowCareerCentreTutorial } from 'services/hooks';
import { getVersionString } from 'services/utils/versions';

const getSteps = (version: TGameVersion = 'standard') => {
  const careerCentreLabel = getVersionString(version, 'CAREER_CENTRE');

  const STEPS: Step[] = [
    {
      target: '#bot_bar-work',
      content: 'Welcome to the Work tab!',
      disableBeacon: true,
    },
    {
      target: '#search_work-root',
      content: `You can find a job here, or visit the ${careerCentreLabel} for more information.`,
    },
    {
      target: '#search_work-career_centre',
      content: `You can check your skills gap in the blue ${careerCentreLabel} button.`,
    },
  ];

  return STEPS;
};

export const SearchWorkTutorialComponent = () => {
  const version = useReduxSelector((redux) => redux.app.version);

  const steps = getSteps(version);

  return <AppTutorialController steps={steps} labelClose="Got it!" />;
};

export const SearchWorkTutorial = () => {
  const isShow = useShowCareerCentreTutorial();

  if (!isShow) {
    return null;
  }

  return <SearchWorkTutorialComponent />;
};
