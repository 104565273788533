import { FC } from 'react';
import { IAbility } from '@avid/common';

import { Theme } from 'themes';

export interface IBonusListProps {
  bonuses: string[];
  abilities: IAbility[];
}

export const BonusList: FC<IBonusListProps> = (props) => {
  const { bonuses, abilities } = props;

  return (
    <>
      {bonuses.map((item) => (
        <Theme.Paragraph $fontSize="header" key={item}>
          {item}
        </Theme.Paragraph>
      ))}
      <Theme.Flexbox $alignItem="center" $isColumn $itemGap="16px">
        {abilities.map(({ name }) => (
          <Theme.Paragraph key={name}>
            {`Increase skill: ${name}`}
          </Theme.Paragraph>
        ))}
      </Theme.Flexbox>
    </>
  );
};
