import { TLog, TLogWithPlayerInfo } from '@avid/common';
import dayjs from 'dayjs';

import { postLog } from 'services/api';
import { IPushLogParams } from './logs.typing';

export const getRoundSecond = (roundStart: number) => {
  const now = dayjs().utc(false).unix();
  return Math.round(now - roundStart);
};

export const mapEmailToUserLog = (log: TLogWithPlayerInfo): TLog =>
  ({
    type: log.type,
    action: log.action,
    params: log.params,
    round: log.round,
    roundSecond: log.roundSecond,
    playerMoney: log.playerMoney,
  }) as TLog;

export const pushLogAsync = (params: IPushLogParams) => {
  const {
    auth: { email: playerEmail, code },
    roundStart,
    log,
    playerMoney,
    playerSkills,
    characterName,
    playerName,
    round,
    playerReferrals,
  } = params;

  const roundSecond = getRoundSecond(roundStart ?? 0);

  const emailLog: TLogWithPlayerInfo = {
    ...log,
    playerEmail,
    playerName,
    characterName,
    round,
    roundSecond,
    playerMoney,
    playerSkills,
    playerReferrals,
  };

  return postLog({
    gameCode: code,
    log: emailLog,
  });
};
