import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
  0%   { transform: scale(0.8)  translateY(11px);  opacity: 0;  }
  10%  { transform: scale(1)    translateY(30px);  opacity: 1;  }
  80%  { transform: scale(1)    translateY(30px);  opacity: 1;  }
  100% { transform: scale(0.8)  translateY(0);     opacity: 0;  }
`;

export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  position: absolute;
  top: 0;
  animation-duration: 0.85s;
  animation-name: ${bounce};
  animation-timing-function: ease;
`;
