import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import {
  init as initSentry,
  browserTracingIntegration,
  ErrorBoundary,
} from '@sentry/react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { ScrollToTop } from 'components/HOC';
import { App } from 'screens/app';
import { APP_ENV } from 'services/firebase';
import { history } from 'services/utils';
import { ReduxProvider } from 'services/redux';
import { GlobalFonts, GlobalStyle } from 'themes';

initSentry({
  dsn: 'https://aefb3b1810c940bb8ed93777eb8bbce1@o542719.ingest.sentry.io/5668151',
  integrations: [browserTracingIntegration()],
  environment: APP_ENV,
  tracesSampleRate: 1.0,
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ErrorBoundary fallback={'An error has occurred' as any}>
    <GlobalFonts />
    <GlobalStyle />

    <ReduxProvider>
      <Router history={history}>
        <ScrollToTop />
        <App />
      </Router>
    </ReduxProvider>
  </ErrorBoundary>
);
