import { IModsRedux } from 'typings/mods';

import { MODS_ACTIONS, TModsActions } from './mods.actions';

const INIT_MODS_STATE: IModsRedux = {};

export const modsReducer: TReducer<IModsRedux, TModsActions> = (
  state = INIT_MODS_STATE,
  action
) => {
  switch (action.type) {
    case MODS_ACTIONS.CLEAR: {
      return INIT_MODS_STATE;
    }

    case MODS_ACTIONS.SHOW_REFERRAL_MODAL: {
      return {
        ...state,
        'bonus-codes': {
          ...state['bonus-codes'],
          isShowModal: true,
          playerNames: action.payload,
        },
      };
    }

    case MODS_ACTIONS.ACCEPT_REFERRAL_NOTIFICATION: {
      return {
        ...state,
        'bonus-codes': {
          ...state['bonus-codes'],
          isShowModal: undefined,
          playerNames: undefined,
        },
      };
    }

    case MODS_ACTIONS.DISPLAY_GAUGES_NOTIFICATION: {
      return {
        ...state,
        gauges: { ...state.gauges, displayLowGauges: action.payload },
      };
    }

    case MODS_ACTIONS.CLOSE_GAUGES_NOTIFICATION: {
      const gaugesType = action.payload;

      return {
        ...state,
        gauges: {
          ...state.gauges,
          displayLowGauges: {
            ...state.gauges?.displayLowGauges,
            [gaugesType]: undefined,
          },
        },
      };
    }

    case MODS_ACTIONS.RAISE_TOAST_MESSAGE: {
      return {
        ...state,
        popupTips: {
          ...state.popupTips,
          toastMessages: [
            action.payload,
            ...(state.popupTips?.toastMessages || []),
          ],
        },
      };
    }

    case MODS_ACTIONS.REMOVE_TOAST_MESSAGE: {
      return {
        ...state,
        popupTips: {
          ...state.popupTips,
          toastMessages: (state.popupTips?.toastMessages || []).filter(
            (message) => message.toastId !== action.payload
          ),
        },
      };
    }

    case MODS_ACTIONS.SET_POPUP_MESSAGE: {
      return {
        ...state,
        popupTips: { ...state.popupTips, popupMessage: action.payload },
      };
    }

    case MODS_ACTIONS.CLOSE_POPUP_MESSAGE: {
      return {
        ...state,
        popupTips: {
          ...state.popupTips,
          popupMessage: undefined,
          toastMessages: state.popupTips?.toastMessages?.filter(
            (toast) => toast.toastId !== action.payload
          ),
        },
      };
    }

    case MODS_ACTIONS.FINANCIAL_ADD_DEDUCTION: {
      return {
        ...state,
        financial: {
          ...state.financial,
          deductions: [action.payload, ...(state.financial?.deductions || [])],
        },
      };
    }

    case MODS_ACTIONS.FINANCIAL_POP_DEDUCTION: {
      return {
        ...state,
        financial: {
          ...state.financial,
          deductions: state.financial?.deductions?.slice(1),
        },
      };
    }

    default:
      return state;
  }
};
