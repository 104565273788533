import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchConstants } from '../app.api';
import { AppActions } from '../app.actions';
import { cacheImagesAsync, setVHInPx } from '../app.utils';

import { AuthLoader } from './auth-loader';

export const AppLoader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Load app images
    cacheImagesAsync();

    // Add viewport resizing
    setVHInPx();
    window.addEventListener('resize', setVHInPx);

    return () => {
      window.removeEventListener('resize', setVHInPx);
    };
  }, []);

  useEffect(() => {
    // Load app constants
    const loadConstants = async () => {
      const fetchedConstants = await fetchConstants();

      dispatch(AppActions.updateConstants(fetchedConstants));
    };

    loadConstants();
  }, [dispatch]);

  return <AuthLoader />;
};
