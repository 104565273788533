import styled from 'styled-components';

import { COLORS, Theme } from 'themes';

export type TWhiteWrapperShadowVariant = 'default' | 'initial';

export const initialBoxShadowValue = `0 6px 6px rgba(45, 45, 55, 0.1)`;

export const WhiteShadowWrapper = styled(Theme.Wrapper)<{
  $shadowVariant?: TWhiteWrapperShadowVariant;
  $isPreventMaxHeight?: boolean;
}>`
  padding: 32px;
  background-color: ${COLORS.white};

  box-shadow: ${({ $shadowVariant = 'default' }) =>
    $shadowVariant === 'default'
      ? '2px 6px 16px rgba(45, 45, 55, 0.1)'
      : initialBoxShadowValue};

  ${({ $isPreventMaxHeight }) => !$isPreventMaxHeight && 'max-height: 350px;'}
  overflow: auto;
  border-radius: 10px;
`;
