import React, { FC } from 'react';

import { Theme } from 'themes';

import { useEducationLevel } from './education-level.state';
import {
  EducationLevelStyles as Styles,
  ImageCss,
} from './education-level.styles';
import { IEducationLevelProps } from './education-level.typing';

export const EducationLevel: FC<IEducationLevelProps> = React.memo((props) => {
  const { image, level, prerequisites, id } = props;

  const { isComplete, isOpen, onClick } = useEducationLevel(props);

  const Title = <Theme.Text>{level}</Theme.Text>;

  return (
    <Styles.Wrapper
      $justifyContent="center"
      $isColumn
      $isComplete={isComplete}
      $isOpen={isOpen(prerequisites)}
      $color="white"
      $itemGap="0px"
      onClick={onClick(prerequisites)}
      $textAlign="center"
      id={id}
    >
      <Styles.ImageBox
        imageName={image}
        elementTitle={Title}
        itemGap="8px"
        imageCss={ImageCss}
      />
      {isComplete && (
        <Styles.Level>
          <Styles.CompleteItem type="completeEducation" size="11px" />
        </Styles.Level>
      )}
    </Styles.Wrapper>
  );
});
