import styled from 'styled-components';

import { Image } from 'components/image';
import { ButtonOld } from 'components/button';
import { COLORS, FONT_SIZES, MEDIA, spacing, Z_INDEXES } from 'themes';

export const RewardModalStyles = {
  Root: styled.div<{ $isMounted: boolean }>`
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    top: -200vh;
    background-color: ${COLORS.backgroundWhite};
    transition: transform 0.3s ease-out;
    transition-delay: 75ms;
    transform: translateZ(0)
      ${({ $isMounted }) => $isMounted && `translateY(200vh)`};
  `,
  ModalWrapper: styled.div`
    position: relative;
    margin-top: 60px;
    margin-bottom: 90px;
    width: 310px;
    left: 50%;
    transform: translateX(-50%);

    ${MEDIA.LAPTOP} {
      margin: ${spacing(10)} 0;
    }
  `,
  BackgroundImage: styled(Image)`
    max-width: 100%;
    height: auto;
    object-fit: cover;
  `,
  ModalContainer: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 24px;
    top: 0;
    left: 0;
  `,
  NextRewardContainer: styled.div`
    display: flex;
    width: 100%;
    margin-top: 20px;
  `,
  NextRewardTitle: styled.div`
    font-size: ${FONT_SIZES.header};
    line-height: 25px;
  `,
  NextReward: styled.div`
    margin-top: -12px;
    transform: translateX(8px);
  `,
  CheckText: styled.p`
    margin-top: 40px;
    text-align: center;
  `,
  FooterContainer: styled.div`
    position: absolute;
    bottom: 30px;
  `,
  CloseIcon: styled(Image)`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: ${Z_INDEXES.TOOLTIP};
  `,
  Button: styled(ButtonOld)<{ isWhiteButton?: boolean }>`
    border-radius: 10px;
    background: ${(props) => (props.isWhiteButton ? 'none' : 'unset')};
    background-color: ${(props) =>
      props.isWhiteButton ? 'unset' : COLORS.purple};
    border: ${(props) =>
      props.isWhiteButton ? `1px solid ${COLORS.purple}` : 'unset'};
    color: ${(props) => (props.isWhiteButton ? COLORS.black : COLORS.white)};
    margin-bottom: 17px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
};
