import {
  IJobCollection,
  replaceForwardSlashAll,
  TGameVersion,
} from '@avid/common';

import { connectWork } from '../../versions.connect';

interface IParams {
  version: TGameVersion;
  sector: string;
  job: string;
}

export class JobAPI {
  private static connectJob(params: IParams) {
    const { version, sector, job } = params;

    return connectWork(version, sector)
      .doc('jobs')
      .collection(replaceForwardSlashAll(job));
  }

  private static async fetchJobData<TDocument extends keyof IJobCollection>(
    jobParams: IParams,
    jobDocument: TDocument
  ) {
    const documentData = (
      await this.connectJob(jobParams).doc(jobDocument).get()
    ).data() as
      | {
          [TKey in TDocument]: IJobCollection[TKey];
        }
      | undefined;

    if (documentData) {
      return documentData[jobDocument];
    }
  }

  public static fetchSalary(params: IParams) {
    return this.fetchJobData(params, 'salary');
  }

  public static fetchDescription(params: IParams) {
    return this.fetchJobData(params, 'description');
  }

  public static fetchSkills(params: IParams) {
    return this.fetchJobData(params, 'skills');
  }

  public static fetchQuestions(params: IParams) {
    return this.fetchJobData(params, 'questions');
  }

  public static async fetchJobDescription(params: IParams) {
    const doc = await connectWork(params.version, params.sector)
      .doc('jobs')
      .collection(params.job)
      .doc('description')
      .get();

    return doc.data() as IJobDescriptionData | undefined;
  }
}
