import { TImageNames } from 'components/image';
import { useMod } from 'services/hooks';
import {
  TCreateCharacterProps,
  TCreateCharacterStep,
} from 'typings/create-character';

import { ReviewAnchors } from '../review-anchors';
import { ReviewNameAvatar } from '../review-name-avatar';
import { ReviewPerformance } from '../review-performance';
import { ReviewSmarts } from '../review-smarts';
import { ReviewValues } from '../review-values';

import { useReviewStepForm } from './review-step-form.state';

export interface IReviewStepFormProps {
  onSetStep: (step: TCreateCharacterStep) => void;
  createCharacter: TCreateCharacterProps;
  lifeGoals?: number[];
}

export const ReviewStepForm = (props: IReviewStepFormProps) => {
  const { createCharacter, lifeGoals = [], onSetStep } = props;

  const {
    name,
    icon,
    smart,
    performance,
    careerAnchors,
    values,
  } = createCharacter;

  const {
    version,
    onEditNameIcon,
    onEditSmarts,
    onEditPerformance,
    onEditAnchors,
    onEditValues,
    onEditGoals,
  } = useReviewStepForm(onSetStep);

  const ReviewGoals = useMod('lifeGoals')?.components.ReviewGoals;

  const RenderAnchors =
    version === 'ntu' ? null : (
      <ReviewAnchors anchors={careerAnchors} onEdit={onEditAnchors} />
    );

  const RenderGoals = ReviewGoals && (
    <ReviewGoals onEdit={onEditGoals} goalIndexes={lifeGoals} />
  );

  return (
    <>
      <ReviewNameAvatar
        name={name}
        icon={icon as TImageNames}
        onEdit={onEditNameIcon}
      />
      <ReviewSmarts smart={smart} onEdit={onEditSmarts} />
      <ReviewPerformance performance={performance} onEdit={onEditPerformance} />
      {RenderAnchors}
      <ReviewValues values={values} onEdit={onEditValues} />
      {RenderGoals}
    </>
  );
};
