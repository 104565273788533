import { useEffect } from 'react';

import { db } from 'services/firebase';
import { useReduxSelector, useUpdateState } from 'services/hooks';

import { IStoredRanking } from './ranking.typing';
import { transformStoredRanking } from './ranking.utils';

interface IRankingState {
  isLoading: boolean;
  earners: Record<number, number>;
  happiest: Record<number, number>;
}

const INIT_STATE: IRankingState = {
  isLoading: true,
  earners: {},
  happiest: {},
};

export const useRanking = () => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  const { gameCode, email } = useReduxSelector((redux) => ({
    gameCode: redux.player.authInfo.code,
    email: redux.player.authInfo.email,
  }));

  useEffect(() => {
    const ref = db.doc(`games/${gameCode}/ranking/${email}`);

    const off = ref.onSnapshot((snapshot) => {
      const rankingData = snapshot.data() as
        | Record<number, IStoredRanking>
        | undefined;

      if (!rankingData) {
        updateState({ isLoading: false });
        return;
      }

      const { earners, happiest } = transformStoredRanking(rankingData);
      updateState({ earners, happiest, isLoading: false });
    });

    return off;
  }, [gameCode, email, updateState]);

  return { ...state };
};
