import { Text } from 'components/ui';
import {
  SelectorDataList,
  SelectorDataListItem,
} from 'components/selector-list';

import { ReviewSection } from '../../../components/create-character/review-section';

interface IProps {
  values: string[];
  onEdit: () => void;
}

export const ReviewValues = (props: IProps) => {
  const { values, onEdit } = props;

  const RenderValues = values.map((value) => (
    <SelectorDataListItem key={value} value={value}>
      <Text>{value}</Text>
    </SelectorDataListItem>
  ));

  return (
    <ReviewSection title="Values" onEdit={onEdit}>
      <SelectorDataList selectedValues={values}>
        {RenderValues}
      </SelectorDataList>
    </ReviewSection>
  );
};
