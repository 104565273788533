import { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { REDUX_STORE } from './redux-middleware';

interface IProps {
  children?: ReactNode;
}

export const ReduxProvider = (props: IProps) => (
  <Provider store={REDUX_STORE}>{props.children}</Provider>
);
