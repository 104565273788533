import { FC, useEffect } from 'react';
import styled from 'styled-components';

import { MEDIA, Theme } from 'themes';
import { Image, IMAGES } from 'components/image';
import { moveToRouteCallback } from 'services/utils';
import { ROUTES } from 'constants/routes';

const Wrapper = styled(Theme.CentralizeFlexbox)`
  background-image: url(${IMAGES.backgroundLogo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;

  ${MEDIA.LAPTOP} {
    background-image: url(${IMAGES.backgroundLogoLaptop});
  }
`;

export const GreetingLogo = () => (
  <Wrapper>
    <Image type="logo" />
  </Wrapper>
);

export const Greeting: FC = () => {
  useEffect(() => {
    const timeout = setTimeout(moveToRouteCallback(ROUTES.login), 2000);

    return () => clearTimeout(timeout);
  }, []);

  return <GreetingLogo />;
};
