import { ReactNode } from 'react';

import { SelectorHeader, SelectorItem, SelectorMenu } from './components';
import {
  ISelectorContextParameters,
  SelectorProvider,
} from './selector.context';

export interface ISelectorProps extends ISelectorContextParameters {
  className?: string;
  children?: ReactNode;
}

export const Selector = (props: ISelectorProps) => {
  const { children, ...selectorProps } = props;
  return <SelectorProvider {...selectorProps}>{children}</SelectorProvider>;
};

Selector.Header = SelectorHeader;
Selector.Menu = SelectorMenu;
Selector.Item = SelectorItem;
