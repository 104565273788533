import { ReactNode } from 'react';
import styled from 'styled-components';

import { IMAGES } from 'components/image';
import { MEDIA } from 'themes';

interface IProps {
  children?: ReactNode;
}

const Root = styled.div`
  height: 100%;
  padding: 32px 16px;

  background-image: url(${IMAGES.backgroundLogo});
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;

  ${MEDIA.LAPTOP} {
    background-image: url(${IMAGES.backgroundLogoLaptop});
  }
`;

export const PurpleBackgroundLayout = (props: IProps) => (
  <Root>{props.children}</Root>
);
