import { FC } from 'react';
import { Theme } from 'themes';

import { InfoImageModal } from 'components/modal';
import { WORK_STRINGS } from 'screens/work/work.strings';

import { moveToRouteCallback } from 'services/utils';

import { ROUTES } from 'constants/routes';

const { BUTTON, SUBTITLE, TITLE } = WORK_STRINGS.FAIL_WORK;

export const FailedWork: FC = () => (
  <InfoImageModal
    buttonText={BUTTON}
    onCloseModal={moveToRouteCallback(ROUTES.findJob)}
    title={<Theme.Title>{TITLE}</Theme.Title>}
    image="notCompleteWork"
  >
    <Theme.Paragraph $fontFamily="bold" $color="darkRed">
      {SUBTITLE}
    </Theme.Paragraph>
  </InfoImageModal>
);
