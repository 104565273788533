import { IPlayer } from '@avid/common';

import { deepMerge } from 'services/utils';
import { ModsService } from 'services/mods';

import { PLAYER_ACTIONS, TPlayerActions } from './player.actions';
import { INIT_PLAYER_STATE } from './player.reducer.constants';

export const playerReducer: TReducer<IPlayer, TPlayerActions> = (
  state = INIT_PLAYER_STATE,
  action
) => {
  switch (action.type) {
    case PLAYER_ACTIONS.UPDATE: {
      return { ...state, ...action.payload };
    }

    case PLAYER_ACTIONS.CLEAR: {
      return INIT_PLAYER_STATE;
    }

    case PLAYER_ACTIONS.SET: {
      return action.payload;
    }

    case PLAYER_ACTIONS.MERGE: {
      return deepMerge(state, action.payload);
    }

    case PLAYER_ACTIONS.UPDATE_ABILITIES: {
      const { skills, lastLearnAbilities } = action.payload;

      return {
        ...state,
        skills,
        character: { ...state.character, lastLearnAbilities },
      };
    }

    case PLAYER_ACTIONS.APPLY_BONUS: {
      const { main, possession, skills, gauges, character } = action.payload;

      return {
        ...state,
        main: { ...state.main, ...main },
        character: { ...state.character, ...character },
        possession,
        skills,
        gauges,
      };
    }

    // Auth
    case PLAYER_ACTIONS.SET_AUTH_INFO: {
      return { ...state, authInfo: action.payload };
    }

    case PLAYER_ACTIONS.UPDATE_AUTH: {
      return { ...state, authInfo: { ...state.authInfo, ...action.payload } };
    }

    // Character

    case PLAYER_ACTIONS.UPDATE_CHARACTER: {
      return { ...state, character: { ...state.character, ...action.payload } };
    }

    // Main

    case PLAYER_ACTIONS.UPDATE_MAIN: {
      return { ...state, main: { ...state.main, ...action.payload } };
    }

    // Possession

    case PLAYER_ACTIONS.UPDATE_POSSESSION: {
      return {
        ...state,
        possession: { ...state.possession, ...action.payload },
      };
    }

    // Work

    case PLAYER_ACTIONS.SET_WORK: {
      return { ...state, work: { ...state.work, ...action.payload } };
    }

    case PLAYER_ACTIONS.UPDATE_WORK: {
      if (!state.work) {
        return state;
      }

      return { ...state, work: { ...state.work, ...action.payload } };
    }

    // Icons

    case PLAYER_ACTIONS.UPDATE_ICON: {
      const { icon, icons } = action.payload;

      return {
        ...state,
        icons,
        createCharacter: { ...state.createCharacter, icon },
      };
    }

    case PLAYER_ACTIONS.BUY_ICON: {
      const { icon, icons, main } = action.payload;
      const { energy, money } = main;

      return {
        ...state,
        icons,
        createCharacter: { ...state.createCharacter, icon },
        main: { ...state.main, energy, money },
      };
    }

    // Rewards
    case PLAYER_ACTIONS.SET_REWARDS: {
      return { ...state, rewards: action.payload };
    }

    //
    case PLAYER_ACTIONS.ACCEPT_REFERRALS: {
      const { newConfirmedReferrals, newMoney } = action.payload;

      return {
        ...state,
        main: { ...state.main, money: newMoney },
        confirmedReferrals: newConfirmedReferrals,
      };
    }

    // Mods - Gauges
    case PLAYER_ACTIONS.APPLY_GAUGES: {
      const { gauges, main, gaugeApplies } = action.payload;

      return {
        ...state,
        gauges,
        gaugeApplies,
        main: { ...state.main, ...main },
      };
    }

    case PLAYER_ACTIONS.GET_GAUGE: {
      const { mood, gaugePart } = action.payload;
      const [gaugeName, gaugeValue] = gaugePart;

      const INIT_GAUGES = ModsService.getOne('gauges')?.constants.INIT_GAUGES;

      if (!INIT_GAUGES) {
        return state;
      }

      return {
        ...state,
        main: { ...state.main, mood },
        gauges: {
          ...(state.gauges || INIT_GAUGES),
          [gaugeName]: gaugeValue,
        },
      };
    }

    default:
      return state;
  }
};
