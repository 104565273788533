import styled from 'styled-components';

export const RewardBonusStyles = {
  Wrapper: styled.div`
    display: flex;
  `,
  BonusWrapper: styled.div`
    margin-left: 4px;
  `,
};
