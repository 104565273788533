import { FC } from 'react';

import { moveToRoute } from 'services/utils';
import { PAGE_ROUTES } from 'constants/routes';
import { useReduxSelector } from 'services/hooks';
import { getVersionString } from 'services/utils/versions';

import { CareerCenterButtonStyles as Styles } from './career-center-button.styles';
interface ICareerCenterButtonProps {
  id?: string;
  className?: string;
}

export const CareerCenterButton: FC<ICareerCenterButtonProps> = (props) => {
  const { id, className } = props;

  const version = useReduxSelector((redux) => redux.app.version);

  const onOpenPage = () => moveToRoute(PAGE_ROUTES.careerCenter);

  return (
    <Styles.Root>
      <Styles.Button
        id={id}
        onClick={onOpenPage}
        className={className}
        title=""
      >
        <Styles.Icon type="house" />
        <span>{getVersionString(version, 'CAREER_CENTRE')}</span>
      </Styles.Button>
    </Styles.Root>
  );
};
