import {
  usePushLog,
  useRealtimeJobLimits,
  useReduxSelector,
  useTotalSalary,
} from 'services/hooks';

import { CompanyModal } from '../../company.typing';

import { toDisplayCompany } from './company-card.utils';
import { IUseCompanyCardProps } from './company-card.typing';
import { useMemo, useState } from 'react';
import { callFunctionAction } from 'services/api';
import { useDispatch } from 'react-redux';
import { PlayerActions } from 'services/redux';

export const useCompanyCard = (props: IUseCompanyCardProps) => {
  const {
    valuesInfo,
    sector,
    job,
    companyName,
    onSetModal,
    companyBonus,
  } = props;

  const gameCode = useReduxSelector((redux) => redux.player.authInfo.code);
  const email = useReduxSelector((redux) => redux.player.authInfo.email);
  const constValues = useReduxSelector((redux) => redux.app.values);
  const chValues = useReduxSelector(
    (redux) => redux.player.createCharacter.values
  );
  const constAnchors = useReduxSelector((redux) => redux.app.anchors);
  const chAnchors = useReduxSelector(
    (redux) => redux.player.createCharacter.careerAnchors
  );

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const pushLog = usePushLog();
  const isLimited = useRealtimeJobLimits(sector);

  const { totalSalary } = useTotalSalary({ job, sector, companyBonus });

  const displayCompany = useMemo(
    () =>
      toDisplayCompany(
        {
          negative: valuesInfo.negative,
          positive: valuesInfo.positive,
        },
        { constAnchors, chAnchors, constValues, chValues }
      ),
    [
      chAnchors,
      chValues,
      constAnchors,
      constValues,
      valuesInfo.negative,
      valuesInfo.positive,
    ]
  );

  const onAccept = async () => {
    if (!gameCode || !email || isLoading) {
      throw new Error('Unauthorized');
    }

    setIsLoading(true);

    try {
      const result = await callFunctionAction('@WORK/GET_JOB', {
        sector,
        job,
        companyName,
      });

      dispatch(PlayerActions.merge(result.mergePlayer));

      pushLog({
        type: 'work',
        action: 'newjob',
        params: {
          position: job,
        },
      });

      onSetModal(CompanyModal.Congrats);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { displayCompany, isLimited, totalSalary, onAccept };
};
