import styled from 'styled-components';

import { ModalWrapper } from 'components/modal';
import { BigAvatar } from 'components/big-avatar';
import { PriceInformation } from 'components/price-information';
import { TImageNames } from 'components/image';
import { Grid, Button } from 'components/ui';
import { ICON_PRICE } from 'constants/prices';
import { Theme } from 'themes';

interface IProps {
  icon: TImageNames;
  onClose: () => void;
}

const { energy, money } = ICON_PRICE;

const EnjoyText = styled(Theme.Text)`
  display: block;
  margin-top: 10px;
`;

export const IconModal = (props: IProps) => {
  const { icon, onClose } = props;

  return (
    <ModalWrapper onCloseModal={onClose}>
      <Grid rowGap="16px">
        <div>
          <Theme.Title>Congratulations</Theme.Title>
          <EnjoyText>Enjoy your new avatar!</EnjoyText>
        </div>

        <BigAvatar image={icon} size="150px" />

        <PriceInformation energy={energy} money={money} />

        <Button onClick={onClose}>Continue</Button>
      </Grid>
    </ModalWrapper>
  );
};
