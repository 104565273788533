import { useEffect, useState } from 'react';

import { ManyTabs } from 'screens/many-tabs';
import { history } from 'services/utils';
import { ROUTES } from 'constants/routes';

import { AppLoader } from './containers';

import { LOCAL_STORAGE_EVENT } from './app.constants';

const checkListened = (pathName: string) =>
  !pathName.startsWith(ROUTES.gameReport) &&
  !pathName.startsWith(ROUTES.gameReportToken);

const triggerStorageEvent = () => {
  localStorage[LOCAL_STORAGE_EVENT] = Date.now();
};

export const App = () => {
  const [isManyTabs, setIsManyTabs] = useState(false);

  useEffect(() => {
    if (checkListened(history.location.pathname)) {
      triggerStorageEvent();
    }

    const unlisten = history.listen((location) => {
      if (checkListened(location.pathname)) {
        triggerStorageEvent();
      }
    });

    const storageEventHandler = (event: StorageEvent) => {
      const isListened = checkListened(history.location.pathname);

      if (isListened && event.key === LOCAL_STORAGE_EVENT) {
        setIsManyTabs(true);
      }
    };

    window.addEventListener('storage', storageEventHandler, false);

    return () => {
      unlisten();
      window.removeEventListener('storage', storageEventHandler);
    };
  }, []);

  return isManyTabs ? <ManyTabs /> : <AppLoader />;
};
