import { InfoImageModal } from 'components/modal';
import { Theme } from 'themes';

import { WORK_STRINGS } from 'screens/work/work.strings';

import { useCongrats } from './congrats.state';

const { BUTTON, TITLE, SUBTITLE } = WORK_STRINGS.JOB_CONGRATULATION;

export const Congrats = () => {
  const { newWork, onUp } = useCongrats();

  const Title = <Theme.Paragraph $fontFamily="bold">{TITLE}</Theme.Paragraph>;

  const Subtitle = (
    <>
      <Theme.Paragraph>{SUBTITLE}</Theme.Paragraph>
      <Theme.Paragraph>{newWork}</Theme.Paragraph>
    </>
  );

  return (
    <InfoImageModal
      title={Title}
      subtitle={Subtitle}
      image="getNewWork"
      buttonText={BUTTON}
      onCloseModal={onUp}
    />
  );
};
