import styled, { css } from 'styled-components';

import { ImageBox } from 'components/image-box';
import { Image } from 'components/image';

import { Theme, COLORS, FONT_SIZES, MEDIA } from 'themes';

interface IEducationLevelStyled {
  $isOpen: boolean;
  $isComplete: boolean;
}

export const EducationLevelStyles = {
  Wrapper: styled(Theme.Flexbox)<IEducationLevelStyled>`
    position: relative;
    ${(p) =>
      p.$isOpen &&
      css`
        background-color: ${COLORS[p.$isComplete ? 'lightGreen' : 'blue']};
        cursor: pointer;
      `}

    ${(p) =>
      !p.$isOpen &&
      css`
        background-color: white;
        cursor: not-allowed;

        &,
        & > * {
          filter: brightness(0.9);
        }
      `}

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    width: 158px;
    height: 102px;
    margin: 0 auto;
    border-radius: 10px;

    ${MEDIA.LAPTOP} {
      width: 220px;
      height: 160px;
      margin: 0;
    }
  `,
  Level: styled(Theme.Flexbox)`
    position: absolute;
    top: 10px;
    right: 10px;
  `,
  CompleteItem: styled(Image)`
    font-size: ${FONT_SIZES.little};
  `,
  ImageBox: styled(ImageBox)`
    ${MEDIA.LAPTOP} {
      flex: 1;
    }
  `,
};

export const ImageCss = css`
  ${MEDIA.LAPTOP} {
    flex-basis: 45%;
  }
`;
