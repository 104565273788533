import { createBrowserHistory } from 'history';

import { CSS_CLASSES } from 'constants/css';

export const history = createBrowserHistory();

export const moveToRouteCallback = (path: string) => () => history.push(path);

export const moveToRoute = (path: string) => history.push(path);

export const replaceRoute = (path: string) => history.replace(path);

export const scrollToTop = () =>
  document.querySelector(`.${CSS_CLASSES.MAIN_WRAPPER}`)?.scrollTo(0, 0);

export const scrollToMainTop = (behavior?: ScrollBehavior) =>
  document
    .querySelector(`.${CSS_CLASSES.MAIN_WRAPPER}`)
    ?.scrollTo({ top: 0, behavior });
