import { memo } from 'react';
import { IQuestion } from '@avid/common';

import { Test } from 'components/test';

interface IProps {
  questions: IQuestion[];
  onAnswer: (percent: number) => void;
  onEndTest: () => void;
  isDisabled?: boolean;
}

export const EducationTest = memo((props: IProps) => {
  const { questions, isDisabled, onAnswer, onEndTest } = props;

  return (
    <Test
      title="Test"
      questions={questions}
      isDisabled={isDisabled}
      increaseRating={onAnswer}
      onEndTest={onEndTest}
    />
  );
});
