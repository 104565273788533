import styled from 'styled-components';

import { ButtonOld } from 'components/button';
import { Image } from 'components/image';
import { COLORS, MEDIA, SCALE, spacing } from 'themes';

import { EducationModal } from '../../education.typing';

interface IChooseUncialsProps {
  title: string;
  isComplete: boolean;
  isOpen: boolean;
  setModal: (modal?: EducationModal) => void;
  setDegree(): void;
  isExceed?: boolean;
}

const Styles = {
  Root: styled(ButtonOld)<{
    $isComplete: boolean;
    $isOpen: boolean;
  }>`
    width: 100%;
    position: relative;
    ${(p) => !p.$isOpen && `color: ${COLORS.ashes};`}
    border-color: ${(p) =>
      COLORS[p.$isOpen ? (p.$isComplete ? 'green' : 'darkYellow') : 'ashes']};

    ${MEDIA.LAPTOP} {
      width: ${spacing(SCALE.wide)};
      border-radius: 10px;
    }
  `,

  AccessDegree: styled(Image)`
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate(-50%, -50%);
  `,

  ExceededImage: styled(Image)`
    position: absolute;
    top: 9px;
    left: calc(50% + 20px);
  `,
};

export const Choose = (props: IChooseUncialsProps) => {
  const { title, isComplete, isOpen, isExceed, setDegree, setModal } = props;

  const handleClick = () => {
    setDegree();

    setModal(
      isExceed
        ? EducationModal.ExceededDegree
        : isOpen
          ? EducationModal.StartTest
          : EducationModal.WrongDegree
    );
  };

  return (
    <Styles.Root
      styleTheme="white"
      onClick={handleClick}
      title={title}
      $isOpen={isOpen && !isExceed}
      $isComplete={isComplete}
    >
      {isComplete && <Styles.AccessDegree type="degreeAccess" />}
      {isExceed && <Styles.ExceededImage type="informationCareer" />}
    </Styles.Root>
  );
};
