import { ReactNode } from 'react';
import styled from 'styled-components';

import { Image } from 'components/image';
import { ScreenModal } from 'components/modal';
import { COLORS, MEDIA, spacing, Theme } from 'themes';

const Wrapper = styled(Theme.Wrapper)`
  width: 330px;
  border-radius: 24px;
  position: relative;
  padding: ${spacing(4)};
  background-color: ${COLORS.white};

  ${MEDIA.LAPTOP} {
    width: 520px;
  }
`;

const CloseTest = styled(Image)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

interface IEducationModalWrapper {
  onCloseModal: () => void;
  children?: ReactNode;
}

export const EducationModalWrapper = (props: IEducationModalWrapper) => {
  const { children, onCloseModal } = props;

  return (
    <ScreenModal isOpen={true}>
      <Wrapper
        $itemGap={{ default: spacing(2) }}
        $fontFamily="bold"
        $textAlign="center"
      >
        <CloseTest type="closeModal" onClick={onCloseModal} />
        {children}
      </Wrapper>
    </ScreenModal>
  );
};
