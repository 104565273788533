import { Settings } from 'react-slick';
import { LAPTOP } from 'themes';

import { CareerTree } from '../career-tree';

import { CareerCentreSliderStyles as Styles } from './slider.styles';
import { SliderTutorial } from './slider-tutorial';

interface ICareerCentreSliderProps {
  careerTree: string[][];
  isSectorEducational: boolean;
  onOpenDescriptionModal: (job: string) => void;
}

const SETTINGS: Settings = {
  slidesToShow: 2,
  slidesToScroll: 2,
  infinite: false,

  responsive: [
    {
      breakpoint: LAPTOP.width - 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
};

export const CareerCentreSlider = (props: ICareerCentreSliderProps) => {
  const { careerTree, isSectorEducational, onOpenDescriptionModal } = props;

  return (
    <>
      <Styles.WidthContainer id="career_centre-slider">
        <Styles.Slider arrows {...SETTINGS}>
          {careerTree.map((tree, index) => (
            <CareerTree
              key={index}
              careerBranch={[...tree].reverse()}
              isWithArrows={isSectorEducational}
              onOpenDescriptionModal={onOpenDescriptionModal}
            />
          ))}
        </Styles.Slider>
      </Styles.WidthContainer>

      <SliderTutorial />
    </>
  );
};
