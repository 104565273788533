import { useCallback } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IReduxStore, TActions } from 'services/redux';

import { AsyncThunkAction } from 'typings';

type TFunction = (...args: any[]) => any;

/**
 *! mapStateToProps hook analog
 */
export const useReduxSelector: TypedUseSelectorHook<IReduxStore> = useSelector;

/**
 * Dispatch one action
 * @param action Any action creator
 */
export const useAction = <T extends TFunction>(action: T) => {
  const dispatch = useDispatch();
  return (...args: Parameters<T>) => {
    dispatch(action(...args));
  };
};

export const useWrappedAction = <T extends TFunction>(action: T) => {
  const dispatch = useDispatch();
  return (...args: Parameters<T>) =>
    () => {
      dispatch(action(...args));
    };
};

export const useReduxDispatch = () => {
  const dispatch = useDispatch();

  const callAction = useCallback(
    <T extends TFunction>(action: T) =>
      (...args: Parameters<T>) => {
        dispatch(action(...args));
      },
    [dispatch]
  );

  const callActionCallback =
    <T extends TFunction>(action: T) =>
    (...args: Parameters<T>) =>
    () => {
      dispatch(action(...args));
    };

  return {
    callAction,
    callActionCallback,
  };
};

export const useThunkDispatch = () => {
  const dispatch = useDispatch();

  return dispatch as ThunkDispatch<IReduxStore, {}, TActions>;
};

export const useThunkAction = () => {
  const dispatch = useDispatch() as ThunkDispatch<IReduxStore, {}, TActions>;

  const thunkActionCallback = <T>(reduxAction: AsyncThunkAction<T>) => {
    return () => dispatch(reduxAction);
  };

  const thunkAction = <T>(reduxAction: AsyncThunkAction<T>) => {
    return dispatch(reduxAction);
  };

  return { thunkAction, thunkActionCallback };
};
