import styled from 'styled-components';

import { COLORS, MEDIA } from 'themes';

export const RankingStyles = {
  Root: styled.div`
    padding: 30px 24px;
    background-color: ${COLORS.white};

    ${MEDIA.LAPTOP} {
      padding: 24px 16px;
    }

    ${MEDIA.CHROMEBOOK} {
      padding: 40px 24px;
    }
  `,
  List: styled.ul`
    max-width: 400px;
  `,
};
