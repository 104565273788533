import styled from 'styled-components';

import { ButtonOld, buttonDisabledStyles } from 'components/button';

export const CourseStyles = {
  Button: styled(ButtonOld)<{ isSkillsChosen?: boolean }>`
    position: fixed;
    bottom: 75px;
    left: 50%;
    transform: translateX(-50%);
    ${({ isSkillsChosen = true }) =>
      !isSkillsChosen && buttonDisabledStyles.default}
  `,
};
