import { ClickSkillModal } from 'components/modal';
import { ReactElement, useContext } from 'react';
import { WorkContext } from 'screens/work/work.context';

import { WorkModal } from 'screens/work/work.typings';
import {
  BudgetingPropose,
  Congrats,
  FailedWork,
  Promotion,
  QuitWork,
  StartRelevant,
  WorkDescription,
  WorkStartTest,
} from '../modals';
import { EndWork } from '../modals/end-work';
import { CyclesExceed } from '../modals/cycles-exceed';

export const WorkModals = () => {
  const { modal, rating, onCloseModal } = useContext(WorkContext);

  if (!modal) {
    return null;
  }

  const Modal: Record<WorkModal, ReactElement> = {
    [WorkModal.StartTest]: <WorkStartTest />,
    [WorkModal.StartTestRelevant]: <StartRelevant />,
    [WorkModal.ClickSkill]: <ClickSkillModal onCloseModal={onCloseModal} />,

    [WorkModal.CompleteWork]: <EndWork rating={rating} />,

    [WorkModal.CareerUp]: <Promotion />,
    [WorkModal.JobCongratulation]: <Congrats />,
    [WorkModal.JobDescription]: <WorkDescription />,

    [WorkModal.QuiteWork]: <QuitWork />,
    [WorkModal.FailedWork]: <FailedWork />,
    [WorkModal.CyclesExceed]: <CyclesExceed />,

    [WorkModal.BudgetingPropose]: <BudgetingPropose />,
  };

  return Modal[modal];
};
