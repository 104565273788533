import { FC } from 'react';
import styled from 'styled-components';
import { COLORS, MEDIA, SCALE, spacing } from 'themes';

import { ButtonOld } from 'components/button';
import { Image } from 'components/image';

import { QUESTION_MODE } from './test.state';

interface ITestAnswerProps {
  text: string;
  mode: QUESTION_MODE;

  onAnswer(): void;
}

const getAnswerColor = (isCorrect: boolean) =>
  isCorrect ? COLORS.green : COLORS.red;

const Answer = styled(ButtonOld)<{ $mode: QUESTION_MODE }>`
  width: 100%;
  position: relative;
  padding: 16px 50px 16px 32px;
  word-break: break-word;
  hyphens: auto;

  ${(p) =>
    p.$mode === QUESTION_MODE.CORRECT || p.$mode === QUESTION_MODE.WRONG
      ? `
      background-color: ${getAnswerColor(p.$mode === QUESTION_MODE.CORRECT)};
      color: ${COLORS.white};
    `
      : `display: block;`}

  ${MEDIA.LAPTOP} {
    width: ${spacing(SCALE.wide)};
    border-width: 1.5px;
    border-radius: 10px;
  }
`;

const Icon = styled(Image)`
  height: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  width: 30px;
  transform: translate(-50%, -50%);
`;

export const TestAnswer: FC<ITestAnswerProps> = (props) => {
  const { text, mode, onAnswer } = props;
  const icon = mode === QUESTION_MODE.CORRECT ? 'rightAnswer' : 'wrongAnswer';

  return (
    <Answer
      styleTheme="white"
      isDisabled={mode !== QUESTION_MODE.NOT_ANSWERED}
      onClick={onAnswer}
      $mode={mode}
      title={text}
    >
      {(mode === QUESTION_MODE.CORRECT || mode === QUESTION_MODE.WRONG) && (
        <Icon height="100%" type={icon} />
      )}
    </Answer>
  );
};
