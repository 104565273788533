import styled from 'styled-components';

import { ButtonOld } from 'components/button';
import { Css, Theme } from 'themes';

export const BadgesListStyles = {
  Root: styled(Theme.PageContainer)`
    ${Css.responsiveCenterWide};
  `,
  List: styled.ul`
    margin-bottom: 80px;
  `,
  ButtonBack: styled(ButtonOld)<{ isLower?: boolean }>`
    position: fixed;
    bottom: ${({ isLower }) => (isLower ? '10px' : '60px')};
    transform: translateX(-50%);
    left: 50%;
  `,
  Title: styled.span`
    width: 100%;
  `,
};
