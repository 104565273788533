import styled from 'styled-components';

import { InfoModal } from './info-modal';

interface IClickSkillModalProps {
  onCloseModal: () => void;
}

const Paragraph = styled.p`
  margin-bottom: 16px;
`;

export const ClickSkillModal = (props: IClickSkillModalProps) => (
  <InfoModal buttonText="Continue" onCloseModal={props.onCloseModal}>
    <Paragraph>Please click on a skill</Paragraph>
  </InfoModal>
);
