import {
  INITIAL_REDUX_STATE,
  REWARDS_ACTIONS as Actions,
} from './rewards.constants';

import { IRewardsReduxState, TRewardActions } from './rewards.typing';

export const reducerRewards: TReducer<IRewardsReduxState, TRewardActions> = (
  state = INITIAL_REDUX_STATE,
  action
) => {
  switch (action.type) {
    case Actions.SET_REWARDS_OPTIONS: {
      return { ...state, options: action.payload };
    }

    case Actions.SET_USER_PRESENTED_REWARDS: {
      return { ...state, userPresentedRewards: action.payload };
    }

    case Actions.PRESENT_REWARD: {
      return {
        ...state,
        userPresentedRewards: state.userPresentedRewards + 1,
      };
    }

    case Actions.CLEAR_USER_REWARDS: {
      const { userPresentedRewards } = INITIAL_REDUX_STATE;
      return { ...state, userPresentedRewards };
    }

    default:
      return state;
  }
};
