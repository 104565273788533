import { useCallback, useContext } from 'react';
import { shallowEqual } from 'react-redux';

import { useReduxSelector } from 'services/hooks';
import { arrayEqual, moveToRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';

import { WorkModal, WorkPage } from 'screens/work/work.typings';
import { WorkContext } from 'screens/work/work.context';
import { CAREER_CONST } from './career.const';

export const useCareer = () => {
  const { values, onOpenModal, onOpenPage } = useContext(WorkContext);

  const work = useReduxSelector((redux) => redux.player.work?.work);
  const sector = useReduxSelector((redux) => redux.player.work?.sector);
  const isFreelance = useReduxSelector(
    (redux) => redux.player.work?.isFreelance
  );
  const isIntern = useReduxSelector((redux) => redux.player.work?.isIntern);
  const career = useReduxSelector(
    (redux) => redux.player.work?.career,
    arrayEqual
  );
  const workExperience = useReduxSelector(
    (redux) => redux.player.work?.experience,
    shallowEqual
  );
  const company = useReduxSelector(
    (redux) => redux.player.work?.company.company_name
  );

  const lastLearnAbilities = useReduxSelector(
    (redux) => redux.player.character?.lastLearnAbilities,
    arrayEqual
  );

  const currentCareerExp = career?.[0].experience || 0;

  const isCycleUnavailable =
    (isFreelance && currentCareerExp >= CAREER_CONST.FREELANCE_CYCLES) ||
    (isIntern && currentCareerExp >= CAREER_CONST.INTERN_CYCLES);

  const onChangeJob = useCallback(() => moveToRoute(ROUTES.findJob), []);

  const onContinueWork = useCallback(
    () => onOpenPage(WorkPage.ChooseSkill),
    [onOpenPage]
  );

  const onQuitJob = useCallback(
    () => onOpenModal(WorkModal.QuiteWork),
    [onOpenModal]
  );

  return {
    work,
    values,
    workExperience,
    sector,
    career,
    company,
    lastLearnAbilities,
    isCycleUnavailable,

    onChangeJob,
    onQuitJob,
    onContinueWork,
  };
};
