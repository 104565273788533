import { FC } from 'react';
import styled, { css } from 'styled-components';
import { IPossessionProperties, PossessionLevel } from '@avid/common';

import { COLORS, Theme } from 'themes';
import { Image } from 'components/image';
import { getPossessionIcon } from 'services/character';
import { useReduxSelector } from 'services/hooks';

import { CostImage } from '../cost-image';
import { COLOR_PALETTE, LEVEL_ICON } from '../../shop.constants';
import { IColorPalette } from '../../shop.typings';

export interface IPossessionItemProps {
  level: PossessionLevel;
  price: IPrice;
  isEnable: boolean;
  isSelect: boolean;
  label: string;

  onSelectItem(): void;
}

interface IWrapperParameters {
  $colors: IColorPalette;
  $isEnable: boolean;
  $isSelect: boolean;
}

const Wrapper = styled(Theme.Flexbox)<IWrapperParameters>`
  background: ${(props) =>
    COLORS[
      props.$isSelect
        ? props.$colors.selectBackground
        : props.$colors.background
    ]};
  border: 1px solid ${(props) => COLORS[props.$colors.border]};
  padding: 16px;
  border-radius: 10px;
  cursor: pointer;

  ${(props) =>
    !props.$isEnable &&
    css`
      filter: contrast(110%) brightness(110%) sepia(30%) grayscale(100%);
      cursor: not-allowed;
    `}
`;

export const PossessionItem: FC<IPossessionItemProps> = (props) => {
  const { level, label, price, isEnable, isSelect, onSelectItem } = props;
  const { energy, money } = price;

  const currentSector = useReduxSelector((redux) => redux.shop.sector);
  const version = useReduxSelector((redux) => redux.app.version);
  const sector = currentSector as keyof IPossessionProperties;

  const handleClick = () => {
    if (isEnable) {
      onSelectItem();
    }
  };

  return (
    <Wrapper
      $colors={COLOR_PALETTE[level]}
      $isEnable={isEnable}
      $isSelect={isSelect}
      $justifyContent="space-between"
      $alignItem="center"
      onClick={handleClick}
    >
      <Theme.Flexbox $itemGap="4px" $alignItem="center">
        <Image type={LEVEL_ICON[level]} size="32px" />
        {version && (
          <Image type={getPossessionIcon(version, sector, level)} size="26px" />
        )}
        <Theme.Paragraph>{label}</Theme.Paragraph>
      </Theme.Flexbox>
      <Theme.Flexbox $itemGap="8px" $fontSize="small">
        <CostImage image="money" value={money ?? 0} />
        <CostImage image="power" value={energy} />
      </Theme.Flexbox>
    </Wrapper>
  );
};
