import { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { spacing, Theme } from 'themes';
import { ButtonOld } from 'components/button';
import { CharacterIcon } from 'components/character-icon';
import { ROUTES } from 'constants/routes';
import { useReduxSelector, useWrappedAction } from 'services/hooks';
import { BUTTON_THEME } from 'components/button/button.constants';

import { MorePage } from '../more.constants';
import { MoreActions } from '../more.actions';
import { GameCode } from './game-code';
import { MORE_STRINGS } from '../more.strings';

const { SUPPORT_TITLE, TUTORIAL_TITLE } = MORE_STRINGS;

const SupportLink = styled.a`
  display: block;
  ${BUTTON_THEME.pink}
`;

const GameReportLinkStyled = styled(Link)`
  display: block;
  ${BUTTON_THEME.pink}
`;

const GameReportLink = () => {
  const isGameReportEnabled = useReduxSelector((redux) =>
    Boolean(redux.app.gameMods?.gameReport)
  );

  const gameCode = useReduxSelector((redux) => redux.player.authInfo.code);

  if (!isGameReportEnabled) {
    return null;
  }

  return (
    <GameReportLinkStyled to={ROUTES.gameReport + '/' + gameCode}>
      Download My Dashboard
    </GameReportLinkStyled>
  );
};

export const MoreStartPage: FC = () => {
  const name = useReduxSelector((redux) => redux.player.createCharacter.name);

  const setPage = useWrappedAction(MoreActions.setPage);

  return (
    <Theme.Wrapper $itemGap={{ default: '48px' }} $textAlign="center">
      <Theme.Wrapper $itemGap={{ default: '16px' }}>
        <CharacterIcon sizeIcon="125px" />
        <Theme.Paragraph>{name}</Theme.Paragraph>
      </Theme.Wrapper>

      <Theme.Wrapper $itemGap={{ default: spacing(3) }}>
        <ButtonOld
          styleTheme="pink"
          title={TUTORIAL_TITLE}
          onClick={setPage(MorePage.Tutorial)}
        />
        <SupportLink
          target="_blank"
          href="https://landbot.online/v3/H-1431368-23PAS0DLC5GWDRMN/index.html"
        >
          {SUPPORT_TITLE}
        </SupportLink>

        <GameReportLink />
      </Theme.Wrapper>

      <GameCode />
    </Theme.Wrapper>
  );
};
