import { useMemo } from 'react';
import styled from 'styled-components';

import { ButtonOld } from 'components/button';

import { RewardModal } from '../reward-modal';

import { useRewardModalHOC } from './reward-modal-hoc.state';

const Error = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100%;
  margin: 0 auto;
  text-align: center;

  > * + * {
    margin-top: 16px;
  }
`;

export const RewardModalHOC = () => {
  const { getShownReward, decreaseUserPresentedReward } = useRewardModalHOC();

  const reward = useMemo(() => getShownReward(), [getShownReward]);

  if (!reward) {
    return (
      <Error>
        <p>{"Couldn't find the reward"}</p>
        <p>Please click this button to try to return to the game</p>
        <ButtonOld title="Click" onClick={decreaseUserPresentedReward} />
      </Error>
    );
  }

  return (
    <RewardModal
      reward={reward}
      decreaseUserPresentedReward={decreaseUserPresentedReward}
    />
  );
};
