import { GreetingLogo } from 'screens/greeting';
import { useAuthenticator } from './authenticator.state';

interface IProps {
  code: string;
}

export const Authenticator = (props: IProps) => {
  const { code } = props;

  useAuthenticator(code);

  return <GreetingLogo />;
};
