import styled from 'styled-components';
import { COLORS, spacing, Theme } from 'themes';

import { ButtonOld } from 'components/button';
import { Progress } from 'components/progress';
import { EDUCATION_STRINGS } from 'screens/education/education.strings';
import { TestAnswer } from './test-answer';

import { ITestStateParameter, useTest } from './test.state';

import { CustomComponent } from 'typings';

import { TestStyles as Styles } from './test.styles';

const { NEXT_TEST_BUTTON } = EDUCATION_STRINGS.TEST;

const Wrapper = styled(Theme.Wrapper)`
  padding: ${spacing(3)} ${spacing(1)};
  border-radius: 10px;
  background-color: ${COLORS.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`;

interface ITestProps extends ITestStateParameter {
  title: string;
  isDisabled?: boolean;
  className?: string;
  isShowRightAnswerOnError?: boolean;
}

export const Test: CustomComponent<ITestProps> = (props) => {
  const { questions, className, isDisabled, increaseRating, onEndTest, title } =
    props;

  const {
    currentQuestNumber,
    answer,
    onAnswerQuestion,
    onNextQuestion,
    getMode,
  } = useTest({ questions, increaseRating, onEndTest });

  const { answers, question } = questions[currentQuestNumber];

  const Card = () => (
    <Theme.Wrapper
      $itemGap={{ default: spacing(3) }}
      $textAlign="center"
      $fontFamily="bold"
    >
      <Theme.Paragraph>{question}</Theme.Paragraph>

      <Theme.Wrapper $itemGap={{ default: spacing(2) }}>
        {answers.map(
          (variant, key) =>
            !!variant && (
              <TestAnswer
                onAnswer={onAnswerQuestion(variant)}
                key={key}
                text={variant}
                mode={getMode(variant)}
              />
            )
        )}
      </Theme.Wrapper>
    </Theme.Wrapper>
  );

  const questionTitle = `${currentQuestNumber + 1}|${questions.length}`;

  return (
    <Styles.Root className={className} $itemGap={{ default: spacing(4) }}>
      <Styles.Header $itemGap={{ default: spacing(2) }}>
        <Theme.Flexbox $justifyContent="space-between" $fontFamily="bold">
          <Theme.Text>{title}</Theme.Text>
          <Theme.Text>{questionTitle}</Theme.Text>
        </Theme.Flexbox>

        <Progress
          completePoint={currentQuestNumber + 1}
          maxPoint={questions.length}
        />
      </Styles.Header>

      <Wrapper $itemGap={{ default: spacing(4) }}>
        <Card />

        <ButtonOld
          title={NEXT_TEST_BUTTON}
          isDisabled={!answer || isDisabled}
          onClick={onNextQuestion}
        />
      </Wrapper>
    </Styles.Root>
  );
};
