import styled from 'styled-components';
import { COLORS, typographyCss } from 'themes';

export const ValuesPanelStyles = {
  Root: styled.div`
    display: grid;
    justify-content: center;
  `,
  TransformWrapper: styled.div`
    transform: rotateX(180deg);
    overflow-x: auto;
    padding-top: 4px;
  `,
  LinesWrapper: styled.div`
    transform: rotateX(180deg);
  `,
};

export const ValuesLineStyles = {
  Root: styled.div`
    display: flex;

    &:not(:first-child) {
      margin-top: 16px;
    }

    & > *:not(:first-child) {
      margin-left: 16px;
    }
  `,
};

export const ValueItemStyles = {
  Root: styled.div<{ isActive: boolean }>`
    width: 190px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid ${COLORS.lightGray};
    padding: 4px;
    cursor: pointer;
    background-color: ${({ isActive }) =>
      isActive ? COLORS.darkYellow : COLORS.white};
    color: ${({ isActive }) => (isActive ? COLORS.white : COLORS.black)};

    ${typographyCss.RalewayBold16};
  `,
};
