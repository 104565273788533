import styled from 'styled-components';

import { Loader } from 'components/loader';

const Root = styled.div`
  padding: 35px 0;
`;

export const LogsLoader = () => (
  <Root>
    <Loader />
  </Root>
);
