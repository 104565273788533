import { FC } from 'react';
import styled from 'styled-components';
import { IAbility } from '@avid/common';

import { getGSCLabel } from 'shared';
import { FONT_FAMILIES, Theme } from 'themes';

import { SkillMultiplier } from './skill-multiplier';

interface IProps {
  ability: IAbility;
  className?: string;
  multi: number;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  gap: 8px;
  font-family: ${FONT_FAMILIES.regular};
  align-items: center;
  justify-content: space-between;
`;

export const SkillDisplay: FC<IProps> = (props) => {
  const { className, ability, multi } = props;
  const { level, name, type } = ability;

  const skillLevel = type === 'GSC' ? getGSCLabel(level) : level.toFixed(2);

  return (
    <Wrapper className={className}>
      <Theme.Paragraph $textAlign="left">{name}</Theme.Paragraph>

      <Theme.Flexbox $itemGap="8px">
        <Theme.Paragraph $fontFamily="bold" $textAlign="right">
          {skillLevel}
        </Theme.Paragraph>

        <SkillMultiplier multi={multi} />
      </Theme.Flexbox>
    </Wrapper>
  );
};
