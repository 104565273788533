import { CareerCenterButton } from 'components/career-center-button';
import { ProgressBar } from 'components/progress-bar';
import { SkillList } from 'components/skills';
import { CompanyParameters } from 'components/company-parameters';
import { spacing, Theme } from 'themes';
import { ProgressBlock } from '../progress-block';
import { WORK_STRINGS } from '../../work.strings';

import { CareerStyles as Styles } from './career.styles';
import { useCareer } from './career.state';
import { CareerText } from '../career-text';
import { CareerSalary } from '../career-salary';

const {
  EXPERIENCE,
  POSITION,
  SKILL,
  CHANGE_BUTTON,
  CONTINUE_BUTTON,
  QUIT_BUTTON,
  COMPANY_NAME,
} = WORK_STRINGS.CAREER;

export const Career = () => {
  const {
    work,
    values,
    workExperience,
    sector,
    career,
    lastLearnAbilities,
    company,
    isCycleUnavailable,

    onChangeJob,
    onQuitJob,
    onContinueWork,
  } = useCareer();

  return (
    <Theme.Flexbox
      $isColumn
      $justifyContent="space-between"
      $itemGap={spacing(3)}
    >
      <Theme.Wrapper $itemGap={{ default: spacing(2), laptop: spacing(4) }}>
        <Styles.TextContainer>
          <CareerText isColumn isBold text={work} title={POSITION} />
          <Theme.Wrapper $itemGap={{ default: spacing(2) }}>
            <CareerText isBold text={company} title={COMPANY_NAME} />

            <CareerSalary />
          </Theme.Wrapper>
        </Styles.TextContainer>

        {values && (
          <CompanyParameters
            positiveParameters={values.positive}
            negativeParameters={values.negative}
          />
        )}

        <Styles.ProgressesContainer>
          <ProgressBlock
            title={EXPERIENCE(
              (workExperience && sector && workExperience[sector]) || 0
            )}
          >
            <ProgressBar
              roundSize="8px"
              itemGap="16px"
              lineHeight="1.5px"
              color="pink"
            >
              {career?.map(({ experience, name }, index) => (
                <CareerText
                  key={index}
                  title={name}
                  text={`${experience}`}
                  justifyContent="space-between"
                />
              ))}
            </ProgressBar>
          </ProgressBlock>

          <ProgressBlock title={SKILL}>
            <SkillList abilities={lastLearnAbilities} />
          </ProgressBlock>
        </Styles.ProgressesContainer>
      </Theme.Wrapper>

      <Theme.Wrapper $itemGap={{ default: spacing(2) }}>
        <Styles.Button
          onClick={onContinueWork}
          title={CONTINUE_BUTTON}
          isDisabled={!work || !sector || isCycleUnavailable}
        />
        <Styles.Button
          onClick={onChangeJob}
          title={CHANGE_BUTTON}
          styleTheme="cancelModal"
        />
        <Styles.Button
          onClick={onQuitJob}
          title={QUIT_BUTTON}
          styleTheme="cancelModal"
          isDisabled={!work}
        />
        <CareerCenterButton />
      </Theme.Wrapper>
    </Theme.Flexbox>
  );
};
