import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Image, TImageNames } from 'components/image';
import { FONT_SIZES, Theme } from 'themes';

interface IPossessionItemProps {
  title: string;
  imageTitle?: TImageNames;
  columnNumber?: number;
  children?: ReactNode;
}

const Grid = styled(Theme.Wrapper)<{ columnNumber: number }>`
  display: grid;
  grid-template-columns: auto repeat(${(props) => props.columnNumber - 1}, 1fr);
  gap: 16px;
  align-items: center;

  > * {
    overflow: auto;
  }
`;
const Title = styled(Theme.Title)`
  font-size: ${FONT_SIZES.regular};
`;

export const PossessionWrapper: FC<IPossessionItemProps> = (props) => {
  const { imageTitle, title, children, columnNumber = 2 } = props;

  const RenderImage = imageTitle && <Image type={imageTitle} />;

  return (
    <Theme.ShadowHomeWrapper $itemGap={{ default: '24px' }}>
      <Theme.Flexbox $itemGap="36px" $alignItem="center">
        {RenderImage}
        <Title>{title}</Title>
      </Theme.Flexbox>

      <Grid columnNumber={columnNumber}>{children}</Grid>
    </Theme.ShadowHomeWrapper>
  );
};
