import styled from 'styled-components';

import { Image, TImageNames } from 'components/image';
import { CareerCenterButton } from 'components/career-center-button';
import { moveToRoute } from 'services/utils';
import { MEDIA, Theme } from 'themes';
import { ROUTES } from 'constants/routes';

import { FIND_JOB_STRINGS } from '../../../find-job.strings';
import { FindJobButton } from '../../../find-job.styles';

interface IProps {
  isHaveDegree: boolean;
}

const { GO_TO_SCHOOL, START_FIND } = FIND_JOB_STRINGS;
const { NOT_HAVE, NOT_EDUCATION, HAVE_DEGREE } = START_FIND;

const Styles = {
  ContentContainer: styled(Theme.Flexbox)`
    ${MEDIA.LAPTOP} {
      width: 50%;
      max-width: 1000px;
    }
  `,
  TextContainer: styled(Theme.Wrapper)`
    text-align: center;
  `,
  Image: styled(Image)`
    height: 139px;
    margin-top: 32px !important;
  `,
};

export const UnavailableWork = (props: IProps) => {
  const { isHaveDegree } = props;

  const onGotoSchool = () => moveToRoute(ROUTES.education);

  const { subtitle, image } = isHaveDegree
    ? { subtitle: HAVE_DEGREE, image: 'planeTableOpen' as TImageNames }
    : {
        subtitle: NOT_EDUCATION,
        image: 'noWork' as TImageNames,
      };

  const RenderGoToSchoolButton = !isHaveDegree && (
    <FindJobButton title={GO_TO_SCHOOL} onClick={onGotoSchool} />
  );

  return (
    <Theme.Flexbox $isColumn $justifyContent="space-between" $alignItem="center">
      <Styles.ContentContainer $isColumn $itemGap="16px" $alignItem="center">
        <Styles.TextContainer $itemGap={{ default: '16px' }}>
          <Theme.Title>{NOT_HAVE}</Theme.Title>
          <Theme.Title>{subtitle}</Theme.Title>
        </Styles.TextContainer>

        <Styles.Image type={image} />

        {RenderGoToSchoolButton}
      </Styles.ContentContainer>

      <CareerCenterButton />
    </Theme.Flexbox>
  );
};
