export const FIND_JOB_STRINGS = {
  HISTORY_EMPTY: 'Your work history is empty now. You can find a job.',
  SELECT_SECTOR_TEXT: 'Which sector do you want to work in?',
  SELECT_SECTOR_PLACEHOLDER: 'Select sector',
  FIND_JOB: 'Find a Job',
  GO_TO_SCHOOL: 'Go to school',
  NOT_EMPLOYED: 'You are not employed. You can find a new job here.',
  POSITION_ACCOUNTANT: (job: string) =>
    `Your current position is ${job}. You can find a new job.`,

  AVAILABLE_TREE_TITLE: 'Jobs available for you',

  WARNING_TEXT:
    '* You are eligible for the above job(s). Unfortunately, there are no vacancies for some of them.',

  DESCRIPTION_BUTTON: 'Check Skills Gap',

  SELECT_JOB: {
    TITLE: 'Apply for the job',
    CONFIRM_BUTTON: 'Next',
    BACK_BUTTON: 'Go Back',
  },

  JOB_OFFER: {
    TITLE: 'Job Offer',
    COMPANY: 'Company:',
    JOB_NAME: 'Job Title:',
    SALARY: 'Salary:',
    ACCEPT_WORK: 'Accept',
    REJECT_WORK: 'Reject',
    MOOD_WORK_INFORMATION: 'Matching Values:',
    COMPANY_VALUES: 'Company Values:',
    CHOOSE_ANOTHER_TITLE:
      'You can also choose job offers from other companies.',
    CHOOSE_ANOTHER_BUTTON: 'Try other companies',
  },

  CONFIRM_JOB_OFFER: {
    TITLE: 'Congratulations!',
    INFO: (job: string, company: string) =>
      `Your position is now: ${job} at ${company}`,
    BUTTON: 'Start working',
  },

  CHANGE_COMPANY: {
    TITLE: 'Check job offer from another company',
    CONFIRM_BUTTON: 'Confirm',
    CANCEL_BUTTON: 'Go Back',
  },

  JOB_SKILL_INFORMATION: {
    TITLE: 'Skills Gap Analysis',
  },

  JOB_DESCRIPTION_BUTTON: 'Check Skills Gap',

  START_FIND: {
    NOT_HAVE: 'Sorry, you do not have enough skills to find a job.',
    NOT_EDUCATION: 'Try graduating with a degree to gain skills.',
    HAVE_DEGREE:
      'Check your skills gap in the Career Center and develop skills in Continuing Education.',
  },
};
