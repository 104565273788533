import { FC } from 'react';
import styled from 'styled-components';
import { TJobSkills } from '@avid/common';

import { Loader } from 'components/loader';
import { InfoModal } from 'components/modal';
import { useFetch, useReduxSelector } from 'services/hooks';
import { VersionsAPI } from 'services/api';
import { spacing, Theme } from 'themes';
import { getAbilitiesByName, removeWorkExperienceFromSkillList } from 'shared';

import { FIND_JOB_STRINGS } from 'screens/find-job/find-job.strings';

import { SkillDescription } from './skill-description';
import { getVersionString } from 'services/utils/versions';

const { TITLE } = FIND_JOB_STRINGS.JOB_SKILL_INFORMATION;

interface ISkillInformationProps {
  sector: string;
  job: string;
  onCloseModal(): void;
}

const SkillList = styled(Theme.Wrapper)`
  max-height: 320px;
  padding: 0 ${spacing(1)};
  overflow: auto;
`;

export const SkillInformation: FC<ISkillInformationProps> = (props) => {
  const { sector, job, onCloseModal } = props;

  const abilities = useReduxSelector((redux) => redux.player.skills);
  const version = useReduxSelector((redux) => redux.app.version);

  const [needAbility, isLoading] = useFetch(() => {
    if (!version) {
      return;
    }

    return VersionsAPI.sectors.work.job.fetchSkills({ version, sector, job });
  });

  const skillList = (skills: TJobSkills) =>
    removeWorkExperienceFromSkillList(skills)
      .filter(([, skillData]) => skillData.level !== null)
      .sort(([first], [second]) => (first < second ? -1 : 1));

  const SkillDescriptionList =
    !isLoading &&
    needAbility &&
    skillList(needAbility).map(([skillName, skillData]) => (
      <SkillDescription
        type={skillData.type}
        key={skillName}
        name={skillName}
        needLevel={skillData.level}
        ability={getAbilitiesByName({ name: skillName, abilities })}
      />
    ));

  return (
    <InfoModal
      buttonText={`Back to ${getVersionString(version, 'CAREER_CENTRE')}`}
      onCloseModal={onCloseModal}
      itemGap={{ default: spacing(5) }}
    >
      <Theme.Wrapper>
        <Theme.Title>{job}</Theme.Title>
        <Theme.Paragraph>{TITLE}</Theme.Paragraph>
      </Theme.Wrapper>
      <Loader isLoading={isLoading}>
        <SkillList $itemGap={{ default: spacing(3) }}>
          {SkillDescriptionList}
        </SkillList>
      </Loader>
    </InfoModal>
  );
};
