import { useReduxSelector } from 'services/hooks';
import { UNIVERSITY } from 'constants/education';

import { EducationModal, EducationPage } from '../../education.typing';
import {
  getFirstUniversityLevel,
  checkPrerequisites,
} from './education-level.utils';
import { IEducationLevelProps } from './education-level.typing';

export const useEducationLevel = (props: IEducationLevelProps) => {
  const { level, setLevel, setModal, setPage } = props;

  const educations = useReduxSelector((redux) => redux.player.educations);
  const educationLevels = useReduxSelector(
    (redux) => redux.app.educationLevels
  );
  const isEducationTutorialPassed = useReduxSelector(
    (redux) =>
      !Boolean(redux.app.gameMods?.popupTips) ||
      Boolean(redux.player.tutorials?.education)
  );

  const resultLevel =
    level === UNIVERSITY ? getFirstUniversityLevel(educationLevels) : level;

  const isOpen = (prerequisites?: IPrerequisites[]) =>
    !!prerequisites &&
    (!prerequisites.length ||
      prerequisites.some(checkPrerequisites(educations)));

  const isComplete = !!educations?.filter((ed) => ed.level === resultLevel)
    .length;

  const onClick = (prerequisites?: IPrerequisites[]) => () => {
    if (!resultLevel || !isEducationTutorialPassed) {
      return;
    }

    if (!isOpen(prerequisites)) {
      return setModal(EducationModal.WrongLevel);
    }

    setLevel(resultLevel);

    if (!educationLevels[resultLevel].isSector) {
      return setModal(EducationModal.StartTest);
    }

    setPage(EducationPage.Sector);
  };

  return {
    isComplete,

    isOpen,
    onClick,
  };
};
