import styled from 'styled-components';

import { Grid, Text } from 'components/ui';
import { createPrice, getModComponent } from 'services/utils';
import { COLORS, typographyCss } from 'themes';
import { useMod } from 'services/hooks';

interface IProps {
  isLoading: boolean;
  salary?: number;
}

const Styles = {
  Root: styled.div`
    padding-bottom: 24px;
    display: grid;
    row-gap: 8px;
    ${typographyCss.RalewayBold16}
  `,
};

export const MoneyChange = (props: IProps) => {
  const { isLoading, salary } = props;

  const ExpensesDeductionMC = getModComponent(
    useMod('financial')?.containers.ExpensesDeduction
  );

  return (
    <Styles.Root>
      <Grid
        columns={2}
        columnGap="16px"
        justifyContent="start"
        alignItems="center"
      >
        <Text>Salary</Text>
        <Text color={COLORS.darkGreen}>
          {isLoading ? '...' : createPrice(salary)}
        </Text>
      </Grid>

      <ExpensesDeductionMC />
    </Styles.Root>
  );
};
