import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PossessionLevel } from '@avid/common';

import { ShopActions, ShopModals } from 'screens/shop';

import { useShopAPI } from 'screens/shop/shop.api';
import { getPrice, useShopUtils } from 'screens/shop/shop.utils';
import {
  useFetch,
  useMod,
  usePushLog,
  useReduxDispatch,
  useReduxSelector,
} from 'services/hooks';
import { callFunctionAction } from 'services/api';
import { PlayerActions } from 'services/redux';

export const useTravelShop = () => {
  const { country, level } = useReduxSelector((redux) => ({
    country: redux.shop.country,
    level: redux.shop.level,
  }));

  const [isLoadingBuy, setIsLoadingBuy] = useState(false);

  const { fetchPossessionSector } = useShopAPI();
  const { checkEnable } = useShopUtils();
  const { callAction, callActionCallback } = useReduxDispatch();

  const [prices, isLoadingPrice] = useFetch(fetchPossessionSector('travel'));

  const pushLog = usePushLog();

  const dispatch = useDispatch();

  const gaugesHard = useMod('gaugesHard');

  const setCountry = callAction(ShopActions.setCountry);
  const setLevel = callActionCallback(ShopActions.setLevel);
  const setLevelPrice = callAction(ShopActions.setLevelPrice);
  const openModal = callActionCallback(ShopActions.openModal)(
    ShopModals.BuyPossession
  );

  useEffect(() => {
    if (country && level && prices) {
      setLevelPrice(prices[level], level);
    }
  }, [country, level]);

  const setTravelSector = (newCountry: IItem) => setCountry(newCountry);

  const buyTravel = async () => {
    if (!prices || !level || isLoadingBuy) {
      return;
    }

    setIsLoadingBuy(true);

    const price = prices[level];

    const { energy, money } = getPrice(price);

    try {
      const result = await callFunctionAction('@SHOP/BUY_TRAVEL', {
        country: country.value,
        level,
      });

      dispatch(PlayerActions.merge(result.mergePlayer));

      openModal();

      pushLog({
        type: 'shop',
        action: 'travel',
        params: {
          country: country.value,
          level: (level as PossessionLevel).toLowerCase(),
          energy: -energy,
          money: money && -money,
          gauges: gaugesHard
            ? gaugesHard.constants.SHOP_GAUGES_BONUS.travel[level]
            : undefined,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingBuy(false);
    }
  };

  return {
    country,
    prices,
    level,

    isLoadingPrice,

    setTravelSector,
    setLevel,
    buyTravel,
    checkPrice: checkEnable(prices),
  };
};
