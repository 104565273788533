import { IPlayerPossessions, IPossessionLevels, TGameVersion } from '@avid/common';
import {
  ICheckEnabledCareerAnchorParams,
  ICheckEnabledCharacteristicParams,
  ICheckEnabledCareerValueParams,
} from './character.typing';
import { POSSESSION_ICONS } from './character.constants';
import { valuesOfObject } from 'services/utils';

const checkIsEnableItems = (parameters: {
  items: ICareerAnchorValue[];
  labelValue: string;
  currentValue: string;
}) => {
  const { currentValue, items, labelValue } = parameters;

  return items.some(
    (item) => currentValue === item.label && item.label === labelValue
  );
};

const checkEnabledCareerAnchor = (params: ICheckEnabledCareerAnchorParams) => {
  const { anchor, chAnchors, constAnchors } = params;

  return chAnchors.some((currentAnchor) =>
    checkIsEnableItems({
      items: constAnchors,
      labelValue: anchor,
      currentValue: currentAnchor,
    })
  );
};

const checkEnabledValue = (params: ICheckEnabledCareerValueParams) => {
  const { chValues, constValues, value } = params;

  return chValues.some((currentValue) =>
    valuesOfObject(constValues)
      .map((valuesArray) => ({
        items: valuesArray.map((item) => ({ label: item })),
      }))
      .some(({ items }) =>
        checkIsEnableItems({
          items,
          labelValue: value,
          currentValue,
        })
      )
  );
};

export const checkEnabledCharacteristic = (
  params: ICheckEnabledCharacteristicParams
) => {
  const { constAnchors, chAnchors, constValues, chValues, characteristic } =
    params;

  return (
    checkEnabledCareerAnchor({
      constAnchors,
      chAnchors,
      anchor: characteristic,
    }) || checkEnabledValue({ constValues, chValues, value: characteristic })
  );
};

export const getPossessionIcon = (
  version: TGameVersion,
  possession: keyof IPlayerPossessions,
  level: keyof IPossessionLevels
) => {
  version = version === 'us' ? 'us' : 'standard';

  return POSSESSION_ICONS[possession][level][version];
};
