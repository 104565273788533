import { Text } from 'components/ui';
import {
  SelectorDataList,
  SelectorDataListItem,
} from 'components/selector-list';
import { useReduxSelector } from 'services/hooks';

import { AnchorTitle } from '../../../components/create-character/create-character-layout';
import { ReviewSection } from '../../../components/create-character/review-section';

interface IProps {
  anchors: string[];
  onEdit: () => void;
}

export const ReviewAnchors = (props: IProps) => {
  const { anchors, onEdit } = props;

  const appAnchors = useReduxSelector((redux) => redux.app.anchors);

  const RenderAnchors = anchors.map((anchor) => {
    const anchorItem = appAnchors.find(
      (appAnchor) => appAnchor.title === anchor
    );

    if (!anchorItem) {
      return null;
    }

    return (
      <SelectorDataListItem key={anchorItem.title} value={anchorItem.title}>
        <AnchorTitle>{anchorItem.title}</AnchorTitle>
        <Text>{anchorItem.description}</Text>
      </SelectorDataListItem>
    );
  });

  return (
    <ReviewSection title="Career Anchors" onEdit={onEdit}>
      <SelectorDataList selectedValues={anchors}>
        {RenderAnchors}
      </SelectorDataList>
    </ReviewSection>
  );
};
