import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CallBackProps, STATUS } from 'react-joyride';
import { IPlayerTutorials } from '@avid/common';

import { callFunctionAction } from 'services/api';
import { PlayerActions } from 'services/redux';

export const useCompleteTutorial = (tutorialKey: keyof IPlayerTutorials) => {
  const passedRef = useRef(false);

  const dispatch = useDispatch();

  const completeTutorial = async () => {
    const result = await callFunctionAction('@TUTORIALS/COMPLETE', {
      tutorialKey,
    });

    dispatch(PlayerActions.merge(result.mergePlayer));
  };

  const handleCallback = (data: CallBackProps) => {
    const { status } = data;

    if (status === STATUS.FINISHED && !passedRef.current) {
      passedRef.current = true;
      completeTutorial();
    }
  };

  return { handleCallback };
};
