import { ButtonOld } from 'components/button';
import styled from 'styled-components';

import { COLORS, FONT_SIZES, MEDIA } from 'themes';

export const HistoryStyles = {
  Root: styled.div`
    background-color: ${COLORS.white};
  `,
  Content: styled.div`
    padding: 10px 0;

    ${MEDIA.LAPTOP} {
      padding: 32px 0;
    }
  `,
  SortLabel: styled.p`
    padding-right: 12px;
    text-align: right;
    font-size: ${FONT_SIZES.px12};

    ${MEDIA.LAPTOP} {
      padding-right: 16%;
    }
  `,
  LoadButton: styled(ButtonOld)`
    width: auto;
    margin-top: 24px;
    padding: 0px 16px;
  `,
  UpdatingLoaderContainer: styled.div`
    margin: 8px 0;
  `,
};
