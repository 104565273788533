import { FC } from 'react';
import styled, { RuleSet, css } from 'styled-components';

import { IItemGap } from 'themes';

import { ISelectorProps, Selector } from './selector';

export interface ISelectorThemedProps extends ISelectorProps {
  items: IItem[];

  topGap?: string;
  itemGap?: IItemGap;
  placeholder?: string;
  menuStyles?: RuleSet<object>;
  menuMaxHeight?: string;
}

const MenuStyled = styled(Selector.Menu)<{
  maxHeight?: string;
  staticCss?: RuleSet<object>;
}>`
  min-height: 70px;

  ${({ maxHeight, staticCss }) => css`
    ${maxHeight && `max-height: ${maxHeight}`};
    ${staticCss && staticCss};
  `}
`;

export const SelectorThemed: FC<ISelectorThemedProps> = (props) => {
  const {
    items,
    topGap = '0px',
    itemGap = { default: '0px' },
    placeholder,
    menuStyles,
    menuMaxHeight,
    ...selectorProps
  } = props;

  return (
    <Selector {...selectorProps}>
      <Selector.Header placeholder={placeholder} />

      <MenuStyled
        topGap={topGap}
        $itemGap={itemGap}
        maxHeight={menuMaxHeight}
        staticCss={menuStyles}
      >
        {items.map((item, index) => (
          <Selector.Item key={index} value={item} />
        ))}
      </MenuStyled>
    </Selector>
  );
};
