import { IEducationLevel } from '@avid/common';

import { VersionsAPI } from 'services/api';
import { callFunctionAction } from 'services/api/callable-functions';
import { entriesObject, isEmptyArray, uniqueItemArray } from 'services/utils';
import {
  checkEnoughExperience,
  checkLevelUpper,
  getAbilitiesByName,
  getSectorCareerTreeAsync,
} from 'shared';

import {
  ICheckEnableWorkParams,
  IGetEnableWorkFromCareerTreeParams,
} from './available-work.typing';

export interface ICheckSectorDegreeParams {
  sector: string;
  educations?: IEducationLevel[];
}

export const checkSectorDegree = (params: ICheckSectorDegreeParams) => {
  const { sector, educations = [] } = params;
  return educations.some((education) => sector === education.sector);
};

const checkDefaultTrueSkill = (needLevel: number | null, skillName: string) =>
  needLevel === null || skillName === 'Work Experience';

export const checkEnableWorkCallback = (params: ICheckEnableWorkParams) => {
  const { sector, version, workExperience, abilities } = params;

  return async (work: string) => {
    const skills = await VersionsAPI.sectors.work.job.fetchSkills({
      version,
      sector,
      job: work,
    });

    if (!skills) {
      return false;
    }

    const { level: workExperienceLevel } = skills['Work Experience'];

    const isEnoughExperience = checkEnoughExperience({
      sector,
      workExperience,
      needWorkExperience: workExperienceLevel || 0,
    });

    if (!isEnoughExperience) {
      return false;
    }

    return entriesObject(skills)
      .filter(
        ([skillName, skillData]) =>
          !checkDefaultTrueSkill(skillData.level, skillName)
      )
      .every(([skillName, skillData]) => {
        const ability = getAbilitiesByName({ name: skillName, abilities });

        if (!ability) {
          return false;
        }

        return checkLevelUpper({
          currentLevel: ability.level,
          needLevel: skillData.level,
          isMinLevel: true,
        });
      });
  };
};

const getEnableWorkFromCareerTreeAsync = async (
  params: IGetEnableWorkFromCareerTreeParams
) => {
  const { treeBranch, ...checkEnableWorkParams } = params;

  const checkEnableWork = checkEnableWorkCallback(checkEnableWorkParams);

  const availableJobs = await Promise.all(
    treeBranch.map(async (job) => {
      if (!job) {
        return;
      }

      const isEnable = await checkEnableWork(job);

      if (isEnable) {
        return job;
      }
    })
  );

  return availableJobs.filter((job) => job) as string[];
};

export const getEnableWorkAsync = async (params: ICheckEnableWorkParams) => {
  const { version, sector } = params;

  const careerTree = await getSectorCareerTreeAsync(version, sector);

  if (!careerTree || isEmptyArray(careerTree)) {
    return;
  }

  const enableWork = await Promise.all(
    careerTree.map((branch) =>
      getEnableWorkFromCareerTreeAsync({
        ...params,
        treeBranch: branch,
      })
    )
  );

  const jobs = uniqueItemArray(enableWork.flat());

  const jobsLimited = callFunctionAction('@WORK/LIMIT_AVAILABLE_JOBS', {
    sector,
    jobs,
  });

  return jobsLimited;
};

export const createItemsFromLimitedJobs = (jobs: [string, boolean][]) =>
  jobs.map(([job, isAvailable]) => ({ value: job, isDisabled: !isAvailable }));
