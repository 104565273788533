import styled from 'styled-components';

import { COLORS, Z_INDEXES } from 'themes';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.black};
  opacity: 0.4;
  z-index: ${Z_INDEXES.MODAL_BACKGROUND};
`;
