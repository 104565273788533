import { ChooseImageModal } from 'components/modal';
import { Theme } from 'themes';

import { WORK_STRINGS } from 'screens/work/work.strings';

import { useQuitWork } from './quit-work.state';

const { CANCEL_BUTTON, CONFIRM_BUTTON, TITLE } = WORK_STRINGS.QUIT_WORK;

export const QuitWork = () => {
  const { onQuitAsync, onCloseModal } = useQuitWork();

  return (
    <ChooseImageModal
      title={<Theme.Paragraph>{TITLE}</Theme.Paragraph>}
      image="quitWork"
      confirmButtonTitle={CONFIRM_BUTTON}
      cancelButtonTitle={CANCEL_BUTTON}
      onConfirm={onQuitAsync}
      onCloseModal={onCloseModal}
    />
  );
};
