import styled from 'styled-components';

import { MEDIA, Theme } from 'themes';

export const TestStyles = {
  Root: styled(Theme.Wrapper)`
    ${MEDIA.LAPTOP} {
      min-width: 1000px !important;
      width: 52%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `,
  Header: styled(Theme.Wrapper)`
    ${MEDIA.LAPTOP} {
      width: 50%;
      margin: 0 auto;
    }
  `,
};
