import styled from 'styled-components';
import { Theme } from 'themes';

import { Badge } from './badge';

import { IRewardOption } from 'services/rewards';

interface IBadgesSectionProps {
  title: string;
  achievedRewards: [string, IRewardOption][];
  availableRewards: [string, IRewardOption][];
}

const Wrapper = styled.li`
  &:not(:first-child) {
    margin-top: 32px;
  }
`;

export const BadgesSection = (props: IBadgesSectionProps) => {
  const { title, achievedRewards, availableRewards } = props;

  const achievedBadgesMap = achievedRewards.map(
    ([rewardName, rewardOptions]) => (
      <li key={rewardName}>
        <Badge rewardName={rewardName} rewardOptions={rewardOptions} isActive />
      </li>
    )
  );

  const availableBadgesMap = availableRewards.map(
    ([rewardName, rewardOptions]) => (
      <li key={rewardName}>
        <Badge
          rewardName={rewardName}
          rewardOptions={rewardOptions}
          isActive={false}
        />
      </li>
    )
  );

  return (
    <Wrapper>
      <Theme.Paragraph>{title}</Theme.Paragraph>
      <ul>{achievedBadgesMap}</ul>
      <ul>{availableBadgesMap}</ul>
    </Wrapper>
  );
};
