import { useReduxSelector } from 'services/hooks';

import { RewardModalHOC } from './reward-modal-hoc';

export const RewardDisplay = () => {
  const userPresentedRewards = useReduxSelector(
    (redux) => redux.rewards.userPresentedRewards
  );
  const isScreenModalOpen = useReduxSelector(
    (redux) => redux.app.isScreenModalOpen
  );

  if (userPresentedRewards < 1 || isScreenModalOpen) {
    return null;
  }

  return <RewardModalHOC />;
};
