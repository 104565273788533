import firebase from 'firebase/app';
import { IAuth, IPlayer } from '@avid/common';

import { db } from '../firebase';

interface IApiParameter {
  document: string;
  subCollection?: string;
  subDocument?: string;
}

interface IApiSourceParameter extends IApiParameter {
  source?: firebase.firestore.GetOptions['source'];
}

interface IConnectionToDatabaseParameters {
  code: string;
  userCode: string;
}

interface IUpdateFieldParams<T extends keyof IPlayer>
  extends IConnectionToDatabaseParameters {
  field: T;
  value: IPlayer[T] | firebase.firestore.FieldValue;
}

export interface IMergeFieldParams<T extends keyof IPlayer>
  extends IConnectionToDatabaseParameters {
  field: T;
  value: Partial<IPlayer[T]> | firebase.firestore.FieldValue;
}

interface IUpdateSubfieldParameters<
  T extends keyof IPlayer,
  S extends keyof IPlayer[T],
> extends IConnectionToDatabaseParameters {
  field: T;
  subfield: S;
  value: IPlayer[T][S];
}

export class GameAPI {
  private $api() {
    return db.collection('games');
  }

  private connect(params: IApiParameter) {
    const { document, subCollection, subDocument } = params;
    return subCollection
      ? this.$api().doc(document).collection(subCollection).doc(subDocument)
      : this.$api().doc(document);
  }

  private connectToDatabase({
    code: gameCode,
    userCode,
  }: IConnectionToDatabaseParameters) {
    return this.connect({
      document: gameCode,
      subCollection: 'users',
      subDocument: userCode,
    });
  }

  public async fetch(params: IApiSourceParameter) {
    const { source, ...connectParams } = params;
    return (await this.connect(connectParams).get({ source })).data();
  }

  public post = async (params: IApiParameter, data: any) => {
    try {
      await this.connect(params).set(data);
    } catch (error) {
      console.error(error);
    }
  };

  public async updateFiled<T extends keyof IPlayer>(
    params: IUpdateFieldParams<T>
  ) {
    const { field, code: gameCode, userCode, value } = params;
    if (!value) {
      return;
    }

    await this.connectToDatabase({ code: gameCode, userCode }).update({
      [field]: value,
    });
  }

  public mergePlayer(auth: IAuth, fields: RecursivePartial<IPlayer>) {
    const { code, email } = auth;
    return this.connectToDatabase({ code: code, userCode: email }).set(fields, {
      merge: true,
    });
  }

  public async mergeField<T extends keyof IPlayer>(
    params: IMergeFieldParams<T>
  ) {
    const { field, code: gameCode, userCode, value } = params;
    if (!value) {
      return;
    }

    await this.connectToDatabase({ code: gameCode, userCode }).set(
      {
        [field]: value,
      },
      { merge: true }
    );
  }

  public async updateSubfield<
    T extends keyof IPlayer,
    S extends keyof IPlayer[T],
  >(params: IUpdateSubfieldParameters<T, S>) {
    const { field, code, userCode, value, subfield } = params;

    if (typeof value === 'undefined') {
      return;
    }

    await this.connectToDatabase({ code, userCode }).update({
      [`${field}.${String(subfield)}`]: value,
    });
  }
}

export const gameAPI = new GameAPI();
