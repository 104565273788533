import { useReduxSelector } from 'services/hooks';
import { moveToRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';

export const useWelcomePage = () => {
  const roundsNumber = useReduxSelector(
    (redux) => redux.game.config?.roundsNumber || 0
  );

  const moveToGame = () => {
    moveToRoute(ROUTES.home);
  };

  return { moveToGame, roundsNumber };
};
