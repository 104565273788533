import { useReduxSelector } from 'services/hooks';
import { checkNtuHighestLevel, objectEqual } from 'services/utils';

import { NotPassDefault } from './not-pass-default';
import { NotPassPhdHard } from './not-pass-phd-hard';

interface IProps {
  rating: number;
  level: string;
  onClose: () => void;
}

export const NotPass = (props: IProps) => {
  const { level, rating, onClose } = props;

  const version = useReduxSelector((redux) => redux.app.version);
  const educationLevels = useReduxSelector(
    (redux) => redux.app.educationLevels,
    objectEqual
  );

  if (checkNtuHighestLevel({ educationLevels, level, version })) {
    return <NotPassPhdHard rating={rating} onClose={onClose} />;
  }

  return <NotPassDefault rating={rating} onClose={onClose} />;
};
