import { Redirect } from 'react-router-dom';

import { useMod, useReduxSelector } from 'services/hooks';
import { ROUTES } from 'constants/routes';
import { getModComponent } from 'services/utils';

import {
  AnchorsStep,
  NameAvatarStep,
  PerformanceStep,
  ReviewStep,
  SmartsStep,
  ValuesStep,
} from './components';

import { useCreateCharacter } from './create-character.state';

const CreateCharacter = () => {
  const {
    step,
    createCharacter,
    lifeGoals,
    setStep,
    onSetNameIcon,
    onSetSmart,
    onSetPerformance,
    onSetAnchors,
    onSetValues,
    onSetLifeGoals,
  } = useCreateCharacter();

  const LifeGoalsStep = getModComponent(
    useMod('lifeGoals')?.components.LifeGoalsStep
  );

  let RenderStep = null;

  switch (step) {
    case 'nameIcon': {
      ``;
      RenderStep = (
        <NameAvatarStep
          initNameIcon={{
            icon: createCharacter.icon,
            name: createCharacter.name,
          }}
          onNext={onSetNameIcon}
        />
      );
      break;
    }

    case 'smarts': {
      RenderStep = (
        <SmartsStep initSmarts={createCharacter.smart} onNext={onSetSmart} />
      );
      break;
    }

    case 'performance': {
      RenderStep = (
        <PerformanceStep
          initPerformance={createCharacter.performance}
          onNext={onSetPerformance}
        />
      );
      break;
    }

    case 'anchors': {
      RenderStep = (
        <AnchorsStep
          initAnchors={createCharacter.careerAnchors}
          onNext={onSetAnchors}
        />
      );
      break;
    }

    case 'values': {
      RenderStep = (
        <ValuesStep initValues={createCharacter.values} onNext={onSetValues} />
      );
      break;
    }

    case 'goals': {
      if (LifeGoalsStep) {
        RenderStep = (
          <LifeGoalsStep
            initLifeGoals={lifeGoals || []}
            onNext={onSetLifeGoals}
          />
        );
      }
      break;
    }

    case 'review': {
      RenderStep = (
        <ReviewStep
          key="review"
          onSetStep={setStep}
          createCharacter={createCharacter}
          lifeGoals={lifeGoals}
        />
      );
      break;
    }

    default: {
      RenderStep = null;
    }
  }

  return <>{RenderStep}</>;
};

export const CreateCharacterRoute = () => {
  const isCreate = useReduxSelector(
    (redux) => redux.player.createCharacter?.isCreate
  );

  if (isCreate) {
    return <Redirect to={ROUTES.home} />;
  }

  return <CreateCharacter />;
};
