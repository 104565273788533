import styled from 'styled-components';

import { spacing, MEDIA } from 'themes';

export const TravelShopStyles = {
  Wrapper: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px ${spacing(2)};

    ${MEDIA.LAPTOP} {
      gap: 0px ${spacing(3)};
    }
  `,
};
