import { encodeEmail } from 'services/utils';
import { realtimeDatabase } from '../../firebase';
import { ISelfRefParams, TRealtimeDBValue } from './realtime.api.typing';

export const gameRef = (gameCode: string) =>
  realtimeDatabase.ref(`games/${gameCode}`);

export const playersRef = (gameCode: string) =>
  gameRef(gameCode).child('players');

export const sectorsRef = (gameCode: string) =>
  gameRef(gameCode).child('sectors');

export const sectorRef = (gameCode: string, sector: string) =>
  sectorsRef(gameCode).child(sector);

export const sectorLimitRef = (gameCode: string, sector: string) =>
  sectorRef(gameCode, sector).child('limit');

export const sectorSalaryRef = (gameCode: string, sector: string) =>
  sectorRef(gameCode, sector).child('salary');

export const sectorPlayersRef = (gameCode: string, sector: string) =>
  sectorRef(gameCode, sector).child('players');

export const firedPlayersRef = (gameCode: string) =>
  gameRef(gameCode).child('firedPlayers');

export const sectorPlayersSelfRef = (params: ISelfRefParams) => {
  const { gameCode, sector, email } = params;
  return sectorPlayersRef(gameCode, sector).child(encodeEmail(email));
};

export const firedPlayersSelfRef = (gameCode: string, email: string) =>
  firedPlayersRef(gameCode).child(encodeEmail(email));

export const updatePlayers = (gameCode: string, value: TRealtimeDBValue) =>
  playersRef(gameCode).update(value);

export const removeFiredPlayer = (gameCode: string, email: string) =>
  firedPlayersSelfRef(gameCode, email).remove();

export const getSectorSalaryMultiplier = async (
  gameCode: string,
  sector: string
) => {
  const snapshot = await sectorSalaryRef(gameCode, sector).get();

  const val = snapshot.val() as number | undefined;

  return val;
};
