import { useReduxSelector } from 'services/hooks';
import { CompleteWork } from '../complete-work';
import { NotCompleteWork } from '../not-complete-work';

import { useEndWork } from './end-work.state';
import { IEndWorkProps } from './end-work.typing';

export const EndWork = (props: IEndWorkProps) => {
  const { rating } = props;

  const { onCompleteWork } = useEndWork(props);

  const passWorkPercent = useReduxSelector(
    (redux) => redux.app.PASS_WORK_PERCENT
  );

  if (rating < passWorkPercent) {
    return <NotCompleteWork onCompleteWork={onCompleteWork} />;
  }

  return <CompleteWork onCompleteWork={onCompleteWork} />;
};
