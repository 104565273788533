import styled from 'styled-components';

import { createPrice } from 'services/utils';
import { Theme } from 'themes';

import { Loader } from './loader';
import { IInformationModalParameters, InfoModal } from './modal';
import { Box, BoxItem } from './ui';

export interface IJobDescriptionProps extends IInformationModalParameters {
  job: string;
  idButton?: string;
  description?: string;
  salary?: number;
  isLoading?: boolean;
}

const MarginParagraph = styled.p`
  margin-top: 16px !important;
`;

export const JobDescription = (props: IJobDescriptionProps) => {
  const {
    description,
    className,
    buttonText,
    isClose,
    isNotCenter,
    itemGap,
    isLoading = false,
    salary = 0,
    job,
    idButton,
    onConfirm,
    onCloseModal,
  } = props;

  const Content = (
    <Box $space="16px">
      <BoxItem>
        <MarginParagraph>
          {description || "Couldn't find the description"}
        </MarginParagraph>
      </BoxItem>

      <div>
        <Theme.Paragraph>Average Salary: {createPrice(salary)}</Theme.Paragraph>
      </div>
    </Box>
  );

  const RenderContent = isLoading ? <Loader /> : Content;

  return (
    <InfoModal
      idButton={idButton}
      className={className}
      isNotCenter={isNotCenter}
      isClose={isClose}
      itemGap={itemGap}
      buttonText={buttonText}
      onCloseModal={onCloseModal}
      onConfirm={onConfirm}
      title={<Theme.Paragraph $fontFamily="bold">{job}</Theme.Paragraph>}
    >
      {RenderContent}
    </InfoModal>
  );
};
