import { useMod } from 'services/hooks';
import { getModComponent } from 'services/utils';

export const ModsStyles = () => {
  const FinancialFonts = getModComponent(
    useMod('financial')?.theme.FinancialFonts
  );
  const FinancialGLobalStyles = getModComponent(
    useMod('financial')?.theme.FinancialGlobalStyles
  );

  return (
    <>
      <FinancialFonts />
      <FinancialGLobalStyles />
    </>
  );
};
