import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { Image, TImageNames } from 'components/image';
import { moveToRouteCallback } from 'services/utils';

import { BotBarStyles as Styles } from './bot-bar-item.styles';

interface IProps {
  path: string;
  additionalPaths?: string[];
  id?: string;
}

type TPropsWithImage = IProps & {
  image: TImageNames;
  Icon?: ReactNode;
};

type TPropsWithIconElement = IProps & {
  Icon: ReactNode;
  image?: TImageNames;
};

type TProps = TPropsWithImage | TPropsWithIconElement;

const checkPath = (path: string, source: string[]) =>
  source.some((sourcePath) => path.startsWith(sourcePath));

export const BotBarItem = (props: TProps) => {
  const { image, path, additionalPaths, Icon, id } = props;

  const { pathname } = useLocation();

  const RenderImage = Icon ? <div>{Icon}</div> : <Image type={image || ''} />;

  const onClick = moveToRouteCallback(path);

  const isActive = additionalPaths
    ? checkPath(pathname, [...additionalPaths, path])
    : pathname.includes(path);

  return (
    <Styles.Root id={id}>
      <Styles.LeftRectangle $isActive={isActive} />

      <Styles.BorderWrapper $isActive={isActive} onClick={onClick}>
        <Styles.ImageWrapper $isActive={isActive}>
          {RenderImage}
        </Styles.ImageWrapper>
      </Styles.BorderWrapper>

      <Styles.RightRectangle $isActive={isActive} />
    </Styles.Root>
  );
};
