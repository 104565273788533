import { useCallback } from 'react';

import { useReduxDispatch, useReduxSelector } from 'services/hooks';
import { RewardsActions } from 'services/rewards';
import { arrayEqual, recordValue } from 'services/utils';

import { IGetReward } from '../reward-modal.typing';

export const useRewardModalHOC = () => {
  const rewardsOptions = useReduxSelector((redux) => redux.rewards.options);
  const userPresentedRewards = useReduxSelector(
    (redux) => redux.rewards.userPresentedRewards
  );
  const userRewardsTypes = useReduxSelector(
    (redux) => redux.player.rewards,
    arrayEqual
  );

  const { callAction } = useReduxDispatch();

  const setUserPresentedRewards = callAction(
    RewardsActions.setUserPresentedRewards
  );

  const decreaseUserPresentedReward = () => {
    if (userPresentedRewards >= 1) {
      setUserPresentedRewards(userPresentedRewards - 1);
    }
  };

  const getShownReward = useCallback(() => {
    if (!rewardsOptions) {
      return;
    }

    const cast = userRewardsTypes || [];
    const lastUserReward = cast[cast.length - userPresentedRewards];

    if (!lastUserReward) {
      return;
    }

    const rewardOptions = recordValue(rewardsOptions, lastUserReward);

    if (!rewardOptions) {
      return;
    }

    const returnObject: IGetReward = {
      rewardName: lastUserReward,
      rewardOptions: rewardOptions,
    };

    const nextRewardName = rewardOptions.nextReward;

    if (nextRewardName) {
      returnObject.nextRewardName = nextRewardName;
      const nextRewardOptions = recordValue(rewardsOptions, nextRewardName);

      if (nextRewardOptions) {
        returnObject.nextRewardOptions = nextRewardOptions;
      }
    }

    return returnObject;
  }, [rewardsOptions, userPresentedRewards, userRewardsTypes]);

  return { decreaseUserPresentedReward, getShownReward };
};
