import { CareerCenterButton } from 'components/career-center-button';
import { CheckboxList } from 'components/checkbox';
import { Loader } from 'components/loader';
import { Theme } from 'themes';

import { FIND_JOB_STRINGS } from '../../find-job.strings';

import { ConfirmJobModal, UnavailableWork } from './components';
import { useAvailableWork } from './available-work.state';
import { AvailableWorkStyles as Styles } from './available-work.styles';
import {
  IAvailableWorkProps,
  UnavailableReason,
} from './available-work.typing';
import { createItemsFromLimitedJobs } from './available-work.utils';

const { AVAILABLE_TREE_TITLE, WARNING_TEXT } = FIND_JOB_STRINGS;

export const AvailableWork = (props: IAvailableWorkProps) => {
  const { selectedJob } = props;

  const {
    isLoading,
    jobs,
    unavailableReason,
    isModalOpen,
    isSectorLimited,
    isPositionsLimited,

    closeModal,
    handleSetJob,
    onConfirmJob,
  } = useAvailableWork(props);

  if (!isLoading && unavailableReason !== undefined) {
    return (
      <UnavailableWork
        isHaveDegree={unavailableReason !== UnavailableReason.NoDegree}
      />
    );
  }

  const ModalConditional = isModalOpen && (
    <ConfirmJobModal onClose={closeModal} onConfirm={onConfirmJob} />
  );
  const WarningTextConditional = (isSectorLimited || isPositionsLimited) && (
    <Styles.WarningText>{WARNING_TEXT}</Styles.WarningText>
  );

  const RenderContent = isLoading ? (
    <Loader />
  ) : (
    jobs && (
      <Styles.ListContainer>
        <CheckboxList
          items={createItemsFromLimitedJobs(jobs)}
          setValue={handleSetJob}
          value={selectedJob}
          $isColumn
          variant="orange"
          $itemGap="16px"
          isDisabled={isSectorLimited}
        />
      </Styles.ListContainer>
    )
  );

  const RenderCareerCentreButton = !isLoading && <CareerCenterButton />;

  return (
    <>
      <Theme.Flexbox
        $isColumn
        $justifyContent="space-between"
        $textAlign="center"
      >
        <Theme.Wrapper $itemGap={{ default: '16px', laptop: '24px' }}>
          <Theme.Title>{AVAILABLE_TREE_TITLE}</Theme.Title>

          {RenderContent}

          {WarningTextConditional}
        </Theme.Wrapper>

        {RenderCareerCentreButton}
      </Theme.Flexbox>

      {ModalConditional}
    </>
  );
};
