import { TGameVersion } from '@avid/common';

import { useReduxSelector } from 'services/hooks';
import { Grid, P } from 'components/ui';

import { GameReviewStyles as Styles } from './game-review.styles';

const getContent = (version: TGameVersion | null) => ({
  href:
    version === 'ntu'
      ? 'https://survey.ntu.edu.sg/efm/se/705E3F2501965D09'
      : 'https://www.surveymonkey.com/r/ntu-game',
  label:
    version === 'ntu'
      ? 'survey.ntu.edu.sg/efm/se/705E3F2501965D09'
      : 'www.surveymonkey.com/r/ntu-game',
});

export const GameReview = () => {
  const version = useReduxSelector((redux) => redux.app.version);

  const content = getContent(version || null);

  return (
    <Styles.Root>
      <Styles.GridWrapper rowGap="40px" justifyContent="center">
        <Grid rowGap="24px" justifyItems="center">
          <Grid rowGap="16px">
            <P $typography="RalewayBold18">Congrats on a life well-lived!</P>

            <Styles.Image type="gameReview" />
          </Grid>

          <Styles.Description>
            We hope you enjoyed the WITGRITFIT career simulation game!
          </Styles.Description>
        </Grid>

        <div>
          <P $typography="QuicksandMedium16">
            Let us know what you think here:
          </P>
          <Styles.Link target="_blank" href={content.href} rel="noreferrer">
            {content.label}
          </Styles.Link>
        </div>
      </Styles.GridWrapper>
    </Styles.Root>
  );
};
