import { useContext, useState } from 'react';

import { usePushLog, useReduxSelector } from 'services/hooks';
import { PlayerActions } from 'services/redux';

import { WorkContext } from '../../work.context';
import { WorkModal } from '../../work.typings';
import { callFunctionAction } from 'services/api';
import { useDispatch } from 'react-redux';

export const useJobPromotion = () => {
  const { nextLevels, onOpenModal } = useContext(WorkContext);

  const [selectedJob, setSelectedJob] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const pushLog = usePushLog();

  const careerUp = useReduxSelector((redux) => redux.player.work?.careerUp);

  const setWorkData = (job: string) => setSelectedJob(job);

  const confirmJob = async () => {
    if (isLoading || !selectedJob) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await callFunctionAction('@WORK/PROMOTE', {
        job: selectedJob,
      });

      dispatch(PlayerActions.merge(result.mergePlayer));
      pushLog({
        type: 'work',
        action: 'promoted',
        params: {
          position: selectedJob,
        },
      });

      onOpenModal(WorkModal.JobCongratulation);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    nextLevels,
    careerUp,
    isLoading,
    selectedJob,
    setWorkData,
    confirmJob,
    onOpenModal,
  };
};
