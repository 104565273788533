export const MORE_ACTION = <const>{
  CLEAR: '@MORE/CLEAR',
  UPDATE_FIELDS: '@MORE/UPDATE_FIELDS',
  SET_PAGE: '@MORE/SET_PAGE',
};

export enum MorePage {
  Start = 'Start',
  Tutorial = 'Tutorial',
}

export interface IMoreRedux {
  page: MorePage;
}

export type IMoreReduxKeys = keyof IMoreRedux;

export const INIT_REDUX_MORE: IMoreRedux = {
  page: MorePage.Start,
};
