import { ISkillInfo, TGameVersion } from '@avid/common';

import { connectSector } from '../versions.connect';

interface ISkillsData {
  skills: ISkillInfo[];
}

export class SectorEducationAPI {
  public static async fetchSkills(version: TGameVersion, sector: string) {
    const data = (
      await connectSector(version, sector)
        .collection('education')
        .doc('skills')
        .get()
    ).data() as ISkillsData | undefined;

    return data?.skills;
  }
}
