import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { callFunction } from 'services/api';
import { arrayEqual, objectEqual } from 'services/utils';
import { PlayerActions } from 'services/redux';
import { usePushLog, useReduxSelector, useUpdateState } from 'services/hooks';
import { CLIENT_ID } from 'constants/settings';

import {
  checkOptional,
  getBonusAndSkills,
  getForwardSide,
  moveToExpenses,
  TCardStep,
} from './life-card.utils';
import { LIFE_CARD_ERRORS } from './life-card.const';

interface IState {
  isLoading: boolean;
  step: TCardStep;
  index: number;
  cards?: ILifeCard[];
}

const INIT_STATE: IState = {
  isLoading: true,
  index: 0,
  step: 'init',
};

export const useLifeCard = () => {
  const version = useReduxSelector((redux) => redux.app.version);
  const gameSkills = useReduxSelector((redux) => redux.app.skills, arrayEqual);
  const maxAbilityLevel = useReduxSelector(
    (redux) => redux.app.MAX_SKILL_LEVEL
  );
  const characterAbilities = useReduxSelector(
    (redux) => redux.player.skills || [],
    arrayEqual
  );
  const characterSmart = useReduxSelector(
    (redux) => redux.player.createCharacter.smart,
    objectEqual
  );

  const { state, updateState } = useUpdateState(INIT_STATE);

  const dispatch = useDispatch();

  const pushLog = usePushLog();

  const isFlipped = state.step !== 'init';

  const card = useMemo(
    () => state.cards?.[state.index],
    [state.cards, state.index]
  );

  const isOptional = card ? checkOptional(card) : false;
  const forwardSide = getForwardSide(isOptional, state.step);

  const { bonus, incrementedSkills = [] } = useMemo(() => {
    if (!card || !version) {
      return { bonus: [], incrementedSkills: [] };
    }

    return getBonusAndSkills({
      card,
      characterAbilities,
      characterSmart,
      gameSkills,
      maxAbilityLevel,
      version,
    });
  }, [
    card,
    characterAbilities,
    characterSmart,
    gameSkills,
    maxAbilityLevel,
    version,
  ]);

  const moveNext = useCallback(() => {
    if (state.index + 1 >= (state.cards?.length ?? 0)) {
      moveToExpenses();
      return;
    }

    updateState((prev) => ({ ...prev, index: prev.index + 1, step: 'init' }));
  }, [state.cards?.length, state.index, updateState]);

  const onAccept = useCallback(async () => {
    if (!card) {
      return;
    }

    // 1. Set accept step
    updateState({ step: 'accept' });

    // 2. Give bonuses
    try {
      const result = await callFunction('applyLifeCard')({
        clientId: CLIENT_ID,
        title: card?.title,
      });

      dispatch(PlayerActions.merge(result.mergePlayer));
    } catch (error) {
      console.error(error);

      if ((error as any).message === LIFE_CARD_ERRORS.LIMIT_REACHED) {
        return moveToExpenses();
      }
    }

    // 3. Push log
    const { AP, bank, lifestyle, skills, title, choose } = card;

    return pushLog({
      type: 'lifeCard',
      action: 'complete',
      params: {
        title,
        choose,
        money: bank ? +bank : undefined,
        energy: AP ? +AP : undefined,
        lifestyle,
        skills,
      },
    });
  }, [card, dispatch, pushLog, updateState]);

  const onDecline = useCallback(() => {
    updateState({ step: 'decline' });

    pushLog({
      type: 'lifeCard',
      action: 'disagree',
      params: { title: card?.title || '' },
    });
  }, [card?.title, pushLog, updateState]);

  // Fetch cards and set to state
  useEffect(() => {
    const request = async () => {
      try {
        const lifeCards = await callFunction('getLifeCards')({
          clientId: CLIENT_ID,
        });

        const cards = lifeCards.lifeCards;

        if (!cards.length) {
          updateState({ isLoading: false });

          moveToExpenses();
          return;
        }

        updateState({ isLoading: false, cards });
      } catch (error) {
        updateState({ isLoading: false, index: undefined });
      }
    };

    request();
  }, [updateState]);

  return {
    ...state,
    card,
    isOptional,
    isFlipped,
    forwardSide,
    bonus,
    incrementedSkills,
    moveNext,
    onAccept,
    onDecline,
  };
};
