import styled from 'styled-components';

import { FONT_FAMILIES, FONT_SIZES } from 'themes';

interface IProps {
  name: string;
  className?: string;
}

const Root = styled.span`
  display: block;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${FONT_FAMILIES.bold};
  font-size: ${FONT_SIZES.regular};
`;

export const CharacterName = (props: IProps) => (
  <Root title={props.name} className={props.className}>
    {props.name}
  </Root>
);
