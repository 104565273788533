import styled, { css } from 'styled-components';

import { Text } from 'components/ui';
import { COLORS } from 'themes';

const plusCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${COLORS.purpleBlue};
`;

export const PerformanceItemBonusStyles = {
  Root: styled.div`
    display: flex;
    justify-content: center;
  `,
  ItemWrapper: styled.div`
    display: flex;
    text-align: left;
  `,
  ItemText: styled(Text)`
    margin-left: 3px;
    flex: 0;
  `,
  PlusWrapper: styled.div`
    position: relative;
    width: 13px;
    height: 13px;
    flex-shrink: 0;
  `,
  PlusVertical: styled.div`
    ${plusCss};
    height: 100%;
    width: 2px;
  `,
  PlusHorizontal: styled.div`
    ${plusCss};
    width: 100%;
    height: 2px;
  `,
  CenterWrapper: styled.div`
    flex: 0;
  `,
};
