import { TGameVersion, IAbility, TWorkExperience } from '@avid/common';

import { FindJobPages } from '../../find-job.typing';

export enum UnavailableReason {
  NoDegree,
  NoSkills,
}

export interface IAvailableWorkProps {
  sector: string;
  onOpenPage: (page: FindJobPages) => void;
  onSetJob: (job?: string) => void;
  selectedJob?: string;
}

export interface IAvailableWorkState {
  isLoading: boolean;
  isConfirmLoading: boolean;
  isModalOpen: boolean;
  jobs?: [string, boolean][];
  unavailableReason?: UnavailableReason;
}

export interface ICheckEnableWorkParams {
  version: TGameVersion;
  sector: string;
  workExperience?: TWorkExperience;
  abilities?: IAbility[];
}

export interface IGetEnableWorkFromCareerTreeParams
  extends ICheckEnableWorkParams {
  treeBranch: string[];
}
