import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IQuestion } from '@avid/common';

import { MainActions } from 'screens/main';

import { useUpdateState } from 'services/hooks';

export enum QUESTION_MODE {
  NOT_ANSWERED = 'NOT',
  CORRECT = 'CORRECT',
  WRONG = 'WRONG',
  ANSWERED = 'ANSWERED',
}

interface ITestPageStateData {
  currentQuestNumber: number;
  answer?: string;
}

const initState: ITestPageStateData = {
  currentQuestNumber: 0,
};

export interface ITestStateParameter {
  questions: IQuestion[];
  increaseRating(percent: number): void;
  onEndTest(): void;
  isShowRightAnswerOnError?: boolean;
}

export const useTest = (params: ITestStateParameter) => {
  const { increaseRating, onEndTest, questions, isShowRightAnswerOnError } =
    params;

  const { state, updateState } = useUpdateState(initState);

  const dispatch = useDispatch();

  const isClosedRef = useRef(false);

  const totalQuestionNumber = questions.length;
  const { answers, rightAnswer } = questions[state.currentQuestNumber];

  useEffect(() => {
    dispatch(MainActions.openTest());

    return () => {
      if (!isClosedRef.current) {
        dispatch(MainActions.closeTest());
      }
    };
  }, [dispatch]);

  const onAnswerQuestion = (variant: string) => () => {
    updateState({ answer: variant });

    if (variant === answers[rightAnswer]) {
      increaseRating(100 / totalQuestionNumber);
    }
  };

  const onNextQuestion = () => {
    if (state.currentQuestNumber === totalQuestionNumber - 1) {
      dispatch(MainActions.closeTest());
      isClosedRef.current = true;

      onEndTest();

      return;
    }

    updateState({
      currentQuestNumber: state.currentQuestNumber + 1,
      answer: undefined,
    });
  };

  const getMode = (text: string): QUESTION_MODE => {
    const wrongAnswerMode =
      text === state.answer ? QUESTION_MODE.WRONG : QUESTION_MODE.ANSWERED;

    const selectAnswer =
      text === answers[rightAnswer] &&
      (text === state.answer || !!isShowRightAnswerOnError)
        ? QUESTION_MODE.CORRECT
        : wrongAnswerMode;

    return state.answer ? selectAnswer : QUESTION_MODE.NOT_ANSWERED;
  };

  return {
    ...state,
    onAnswerQuestion,
    onNextQuestion,
    getMode,
  };
};
