import { IRewardBonus, TRewardBonusTypes } from './rewards.typing';

export const findBonus = (bonuses: IRewardBonus[]) => (
  reward: TRewardBonusTypes
) => bonuses.find((bonus) => bonus.valueType === reward);

export const defaultRewardBonusesObject = (bonuses: IRewardBonus[]) => {
  const searchBonus = findBonus(bonuses);
  return {
    money: searchBonus('money')?.bonus,
    satisfaction: searchBonus('mood')?.bonus,
    energy: searchBonus('power')?.bonus,
  };
};
