import { TImageNames } from 'components/image';
import { ImageBox } from 'components/image-box';
import { Title } from '../title';

import { combineWithDot } from 'services/utils';
import { useStatItemClone } from './stat-item-clone.state';

import { Wrapper } from './stat-item-clone.styles';

interface IStatItemCloneProps {
  value: number;
  image: TImageNames;
  onAnimationEnd: () => void;
}

export const StatItemClone = (props: IStatItemCloneProps) => {
  const { value, image, onAnimationEnd } = props;

  const { ref } = useStatItemClone(onAnimationEnd);

  return (
    <Wrapper ref={ref}>
      <ImageBox
        sizeImage="12px"
        elementTitle={
          <Title
            value={(value >= 0 ? '+ ' : '- ') + combineWithDot(Math.abs(value))}
          />
        }
        titlePosition="right"
        imageName={image}
      />
    </Wrapper>
  );
};
