import { SmartRadar } from 'components/smart-radar';

import { SmartsEditorPopup } from './smarts-editor-popup';
import { ISmartsEditorProps, useSmartsEditor } from './smarts-editor.state';

export const SmartsEditor = (props: ISmartsEditorProps) => {
  const { characterSmart } = props;

  const {
    selectedSmart,
    onOpenModal,
    onClosePopup,
    onDecrease,
    onIncrease,
  } = useSmartsEditor(props);

  const RenderPopup = selectedSmart && (
    <SmartsEditorPopup
      characterSmart={characterSmart}
      selectedSmart={selectedSmart}
      onClose={onClosePopup}
      onDecrease={onDecrease}
      onIncrease={onIncrease}
    />
  );

  return (
    <>
      <SmartRadar characterSmart={characterSmart} openModal={onOpenModal} />

      {RenderPopup}
    </>
  );
};
