import styled from 'styled-components';

import { ButtonOld, buttonDisabledStyles } from 'components/button';
import { CharacterName } from 'components/character-name';
import { COLORS, FONT_FAMILIES, MEDIA, Theme } from 'themes';

export const TopBarStyles = {
  Root: styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    ${MEDIA.LAPTOP} {
      justify-content: space-evenly;
    }
  `,

  InfoWrapper: styled(Theme.Wrapper)`
    margin-left: 16px;
  `,

  CharacterIconWrapper: styled.div`
    width: 96px;
    height: 96px;

    ${MEDIA.LAPTOP} {
      width: 202px;
      height: 202px;
    }
  `,

  CharacterName: styled(CharacterName)`
    margin-left: 12px;

    ${MEDIA.LAPTOP} {
      margin-left: 30px;
    }
  `,

  IconNameWrapper: styled.div`
    display: flex;
    align-items: center;
  `,

  Button: styled(ButtonOld)<{ isWork: boolean; isHidden?: boolean }>`
    margin: 0;
    background-color: ${COLORS.darkYellow};
    font-family: ${FONT_FAMILIES.bold};
    width: 103px;
    height: 56px;
    padding: 4px;
    ${({ isWork }) => !isWork && buttonDisabledStyles.default};
    ${({ isHidden }) => isHidden && 'visibility: hidden;'}

    ${MEDIA.LAPTOP} {
      margin-left: 36px;
    }
  `,
};
