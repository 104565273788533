import { Text } from 'components/ui';
import { SelectorList, SelectorListItem } from 'components/selector-list';
import { useReduxSelector } from 'services/hooks';

import { AnchorTitle } from '../../../../components/create-character/create-character-layout';

interface IItemProps {
  title: string;
  description: string;
}

interface IProps {
  selectedAnchors: string[];
  onSelectAnchor: (value: string) => void;
}

const AnchorItem = (props: IItemProps) => {
  const { title, description } = props;

  return (
    <SelectorListItem value={title}>
      <AnchorTitle>{title}</AnchorTitle>
      <Text>{description}</Text>
    </SelectorListItem>
  );
};

export const AnchorsSelector = (props: IProps) => {
  const { onSelectAnchor, selectedAnchors } = props;

  const appAnchors = useReduxSelector((redux) => redux.app.anchors);

  const RenderItems = appAnchors.map((anchor) => (
    <AnchorItem
      key={anchor.title}
      title={anchor.title}
      description={anchor.description}
    />
  ));

  return (
    <SelectorList selectedValues={selectedAnchors} onSelect={onSelectAnchor}>
      {RenderItems}
    </SelectorList>
  );
};
