import { IPrice, IPlayerBonus } from '@avid/common';

export const getStatsFromPriceAndBonus = (
  price: IPrice,
  bonus: IPlayerBonus
) => {
  const stats = { ...bonus };

  if (price.energy) {
    stats.energy = -price.energy;
  }

  if (price.money) {
    stats.money = -price.money;
  }

  return stats;
};
