import { useCallback } from 'react';
import { TPlayerLog } from '@avid/common';

import { pushLogThunk } from '../redux';
import { useThunkDispatch } from './redux';

export const usePushLog = () => {
  const dispatch = useThunkDispatch();

  const pushLog = useCallback(
    (playerLog: TPlayerLog) => dispatch(pushLogThunk(playerLog)),
    [dispatch]
  );

  return pushLog;
};
