import { PossessionLevel } from '@avid/common';

import { TColorNames } from 'themes';

export enum ShopPages {
  Sector = 'Sector',
  BuyTravel = 'BuyTravel',
  BuyItem = 'BuyItem',
  Icon = 'Icon',
}

export enum ShopModals {
  BuyPossession = 'BuyPossession',
}

export interface IShopRedux {
  sector: string;
  country: IItem;
  page: ShopPages;
  price: IPrice;

  level?: PossessionLevel;
  modal?: ShopModals;
}

export interface IColorPalette {
  border: TColorNames;
  background: TColorNames;
  selectBackground: TColorNames;
}
