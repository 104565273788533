import { GameReview } from 'screens/game-review';
import { useMod, useReduxSelector } from 'services/hooks';
import { getModComponent } from 'services/utils';

import { GreetingLogo } from '../greeting';

import { GameInProgress, GamePaused } from './components';

import { useGameCoreLoader } from './game-core.state';

const GameCore = () => {
  const gameStatus = useReduxSelector((redux) => redux.game.status);
  const isTested = useReduxSelector((redux) => redux.main.isTested);
  const version = useReduxSelector((redux) => redux.app.version);

  if (gameStatus === 'finished') {
    if (version === 'ntu') {
      return <GameReview />;
    }

    return <GameInProgress />;
  }

  if (!isTested || gameStatus === 'in-progress') {
    return <GameInProgress />;
  }

  return <GamePaused />;
};

export const GameCoreLoader = () => {
  const { isLoaded } = useGameCoreLoader();

  const GaugesApplierMC = getModComponent(
    useMod('gauges')?.components.MissedGaugesDeductsApplier
  );
  const RealtimeMessagesHandlerMC = getModComponent(
    useMod('bonusCodes')?.components.RealtimeMessagesHandler
  );
  const CachePopupTipsImagesMC = getModComponent(
    useMod('popupTips')?.components.CachePopupTipsImages
  );

  const RenderCore = isLoaded ? <GameCore /> : <GreetingLogo />;

  return (
    <>
      {RenderCore}

      <GaugesApplierMC />
      <RealtimeMessagesHandlerMC />
      <CachePopupTipsImagesMC />
    </>
  );
};
