import styled, { css } from 'styled-components';

import { Theme, MEDIA } from 'themes';

export const LiveExpensesStyles = {
  Root: styled(Theme.Flexbox)`
    justify-content: space-between;

    ${MEDIA.TABLET} {
      margin: auto;

      & > * {
        margin: auto;
        padding: 24px 0;
      }
    }
  `,
  ImageContainer: styled.div`
    ${MEDIA.LAPTOP} {
      width: 35%;
      height: 35%;
    }
  `,
};

export const ImageCss = css`
  ${MEDIA.LAPTOP} {
    width: 100%;
    height: 100%;
  }
`;
