import { PossessionLevel } from '@avid/common';

import { useReduxDispatch, useReduxSelector } from 'services/hooks';

import { IItemPrice } from 'typings';

import { ShopActions } from './shop.actions';

export const useShopUtils = () => {
  const { playerEnergy, playerMoney, minimalMoney } = useReduxSelector(
    (redux) => ({
      playerMoney: redux.player.main.money,
      playerEnergy: redux.player.main.energy,
      minimalMoney: redux.app.minimalMoney,

      currentLevel: redux.shop.level,
    })
  );

  const { callAction } = useReduxDispatch();

  const moveToStartPage = callAction(ShopActions.moveToStartPage);

  const checkEnable = (sectorPrises?: Record<PossessionLevel, IPrice>) => (
    level: PossessionLevel
  ) => {
    if (!sectorPrises) {
      return false;
    }

    const price = sectorPrises[level];

    return (
      price.energy <= playerEnergy &&
      (!price.money || price.money <= playerMoney - minimalMoney)
    );
  };

  return {
    checkEnable,
    moveToStartPage,
  };
};

const convertItemPriceToPrice = (price: IItemPrice): IPrice => ({
  energy: price.energy,
  money: price.money,
});

const isItemPrice = (price: any): price is IItemPrice => !!price?.money;
export const getPrice = (price: IItemPrice | IPrice): IPrice =>
  isItemPrice(price) ? convertItemPriceToPrice(price) : price;
