import { FC, ReactElement } from 'react';

import { ButtonOld, IButtonOldProps } from 'components/button';
import { OnetimeClickButton } from 'components/onetime-click-button';
import { IModalWrapperProps, ModalWrapper } from './modal-wrapper';
import { BoxItem } from 'components/ui';

export interface IInformationModalParameters extends IModalWrapperProps {
  buttonText: string;
  onConfirm?: () => void;
}

export interface IInfoModalProps extends IInformationModalParameters {
  idButton?: string;
  title?: ReactElement;
  isMultiClickButton?: boolean;
}

const BottomButton = (
  props: IButtonOldProps & { isMultiClickButton?: boolean }
) => {
  const { isMultiClickButton, ...buttonProps } = props;
  return isMultiClickButton ? (
    <ButtonOld {...buttonProps} />
  ) : (
    <OnetimeClickButton {...buttonProps} />
  );
};

export const InfoModal: FC<IInfoModalProps> = (props) => {
  const {
    children,
    buttonText,
    title,

    onCloseModal,
    onConfirm = onCloseModal,

    isMultiClickButton,
    idButton,

    ...modalProps
  } = props;

  return (
    <ModalWrapper onCloseModal={onCloseModal} {...modalProps}>
      <BoxItem>{title}</BoxItem>
      <BoxItem>{children}</BoxItem>
      <BoxItem>
        <BottomButton
          id={idButton}
          isMultiClickButton={isMultiClickButton}
          onClick={onConfirm}
          title={buttonText}
        />
      </BoxItem>
    </ModalWrapper>
  );
};
