import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CallBackProps, STATUS } from 'react-joyride';

import { PlayerActions } from 'services/redux';
import { useReduxSelector } from 'services/hooks';
import { getTooltips } from 'services/utils';
import { callFunctionAction } from 'services/api';

export const useMainTutorial = () => {
  const version = useReduxSelector((redux) => redux.app.version);
  const isGauges = useReduxSelector((redux) =>
    Boolean(redux.app.gameMods?.gauges)
  );

  const passedRef = useRef(false);

  const dispatch = useDispatch();

  const steps = getTooltips(version || 'standard', isGauges);

  const completeTutorial = async () => {
    const result = await callFunctionAction('@TUTORIALS/COMPLETE', {
      tutorialKey: 'main',
    });

    dispatch(PlayerActions.merge(result.mergePlayer));
  };

  const handleCallback = (data: CallBackProps) => {
    const { status } = data;

    if (status === STATUS.FINISHED && !passedRef.current) {
      passedRef.current = true;
      completeTutorial();
    }
  };

  return { steps, handleCallback };
};
