import styled from 'styled-components';

import { Image } from 'components/image';
import { CHECKBOX_STYLE_THEMES } from 'components/checkbox/checkbox.constants';
import { Theme, COLORS } from 'themes';

export const ARROW_CLASS_NAME = 'career-tree-item-arrow';

export const CareerTreeItemStyles = {
  Item: styled.div`
    position: relative;

    &:last-child .${ARROW_CLASS_NAME} {
      display: none;
    }
  `,

  Information: styled(Theme.Wrapper)`
    ${CHECKBOX_STYLE_THEMES.orange.disable}
    position: relative;
    padding: 18px 30px;
    cursor: pointer;

    &:hover {
      background-color: ${COLORS.yellow};
    }
  `,

  Icon: styled(Image)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
  `,

  Arrow: styled(Image)`
    position: absolute;
    left: 50%;
  `,
};
