import { css } from 'styled-components';

import { FONT_SIZES } from './font-sizes';
import { FONT_FAMILIES } from './global-fonts';

export const typographyCss = {
  regular: css`
    font-family: ${FONT_FAMILIES.regular};
    font-size: ${FONT_SIZES.regular};
    line-height: 20px;
  `,

  titleBold: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.title};
    line-height: 40px;
  `,

  RalewayBold9: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES[9]};
    line-height: normal;
  `,
  RalewayBold10: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.little};
    line-height: normal;
  `,
  RalewayBold11: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES[11]};
    line-height: normal;
  `,
  RalewayBold12: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.px12};
    line-height: normal;
  `,
  RalewayBold13: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES[13]};
    line-height: normal;
  `,
  RalewayBold14: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.small};
    line-height: normal;
  `,
  RalewayBold16: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.regular};
    line-height: 20px;
  `,
  RalewayBold17: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES[17]};
    line-height: 20px;
  `,
  RalewayBold18: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.px18};
    line-height: normal;
  `,
  RalewayBold20: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.header};
  `,
  RalewayBold22: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES[22]};
  `,
  regularMedium: css`
    font-family: ${FONT_FAMILIES.medium};
    font-size: ${FONT_SIZES.regular};
    line-height: 20px;
  `,

  smallRegular: css`
    font-family: ${FONT_FAMILIES.regular};
    font-size: ${FONT_SIZES.small};
    line-height: 18px;
  `,
  px12Bold: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.px12};
    line-height: 15px;
  `,
  littleRegular: css`
    font-family: ${FONT_FAMILIES.regular};
    font-size: ${FONT_SIZES.little};
    line-height: 12.5px;
  `,
  littleBold: css`
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.little};
    line-height: 12.5px;
  `,
  QuicksandRegular12: css`
    font-family: ${FONT_FAMILIES.regular};
    font-size: ${FONT_SIZES.px12};
    line-height: normal;
  `,
  QuicksandRegular18: css`
    font-family: ${FONT_FAMILIES.regular};
    font-size: ${FONT_SIZES.px18};
    line-height: normal;
  `,
  QuicksandMedium9: css`
    font-family: ${FONT_FAMILIES.medium};
    font-size: ${FONT_SIZES[9]};
    line-height: normal;
  `,
  QuicksandMedium10: css`
    font-family: ${FONT_FAMILIES.medium};
    font-size: ${FONT_SIZES.little};
    line-height: normal;
  `,
  QuicksandMedium12: css`
    font-family: ${FONT_FAMILIES.medium};
    font-size: ${FONT_SIZES.px12};
    line-height: normal;
  `,
  QuicksandMedium14: css`
    font-family: ${FONT_FAMILIES.medium};
    font-size: ${FONT_SIZES.small};
  `,
  QuicksandRegular14: css`
    font-family: ${FONT_FAMILIES.regular};
    font-size: ${FONT_SIZES.small};
  `,
  QuicksandMedium16: css`
    font-family: ${FONT_FAMILIES.medium};
    font-size: ${FONT_SIZES.regular};
  `,
};
