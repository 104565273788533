import styled from 'styled-components';

import { BigAvatar } from 'components/big-avatar';
import { PriceInformation } from 'components/price-information';
import { COLORS, MEDIA, Theme } from 'themes';

export const IconShopStyles = {
  Wrapper: styled.div`
    position: relative;

    ${MEDIA.LAPTOP} {
      width: 760px;
      margin: 0 auto;
    }
  `,
  ContentWrapper: styled.div`
    margin-bottom: 150px;
  `,
  Title: styled(Theme.Title)`
    text-align: center;
  `,
  SelectedAvatar: styled(BigAvatar)`
    margin-top: 24px;
  `,
  SelectorWrapper: styled.div`
    margin-top: 16px;
  `,
  ButtonsWrapper: styled.div`
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    margin-left: -20px;
    padding: 12px 0;
    padding-left: 20px;
    background-color: ${COLORS.backgroundWhite};

    & > *:not(:first-child) {
      margin-top: 12px;
    }
  `,
  Price: styled(PriceInformation)<{ $isShow?: boolean }>`
    margin-top: 12px;
    ${({ $isShow }) => !$isShow && 'visibility: hidden'};
  `,
};
