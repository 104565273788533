import { AppTutorialController } from 'components/tutorial';
import { Step } from 'react-joyride';
import { useShowCareerCentreTutorial } from 'services/hooks';

const STEPS: Step[] = [
  {
    target: '#career_centre-slider',
    content: 'Choose any job.',
    disableBeacon: true,
  },
];

export const SliderTutorialComponent = () => {
  return <AppTutorialController steps={STEPS} labelClose="Got it!" />;
};

export const SliderTutorial = () => {
  const isShow = useShowCareerCentreTutorial();

  if (!isShow) {
    return null;
  }

  return <SliderTutorialComponent />;
};
