import styled from 'styled-components';

import { Image } from 'components/image';
import { COLORS, FONT_SIZES } from 'themes';

export const BadgesStyles = {
  Wrapper: styled.div<{ paddingBottom?: string }>`
    display: flex;
    max-height: 350px;
    width: 100%;
    height: auto;
    margin-top: 12px;
    padding: 12px;
    padding-bottom: ${({ paddingBottom }) => paddingBottom};
    background-color: ${COLORS.white};
    box-shadow: 2px 6px 16px rgb(45 45 55 / 10%);
    border-radius: 10px;
    overflow: hidden;
  `,
  RewardImageContainer: styled.div<{ paddingLeft?: string }>`
    position: relative;
    width: 72px;
    padding-left: ${({ paddingLeft }) => paddingLeft};
  `,
  MainContentContainer: styled.div<{ marginLeft?: string }>`
    display: flex;
    flex-direction: column;
    margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '12px')};
  `,
  SectorName: styled.span<{ color: string }>`
    font-size: ${FONT_SIZES.header};
    font-weight: 700;
    color: ${(props) => props.color || COLORS.black};
  `,
  RewardType: styled.span<{ color: string }>`
    font-size: ${FONT_SIZES.regular};
    font-weight: 700;
    color: ${(props) => props.color || COLORS.black};
  `,
  DescriptionWrapper: styled.div`
    display: flex;
    margin-top: 16px;
  `,
  AchievementImage: styled(Image)`
    align-self: flex-start;
    margin: 2px 8px 0 0;
    width: 11px;
    min-width: 11px;
  `,
  Description: styled.p<{ isActive: boolean }>`
    font-size: ${FONT_SIZES.small};
    color: ${({ isActive }) => !isActive && COLORS.grey};
  `,
  QuestionMark: styled.span`
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    font-size: ${FONT_SIZES.px36};
    color: ${COLORS.white};
    font-weight: 500;
  `,
};
