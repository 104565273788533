import { Step } from 'react-joyride';

import { AppTutorialController } from 'components/tutorial';
import { useShowCourseTutorial } from 'services/hooks';

import { useChooseSkillsTutorial } from './choose-skills-tutorial.state';

const STEPS: Step[] = [
  {
    target: '#course-choose_skills',
    content: 'Choose any 1 skill and click "Continue".',
    disableBeacon: true,
  },
];

export const ChooseSkillsTutorialComponent = () => {
  const { handleCallback } = useChooseSkillsTutorial();

  return (
    <AppTutorialController
      steps={STEPS}
      labelClose="Got it!"
      callback={handleCallback}
    />
  );
};

export const ChooseSkillsTutorial = () => {
  const isShow = useShowCourseTutorial();

  if (!isShow) {
    return null;
  }

  return <ChooseSkillsTutorialComponent />;
};
