import styled from 'styled-components';

import { P } from 'components/ui';
import { COLORS, Css, typographyCss } from 'themes';

export const ConnectGameStyles = {
  Form: styled.form`
    ${Css.Loginwrapper}
  `,
  FieldExceedWrapper: styled.div`
    width: 100%;
    display: grid;
    justify-items: center;
  `,
  ExceedP: styled(P)<{ $isShow: boolean }>`
    margin-top: 16px;
    color: ${COLORS.red};
    ${typographyCss.regularMedium};

    visibility: ${({ $isShow }) => ($isShow ? 'visible' : 'hidden')};
  `,
};
