import { EducationProgressList } from 'components/education-progress-list';
import { SkillList } from 'components/skills';
import { CareerProgressList } from 'components/career-progress-list';
import { WhiteShadowWrapper } from 'components/ui';
import { useMod, useReduxSelector } from 'services/hooks';
import { arrayEqual, getModComponent } from 'services/utils';
import { spacing, Theme } from 'themes';

import { RewardsCollection } from './components';

export const Achievement = () => {
  const abilities = useReduxSelector(
    (redux) => redux.player.skills,
    arrayEqual
  );

  const GoalsAchiementMC = getModComponent(
    useMod('lifeGoals')?.components.GoalsAchievement
  );

  return (
    <Theme.Wrapper $itemGap={{ default: spacing(2) }}>
      <GoalsAchiementMC />

      <RewardsCollection />

      <CareerProgressList />

      <WhiteShadowWrapper>
        <SkillList abilities={abilities} align="left" isDisplayEmptySection />
      </WhiteShadowWrapper>

      <EducationProgressList />
    </Theme.Wrapper>
  );
};
