export const DEFAULT_SPACE = 8;

export const spacing = (scale: number) => `${DEFAULT_SPACE * scale}px`;

export const SCALE = {
  default: 1, // 8
  double: 2, // 16
  triple: 3, // 24
  wide: 60, // 480
} as const;
