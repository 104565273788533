import styled from 'styled-components';

import { COLORS, FONT_FAMILIES, FONT_SIZES } from 'themes';

export const RankingItemStyles = {
  Root: styled.li`
    &:not(:first-child) {
      margin-top: 44px;
    }
  `,
  Title: styled.p`
    margin-left: 16px;
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.px18};
  `,
  FirstItem: styled.div`
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    padding: 8px 18px;
    background-color: ${COLORS.backgroundWhite};
    border-radius: 2px;
  `,
  Item: styled.div`
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    padding: 3px 18px;
  `,
  Value: styled.dd`
    font-family: ${FONT_FAMILIES.medium};
  `,
};
