import { useReduxSelector } from 'services/hooks';
import { checkNtuHighestLevel, objectEqual } from 'services/utils';

export interface IUsePassProps {
  level: string;
}

export const usePass = (props: IUsePassProps) => {
  const { level } = props;

  const version = useReduxSelector((redux) => redux.app.version);
  const educationLevels = useReduxSelector(
    (redux) => redux.app.educationLevels,
    objectEqual
  );

  const isPhdHard = checkNtuHighestLevel({ educationLevels, level, version });

  return {
    isPhdHard,
  };
};
