import { ITravel } from '@avid/common';
import styled from 'styled-components';

import { ExpandedImage, TImageNames } from 'components/image';
import { Theme } from 'themes';

import { PossessionsRowBase } from './possessions-row-base';

interface IProps {
  title: string;
  travels: ITravel[];
}

const CountriesWrapper = styled.p`
  white-space: nowrap;
`;

export const TravelsRow = (props: IProps) => {
  const { title, travels } = props;

  if (!travels.length) {
    return null;
  }

  const RenderIcons = travels.map((travel, index) => (
    <ExpandedImage key={index} type={travel.image as TImageNames} />
  ));
  const RenderCountries = travels.map((travel, index) => {
    const comma = index === travels.length - 1 ? '' : ', ';

    return (
      <Theme.Text key={index}>
        {travel.country}
        {comma}
      </Theme.Text>
    );
  });

  return (
    <PossessionsRowBase title={`${travels.length} ${title}`}>
      <Theme.Wrapper $itemGap={{ default: '8px' }}>
        <Theme.Flexbox $itemGap="8px">{RenderIcons}</Theme.Flexbox>

        <CountriesWrapper>{RenderCountries}</CountriesWrapper>
      </Theme.Wrapper>
    </PossessionsRowBase>
  );
};
