import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { COLORS } from 'themes';

import { Grid } from './grid';

interface IBarProps {
  $barColor?: string;
  $barRadius?: string;
  $barWidth?: string;
  $barHeight?: string;
}

interface IProps extends IBarProps, HTMLAttributes<HTMLDivElement> {
  rowGap?: string;
}

const Styles = {
  Root: styled(Grid)`
    cursor: pointer;
  `,
  Bar: styled.div<IBarProps>`
    ${(props) => css`
      width: ${props.$barWidth || '20px'};
      height: ${props.$barHeight || '3px'};
      background-color: ${props.$barColor || COLORS.purple};
      border-radius: ${props.$barRadius || '25px'};
    `}
  `,
};

export const BurgerButton = (props: IProps) => {
  const {
    rowGap = '5px',
    $barColor,
    $barHeight,
    $barRadius,
    $barWidth,
    ...htmlProps
  } = props;

  const barProps = { $barColor, $barHeight, $barRadius, $barWidth };

  return (
    <Styles.Root rowGap={rowGap} {...htmlProps}>
      <Styles.Bar {...barProps} />
      <Styles.Bar {...barProps} />
      <Styles.Bar {...barProps} />
    </Styles.Root>
  );
};
