import { combineReducers } from 'redux';
import { IPlayer } from '@avid/common';

import { IAppRedux, TAppActions } from 'screens/app';
import { reducerApp } from 'screens/app/app.reducer';

import { IMainRedux, TMainActions } from 'screens/main';
import { reducerMain } from 'screens/main/main.reducer';
import { IMoreRedux, reducerMore, TMoreActions } from 'screens/more';
import { IShopRedux, reducerShop, TShopActions } from 'screens/shop';
import { IModsRedux } from 'typings/mods';
import { IGameRedux } from 'typings/game.typing';

import {
  reducerRewards,
  IRewardsReduxState,
  TRewardActions,
} from 'services/rewards';
import {
  gameReducer,
  modsReducer,
  playerReducer,
  TGameActions,
  TModsActions,
  TPlayerActions,
} from './reducers';

export interface IReduxStore {
  player: IPlayer;
  main: IMainRedux;
  app: IAppRedux;
  shop: IShopRedux;
  more: IMoreRedux;
  rewards: IRewardsReduxState;
  mods: IModsRedux;
  game: IGameRedux;
}

export type TActions =
  | TPlayerActions
  | TMainActions
  | TAppActions
  | TShopActions
  | TMoreActions
  | TRewardActions
  | TModsActions
  | TGameActions;

export const rootReducer = combineReducers({
  player: playerReducer,
  main: reducerMain,
  app: reducerApp,
  shop: reducerShop,
  more: reducerMore,
  rewards: reducerRewards,
  mods: modsReducer,
  game: gameReducer,
});
