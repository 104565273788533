import styled from 'styled-components';

import { InfoImageModal } from 'components/modal';
import { Theme } from 'themes';

import { useFireWork } from './fire-work.state';

const Styles = {
  MassageContainer: styled.div`
    margin: 20px 0 30px 0;
  `,
};

export const FireWork = () => {
  const { isShow, isInProgress, onFindNewJob } = useFireWork();

  if (!isShow) {
    return null;
  }

  const buttonLabel = isInProgress ? 'Find a new job' : 'Got it';

  return (
    <InfoImageModal
      buttonText={buttonLabel}
      onCloseModal={onFindNewJob}
      onConfirm={onFindNewJob}
      title={
        <Theme.Title $textAlign="center">Sorry, you lost your job!</Theme.Title>
      }
      image="notCompleteWork"
      isNotCenter
    >
      <Styles.MassageContainer>
        <Theme.Paragraph
          $fontFamily="bold"
          $color="darkRed"
          $textAlign="center"
        >
          This industry hasn’t been doing well and you have just been
          retrenched. Please find a new job.
        </Theme.Paragraph>
      </Styles.MassageContainer>
    </InfoImageModal>
  );
};
