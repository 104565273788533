import { memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  useMod,
  useReduxSelector,
  useRoundCountdownIntervalJob,
} from 'services/hooks';
import { getRoundSecondsPassed } from 'services/utils';

import { IIntervalJob } from 'typings/interval-jobs.typing';

export const MainInterval = memo(() => {
  const roundStartUnix = useReduxSelector(
    (redux) => redux.game.round.startUnix
  );

  const roundCountdownIntervalJob = useRoundCountdownIntervalJob();

  const [gaugesJob, secondsPlayedJob, expensesJob] = [
    useMod('gauges')?.hooks.useGaugesIntervalJob(),
    useMod('popupTips')?.hooks.useSecondsPlayerIntervalJob(),
    useMod('financial')?.hooks.useExpensesIntervalJob(),
  ];

  const dispatch = useDispatch();

  const jobs = useMemo(() => {
    const intervalJobs = [
      roundCountdownIntervalJob,
      gaugesJob,
      secondsPlayedJob,
      expensesJob,
    ].filter(Boolean) as IIntervalJob[];

    return intervalJobs;
  }, [roundCountdownIntervalJob, gaugesJob, secondsPlayedJob, expensesJob]);

  useEffect(() => {
    const interval = setInterval(() => {
      const secondsPassed = getRoundSecondsPassed(roundStartUnix);

      if (secondsPassed <= 0) {
        return;
      }

      jobs
        .filter((job) => secondsPassed % job.interval === 0)
        .forEach((job) => job.handler(secondsPassed));
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch, jobs, roundStartUnix]);

  return <></>;
});
