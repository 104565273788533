import { ButtonOld } from 'components/button';
import styled from 'styled-components';

import { Theme, MEDIA, spacing } from 'themes';

export const WelcomePageStyles = {
  PageRoot: styled(Theme.PageContainer)`
    padding-top: ${spacing(3)};
  `,

  PageContent: styled(Theme.Wrapper)`
    ${MEDIA.TABLET} {
      max-width: 343px;
      margin: 0 auto;
      margin-bottom: 60px;
      display: grid;
      justify-content: center;
    }

    ${MEDIA.LAPTOP} {
      margin-bottom: 0;
    }
  `,

  ButtonContainerTablet: styled.div`
    display: none;

    ${MEDIA.TABLET} {
      display: block;
      margin-top: 24px !important;
    }

    ${MEDIA.CHROMEBOOK} {
      margin-top: 48px !important;
    }
  `,

  ButtonLaptop: styled(ButtonOld)`
    display: none;

    ${MEDIA.LAPTOP} {
      display: block;
      max-height: 66px;
      margin-top: 60px !important;
      margin-bottom: 20px;
    }
  `,
};
