import styled from 'styled-components';

import { FONT_SIZES, COLORS } from 'themes';

export const RewardAppearanceStyles = {
  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `,
  ModalTitle: styled.span<{ color: string }>`
    font-size: ${FONT_SIZES.big};
    font-weight: 700;
    color: ${(props) => (props.color ? props.color : COLORS.black)};
    margin-bottom: 5px;
  `,
  ModalSubtitle: styled.span`
    font-size: ${FONT_SIZES.header};
    font-weight: 500;
    line-height: 25px;
  `,
  RewardSectorTitle: styled.span`
    font-size: ${FONT_SIZES.header};
    font-weight: 700;
    line-height: 25px;
  `,
  RewardTypeTitle: styled.span<{ color: string }>`
    font-size: ${FONT_SIZES.header};
    font-weight: 700;
    line-height: 25px;
    color: ${(props) => (props.color ? props.color : COLORS.black)};
    margin-bottom: 5px;
  `,
  RewardContainer: styled.div`
    display: flex;
    justify-content: center;
    height: 218px;
    padding-left: 22px;
  `,
  BonusesContainer: styled.div<{ $isProgressBar: boolean }>`
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin-top: ${(props) => (props.$isProgressBar ? '16px' : '28px')};
  `,
};
