import { ModsService } from 'services/mods';

import { valuesOfObject } from './object';

export const combineModsRoutes = <T extends Record<string, string>>(
  baseRoutes: T
) => {
  const paths: string[] = valuesOfObject(baseRoutes);

  const FINANCIAL_ROUTES =
    ModsService.getOne('financial')?.constants.FINANCIAL_ROUTES;

  if (FINANCIAL_ROUTES) {
    paths.push(FINANCIAL_ROUTES.ROOT);
  }

  return paths;
};
