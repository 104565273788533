import { useCallback, useContext, useEffect } from 'react';
import { ISkillInfo } from '@avid/common';

import { useReduxSelector, useRequest, useUpdateState } from 'services/hooks';
import { VersionsAPI } from 'services/api';

import { WorkContext } from 'screens/work/work.context';
import { WorkModal, WorkPage } from 'screens/work/work.typings';

import {
  checkCanFoundRelevantSkillAsync,
  updateSkillsArray,
} from './work-choose-skill.utils';
import { arrayEqual } from 'services/utils';

export interface IWorkChooseSkillState {
  isCanRelevant: boolean;
  fetchedSkills: ISkillInfo[];
}

const INIT_STATE: IWorkChooseSkillState = {
  isCanRelevant: false,
  fetchedSkills: [],
};

export const useWorkChooseSkill = () => {
  const {
    skills,
    isSelfSelect,

    onOpenModal,
    onOpenPage,
    onSetSkills,
  } = useContext(WorkContext);

  const { state, updateState } = useUpdateState(INIT_STATE);

  const { isLoading, sendRequest } = useRequest();

  const sector = useReduxSelector((redux) => redux.player.work?.sector);
  const work = useReduxSelector((redux) => redux.player.work?.work);
  const abilities = useReduxSelector(
    (redux) => redux.player.skills,
    arrayEqual
  );
  const skillsLimitToStartWork = useReduxSelector(
    (redux) => redux.app.LIMIT_SELECT_SKILL_BEFORE_START_WORK
  );
  const maxSkillLevel = useReduxSelector((redux) => redux.app.MAX_SKILL_LEVEL);
  const version = useReduxSelector((redux) => redux.app.version);

  useEffect(() => {
    if (!version || !work || !sector) {
      return;
    }

    sendRequest(async () => {
      const [isCanRelevant, fetchedSkills] = await Promise.all([
        checkCanFoundRelevantSkillAsync({
          version,
          sector,
          work,
          abilities,
          maxSkillLevel,
        }),
        VersionsAPI.sectors.education.fetchSkills(version, sector),
      ]);

      updateState({ isCanRelevant, fetchedSkills });
    });
  }, [
    version,
    sector,
    work,
    maxSkillLevel,
    sendRequest,
    updateState,
    abilities,
  ]);

  const isNeedSkillNumber =
    skills.length >= 1 && skills.length <= skillsLimitToStartWork;

  const onStartTest = useCallback(
    () =>
      isSelfSelect
        ? onOpenModal(WorkModal.StartTest)
        : onOpenPage(WorkPage.Test),
    [isSelfSelect, onOpenModal, onOpenPage]
  );

  const onContinue = useCallback(
    () =>
      isNeedSkillNumber ? onStartTest() : onOpenModal(WorkModal.ClickSkill),
    [isNeedSkillNumber, onOpenModal, onStartTest]
  );

  const openWorkStartPage = useCallback(() => {
    onOpenPage(WorkPage.Start);
  }, [onOpenPage]);

  const onChooseRelevantSkill = useCallback(
    () => onOpenModal(WorkModal.StartTestRelevant),
    [onOpenModal]
  );

  const onSetSkill = useCallback(
    (skillName: string) => {
      if (!state.fetchedSkills) {
        return;
      }

      const newSkills = updateSkillsArray({
        allSkills: state.fetchedSkills,
        skills,
        skillName,
        limit: skillsLimitToStartWork,
      });

      if (newSkills) {
        onSetSkills(newSkills);
      }
    },
    [skillsLimitToStartWork, skills, state.fetchedSkills, onSetSkills]
  );

  return {
    ...state,
    isLoading,
    version,
    isNeedSkillNumber,
    skills,

    isSelfSelect,
    LIMIT_SELECT_SKILL_BEFORE_START_WORK: skillsLimitToStartWork,

    openWorkStartPage,
    onContinue,
    onSetSkill,
    onChooseRelevantSkill,
  };
};
