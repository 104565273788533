import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  usePushLog,
  useReduxSelector,
  useScrollTopOnChangePage,
} from 'services/hooks';
import { checkNtuHighestLevel, objectEqual, recordValue } from 'services/utils';

import {
  EducationModal,
  EducationPage,
  IEducationState,
} from './education.typing';
import { INIT_EDUCATION_STATE } from './education.constants';

import { callFunctionAction } from 'services/api';
import { PlayerActions } from 'services/redux';

export const useEducation = () => {
  const [state, setState] = useState(INIT_EDUCATION_STATE);

  const educationLevels = useReduxSelector(
    (redux) => redux.app.educationLevels,
    objectEqual
  );
  const passTestRating = useReduxSelector(
    (redux) => redux.app.PASS_TEST_RATING
  );
  const version = useReduxSelector((redux) => redux.app.version);

  const pushLog = usePushLog();
  const dispatch = useDispatch();

  useScrollTopOnChangePage(state.page);

  const updateState = useCallback(
    (partialState: Partial<IEducationState>) =>
      setState((prev) => ({ ...prev, ...partialState })),
    []
  );

  const onAnswerQuestion = useCallback(
    (percent: number) =>
      setState((prev) => ({ ...prev, rating: prev.rating + percent })),
    []
  );

  const clearState = useCallback(() => setState(INIT_EDUCATION_STATE), []);

  const setSector = useCallback(
    (sector: string) => updateState({ sector }),
    [updateState]
  );

  const setModal = useCallback(
    (modal?: EducationModal) => updateState({ modal }),
    [updateState]
  );

  const setPage = useCallback(
    (page: EducationPage) => updateState({ page }),
    [updateState]
  );

  const closeModal = useCallback(() => setModal(), [setModal]);

  const setLevelPage = useCallback(
    () => setPage(EducationPage.Level),
    [setPage]
  );

  const setLevel = useCallback(
    (level: string) => updateState({ level }),
    [updateState]
  );

  const onPassTest = useCallback(async () => {
    if (!version || !state.level) {
      return;
    }

    updateState({ isLoading: true });

    try {
      const result = await callFunctionAction('@EDUCATION/COMPLETE', {
        level: state.level,
        sector: state.sector,
        rating: state.rating,
      });

      dispatch(PlayerActions.merge(result.mergePlayer));

      updateState({
        learnedAbilities: result.learntSkills,
        isLoading: false,
      });

      pushLog({
        type: 'education',
        action: 'end',
        params: {
          result: 'complete',
          level: state.level,
          sector: state.sector,
        },
      });
    } catch (error) {
      console.error(error);
      updateState({ isLoading: false });
    }
  }, [
    version,
    state.level,
    state.sector,
    state.rating,
    dispatch,
    updateState,
    pushLog,
  ]);

  const onSetSector = useCallback(
    (newSector: string) => {
      if (!state.level) {
        return;
      }

      const isUniversityLevel = recordValue(
        educationLevels,
        state.level
      )?.isUniversity;

      setModal(
        isUniversityLevel
          ? EducationModal.SelectUniversitySector
          : EducationModal.StartTest
      );

      setSector(newSector);
    },
    [educationLevels, setModal, setSector, state.level]
  );

  const onFailTest = useCallback(() => {
    setModal(EducationModal.NotPass);

    if (!state.level) {
      return;
    }

    pushLog({
      type: 'education',
      action: 'end',
      params: { result: 'fail', level: state.level, sector: state.sector },
    });
  }, [pushLog, setModal, state.level, state.sector]);

  const onEndTest = useCallback(async () => {
    if (!state.level || state.isLoading) {
      return;
    }

    let passRating = passTestRating;

    if (
      checkNtuHighestLevel({ version, educationLevels, level: state.level })
    ) {
      passRating = 100;
    }

    if (state.rating >= passRating) {
      await onPassTest();

      setModal(EducationModal.Pass);

      return;
    }

    onFailTest();
  }, [
    state.level,
    state.isLoading,
    state.rating,
    passTestRating,
    version,
    educationLevels,
    onFailTest,
    onPassTest,
    setModal,
  ]);

  const onCloseFail = useCallback(() => {
    clearState();
  }, [clearState]);

  const onClosePass = useCallback(() => {
    if (!state.isLoading) {
      clearState();
    }
  }, [clearState, state.isLoading]);

  return {
    ...state,

    setModal,
    closeModal,
    setPage,
    setLevelPage,
    onSetSector,
    onAnswerQuestion,
    setLevel,
    onEndTest,
    updateState,
    clearState,
    onCloseFail,
    onClosePass,
  };
};
