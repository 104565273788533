import { useEffect, useMemo, useState } from 'react';
import { TJobSkills } from '@avid/common';

import { getTotalSalary } from 'shared';
import { VersionsAPI, sectorSalaryRef } from '../api';

import { useUpdateState } from './react';
import { useReduxSelector } from './redux';
import { arrayEqual } from 'services/utils';

interface IProps {
  sector?: string;
  job?: string;
  companyBonus?: number;
}

interface IState {
  isLoading: boolean;
  jobSalary?: number;
  jobSkills?: TJobSkills;
  totalSalary?: number;
}

const INIT_STATE: IState = {
  isLoading: true,
};

export const useTotalSalary = (props: IProps) => {
  const { sector, job, companyBonus } = props;

  const { state, updateState } = useUpdateState(INIT_STATE);

  const [sectorMultiplier, setSectorMultiplier] = useState<number>();

  const gameCode = useReduxSelector((redux) => redux.player.authInfo.code);
  const version = useReduxSelector((redux) => redux.app.version);
  const maxSkillSalaryBonus = useReduxSelector(
    (redux) => redux.app.MAX_SKILL_SALARY_BONUS
  );
  const oneSkillSalaryBonus = useReduxSelector(
    (redux) => redux.app.ONE_SKILL_SALARY_BONUS
  );
  const characterAbilities = useReduxSelector(
    (redux) => redux.player.skills,
    arrayEqual
  );
  const performanceSalaryBonus = useReduxSelector(
    (redux) => redux.player.createCharacter.performanceBonus.salary
  );

  const totalSalary = useMemo(() => {
    if (state.jobSalary === undefined || !state.jobSkills) {
      return;
    }

    return getTotalSalary({
      jobSalary: state.jobSalary,
      jobSkills: state.jobSkills,
      sectorMultiplier,
      bonusData: {
        companyBonus,
        maxSkillSalaryBonus,
        oneSkillSalaryBonus,
        characterAbilities,
        performanceSalaryBonus,
      },
    });
  }, [
    state.jobSalary,
    state.jobSkills,
    sectorMultiplier,
    companyBonus,
    maxSkillSalaryBonus,
    oneSkillSalaryBonus,
    characterAbilities,
    performanceSalaryBonus,
  ]);

  useEffect(() => {
    const request = async () => {
      try {
        if (!version) {
          updateState({ isLoading: false });
          throw new Error('No game version');
        }

        if (!sector || !job) {
          updateState({ isLoading: false });
          return;
        }

        const [jobSalary, jobSkills] = await Promise.all([
          VersionsAPI.sectors.work.job.fetchSalary({ version, sector, job }),
          VersionsAPI.sectors.work.job.fetchSkills({ version, sector, job }),
        ]);

        updateState({
          isLoading: false,
          jobSalary,
          jobSkills,
        });
      } catch (error) {
        updateState({ isLoading: false });
        console.error(error);
      }
    };

    request();
  }, [job, sector, version, updateState]);

  useEffect(() => {
    if (!sector) {
      return;
    }

    const ref = sectorSalaryRef(gameCode, sector);

    ref.on('value', (snapshot) => {
      const val = snapshot.val();

      if (val === null || val === undefined || isNaN(+val)) {
        setSectorMultiplier(1);
        return;
      }

      setSectorMultiplier(+val);
    });

    return () => {
      ref.off();
    };
  }, [gameCode, sector]);

  return { ...state, totalSalary };
};

export const usePlayerSalary = () => {
  const sector = useReduxSelector((redux) => redux.player.work?.sector);
  const job = useReduxSelector((redux) => redux.player.work?.work);
  const companyBonus = useReduxSelector(
    (redux) => redux.player.work?.company.bonus
  );

  const { isLoading, totalSalary: salary } = useTotalSalary({
    sector,
    job,
    companyBonus,
  });

  return { isLoading, salary };
};
