import { IRewardRequirements } from '../rewards.typing';

export const checkEducationRequirements = (
  requirements: IRewardRequirements<string>,
  completedEducationLevels: string[]
) => {
  const strictRequirements =
    requirements.strictRequirements?.requirements ?? [];
  const notStrictRequirements =
    requirements.notStrictRequirements?.requirements ?? [];

  return (
    strictRequirements.every((requiredLevel) =>
      completedEducationLevels.includes(requiredLevel)
    ) &&
    (!notStrictRequirements.length ||
      notStrictRequirements.some((requiredLevel) =>
        completedEducationLevels.includes(requiredLevel)
      ))
  );
};

export const pushIfAchieved = (
  isAchieved: boolean,
  typesArray: string[],
  type: string
) => {
  if (isAchieved) {
    typesArray.push(type);
  }
};
