import { Grid, ProgressList } from 'components/ui';
import { useMod, useReduxSelector } from 'services/hooks';
import { Theme } from 'themes';

import { PossessionsSection } from './components';
import { arrayEqual } from 'services/utils';

export interface IProps {
  isPreventMaxHeight?: boolean;
  isLifeDashboardResponsive?: boolean;
  className?: string;
}

export const Lifestyle = (props: IProps) => {
  const { className, isPreventMaxHeight, isLifeDashboardResponsive } = props;

  const status = useReduxSelector(
    (redux) => redux.player.character?.status,
    arrayEqual
  );

  const DashboardSection = useMod('gauges')?.components.DashboardSection;

  const RenderDashboardSection = DashboardSection ? (
    <DashboardSection isResponsive={isLifeDashboardResponsive} />
  ) : null;

  const statusList = status?.map((text, index) => (
    <Theme.Paragraph key={index}>{text}</Theme.Paragraph>
  ));

  return (
    <Grid className={className} rowGap="16px">
      {RenderDashboardSection}

      <ProgressList
        title="Status"
        items={statusList}
        shadowVariant="initial"
        isPreventMaxHeight={isPreventMaxHeight}
      />

      <PossessionsSection />
    </Grid>
  );
};
