import React from 'react';

import { Theme } from 'themes';

import { RANKING_STRINGS } from '../ranking.constants';

const { EMPTY } = RANKING_STRINGS;

export const EmptyRanking = () => (
  <Theme.Paragraph $textAlign="center" $fontFamily="medium" $fontSize="px18">
    {EMPTY}
  </Theme.Paragraph>
);
