import { memo } from 'react';

import { Image } from 'components/image';
import { Loader } from 'components/loader';
import { Theme } from 'themes';

import { ButtonBar, ForwardSideCard, LifeCardInformation } from './components';

import { useLifeCard } from './life-card.state';
import { LifeCardStyles as Styles } from './life-card.styles';
import { LIFE_CARD_STRING } from './life-card.const';

const { TITLE } = LIFE_CARD_STRING.CARD;

export const LifeCard = memo(() => {
  const {
    isLoading,
    card,
    isFlipped,
    forwardSide,
    isOptional,
    bonus,
    incrementedSkills,
    moveNext,
    onAccept,
    onDecline,
  } = useLifeCard();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Theme.PageContainer $isFullHeight={false}>
      <Styles.Wrapper $isColumn>
        <Theme.Flexbox $isColumn $alignItem="center" $itemGap="20px">
          <Theme.Paragraph $fontFamily="bold">{TITLE}</Theme.Paragraph>

          <LifeCardInformation
            frontSide={
              <Theme.CentralizeFlexbox
                $isColumn
                $itemGap="34px"
                $fontFamily="bold"
              >
                <Image type="startLifeCard" />
                <Theme.Paragraph>{card?.title}</Theme.Paragraph>
              </Theme.CentralizeFlexbox>
            }
            backSide={
              <ForwardSideCard
                description={card?.description || ''}
                abilities={incrementedSkills}
                forwardSide={forwardSide}
                bonuses={bonus}
              />
            }
            isFlipped={isFlipped}
          />
        </Theme.Flexbox>
        <Styles.ButtonsContainer>
          <ButtonBar
            onCloseLifeCard={moveNext}
            onDisagreeCard={onDecline}
            onGetBonus={onAccept}
            isChooseType={isOptional}
            isFlipped={isFlipped}
          />
        </Styles.ButtonsContainer>
      </Styles.Wrapper>
    </Theme.PageContainer>
  );
});
