import styled, { css } from 'styled-components';

import { MEDIA, Theme } from 'themes';

interface IProps {
  title: string;
  text?: string;
  isBold?: boolean;
  isColumn?: boolean;
  justifyContent?: string;
  children?: React.ReactNode;
}

const ColumnTextCss = css`
  flex-direction: column;

  & > * + * {
    margin-top: 8px;
  }

  ${MEDIA.LAPTOP} {
    & > * + * {
      margin-top: 16px;
    }
  }
`;

const RowTextCss = css`
  flex-direction: row;

  & > * + * {
    margin-left: 8px;
  }

  ${MEDIA.LAPTOP} {
    & > * + * {
      margin-left: 16px;
    }
  }
`;

const Styles = {
  TextWithTitle: styled.div<{ isColumn?: boolean; justifyContent?: string }>`
    display: flex;

    ${({ justifyContent }) =>
      justifyContent && `justify-content: ${justifyContent}`};

    ${({ isColumn }) => (isColumn ? ColumnTextCss : RowTextCss)};
  `,
};

export const CareerText = (props: IProps) => {
  const { title, text, isColumn, isBold, justifyContent, children } = props;

  return (
    <Styles.TextWithTitle isColumn={isColumn} justifyContent={justifyContent}>
      <Theme.Text $fontFamily={isBold ? 'bold' : undefined}>{title}</Theme.Text>
      <Theme.Text>{children || text}</Theme.Text>
    </Styles.TextWithTitle>
  );
};
