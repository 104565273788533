import { useReduxSelector } from 'services/hooks';
import { TCreateCharacterStep } from 'typings/create-character';

export const useReviewStepForm = (
  onSetStep: (step: TCreateCharacterStep) => void
) => {
  const version = useReduxSelector((redux) => redux.app.version);

  const onEditNameIcon = () => onSetStep('nameIcon');
  const onEditSmarts = () => onSetStep('smarts');
  const onEditPerformance = () => onSetStep('performance');
  const onEditAnchors = () => onSetStep('anchors');
  const onEditValues = () => onSetStep('values');
  const onEditGoals = () => onSetStep('goals');

  return {
    version,

    onEditNameIcon,
    onEditSmarts,
    onEditPerformance,
    onEditAnchors,
    onEditValues,
    onEditGoals,
  };
};
