import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { COLORS, typographyCss } from 'themes';

export type TButtonVariant = 'primary' | 'white';

interface IRootProps {
  variant?: TButtonVariant;
  borderRadius?: string;
}

export interface IButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    IRootProps {}

const variantButtonStyle = {
  primary: css<{ $borderRadius?: string }>`
    border-radius: ${({ $borderRadius = '10px' }) => $borderRadius};
    background-color: ${COLORS.purple};
    color: ${COLORS.white};

    &:disabled {
      filter: grayscale(100%);
    }
  `,
  white: css<{ $borderRadius?: string }>`
    border-radius: ${({ $borderRadius = '20px' }) => $borderRadius};
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    border: 1px solid ${COLORS.purple};

    &:disabled {
      background-color: ${COLORS.darkAches};
    }
  `,
};

const Root = styled.button<{
  $variant?: keyof typeof variantButtonStyle;
  $borderRadius?: string;
}>`
  display: block;
  width: 280px;
  height: 56px;
  border-radius: ${({ $borderRadius = '10px' }) => $borderRadius};
  ${typographyCss.regularMedium};

  transition: all 0.2s ease-in-out;

  ${({ $variant = 'primary' }) => variantButtonStyle[$variant]}
`;

export const Button = (props: IButtonProps) => {
  const { variant, borderRadius } = props;

  return <Root $variant={variant} $borderRadius={borderRadius} {...props} />;
};
