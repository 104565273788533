import { moveToRouteCallback } from 'services/utils';
import { ROUTES } from 'constants/routes';

import { BadgesSection } from './components';
import { useBadgesList } from './badges-list.state';
import { BADGES_LIST_STRINGS } from './badges-list.constants';
import { IBadgesListProps } from './badges-list.typing';

import { BadgesListStyles as Styles } from './badges-list.styles';

const { buttonBack } = BADGES_LIST_STRINGS;

export const BadgesList = (props: IBadgesListProps) => {
  const { isWaiting } = props;

  const state = useBadgesList();

  if (!state) {
    return null;
  }

  const categoriesMap = state.splitRewards.map((category, index) => (
    <BadgesSection
      key={index}
      title={category.title}
      achievedRewards={category.rewards.includedRewards}
      availableRewards={category.rewards.notIncludedRewards}
    />
  ));

  return (
    <Styles.Root>
      <Styles.List>{categoriesMap}</Styles.List>
      <Styles.ButtonBack
        title={buttonBack}
        isLower={isWaiting}
        onClick={moveToRouteCallback(ROUTES.home)}
      />
    </Styles.Root>
  );
};
