import { action } from 'services/utils';

import { TRewardsMap } from './rewards.typing';
import { REWARDS_ACTIONS } from './rewards.constants';

const {
  SET_REWARDS_OPTIONS,
  CLEAR_USER_REWARDS,
  PRESENT_REWARD,
  SET_USER_PRESENTED_REWARDS,
} = REWARDS_ACTIONS;

export const RewardsActions = {
  setRewardsOptions: (rewardsOptions: TRewardsMap) =>
    action(SET_REWARDS_OPTIONS, rewardsOptions),

  clearUserRewards: () => action(CLEAR_USER_REWARDS),

  setUserPresentedRewards: (rewardsCount: number) =>
    action(SET_USER_PRESENTED_REWARDS, rewardsCount),

  presentReward: () => action(PRESENT_REWARD),
};
