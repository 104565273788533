import { FC } from 'react';
import { Theme } from 'themes';

import { Image, IMAGES } from 'components/image';
import { ModalWrapper } from 'components/modal/modal-wrapper';

import { useModalState } from './main-modal.state';
import { modalContent } from './main-modal.utils';
import { MainModalStyles } from './main-modal.styles';

export const MainModal: FC = () => {
  const { gameStatus, isTested, gameCloseConfirm } = useModalState();

  if (gameStatus !== 'finished') {
    return null;
  }

  if (!isTested) {
    return null;
  }

  const { title, text, image, link } = modalContent;

  return (
    <ModalWrapper onCloseModal={gameCloseConfirm} isScreenModal={false}>
      <MainModalStyles.TitleTextWrapper>
        <Theme.Title>{title}</Theme.Title>
      </MainModalStyles.TitleTextWrapper>

      <Image type={image as keyof typeof IMAGES} />

      {text && (
        <MainModalStyles.TitleTextWrapper>
          <Theme.Text>{text}</Theme.Text>

          {link && (
            <Theme.Paragraph $color="solidBlue">
              <a href={link}>{link}</a>
            </Theme.Paragraph>
          )}
        </MainModalStyles.TitleTextWrapper>
      )}
    </ModalWrapper>
  );
};
