import { Loader } from 'components/loader';
import { EmptyRanking, PositionsList } from './components';

import { keyofObject } from 'services/utils';
import { useRanking } from './ranking.state';

import { RANKING_STRINGS } from './ranking.constants';

import { RankingStyles as Styles } from './ranking.styles';

const { HAPPIEST, EARNERS } = RANKING_STRINGS;

export const Ranking = () => {
  const { earners, happiest, isLoading } = useRanking();

  const isRankingEmpty =
    keyofObject(earners).length === 0 && keyofObject(happiest).length === 0;

  return (
    <Styles.Root>
      <Loader isLoading={isLoading}>
        {isRankingEmpty ? (
          <EmptyRanking />
        ) : (
          <Styles.List>
            <PositionsList title={HAPPIEST} positions={happiest} />
            <PositionsList title={EARNERS} positions={earners} />
          </Styles.List>
        )}
      </Loader>
    </Styles.Root>
  );
};
