import { memo } from 'react';
import styled from 'styled-components';
import { ICharacterSmart } from '@avid/common';

import { TImageNames } from 'components/image';
import { ImageBox } from 'components/image-box';
import { Theme } from 'themes';
import { SMART_LABELS } from 'constants/create-character';

interface ITitleProps {
  smartKey: keyof ICharacterSmart;
  smartValue: number;
}

export interface ISmartRadarProps {
  image: TImageNames;
  smartKey: keyof ICharacterSmart;
  smartValue: number;
  className?: string;
  titlePosition?: 'top';
  openModal?: (smartKey: keyof ICharacterSmart) => void;
}

export const SmartRadarItemStyled = styled(ImageBox)<{ isClickable: boolean }>`
  position: absolute;
  ${({ isClickable }) => isClickable && 'cursor: pointer'};
`;

const Title = ({ smartKey, smartValue }: ITitleProps) => (
  <Theme.Flexbox $fontSize="little" $itemGap="4px">
    <Theme.Text>{SMART_LABELS[smartKey]}</Theme.Text>
    <Theme.Text $fontFamily="bold">{smartValue}</Theme.Text>
  </Theme.Flexbox>
);

export const SmartRadarItem = memo((props: ISmartRadarProps) => {
  const {
    className,
    openModal,
    titlePosition,
    image,
    smartKey,
    smartValue,
  } = props;

  const handleOpenModal = () => openModal && openModal(smartKey);

  return (
    <SmartRadarItemStyled
      className={className}
      isClickable={openModal !== undefined}
      sizeImage="34px"
      titlePosition={titlePosition}
      imageName={image}
      elementTitle={<Title smartKey={smartKey} smartValue={smartValue} />}
      onClick={handleOpenModal}
    />
  );
});
