import { IGameRedux } from 'typings/game.typing';
import { deepMerge } from 'services/utils';

import { GAME_ACTIONS, TGameActions } from './game.actions';

const INIT_STATE: IGameRedux = {
  status: 'fetching',
  passedSeconds: 0,
  round: { number: 0, startUnix: 0 },
};

export const gameReducer: TReducer<IGameRedux, TGameActions> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {
    case GAME_ACTIONS.MERGE: {
      return deepMerge(state, action.payload);
    }

    default:
      return state;
  }
};
