import styled from 'styled-components';

import { Button, Field, Text } from 'components/ui';
import { Image } from 'components/image';
import { PurpleBackgroundLayout } from 'components/purple-background-layout';
import { COLORS, typographyCss } from 'themes';

import { useConnectGame } from './connect-game.state';
import { ConnectGameStyles as Styles } from './connect-game.styles';

const MiddleWrapper = styled.div<{ gap?: string }>`
  width: 100%;
  display: grid;
  justify-items: center;
  align-content: center;

  gap: ${({ gap }) => gap || '8px'};
`;

export const ConnectGame = () => {
  const { isLoading, isError, isExceed, gameCode, onChangeCode, onSubmit } =
    useConnectGame();

  return (
    <PurpleBackgroundLayout>
      <Styles.Form onSubmit={onSubmit}>
        <MiddleWrapper gap="60px">
          <Text
            as="h1"
            typography={typographyCss.titleBold}
            color={COLORS.white}
          >
            Hello There
          </Text>

          <Styles.FieldExceedWrapper>
            <Field
              rootWidth="100%"
              isValid={!isError}
              Icon={<Image type={isError ? 'errorLock' : 'lock'} />}
              inputProps={{
                type: 'number',
                placeholder: 'Game Code',
                value: gameCode,
                onChange: onChangeCode,
              }}
            />
            <Styles.ExceedP $isShow={isExceed}>
              Please refresh your screen and enter your game code again
            </Styles.ExceedP>
          </Styles.FieldExceedWrapper>
        </MiddleWrapper>

        <div>
          <Button variant="white" disabled={isLoading || !gameCode}>
            Next
          </Button>
        </div>
      </Styles.Form>
    </PurpleBackgroundLayout>
  );
};
