import { Redirect } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { useMod } from 'services/hooks';

export const LifeGoals = () => {
  const Screen = useMod('lifeGoals')?.screens.LifeGoals;

  if (Screen) {
    return <Screen />;
  }

  return <Redirect to={ROUTES.home} />;
};
