import { forwardRef, useEffect, useState } from 'react';

import { AppActions } from 'screens/app';
import { useReduxDispatch, useReduxSelector } from 'services/hooks';

import { IModalProps, Modal } from './modal';

export const ScreenModal = forwardRef<HTMLDivElement, IModalProps>(
  (props, ref) => {
    const { isOpen } = props;

    const [isMounted, setIsMounted] = useState(false);

    const isScreenModalOpen = useReduxSelector(
      (redux) => redux.app.isScreenModalOpen
    );

    const { callAction } = useReduxDispatch();
    const setModal = callAction(AppActions.setModal);

    useEffect(() => {
      setIsMounted(true);

      return () => {
        setModal(false);
      };
    }, []);

    useEffect(() => {
      setModal(Boolean(isOpen));
    }, [isOpen]);

    return isMounted && isScreenModalOpen ? (
      <Modal ref={ref} {...props} />
    ) : null;
  }
);
