import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Image } from 'components/image';
import { useSelectorContext } from '../selector.context';

import { getItemText } from 'services/utils';

import { SELECTOR_THEME } from '../selector.constants';

import { CSS } from 'typings';

interface ISelectorHeaderProps {
  className?: string;
  placeholder?: string;
  children?: ReactNode;
}

const Header = styled.div<{ $variant: CSS }>`
  min-height: 16px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 4px;

  ${(props) => props.$variant}
`;

const Icon = styled(Image)`
  position: absolute;

  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const SelectorHeader: FC<ISelectorHeaderProps> = (props) => {
  const { className, children, placeholder } = props;
  const {
    selectValue,
    setOpen,
    isOpen,
    theme = 'orange',
  } = useSelectorContext();
  const themeStyled = SELECTOR_THEME[theme].header;

  return (
    <Header
      className={className}
      onClick={setOpen(!isOpen)}
      $variant={themeStyled}
    >
      <Icon type="arrowDown" />
      {selectValue?.value ? getItemText(selectValue) : placeholder}
      {children}
    </Header>
  );
};
