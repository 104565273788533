import { useState, useEffect, useRef } from 'react';

import { REWARDS_APPEARANCE } from 'constants/rewards-appearance';
import { usePushLog, useReduxSelector, useThunkDispatch } from 'services/hooks';
import { defaultRewardBonusesObject, IRewardBonus } from 'services/rewards';
import { recordValue } from 'services/utils';
import { PlayerThunk } from 'services/redux';

import { IGetReward } from './reward-modal.typing';

export interface IRewardModalProps {
  reward: IGetReward;
  decreaseUserPresentedReward: () => void;
}

export const useRewardModal = (props: IRewardModalProps) => {
  const { reward, decreaseUserPresentedReward } = props;

  const [isMounted, setIsMounted] = useState(false);

  const { userPresentedRewards } = useReduxSelector((redux) => ({
    userPresentedRewards: redux.rewards.userPresentedRewards,
  }));

  const modalWrapperRef = useRef<HTMLDivElement>(null);

  const thunkDispatch = useThunkDispatch();

  const pushLog = usePushLog();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (userPresentedRewards >= 1) {
      const parentElement = modalWrapperRef.current?.parentElement;
      parentElement?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [modalWrapperRef, userPresentedRewards]);

  const {
    rewardName,
    rewardOptions,
    nextRewardName,
    nextRewardOptions,
  } = reward;

  const handleRewardBonuses = (bonuses: IRewardBonus[]) => {
    const mood = bonuses.find((bonus) => bonus.valueType === 'mood')?.bonus;
    const money = bonuses.find((bonus) => bonus.valueType === 'money')?.bonus;
    const energy = bonuses.find((bonus) => bonus.valueType === 'power')?.bonus;

    return thunkDispatch(PlayerThunk.addStats({ energy, mood, money }));
  };

  const onConfirm = () => {
    const bonuses = rewardOptions.bonuses;
    handleRewardBonuses(bonuses);

    const badge = rewardOptions.badge;
    const transformedBonuses = defaultRewardBonusesObject(bonuses);
    const title = recordValue(REWARDS_APPEARANCE, rewardName)?.title;

    pushLog({
      type: 'reward',
      action: 'receive',
      params: {
        type: rewardName,
        title: title || '',
        badge,
        ...transformedBonuses,
      },
    });

    decreaseUserPresentedReward();
  };

  return {
    isMounted,
    rewardName,
    rewardOptions,
    nextRewardName,
    nextRewardOptions,
    userPresentedRewards,
    modalWrapperRef,

    onConfirm,
  };
};
