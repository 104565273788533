import { useEffect, useRef } from 'react';

export const useStatItemClone = (onAnimationEnd: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const refCurrent = ref.current;
    refCurrent?.addEventListener('animationend', onAnimationEnd);

    return () => {
      refCurrent?.removeEventListener('animationend', onAnimationEnd);
      onAnimationEnd();
    };
  }, [onAnimationEnd]);

  return {
    ref,
  };
};
