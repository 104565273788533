import { IRewardAppearance } from 'services/rewards';

export const REWARDS_APPEARANCE: Record<string, IRewardAppearance> = {
  'education-bronze': {
    title: 'Education',
    badgeColor: '#8DC63F',
    badge_text_color: '#A03B22',
    rewardImage: {
      mainImage: 'educationSector',
      ribbonType: 'StraightRibbon',
      styleKit: 'Education',
      ribbon: 'ribbonLargeStraightGreen',
      mainTopOffset: 24,
      background: 'educationMainBronze',
    },
  },
  'education-silver': {
    title: 'Education',
    badgeColor: '#8DC63F',
    badge_text_color: '#BBBDBF',
    rewardImage: {
      ribbon: 'ribbonLargeStraightGreen',
      styleKit: 'Education',
      ribbonType: 'StraightRibbon',
      mainTopOffset: 24,
      background: 'educationMainSilver',
      mainImage: 'educationSector',
    },
  },
  'education-gold': {
    title: 'Education',
    rewardImage: {
      styleKit: 'Education',
      ribbonType: 'StraightRibbon',
      mainImage: 'educationSector',
      background: 'educationMainGold',
      ribbon: 'ribbonLargeStraightGreen',
      mainTopOffset: 24,
    },
    badgeColor: '#8DC63F',
    badge_text_color: '#FFDB74',
  },

  'skills-bronze': {
    title: 'Skills',
    badge_text_color: '#A03B22',
    badgeColor: '#FFD530',
    rewardImage: {
      mainImage: 'skillsSector',
      styleKit: 'Skills',
      ribbon: 'ribbonLargeSlopingOrange',
      ribbonType: 'SlopingRibbon',
      background: 'skillsMainBronze',
      mainTopOffset: 32,
    },
  },
  'skills-silver': {
    title: 'Skills',
    badgeColor: '#FFD530',
    badge_text_color: '#BBBDBF',
    rewardImage: {
      styleKit: 'Skills',
      background: 'skillsMainSilver',
      ribbonType: 'SlopingRibbon',
      mainImage: 'skillsSector',
      mainTopOffset: 32,
      ribbon: 'ribbonLargeSlopingOrange',
    },
  },
  'skills-gold': {
    title: 'Skills',
    rewardImage: {
      mainImage: 'skillsSector',
      mainTopOffset: 32,
      background: 'skillsMainGold',
      ribbon: 'ribbonLargeSlopingOrange',
      ribbonType: 'SlopingRibbon',
      styleKit: 'Skills',
    },
    badgeColor: '#FFD530',
    badge_text_color: '#FFD774',
  },

  'happiness-bronze': {
    title: 'Happiness',
    badgeColor: '#5448A9',
    rewardImage: {
      mainTopOffset: 15,
      mainImage: 'happinessSector',
      background: 'happinessMainBronze',
      ribbon: 'ribbonSmallStraightDarkBlue',
      ribbonType: 'SmallStraightRibbon',
      styleKit: 'Happiness',
    },
    badge_text_color: '#A03B22',
  },
  'happiness-silver': {
    title: 'Happiness',
    badgeColor: '#5448A9',
    rewardImage: {
      background: 'happinessMainSilver',
      ribbon: 'ribbonSmallStraightDarkBlue',
      styleKit: 'Happiness',
      mainImage: 'happinessSector',
      mainTopOffset: 15,
      ribbonType: 'SmallStraightRibbon',
    },
    badge_text_color: '#BBBDBF',
  },
  'happiness-gold': {
    title: 'Happiness',
    rewardImage: {
      mainImage: 'happinessSector',
      mainTopOffset: 15,
      background: 'happinessMainGold',
      styleKit: 'Happiness',
      ribbon: 'ribbonSmallStraightDarkBlue',
      ribbonType: 'SmallStraightRibbon',
    },
    badge_text_color: '#FFDB74',
    badgeColor: '#5448A9',
  },

  'career-bronze': {
    title: 'Career',
    rewardImage: {
      ribbon: 'ribbonSmallStraightBlue',
      styleKit: 'Career',
      background: 'careerMainBronze',
      ribbonType: 'SmallStraightRibbon',
      mainTopOffset: 35,
      mainImage: 'careerSector',
    },
    badge_text_color: '#A03B22',
    badgeColor: '#29ABE2',
  },
  'career-silver': {
    title: 'Career',
    badge_text_color: '#BBBDBF',
    badgeColor: '#29ABE2',
    rewardImage: {
      ribbon: 'ribbonSmallStraightBlue',
      mainImage: 'careerSector',
      background: 'careerMainSilver',
      styleKit: 'Career',
      mainTopOffset: 35,
      ribbonType: 'SmallStraightRibbon',
    },
  },
  'career-gold': {
    title: 'Career',
    badgeColor: '#29ABE2',
    badge_text_color: '#FFDB74',
    rewardImage: {
      ribbonType: 'SmallStraightRibbon',
      ribbon: 'ribbonSmallStraightBlue',
      mainTopOffset: 35,
      mainImage: 'careerSector',
      background: 'careerMainGold',
      styleKit: 'Career',
    },
  },

  'money-bronze': {
    title: 'Wealth',
    badge_text_color: '#A03B22',
    badgeColor: '#EF60A2',
    rewardImage: {
      styleKit: 'Rich',
      ribbonType: 'StraightRibbon',
      ribbon: 'ribbonLargeStraightViolet',
      mainTopOffset: 28,
      background: 'moneyMainBronze',
      mainImage: 'moneySector',
    },
  },
  'money-silver': {
    title: 'Wealth',
    badgeColor: '#EF60A2',
    rewardImage: {
      styleKit: 'Rich',
      mainTopOffset: 28,
      background: 'moneyMainSilver',
      ribbonType: 'StraightRibbon',
      ribbon: 'ribbonLargeStraightViolet',
      mainImage: 'moneySector',
    },
    badge_text_color: '#BBBDBF',
  },
  'money-gold': {
    title: 'Wealth',
    rewardImage: {
      mainTopOffset: 28,
      background: 'moneyMainGold',
      styleKit: 'Rich',
      mainImage: 'moneySector',
      ribbon: 'ribbonLargeStraightViolet',
      ribbonType: 'StraightRibbon',
    },
    badgeColor: '#EF60A2',
    badge_text_color: '#FFD774',
  },

  'avid-traveller': {
    title: 'Avid Traveller',
    rewardImage: {
      mainTopOffset: 38,
      styleKit: 'Skills',
      ribbon: 'ribbonSmallSlopingBlue',
      background: 'skillsMainSilver',
      ribbonType: 'SmallSlopingRibbon',
      mainImage: 'avidTravellerSector',
    },
    badgeColor: '#29ABE2',
    badge_text_color: '#29ABE2',
  },
  'world-traveller': {
    title: 'World Traveller',
    badge_text_color: '#BBBDBF',
    rewardImage: {
      mainTopOffset: 24,
      styleKit: 'Education',
      ribbon: 'ribbonLargeStraightOrange',
      mainImage: 'worldTravellerSector',
      background: 'educationMainSilver',
      ribbonType: 'StraightRibbon',
    },
    badgeColor: '#FFD530',
  },
  nomad: {
    title: 'Nomad',
    badge_text_color: '#FFDB74',
    badgeColor: '#FFD530',
    rewardImage: {
      mainTopOffset: 24,
      ribbonType: 'StraightRibbon',
      mainImage: 'worldTravellerSector',
      background: 'educationMainGold',
      styleKit: 'Education',
      ribbon: 'ribbonLargeStraightOrange',
    },
  },
  'well-connected': {
    title: 'Well-connected',
    rewardImage: {
      mainImage: 'wellConnectedSector',
      ribbon: 'ribbonLargeSlopingDarkBlue',
      ribbonType: 'SlopingRibbon',
      styleKit: 'Rich',
      mainTopOffset: 32,
      background: 'moneyMainPink',
    },
    badge_text_color: '#BBBDBF',
    badgeColor: '#554CA0',
  },
  driver: {
    title: 'Driver',
    badgeColor: '#FFD530',
    badge_text_color: '#BBBDBF',
    rewardImage: {
      mainTopOffset: 35,
      ribbon: 'ribbonSmallStraightOrange',
      styleKit: 'Rich',
      mainImage: 'driverSector',
      ribbonType: 'SmallStraightRibbon',
      background: 'moneyMainPink',
    },
  },
  security: {
    title: 'Security',
    rewardImage: {
      mainImage: 'securitySector',
      ribbon: 'ribbonLargeStraightBlue',
      background: 'educationMainPink',
      styleKit: 'Education',
      ribbonType: 'StraightRibbon',
      mainTopOffset: 35,
    },
    badge_text_color: '#BBBDBF',
    badgeColor: '#8DC63F',
  },
  stylish: {
    title: 'Stylish',
    rewardImage: {
      fontSize: 11,
      styleKit: 'Happiness',
      mainTopOffset: 22,
      mainImage: 'stylishSector',
      background: 'happinessMainPink',
      ribbonType: 'SmallStraightRibbon',
      ribbon: 'ribbonSmallStraightViolet',
    },
    badge_text_color: '#BBBDBF',
    badgeColor: '#EF60A2',
  },

  master: {
    title: 'Master',
    badge_text_color: '#BBBDBF',
    badgeColor: '#8DC63F',
    rewardImage: {
      mainTopOffset: 40,
      background: 'educationMainSilver',
      ribbonType: 'StraightRibbon',
      styleKit: 'Education',
      mainImage: 'masterSector',
      ribbon: 'ribbonLargeStraightGreen',
    },
  },
  'mid-career-change': {
    title: 'Mid-career Change',
    badgeColor: '#29ABE2',
    badge_text_color: '#FFD774',
    rewardImage: {
      styleKit: 'Rich',
      mainImage: 'midCareerChangeSector',
      mainTopOffset: 35,
      background: 'moneyMainGold',
      ribbon: 'ribbonSmallSlopingBlue',
      ribbonType: 'SmallSlopingRibbon',
    },
  },
  'loyal-employee': {
    title: 'Loyal Employee',
    badge_text_color: '#BBBDBF',
    badgeColor: '#EF60A2',
    rewardImage: {
      ribbonType: 'StraightRibbon',
      styleKit: 'Rich',
      background: 'moneyMainSilver',
      ribbon: 'ribbonLargeStraightViolet',
      mainImage: 'loyalEmployeeSector',
      mainTopOffset: 32,
    },
  },
  'job-hopper': {
    title: 'Job Hopper',
    badgeColor: '#8DC63F',
    badge_text_color: '#FFD774',
    rewardImage: {
      mainTopOffset: 35,
      styleKit: 'Rich',
      mainImage: 'jobHopperSector',
      ribbonType: 'SlopingRibbon',
      background: 'moneyMainGold',
      ribbon: 'ribbonLargeSlopingGreen',
    },
  },
};
