import {
  TGameVersion,
  IAbility,
  IPlayerPossessions,
  ITravelPossession,
  IPlayerWork,
} from '@avid/common';

import {
  IRewardRequirements,
  SPECIFIC_REWARD_TYPES,
  TCareerRewardRequirementType,
  TPossessionRewardRequirement,
} from 'services/rewards';
import { VersionsAPI } from 'services/api';

import {
  TCareerRewardRequirement,
  TRewardsMapArray,
  ISkillRewardRequirement,
} from '../rewards.typing';

import { checkEducationRequirements, pushIfAchieved } from './checkers.utils';

export const rewardsCheckers = {
  education: (
    rewards: TRewardsMapArray,
    completedEducationLevels: string[] = []
  ) => {
    const achievedEducationRewardTypes: string[] = [];

    rewards.forEach(([rewardName, rewardOptions]) => {
      const requirements =
        rewardOptions.requirements as IRewardRequirements<string>;

      if (checkEducationRequirements(requirements, completedEducationLevels)) {
        achievedEducationRewardTypes.push(rewardName);
      }
    });

    return achievedEducationRewardTypes;
  },

  rich: (rewards: TRewardsMapArray, money: number) => {
    const achievedRichRewardTypes: string[] = [];

    rewards.forEach(([rewardName, rewardOptions]) => {
      const rewardRequirements = rewardOptions.requirements.strictRequirements;

      if (!rewardRequirements) {
        return;
      }

      const requiredMoney = +rewardRequirements.requirements[0];
      if (money >= requiredMoney) {
        achievedRichRewardTypes.push(rewardName);
      }
    });

    return achievedRichRewardTypes;
  },

  happiness: (rewards: TRewardsMapArray, mood: number) => {
    const achievedHappinessRewardTypes: string[] = [];

    rewards.forEach(([rewardName, rewardOptions]) => {
      const rewardRequirements = rewardOptions.requirements.strictRequirements;

      if (!rewardRequirements) {
        return;
      }

      const requiredMood = +rewardRequirements.requirements[0];
      if (mood >= requiredMood) {
        achievedHappinessRewardTypes.push(rewardName);
      }
    });

    return achievedHappinessRewardTypes;
  },

  skills: (rewards: TRewardsMapArray, skills: IAbility[] = []) => {
    const TSCSkills = skills.filter((skill) => skill.type === 'TSC');
    const GSCSkills = skills.filter((skill) => skill.type === 'GSC');

    const achievedSkillsRewardTypes: string[] = [];

    rewards.forEach(([rewardName, rewardOptions]) => {
      const rewardRequirements =
        (
          rewardOptions.requirements as IRewardRequirements<ISkillRewardRequirement>
        ).strictRequirements?.requirements ?? [];

      const isAchieved = rewardRequirements.every((requirement) => {
        const { skill_type, amount, level } = requirement;

        if (skill_type === 'TSC') {
          const TSCFilteredSkills = TSCSkills.filter(
            (skill) => skill.level >= level
          );
          return TSCFilteredSkills.length >= amount;
        }
        if (skill_type === 'GSC') {
          const GCSFilteredSkills = GSCSkills.filter(
            (skill) => skill.level >= level
          );
          return GCSFilteredSkills.length >= amount;
        }
      });

      pushIfAchieved(isAchieved, achievedSkillsRewardTypes, rewardName);
    });

    return achievedSkillsRewardTypes;
  },

  career: (rewards: TRewardsMapArray, work?: IPlayerWork) => {
    if (!work) {
      return [];
    }

    const achievedCareerRewardTypes: string[] = [];

    rewards.forEach(([rewardName, rewardOptions]) => {
      const rewardRequirements =
        (
          rewardOptions.requirements as IRewardRequirements<TCareerRewardRequirement>
        ).strictRequirements?.requirements ?? [];
      if (rewardRequirements.length === 0) {
        return;
      }

      const { career, statistics } = work;

      const verifyingProperties: Record<TCareerRewardRequirementType, number> =
        {
          job_changes: statistics.changes,
          promotions: statistics.promotions,
          current_cycles: statistics.currentCycles,
          sectors: new Set(career.map((careerItem) => careerItem.sector)).size,
        };

      const isAchieved = rewardRequirements.every(
        (requirement) =>
          verifyingProperties[requirement.type] >= requirement.amount
      );

      pushIfAchieved(isAchieved, achievedCareerRewardTypes, rewardName);
    });

    return achievedCareerRewardTypes;
  },

  possession: (
    rewards: TRewardsMapArray,
    possessions?: Partial<IPlayerPossessions>
  ) => {
    const achievedPossessionRewardTypes: string[] = [];

    rewards.forEach(([rewardName, rewardOptions]) => {
      const rewardRequirements =
        (
          rewardOptions.requirements as IRewardRequirements<TPossessionRewardRequirement>
        ).strictRequirements?.requirements ?? [];

      if (rewardRequirements.length === 0) {
        return;
      }

      const isAchieved = rewardRequirements.every((requirement) => {
        if (!possessions) {
          return false;
        }

        const checkedPossession = possessions[requirement.possession];

        if (!checkedPossession) {
          return false;
        }

        const flattenValues = Object.values(checkedPossession).flat();

        return flattenValues.length >= requirement.amount;
      });

      pushIfAchieved(isAchieved, achievedPossessionRewardTypes, rewardName);
    });

    return achievedPossessionRewardTypes;
  },

  travels: (rewards: TRewardsMapArray, travel?: ITravelPossession) => {
    if (!travel) {
      return [];
    }

    const userTravelCountries = Object.values(travel)
      .flat()
      .map((travelItem) => travelItem.country);
    const uniqueCountriesCount = new Set(userTravelCountries).size;

    const achievedTravelsRewardTypes = rewards
      .filter(([, rewardOptions]) => {
        const rewardRequirements =
          rewardOptions.requirements.strictRequirements?.requirements ?? [];

        return (
          rewardRequirements.length !== 0 &&
          uniqueCountriesCount >= (rewardRequirements[0] as number)
        );
      })
      .map(([rewardName]) => rewardName);

    return achievedTravelsRewardTypes;
  },

  outfit: (icons: string[] = []) => {
    if (icons?.length > 1) {
      return [SPECIFIC_REWARD_TYPES.outfit.stylish];
    }
    return [];
  },

  careerGold: async (version: TGameVersion, work?: IPlayerWork) => {
    if (!work) {
      return;
    }

    const careerGoldRewardType = SPECIFIC_REWARD_TYPES.career.careerGold;

    const { work: workName, sector } = work;

    const sectorHighestPositions =
      await VersionsAPI.sectors.work.fetchHighestPositions({
        version,
        sector,
      });

    if (!sectorHighestPositions) {
      return;
    }

    if (sectorHighestPositions.includes(workName)) {
      return [careerGoldRewardType];
    }
  },
};
