import styled from 'styled-components';

import { FONT_SIZES, COLORS, Css, MEDIA } from 'themes';

export const AvailableWorkStyles = {
  ListContainer: styled.div`
    ${Css.responsiveCenterWide}
  `,
  WarningText: styled.p`
    text-align: start;
    font-size: ${FONT_SIZES.regular};
    line-height: 20px;
    color: ${COLORS.red};
    margin-top: 30px !important;

    ${MEDIA.LAPTOP} {
      text-align: center;
    }
  `,
};
