import { Loader } from 'components/loader';
import { Test } from 'components/test';

import { useWorkTest } from './work-test.state';

export const WorkTest = () => {
  const {
    question,
    isLoadingQuestion,
    onEndTest,
    onAnswerQuestion,
  } = useWorkTest();

  if (!question || isLoadingQuestion) {
    return <Loader />;
  }

  return (
    <Test
      title="Work project"
      increaseRating={onAnswerQuestion}
      questions={[question]}
      onEndTest={onEndTest}
    />
  );
};
