import { CensorSensor } from 'censor-sensor';

const censor = new CensorSensor();

const CUSTOM_BAD_WORDS = ['poop', 'poo', 'pee', 'motherfuck'];
CUSTOM_BAD_WORDS.forEach((word) => censor.addWord(word));

export const checkCensor = (value: string) => {
  if (censor.isProfane(value)) {
    return true;
  }

  const replacedString = value
    .replace('@', 'a')
    .replace('0', 'o')
    .replace('1', 'i');

  return censor.isProfane(replacedString);
};
