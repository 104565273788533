import { ROUTES } from 'constants/routes';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { removeFiredPlayer, firedPlayersSelfRef } from 'services/api';
import { usePushLog, useReduxSelector } from 'services/hooks';
import { PlayerActions } from 'services/redux';
import { moveToRoute } from 'services/utils';

const PLAYER_FIRED_WORK = {
  work: '',
  countNotPassWork: 0,
} as const;

export const useFireWork = () => {
  const gameCode = useReduxSelector((redux) => redux.player.authInfo.code);
  const email = useReduxSelector((redux) => redux.player.authInfo.email);
  const isInProgress = useReduxSelector(
    (redux) => redux.game.status === 'in-progress'
  );

  const [isShow, setIsShow] = useState(false);

  const dispatch = useDispatch();

  const pushLog = usePushLog();

  const onFindNewJob = useCallback(() => {
    setIsShow(false);

    if (isInProgress) {
      moveToRoute(ROUTES.findJob);
    }
  }, [isInProgress]);

  const quitWorkAndPushLog = useCallback(() => {
    dispatch(PlayerActions.updateWork(PLAYER_FIRED_WORK));

    removeFiredPlayer(gameCode, email);

    pushLog({ type: 'work', action: 'quit', params: { reason: 'limits' } });
  }, [dispatch, email, gameCode, pushLog]);

  useEffect(() => {
    if (!gameCode || !email) {
      return;
    }

    const ref = firedPlayersSelfRef(gameCode, email);

    ref.on('value', (snapshot) => {
      const val = snapshot.val();

      if (val) {
        quitWorkAndPushLog();
        setIsShow(true);
      }
    });

    return () => ref.off();
  }, [email, gameCode, quitWorkAndPushLog]);

  return { isShow, isInProgress, onFindNewJob };
};
