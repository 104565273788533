import styled, { css } from 'styled-components';

import { SelectorThemed } from 'components/selector';
import { useSectorsNoWork } from 'services/hooks';
import { Theme } from 'themes';

import { FIND_JOB_STRINGS } from '../../../find-job.strings';

const { SELECT_SECTOR_TEXT } = FIND_JOB_STRINGS;

interface IProps {
  onSelectSector: (sectorItem: IItem) => void;
  selectedSector?: IItem;
}

const Root = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`;

const SelectorMenuStyles = css`
  max-height: 200px;
`;

export const SectorSelector = (props: IProps) => {
  const { selectedSector, onSelectSector } = props;

  const sectors = useSectorsNoWork();

  return (
    <>
      <Theme.Paragraph $fontSize="regular" $fontFamily="medium">
        {SELECT_SECTOR_TEXT}
      </Theme.Paragraph>

      <Root>
        <SelectorThemed
          items={sectors}
          selectValue={selectedSector}
          setValue={onSelectSector}
          menuStyles={SelectorMenuStyles}
          theme="blue"
          placeholder="Select sector"
          topGap="16px"
        />
      </Root>
    </>
  );
};
