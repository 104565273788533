import { useClearPlayer, useReduxSelector } from 'services/hooks';
import { moveToRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';

export const useModalState = () => {
  const gameStatus = useReduxSelector((redux) => redux.game.status);
  const isTested = useReduxSelector((redux) => redux.main.isTested);

  const clearData = useClearPlayer();

  const gameCloseConfirm = () => {
    clearData();
    moveToRoute(ROUTES.login);
  };

  return { gameStatus, isTested, gameCloseConfirm };
};
