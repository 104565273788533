import RadarChart from 'react-svg-radar-chart';
import { ICharacterSmart } from '@avid/common';

import { getRadarData } from 'services/utils';

interface IProps {
  characterSmart: ICharacterSmart;
}

export const SMART_CAPTIONS = {
  nature: '',
  logic: '',
  self: '',
  people: '',
  music: '',
  body: '',
  picture: '',
  word: '',
};

export const SmartRadarChart = (props: IProps) => {
  const { characterSmart } = props;

  const data = getRadarData(characterSmart);

  return (
    <RadarChart
      captions={SMART_CAPTIONS}
      data={data}
      size={235}
      options={{ scales: 1 }}
    />
  );
};
