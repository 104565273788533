import { IAuth, IGame, IPlayer, IPlayerStored } from '@avid/common';

import { db } from '../firebase';

class GamesAPI {
  async getGame(gameCode: string) {
    const doc = await db.collection('games').doc(gameCode).get();

    return doc.data() as TFirestoreData<IGame>;
  }

  async getPlayer(gameCode: string, playerEmail: string) {
    const doc = await db
      .collection('games')
      .doc(gameCode)
      .collection('users')
      .doc(playerEmail)
      .get();

    return doc.data() as TFirestoreData<IPlayerStored>;
  }

  mergePlayer(auth: IAuth, data: TDeepPartial<IPlayer>) {
    return db
      .doc(`games/${auth.code}/users/${auth.email}`)
      .set(data, { merge: true });
  }
}

export const gamesAPI = new GamesAPI();
