import {
  IPlayerPossessions,
  ITravel,
  TGameVersion,
  versionsPossessionsLevels,
} from '@avid/common';

import { entriesObject } from 'services/utils';
import { TImageNames } from 'components/image';

import { P, WhiteShadowWrapper } from '../ui';

import { PossessionsListStyles as Styles } from './possessions-list.styles';
import { PossessionsRow, TravelsRow } from './components';
import { ReactNode } from 'react';

interface IProps {
  version: TGameVersion;
  possessions: IPlayerPossessions | null;
}

export const PossesionsList = (props: IProps) => {
  const { possessions, version } = props;

  let RenderPossesions: ReactNode = null;

  if (possessions) {
    RenderPossesions = entriesObject(possessions).map(([item, possession]) => {
      if (!possession) {
        return;
      }

      return entriesObject(possession).map(([level, items]) => {
        const title = version
          ? versionsPossessionsLevels[version]?.[item]?.[level] || level
          : level;

        if (item === 'travel') {
          return (
            <TravelsRow
              key={item + level}
              title={level}
              travels={items as ITravel[]}
            />
          );
        }

        return (
          <PossessionsRow
            key={item + level}
            title={title}
            images={items as TImageNames[]}
          />
        );
      });
    });
  }

  return (
    <WhiteShadowWrapper $isPreventMaxHeight>
      <Styles.ContentWrapper>
        <Styles.SectionWrapper>
          <P $typography="RalewayBold16">Possessions</P>
          <Styles.ItemsWrapper>{RenderPossesions}</Styles.ItemsWrapper>
        </Styles.SectionWrapper>
      </Styles.ContentWrapper>
    </WhiteShadowWrapper>
  );
};
