import { useReduxSelector } from 'services/hooks';

import {
  ValueItemStyles,
  ValuesLineStyles,
  ValuesPanelStyles,
} from './values-picker.styles';
import { entriesObject } from 'services/utils';

interface IValueItemProps {
  value: string;
  isActive: boolean;
  onSelect: (value: string) => void;
}

interface IValueLineProps {
  valueItems: string[];
  selectedValues: string[];
  onSelectValue: (value: string) => void;
}

interface IValuesPanelProps {
  values: Record<string, string[]>;
  selectedValues: string[];
  onSelectValue: (value: string) => void;
}

interface IProps {
  selectedValues: string[];
  onSelectValue: (value: string) => void;
}

const ValueItem = (props: IValueItemProps) => {
  const { onSelect, isActive, value } = props;

  const onClick = () => onSelect(value);

  return (
    <ValueItemStyles.Root key={value} onClick={onClick} isActive={isActive}>
      {value}
    </ValueItemStyles.Root>
  );
};

const ValueLine = (props: IValueLineProps) => {
  const { valueItems, selectedValues, onSelectValue } = props;

  const RenderItems = valueItems.map((item) => {
    const value = item || '';

    return (
      <ValueItem
        key={value}
        value={value}
        onSelect={onSelectValue}
        isActive={selectedValues.includes(value)}
      />
    );
  });

  return <ValuesLineStyles.Root>{RenderItems}</ValuesLineStyles.Root>;
};

const ValuesPanel = (props: IValuesPanelProps) => {
  const { values, selectedValues, onSelectValue } = props;

  const RenderLines = entriesObject(values).map(([key, items]) => (
    <ValueLine
      key={key}
      valueItems={items}
      onSelectValue={onSelectValue}
      selectedValues={selectedValues}
    />
  ));

  return (
    <ValuesPanelStyles.Root>
      <ValuesPanelStyles.TransformWrapper>
        <ValuesPanelStyles.LinesWrapper>
          {RenderLines}
        </ValuesPanelStyles.LinesWrapper>
      </ValuesPanelStyles.TransformWrapper>
    </ValuesPanelStyles.Root>
  );
};

export const ValuesPicker = (props: IProps) => {
  const { selectedValues, onSelectValue } = props;

  const values = useReduxSelector((redux) => redux.app.values);

  return (
    <ValuesPanel
      values={values}
      selectedValues={selectedValues}
      onSelectValue={onSelectValue}
    />
  );
};
