import { ISkillInfo } from '@avid/common';
import { useCallback, useEffect } from 'react';

import { useUpdateState } from 'services/hooks';

import { useWorkSelectors } from './work.selectors';
import { IWorkState, WorkModal, WorkPage } from './work.typings';
import { formValuesAsync } from './work.utils';

export const INIT_STATE: IWorkState = {
  isLoadingValues: true,
  page: WorkPage.Start,
  rating: 0,
  isSelfSelect: true,
  skills: [],
  nextLevels: [],
  values: null,
};

export const useWork = () => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  const {
    version,

    positiveValues,
    negativeValues,

    chAnchors,
    chValues,

    constAnchors,
    constValues,
  } = useWorkSelectors();

  const onOpenPage = useCallback(
    (page: WorkPage) => updateState({ page }),
    [updateState]
  );

  const onOpenModal = useCallback(
    (modal: WorkModal) => updateState({ modal }),
    [updateState]
  );

  const onCloseModal = useCallback(
    () => updateState({ modal: undefined }),
    [updateState]
  );

  const onSetSkills = useCallback(
    (skills: ISkillInfo[]) => updateState({ skills }),
    [updateState]
  );

  const onSetRating = useCallback(
    (rating: number) => updateState({ rating }),
    [updateState]
  );

  const onSetSelfSelect = useCallback(
    () => updateState({ isSelfSelect: false }),
    [updateState]
  );

  const onSetNextLevels = useCallback(
    (works: string[]) => updateState({ nextLevels: works }),
    [updateState]
  );

  const onReset = useCallback(
    (statePayload?: Partial<IWorkState>) => {
      updateState({
        page: WorkPage.Start,
        modal: undefined,
        rating: 0,
        isSelfSelect: true,
        skills: [],

        ...statePayload,
      });
    },
    [updateState]
  );

  useEffect(() => {
    const request = async () => {
      if (!(positiveValues && negativeValues && version)) {
        return;
      }

      try {
        const values = await formValuesAsync({
          version,
          positiveValues,
          negativeValues,
          constAnchors,
          constValues,
          chAnchors,
          chValues,
        });

        updateState({ values });
      } catch (ex) {
        console.error(ex);
      } finally {
        updateState({ isLoadingValues: false });
      }
    };

    request();
  }, [
    chAnchors,
    chValues,
    constAnchors,
    constValues,
    negativeValues,
    positiveValues,
    version,
    updateState,
  ]);

  const isLoading = state.isLoadingValues;

  return {
    ...state,
    isLoading,

    onOpenPage,
    onOpenModal,
    onCloseModal,
    onSetSkills,
    onSetRating,
    onSetSelfSelect,
    onSetNextLevels,
    onReset,
  };
};
