import { JobDescription } from 'components/job-description';
import { Loader } from 'components/loader';

import { useWorkDescription } from './work-description.state';

export const WorkDescription = () => {
  const { isLoaded, description, upWork, onCloseModal } = useWorkDescription();

  return (
    <Loader isLoading={!isLoaded}>
      {upWork && (
        <JobDescription
          onCloseModal={onCloseModal}
          buttonText="Close"
          job={upWork}
          description={description}
        />
      )}
    </Loader>
  );
};
