import styled from 'styled-components';

import { SmallAvatar } from 'components/small-avatar';

export const IconsListStyles = {
  Wrapper: styled.div`
    &:not(:first-child) {
      margin-top: 24px;
    }
  `,

  IconsContainer: styled.div`
    margin-top: 10px;
  `,

  Avatar: styled(SmallAvatar)`
    display: inline-block;
  `,
};
