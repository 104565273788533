import { FC } from 'react';
import { Theme } from 'themes';

import { ButtonOld } from 'components/button';
import { LIFE_CARD_STRING } from '../life-card.const';

import { LifeCardStyles } from '../life-card.styles';

interface IButtonBarProps {
  isChooseType: boolean;
  isFlipped: boolean;

  onCloseLifeCard(): void;
  onDisagreeCard(): void;
  onGetBonus(): void;
}

const { AGREE_BUTTON, DISAGREE_BUTTON, NEXT_BUTTON } = LIFE_CARD_STRING.CARD;

export const ButtonBar: FC<IButtonBarProps> = (props) => {
  const {
    onCloseLifeCard,
    onDisagreeCard,
    onGetBonus,
    isChooseType,
    isFlipped,
  } = props;

  return (
    <Theme.Flexbox>
      {isChooseType && !isFlipped ? (
        <LifeCardStyles.List $itemGap="32px">
          <ButtonOld title={AGREE_BUTTON} onClick={onGetBonus} />
          <ButtonOld title={DISAGREE_BUTTON} onClick={onDisagreeCard} />
        </LifeCardStyles.List>
      ) : (
        <ButtonOld
          title={NEXT_BUTTON}
          onClick={isFlipped ? onCloseLifeCard : onGetBonus}
        />
      )}
    </Theme.Flexbox>
  );
};
