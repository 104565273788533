import styled, { css } from 'styled-components';

import { Text } from 'components/ui';
import { COLORS } from 'themes';

interface IRootProps {
  isActive: boolean;
}

export const PerformanceItemStyles = {
  Root: styled.div<IRootProps>`
    width: 140px;
    height: 140px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    padding-top: 4px;
    text-align: center;
    transition: background-color 0.2s ease-in-out;

    ${({ isActive }) => css`
      border: 1px dashed ${isActive ? 'transparent' : COLORS.darkYellow};
      background-color: ${isActive ? COLORS.darkYellow : COLORS.white};
    `}

    & > *:not(:first-child) {
      margin-top: 4px;
    }
  `,
  Title: styled(Text)`
    white-space: nowrap;
    transition: color 0.2s ease-in-out;
  `,
  Description: styled(Text)`
    transition: color 0.2s ease-in-out;
  `,
};
