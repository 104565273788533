import styled from 'styled-components';

import { COLORS, MEDIA } from 'themes';

export const LogListStyles = {
  List: styled.ul`
    margin-top: 16px;
  `,
  Item: styled.li<{ $isRoundStartLog?: boolean }>`
    padding: 8px 14px;
    border-bottom: 1px solid #f2f2f2;
    ${({ $isRoundStartLog }) =>
      $isRoundStartLog &&
      `background-color: ${COLORS.purple}; color: ${COLORS.white}`};

    ${MEDIA.LAPTOP} {
      padding: 8px 40px;
    }
  `,
};
