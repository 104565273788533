import { CSS } from 'typings';

interface IDeviseSize {
  height: number;
  width: number;
}

export const IPHONE_X: IDeviseSize = {
  height: 812,
  width: 375,
};
export const IPHONE_XS_MAX: IDeviseSize = {
  height: 896,
  width: 414,
};
export const LAPTOP: IDeviseSize = {
  height: 600,
  width: 1024,
};
export const CHROMEBOOK: IDeviseSize = {
  height: 768,
  width: 1366,
};

export const LOW = 360;

export const MEDIA = {
  MOBILE: `@media screen and (max-width: ${IPHONE_X.width}px) and (max-height: 670px)`,

  TABLET: `@media screen and (min-width: ${IPHONE_XS_MAX.width + 1}px)`,

  LAPTOP: `@media screen and (min-width: ${LAPTOP.width}px)`,

  CHROMEBOOK: `@media screen and (min-width: ${CHROMEBOOK.width}px)`,

  SAFARI_ONLY: (css: CSS) => `
  @media not all and (min-resolution:.001dpcm)
    { 
      @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        ${css /* Safari 11.0+ */}
      }
    }`,
} as const;
