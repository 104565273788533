import { Theme } from 'themes';

import { ButtonOld } from 'components/button';
import { Image, TImageNames } from 'components/image';
import { EducationModalWrapper } from './education-modal-wrapper';

export interface IDefaultEducationModalProps {
  image: TImageNames;
  title: string;
  buttonText: string;
  onCloseModal: () => void;
}

export const DefaultEducationModal = (props: IDefaultEducationModalProps) => {
  const { image, title, buttonText, onCloseModal } = props;

  return (
    <EducationModalWrapper onCloseModal={onCloseModal}>
      <Theme.Paragraph>{title}</Theme.Paragraph>
      <Image type={image} />
      <ButtonOld onClick={onCloseModal} title={buttonText} />
    </EducationModalWrapper>
  );
};
