import { TImageNames } from 'components/image';

export const BASIC_ICONS: TImageNames[] = [
  'avatar1',
  'avatar2',
  'avatar3',
  'avatar4',
  'avatar5',
  'avatar6',
];

export const ALL_ICONS: TImageNames[] = [
  ...BASIC_ICONS,
  'avatar7',
  'avatar8',
  'avatar9',
  'avatar10',
  'avatar11',
  'avatar12',
  'avatar13',
  'avatar14',
  'avatar15',
  'avatar16',
  'avatar17',
  'avatar18',
];

export const INIT_ICON: TImageNames = 'characterIcon';
