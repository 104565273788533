import styled from 'styled-components';

import { Text } from 'components/ui';
import { typographyCss, COLORS } from 'themes';

interface IProps {
  usedPoints: number;
  maxPoints: number;
  labelEnd: string;
  labelMiddle?: string;
}

export const CreateCharacterProgress = styled(Text)`
  margin-top: 16px;
  ${typographyCss.regularMedium};
`;

export const CreateCharacterPoints = styled(Text)`
  color: ${COLORS.darkYellow};
  font: inherit;
`;

export const CreateCharacterTrack = (props: IProps) => {
  const { labelEnd, maxPoints, usedPoints, labelMiddle = 'of' } = props;

  return (
    <CreateCharacterProgress>
      <CreateCharacterPoints as="span">{usedPoints}</CreateCharacterPoints>{' '}
      {labelMiddle} {maxPoints} {labelEnd}
    </CreateCharacterProgress>
  );
};
