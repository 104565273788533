import styled from 'styled-components';

import { COLORS, SCALE, spacing, MEDIA } from 'themes';

export const ProgressBlockStyles = {
  Root: styled.div`
    max-height: ${spacing(30)};
    padding: ${spacing(3)};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: ${COLORS.white};
    box-shadow: 2px 6px 16px 0px ${COLORS.shadowLite};

    ${MEDIA.LAPTOP} {
      width: ${spacing(SCALE.wide)};
    }
  `,

  ListContainer: styled.div`
    margin-top: ${spacing(2)};
    padding-right: ${spacing(2)};
    overflow-y: auto;
  `,
};
