import { action } from '../../../utils';
import { ILowGauges, ITipMessageRedux } from 'typings/mods';

export const MODS_ACTIONS = {
  // Clear player
  CLEAR: '@MODS/CLEAR',
  // Referral codes
  SHOW_REFERRAL_MODAL: '@MODS/SHOW_REFERRAL_MODAL',
  ACCEPT_REFERRAL_NOTIFICATION: '@MODS/ACCEPT_REFERRAL_NOTIFICATION',
  // Gauges
  DISPLAY_GAUGES_NOTIFICATION: '@MODS/DISPLAY_GAUGES_NOTIFICATION',
  CLOSE_GAUGES_NOTIFICATION: '@MODS/CLOSE_GAUGES_NOTIFICATION',
  // Popup tips
  RAISE_TOAST_MESSAGE: '@MODS/RAISE_TOAST_MESSAGE',
  REMOVE_TOAST_MESSAGE: '@MODS/REMOVE_TOAST_MESSAGE',
  SET_POPUP_MESSAGE: '@MODS/SET_POPUP_MESSAGE',
  CLOSE_POPUP_MESSAGE: '@MODS/CLOSE_POPUP_MESSAGE',
  // Financial
  FINANCIAL_ADD_DEDUCTION: '@MODS/FINANCIAL/ADD_DEDUCTION',
  FINANCIAL_POP_DEDUCTION: '@MODS/FINANCIAL/POP_DEDUCTION',
} as const;

export const ModsActions = {
  clear: () => action(MODS_ACTIONS.CLEAR),
  showReferralModal: (playerNames: string[]) =>
    action(MODS_ACTIONS.SHOW_REFERRAL_MODAL, playerNames),
  acceptReferralNotification: () =>
    action(MODS_ACTIONS.ACCEPT_REFERRAL_NOTIFICATION),
  displayGaugesNotification: (lowGauges: ILowGauges) =>
    action(MODS_ACTIONS.DISPLAY_GAUGES_NOTIFICATION, lowGauges),
  closeGaugesNotification: (gaugesType: keyof ILowGauges) =>
    action(MODS_ACTIONS.CLOSE_GAUGES_NOTIFICATION, gaugesType),
  raiseToastMessage: (messageContent: ITipMessageRedux) =>
    action(MODS_ACTIONS.RAISE_TOAST_MESSAGE, messageContent),
  removeToastMessage: (toastId: string) =>
    action(MODS_ACTIONS.REMOVE_TOAST_MESSAGE, toastId),
  setPopupMessage: (messageContent?: ITipMessageRedux) =>
    action(MODS_ACTIONS.SET_POPUP_MESSAGE, messageContent),
  closePopupMessage: (toastId: string) =>
    action(MODS_ACTIONS.CLOSE_POPUP_MESSAGE, toastId),
  addDeduction: (money: number) =>
    action(MODS_ACTIONS.FINANCIAL_ADD_DEDUCTION, money),
  popDeduction: () => action(MODS_ACTIONS.FINANCIAL_POP_DEDUCTION),
};

export type TModsActions = ReturnType<TCombiner<typeof ModsActions>>;
