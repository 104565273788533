import { RuleSet } from 'styled-components';
import { entriesObject } from '@avid/common';

import { keyofObject } from 'services/utils';

import { MEDIA } from './media';

type TPropsWithMedia<TProps> = TProps & {
  $media?: Partial<Record<keyof typeof MEDIA, TProps>>;
};

export const overrideMediaCss = <TProps>(
  props?: TPropsWithMedia<TProps>,
  override?: (props: TProps, isOverride: boolean) => string | RuleSet
) => {
  if (!props || !override) {
    return '';
  }

  const { $media } = props;

  if (!$media || keyofObject($media).length === 0) {
    return '';
  }

  const mediaCss = entriesObject($media).reduce(
    (cssString: string, [mediaKey, mediaProps]) => {
      if (mediaProps) {
        const cssProps = override(mediaProps, true);

        cssString += `
          ${MEDIA[mediaKey]} {
            ${cssProps.toString().replaceAll('\n  ,', '')}
          }
        `;
      }

      return cssString;
    },
    ''
  );

  return mediaCss;
};
