import { memo, useEffect } from 'react';

import { useReduxSelector, useRoundCountdown } from 'services/hooks';
import { getRoundSecondsPassed } from 'services/utils';

export const ApplyRoundCountdown = memo(() => {
  const applyRoundCountdown = useRoundCountdown();

  const roundStartUnix = useReduxSelector(
    (redux) => redux.game.round.startUnix
  );

  useEffect(() => {
    const secondsPassed = getRoundSecondsPassed(roundStartUnix);

    applyRoundCountdown(secondsPassed);
  }, [applyRoundCountdown, roundStartUnix]);

  return <></>;
});
