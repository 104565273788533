import { COLORS, typographyCss } from 'themes';

import { PerformanceItemBonusStyles as Styles } from './performance-item-bonus.styles';

interface IItemProps {
  bonus: string;
}

interface IProps {
  bonuses: string[];
}

const Plus = () => (
  <Styles.PlusWrapper>
    <Styles.PlusVertical />
    <Styles.PlusHorizontal />
  </Styles.PlusWrapper>
);

const Item = (props: IItemProps) => (
  <Styles.ItemWrapper>
    <Plus />
    <Styles.ItemText
      typography={typographyCss.littleBold}
      color={COLORS.purpleBlue}
    >
      {props.bonus}
    </Styles.ItemText>
  </Styles.ItemWrapper>
);

export const PerformanceItemBonus = (props: IProps) => {
  const { bonuses } = props;

  const RenderItems = bonuses.map((bonus) => (
    <Item key={bonus} bonus={bonus} />
  ));

  return (
    <Styles.Root>
      <Styles.CenterWrapper>{RenderItems}</Styles.CenterWrapper>
    </Styles.Root>
  );
};
