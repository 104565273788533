import { useEffect, useMemo } from 'react';

import {
  usePushLog,
  useRealtimeJobLimits,
  useReduxSelector,
  useUpdateState,
} from 'services/hooks';
import { PlayerActions } from 'services/redux';

import { FindJobPages } from '../../find-job.typing';

import {
  IAvailableWorkState,
  IAvailableWorkProps,
  UnavailableReason,
} from './available-work.typing';
import { checkSectorDegree, getEnableWorkAsync } from './available-work.utils';
import { callFunctionAction } from 'services/api';
import { useDispatch } from 'react-redux';
import { arrayEqual, objectEqual } from 'services/utils';

const INIT_STATE: IAvailableWorkState = {
  isLoading: true,
  isConfirmLoading: false,
  isModalOpen: false,
};

export const useAvailableWork = (props: IAvailableWorkProps) => {
  const { sector, selectedJob, onOpenPage, onSetJob } = props;

  const version = useReduxSelector((redux) => redux.app.version);

  const workExperience = useReduxSelector(
    (redux) => redux.player.work?.experience,
    objectEqual
  );
  const abilities = useReduxSelector(
    (redux) => redux.player.skills,
    arrayEqual
  );
  const educations = useReduxSelector(
    (redux) => redux.player.educations,
    arrayEqual
  );
  const gameCode = useReduxSelector((redux) => redux.player.authInfo.code);

  const { state, updateState } = useUpdateState(INIT_STATE);
  const dispatch = useDispatch();
  const isSectorLimited = useRealtimeJobLimits(sector);
  const pushLog = usePushLog();

  const closeModal = () => updateState({ isModalOpen: false });

  const onConfirmJob = async (actionEnergy: number, actionMoney?: number) => {
    if (!selectedJob || state.isConfirmLoading) {
      return;
    }

    updateState({ isConfirmLoading: true });

    try {
      const result = await callFunctionAction('@WORK/APPLY_JOB', undefined);

      dispatch(PlayerActions.merge(result.mergePlayer));

      pushLog({
        type: 'work',
        action: 'apply',
        params: {
          position: selectedJob,
          energy: -actionEnergy,
          money: actionMoney && -actionMoney,
        },
      });

      onOpenPage(FindJobPages.Company);
    } catch (error) {
      console.error(error);
    } finally {
      updateState({ isConfirmLoading: false });
    }
  };

  const handleSetJob = (newJob: string) => {
    onSetJob(newJob);
    updateState({ isModalOpen: true });
  };

  const isPositionsLimited = useMemo(
    () =>
      (state.jobs || []).findIndex(([, isAvailable]) => !isAvailable) !== -1,
    [state.jobs]
  );

  useEffect(() => {
    const request = async () => {
      try {
        if (!version) {
          throw new Error('No game version');
        }

        const jobs = await getEnableWorkAsync({
          version,
          sector,
          abilities,
          workExperience,
        });

        if (!jobs?.length) {
          const isHaveDegree = checkSectorDegree({ educations, sector });
          const unavailableReason = isHaveDegree
            ? UnavailableReason.NoSkills
            : UnavailableReason.NoDegree;

          updateState({ isLoading: false, unavailableReason });
        }

        updateState({ isLoading: false, jobs });
      } catch (error) {
        console.error(error);
        updateState({ isLoading: false });
      }
    };

    request();
  }, [
    sector,
    version,
    gameCode,
    abilities,
    workExperience,
    updateState,
    educations,
  ]);

  return {
    ...state,
    isSectorLimited,
    isPositionsLimited,
    handleSetJob,
    closeModal,
    onConfirmJob,
  };
};
