import { FC } from 'react';
import styled from 'styled-components';

import { MEDIA, SCALE, spacing, Theme } from 'themes';
import { useReduxSelector } from 'services/hooks';
import { arrayEqual } from 'services/utils';

import { ButtonOld } from './button';
import { CheckboxList } from './checkbox';
import { Box, BoxItem } from './ui';

interface ISelectSectorProps {
  title: string;

  onSelectSector(sector: string): void;

  listId?: string;
  className?: string;
  selectSector?: string;
  isFullSectors?: boolean;
  onClickGoBack?: () => void;
}

const ListCenterContainer = styled.div`
  width: 100%;

  ${MEDIA.LAPTOP} {
    width: 25%;
    min-width: ${spacing(SCALE.wide)};
    margin: 0 auto;
  }
`;

export const SelectSector: FC<ISelectSectorProps> = (props) => {
  const {
    listId,
    title,
    selectSector,
    className,
    isFullSectors = true,
    onClickGoBack,
    onSelectSector,
  } = props;

  const gameSectors = useReduxSelector(
    (redux) => redux.app.sectors,
    arrayEqual
  );

  const sectors = isFullSectors
    ? gameSectors.filter((sector) => sector.isFull)
    : gameSectors;

  return (
    <Theme.FullSizeFlexbox
      $isColumn
      className={className}
      $fontFamily="bold"
      $textAlign="center"
      $itemGap="16px"
      $justifyContent="space-between"
    >
      <Box $laptopSpace="24px">
        <BoxItem>
          <Theme.Paragraph $fontSize="header">{title}</Theme.Paragraph>
        </BoxItem>

        <BoxItem>
          <ListCenterContainer>
            <CheckboxList
              id={listId}
              $isColumn
              $itemGap="16px"
              variant="orange"
              items={sectors}
              setValue={onSelectSector}
              value={selectSector}
              isDisabled={false}
            />
          </ListCenterContainer>
        </BoxItem>
      </Box>
      {onClickGoBack && <ButtonOld onClick={onClickGoBack} title="Go back" />}
    </Theme.FullSizeFlexbox>
  );
};
