export const PAGE_ROUTES = {
  home: '/home',
  education: '/education',
  course: '/course',
  work: '/work',
  shop: '/shop',
  more: '/more',
  careerCenter: '/career-center',
  findJob: '/find-job',
  lifeDashboard: '/life-dashboard',
  badgesList: '/badges-list',
  lifeGoals: '/life-goals',
} as const;

export const PRIVATE_CREATE_CHARACTER_ROUTES = {
  onboarding: '/onboarding',
  createCharacter: '/create-character',
  setSmart: '/set-smart',
};

export const GAME_ROUTES = {
  ...PAGE_ROUTES,
  lifeCard: '/life-card',
  lifeIteration: '/life-iteration',
};

export const ROUTES = {
  greeting: '/',
  login: '/log-in',
  joinGame: '/game',
  auth: '/auth',
  waiting: '/waiting',
  welcome: '/welcome',
  gameReport: '/game-report',
  gameReportToken: '/game-report-token',
  gameReportDownload: '/download-game-report',
  ...GAME_ROUTES,
  ...PRIVATE_CREATE_CHARACTER_ROUTES,
};
