import { memo } from 'react';
import styled from 'styled-components';
import { Theme } from 'themes';

const NoWrappingText = styled(Theme.Text)`
  white-space: nowrap;
`;

interface IProps {
  value: string | number;
}

export const Title = memo((props: IProps) => (
  <NoWrappingText $fontSize="small" $fontFamily="bold">
    {props.value}
  </NoWrappingText>
));
