import ReactJoyride, { Props, TooltipRenderProps } from 'react-joyride';

import { useReduxSelector, useWindowDimensions } from 'services/hooks';

import { Tooltip } from './components';

interface ITutorialProps extends Props {
  labelClose?: string;
}

interface IProps extends ITutorialProps {
  isShowWhenTipsDisabled?: boolean;
}

const AppTutorial = (props: ITutorialProps) => {
  const { labelClose, ...other } = props;

  useWindowDimensions();

  return (
    <ReactJoyride
      tooltipComponent={(tooltipProps: TooltipRenderProps) => (
        <Tooltip {...tooltipProps} labelClose={labelClose} />
      )}
      continuous
      disableCloseOnEsc
      disableScrolling
      disableScrollParentFix
      disableOverlayClose
      styles={{ overlay: { position: 'fixed' } }}
      {...other}
    />
  );
};

export const AppTutorialController = (props: IProps) => {
  const { isShowWhenTipsDisabled, ...other } = props;

  const userPresentedRewards = useReduxSelector(
    (redux) => redux.rewards.userPresentedRewards
  );

  const isPopupTipsEnabled = useReduxSelector((redux) =>
    Boolean(redux.app.gameMods?.popupTips)
  );

  if (
    (!isPopupTipsEnabled && !isShowWhenTipsDisabled) ||
    userPresentedRewards
  ) {
    return null;
  }

  return <AppTutorial {...other} />;
};
