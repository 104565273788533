import firebase from 'firebase/app';
import { TStoredLog, TLogWithPlayerInfo } from '@avid/common';

import { logsCollectionRef, playerLogsQuery } from './logs.queries';
import { IAppLog, mapEmailToUserLog } from 'services/logs';

interface ILogsAPIPushParams {
  gameCode: string;
  log: TLogWithPlayerInfo;
}

export const postLog = async (params: ILogsAPIPushParams) => {
  const { gameCode, log } = params;

  const collectionRef = logsCollectionRef(gameCode);

  const logSnapshot = await collectionRef.add({
    ...log,
    created: firebase.firestore.FieldValue.serverTimestamp(),
  });

  return logSnapshot.id;
};

export const logsFetcher = (gameCode: string, email: string) => {
  let lastDoc: any = null;

  const fetchNextLogs = async () => {
    let query = playerLogsQuery(gameCode, email);

    if (lastDoc) {
      query = query.startAfter(lastDoc);
    }

    const data = await query.get();
    lastDoc = data.docs[data.docs.length - 1];

    const userLogs: IAppLog[] = data.docs.map((snapshot) => ({
      id: snapshot.id,
      log: mapEmailToUserLog(snapshot.data() as TStoredLog),
    }));

    return userLogs;
  };

  return { fetchNextLogs };
};
