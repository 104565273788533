import { useContext, useState } from 'react';

import { usePushLog } from 'services/hooks';
import { moveToRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';

import { WorkContext } from 'screens/work/work.context';
import { callFunctionAction } from 'services/api';
import { useDispatch } from 'react-redux';
import { PlayerActions } from 'services/redux';

export const useQuitWork = () => {
  const { onCloseModal } = useContext(WorkContext);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const pushLog = usePushLog();

  const onQuitAsync = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await callFunctionAction('@WORK/QUIT_JOB', undefined);

      dispatch(PlayerActions.merge(result.mergePlayer));

      pushLog({
        type: 'work',
        action: 'quit',
        params: { reason: 'own' },
      });

      moveToRoute(ROUTES.findJob);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { onQuitAsync, onCloseModal };
};
