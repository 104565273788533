import styled from 'styled-components';

import { BigAvatar } from 'components/big-avatar';
import { TImageNames } from 'components/image';
import { P } from 'components/ui';

import { AvatarsList } from '../avatars-list';

interface IProps {
  onSetAvatar: (icon: TImageNames) => void;
  selectedAvatar?: TImageNames;
}

const Styles = {
  Root: styled.div`
    padding-top: 32px;
  `,
  P: styled(P)`
    padding-top: 16px;
    text-align: center;
  `,
};

export const AvatarSelector = (props: IProps) => {
  const { selectedAvatar, onSetAvatar } = props;

  return (
    <Styles.Root>
      <BigAvatar size="196px" image={selectedAvatar || 'characterIcon'} />

      <Styles.P>
        This could be you or an imaginary character. Get creative!
      </Styles.P>

      <AvatarsList selectedAvatar={selectedAvatar} onSetAvatar={onSetAvatar} />
    </Styles.Root>
  );
};
