import { entriesObject } from 'services/utils';

import { IStoredRanking } from './ranking.typing';

export const transformStoredRanking = (
  storedRanking: Record<number, IStoredRanking>
) => {
  const earners: Record<number, number> = {};
  const happiest: Record<number, number> = {};

  entriesObject(storedRanking).forEach(([round, ranking]) => {
    const { earners: earnersPos, happiest: happiestPos } = ranking;
    earners[round] = earnersPos;
    happiest[round] = happiestPos;
  });

  return { earners, happiest };
};
