import {
  ICharacterSmart,
  IPerformanceBonuses,
  IPlayer,
  IPlayerWork,
} from '@avid/common';

export const DEFAULT_SMART_CHARACTER: ICharacterSmart = {
  body: 5,
  picture: 5,
  logic: 5,
  music: 5,
  nature: 5,
  people: 5,
  self: 5,
  word: 5,
};

export const DEFAULT_PERFORMANCE_BONUS: IPerformanceBonuses = {
  'life-card': 0,
  energy: 0,
  money: 0,
  salary: 0,
  satisfaction: 0,
};

export const DEFAULT_POSSESSION = {
  Budget: [],
  Standard: [],
  Luxury: [],
};

export const DEFAULT_USER_POSSESSION = {
  car: DEFAULT_POSSESSION,
  mobile: DEFAULT_POSSESSION,
  home: DEFAULT_POSSESSION,
  travel: DEFAULT_POSSESSION,
};

export const INIT_CAREER_UP = {
  job: '',
  salary: 0,
};

const INIT_CAREER_STATISTICS = {
  currentCycles: 0,
  changes: 0,
  promotions: 0,
};

export const INIT_PLAYER_WORK: IPlayerWork = {
  career: [],
  countNotPassWork: 0,

  statistics: INIT_CAREER_STATISTICS,

  work: '',
  sector: 'HR',
  mood: 0,
  company: {
    company_name: '',
    positive: [],
    negative: [],
  },
};

export const INIT_PLAYER_STATE: IPlayer = {
  authInfo: {
    code: '',
    email: '',
    name: '',
  },
  createCharacter: {
    careerAnchors: [],
    icon: 'characterIcon',
    name: '',
    performance: '',
    smart: DEFAULT_SMART_CHARACTER,
    values: [],
    performanceBonus: DEFAULT_PERFORMANCE_BONUS,
    isCreate: false,
  },
  main: {
    money: 0,
    energy: 0,
    mood: 50,
    numberCompleteLifeCard: 0,
    round: 0,
  },
  tutorials: {
    main: false,
    education: false,
    course: false,
    careerCentre: false,
    lifeDashboard: false,
  },
  secondsPlayed: 0,
};
