import { css } from 'styled-components';

import { COLORS, FONT_FAMILIES, FONT_SIZES, MEDIA } from 'themes';

import { ICheck } from 'typings';

const commonCheckboxStyles = css`
  border-radius: 16px;
  flex: 1;
  text-align: center;

  ${MEDIA.LAPTOP} {
    border-radius: 10px;
  }
`;

const DEFAULT: ICheck = {
  active: css`
    ${commonCheckboxStyles};
    color: ${COLORS.white};
    background-color: ${COLORS.darkYellow};
    border: 1px solid ${COLORS.darkYellow};

    &:hover {
      background-color: ${COLORS.darkYellow};
      color: ${COLORS.white};
    }
  `,
  disable: css`
    ${commonCheckboxStyles};
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    border: 1px solid ${COLORS.lightGray};
  `,
  notAllowed: css`
    ${commonCheckboxStyles};
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    border: 1px solid ${COLORS.lightGray};
  `,
};

const ORANGE: ICheck = {
  active: DEFAULT.active,
  disable: css`
    ${DEFAULT.disable}
    border: 1px solid ${COLORS.darkYellow};
  `,
  notAllowed: css`
    ${commonCheckboxStyles};
    background-color: ${COLORS.disableButton};
    color: ${COLORS.white};
    border: 1px solid ${COLORS.lightGray};
  `,
};

const HIGHLINE_BOTTOM: ICheck = {
  active: css`
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      height: 3px;
      border-radius: 1.5px;
      width: 100%;
      background-color: ${COLORS.purple};
    }
  `,
  disable: css`
    font-family: ${FONT_FAMILIES.regular};
  `,
};

const PINK: ICheck = {
  active: css`
    background-color: ${COLORS.pinkBackground};
    color: ${COLORS.white};
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      right: 16px;
      top: 16px;
      background-color: ${COLORS.white};
      border-radius: 50%;
    }
  `,
  disable: css`
    background: ${COLORS.lightPinkBackground};
  `,
  identical: css`
    border: 1px solid ${COLORS.pink};
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.px18};
    border-radius: 10px;
    min-height: 115px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: ${COLORS.pink};
      color: ${COLORS.white};
    }
  `,
};

export const CHECKBOX_STYLE_THEMES = {
  default: DEFAULT,
  highlineBottomBar: HIGHLINE_BOTTOM,
  orange: ORANGE,
  pink: PINK,
};

export type TCheckboxTheme = keyof typeof CHECKBOX_STYLE_THEMES;
