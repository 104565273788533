import { TImageNames } from 'components/image';
import { Theme } from 'themes';

import { CompanyAnchorsStyles as Styles } from './company-anchors.styles';

export interface ICompanyAnchorProps {
  anchor: string;
  anchorInformation: string;
  isEnable: boolean;
  isPositive?: boolean;
}

export const CompanyAnchor = (props: ICompanyAnchorProps) => {
  const { isPositive, anchor, anchorInformation, isEnable } = props;

  const icon: TImageNames = isPositive
    ? 'positiveCompanyAnchor'
    : 'negativeCompanyAnchor';

  return (
    <Styles.Anchor
      isInclude={isEnable}
      isPositive={!!isPositive}
      $textAlign="center"
      $isColumn
    >
      {isEnable && <Styles.AnchorIcon type={icon} />}
      <Theme.Paragraph $fontFamily="bold">{anchor}</Theme.Paragraph>
      {anchorInformation}
    </Styles.Anchor>
  );
};
