import styled from 'styled-components';

import { Flip } from 'components/flip-card';

import { COLORS, Theme, MEDIA } from 'themes';

export const LifeCardStyles = {
  Wrapper: styled(Theme.Flexbox)`
    justify-content: center;
    min-height: 100%;
  `,
  Card: styled(Flip)`
    width: 306px;
    height: 430px;
    border-radius: 8px;
  `,
  CardSide: styled(Theme.CentralizeFlexbox)`
    background-color: ${COLORS.pink};
    color: ${COLORS.white};
    border-radius: 8px;

    padding: 16px;
  `,
  List: styled(Theme.Flexbox)`
    width: 100%;
    > * {
      flex: 1;
    }
  `,
  ButtonsContainer: styled.div`
    margin-top: 48px;

    ${MEDIA.TABLET} {
      max-width: 420px;
      margin: 0 auto;
      margin-top: 48px;
    }
  `,
};
