import { TGameVersion } from '@avid/common';
import { Step } from 'react-joyride';

export const getSteps = (version: TGameVersion): Step[] => {
  return [
    {
      target: '#top_bar-money',
      disableBeacon: true,
      content:
        'This is the bank account you are starting out with. Earn more money by getting a job and spend it on your needs.',
    },
    {
      target: '#top_bar-energy',
      content: 'Use your Energy wisely to take actions and progress in life.',
    },

    {
      target: '#top_bar-time',
      content: 'The round ends when this timer runs out.',
    },
    {
      target: '#bot_bar-education',
      content:
        'Gain certificates and skills when you graduate from different education levels!',
    },
    {
      target: '#bot_bar-skills',
      content:
        'Level up in specific skills by attending Continuing Education courses.',
    },
    {
      target: '#bot_bar-work',
      content:
        version === 'ntu'
          ? 'You can find a job here and start earning money. Visit the Career and Attachment Office (CAO) to find out more about the jobs and skills in NTUniverse'
          : "Once you gain skills, you can get a job and start earning money.\nSee what's needed for different career pathways and design your own career journey.",
    },

    {
      target: '#bot_bar-gauges',
      content: 'Balance your Life Dashboard well to get a boost in happiness.',
    },
  ];
};

export const getTooltips = (version: TGameVersion, isGauges: boolean) => {
  const steps = getSteps(version);

  if (isGauges) {
    return steps;
  }

  return steps.filter((step) => step.target !== '#bot_bar-gauges');
};
