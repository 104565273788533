import { ISkillInfo, TGameVersion } from '@avid/common';

import { connectVersion } from './versions.connect';

export class AllSkillsAPI {
  private static connectAllSkills(version: TGameVersion) {
    return connectVersion(version).collection('all-skills').doc('all-skills');
  }

  public static async fetchAllSkills(version: TGameVersion) {
    const data = (await this.connectAllSkills(version).get()).data();
    return (data as { skills: ISkillInfo[] } | undefined)?.skills;
  }
}
