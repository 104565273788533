import { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import {
  IPlayerPossessions,
  PossessionsUtils,
  TPossessionItem,
  PossessionLevel,
  IPossessionProperties,
} from '@avid/common';

import { ShopActions } from 'screens/shop/shop.actions';
import { useShopAPI } from 'screens/shop/shop.api';
import { useShopUtils } from 'screens/shop/shop.utils';
import {
  useFetch,
  useMod,
  usePushLog,
  useReduxDispatch,
  useReduxSelector,
} from 'services/hooks';

import { ShopModals } from 'screens/shop/shop.typings';
import { callFunctionAction } from 'services/api';
import { PlayerActions } from 'services/redux';

const { getVersionPossessionLevels } = PossessionsUtils;

export const useShopPossessionItem = () => {
  const sector = useReduxSelector((redux) => redux.shop.sector);
  const currentLevel = useReduxSelector((redux) => redux.shop.level);
  const currentPrice = useReduxSelector(
    (redux) => redux.shop.price,
    shallowEqual
  );
  const version = useReduxSelector((redux) => redux.app.version);

  const [isLoadingBuy, setIsLoadingBuy] = useState(false);

  const { callAction } = useReduxDispatch();
  const { checkEnable, moveToStartPage } = useShopUtils();
  const { fetchPossessionSector } = useShopAPI();
  const pushLog = usePushLog();
  const dispatch = useDispatch();

  const setLevelPrice = callAction(ShopActions.setLevelPrice);
  const openModal = callAction(ShopActions.openModal);

  const [sectorPrises, isLoading] = useFetch(
    fetchPossessionSector(sector as keyof IPlayerPossessions)
  );

  const gaugesHard = useMod('gaugesHard');

  const onSelectItem = (level: PossessionLevel) => () => {
    if (!sectorPrises) {
      return;
    }
    const price = sectorPrises[level];
    setLevelPrice(price, level);
  };

  const buyPossessionItem = async () => {
    if (!currentLevel || isLoadingBuy) {
      return;
    }

    setIsLoadingBuy(true);

    try {
      const result = await callFunctionAction('@SHOP/BUY_POSSESSION', {
        item: sector,
        level: currentLevel,
      });

      dispatch(PlayerActions.merge(result.mergePlayer));

      openModal(ShopModals.BuyPossession);
      setIsLoadingBuy(false);

      const { energy, money } = currentPrice;

      pushLog({
        type: 'shop',
        action: 'buy',
        params: {
          level: currentLevel.toLocaleLowerCase(),
          item: sector,
          energy: -energy,
          money: money && -money,
          gauges: gaugesHard
            ? gaugesHard.constants.SHOP_GAUGES_BONUS[
                sector as keyof IPossessionProperties
              ][currentLevel]
            : undefined,
        },
      });
    } catch (error) {
      setIsLoadingBuy(false);
      console.error(error);
    }
  };

  const checkPrice = checkEnable(sectorPrises);

  const itemLabels =
    version &&
    getVersionPossessionLevels({
      version,
      possession: sector as TPossessionItem,
    });

  return {
    sector,
    currentLevel,
    sectorPrises,
    isLoading,
    currentPrice,
    itemLabels,
    checkPrice,
    onSelectItem,
    buyPossessionItem,
    moveToStartPage,
  };
};
