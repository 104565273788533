import { TGameVersion } from '@avid/common';

import { IMAGES } from 'components/image';
import { TRewardsMap } from 'services/rewards';

export const cacheImagesAsync = async () => {
  const srcArray = Object.values(IMAGES);

  const promises = srcArray.map((src) => {
    return new Promise(function (resolve, reject) {
      const img = new Image();

      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  });

  await Promise.all(promises);
};

export const loadVersionRewards = async (version: TGameVersion) => {
  const rewardsImport = await import(
    `constants/versions/${version}/rewards-options`
  );

  return rewardsImport.REWARDS_OPTIONS as TRewardsMap;
};

export const setVHInPx = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
