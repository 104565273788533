import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { callFunction } from 'services/api';

import { PlayerActions } from 'services/redux';
import { moveToRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';
import { CLIENT_ID } from 'constants/settings';
import { TCreateCharacterProps } from 'typings/create-character';

export const useReviewStep = (
  createCharacterProps: TCreateCharacterProps,
  lifeGoals?: number[]
) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onConfirm = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await callFunction('createCharacter')({
        clientId: CLIENT_ID,
        createCharacter: createCharacterProps,
        lifeGoals,
      });

      dispatch(PlayerActions.merge(response.mergePlayer));

      moveToRoute(
        response.gameStatus === 'paused' ? ROUTES.welcome : ROUTES.home
      );
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return { isLoading, onConfirm };
};
