import { ICompany } from '@avid/common';

export interface IUseCompanyProps {
  sector: string;
  job: string;
}

export interface ICompanyProps extends IUseCompanyProps {
  onReject: () => void;
}

export enum CompanyModal {
  OtherCompany = 1,
  Congrats,
}

export interface ICompanyState {
  isLoading: boolean;
  previousCompanyIndexes: number[];
  modal?: CompanyModal;
  values?: Record<string, ICompanyValue>;
  companies?: ICompany[];
  cycles?: number;
  activeCompanyIndex?: number;
}

export interface IRandomCompanyIndex {
  index: number;
  exceed: boolean;
}

export interface IToValuesWithInfoParams {
  allValues: Record<string, ICompanyValue>;
  positive: string[];
  negative: string[];
}
