import { RewardConstructor } from 'components/reward-constructor';

import { RewardsListStyles as Styles } from './rewards-list.styles';

interface IRewardsListProps {
  rewardsNames: string[];
}

export const RewardsList = (props: IRewardsListProps) => {
  const { rewardsNames } = props;

  return (
    <Styles.Wrapper>
      {rewardsNames.map((rewardName) => (
        <Styles.Item key={rewardName}>
          <RewardConstructor rewardName={rewardName} size="smallSize" />
        </Styles.Item>
      ))}
    </Styles.Wrapper>
  );
};
