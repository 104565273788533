import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';

import { GreetingLogo } from 'screens/greeting';
import { useLoadGamePlayer, useUpdateState } from 'services/hooks';
import { AuthContext } from 'services/context';
import { appAuth } from 'services/firebase';
import { PlayerActions, INIT_PLAYER_STATE } from 'services/redux';

import { GameConstantsLoader } from './game-constants-loader';

interface IState {
  isLoaded: boolean;
  user: firebase.User | null;
  error: firebase.auth.Error | null;
}

const INIT_STATE: IState = {
  isLoaded: false,
  user: null,
  error: null,
};

export const AuthLoader = () => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  const dispatch = useDispatch();

  const loadGamePlayer = useLoadGamePlayer();

  useEffect(() => {
    const unsub = appAuth.onAuthStateChanged(
      async (user) => {
        updateState({ isLoaded: false });

        if (user && user.email) {
          await loadGamePlayer();

          updateState({ user, isLoaded: true, error: null });
        } else {
          dispatch(PlayerActions.set(INIT_PLAYER_STATE));
          updateState({ user, isLoaded: true, error: null });
        }
      },
      (error) => updateState({ error, isLoaded: true })
    );

    return () => unsub();
  }, [dispatch, loadGamePlayer, updateState]);

  if (state.isLoaded) {
    return (
      <AuthContext.Provider value={{ ...state }}>
        <GameConstantsLoader />
      </AuthContext.Provider>
    );
  }

  return <GreetingLogo />;
};
