import { FC } from 'react';
import styled from 'styled-components';

import { spacing, Theme } from 'themes';

import { CareerTreeItem } from './career-tree-item/career-tree-item';

export interface ICareerTreeProps {
  careerBranch: string[];
  isWithArrows: boolean;
  onOpenDescriptionModal: (job: string) => void;
}

const ListWrapper = styled(Theme.Wrapper)`
  padding: 8px;
`;

export const CareerTree: FC<ICareerTreeProps> = (props) => {
  const { careerBranch, onOpenDescriptionModal, isWithArrows } = props;

  return (
    <ListWrapper $itemGap={{ default: spacing(2) }}>
      {careerBranch.map((job) => (
        <CareerTreeItem
          key={job}
          job={job}
          isWithArrow={isWithArrows}
          onOpenDescriptionModal={onOpenDescriptionModal}
        />
      ))}
    </ListWrapper>
  );
};
