import { TShopActions } from './shop.actions';
import { getPrice } from './shop.utils';

import { INIT_REDUX_SHOP, SHOP_ACTION } from './shop.constants';

import { IShopRedux, ShopPages } from './shop.typings';

const {
  ON_OPEN_START_PAGE,
  CLEAR,
  SET_PRICE,
  OPEN_SECTOR_PAGE,
  SET_LEVEL_PRICE,
  OPEN_MODAL,
  SET_COUNTRY,
  SET_LEVEL,
  MOVE_TO_START_PAGE,
} = SHOP_ACTION;

export const reducerShop = (
  state = INIT_REDUX_SHOP,
  action: TShopActions
): IShopRedux => {
  switch (action.type) {
    case ON_OPEN_START_PAGE: {
      return { ...state, ...INIT_REDUX_SHOP };
    }

    case MOVE_TO_START_PAGE: {
      return { ...state, ...INIT_REDUX_SHOP, page: ShopPages.Sector };
    }

    case CLEAR: {
      return INIT_REDUX_SHOP;
    }

    case SET_PRICE: {
      return { ...state, price: getPrice(action.payload) };
    }
    case OPEN_MODAL: {
      return { ...state, modal: action.payload };
    }
    case SET_COUNTRY: {
      return { ...state, country: action.payload };
    }
    case SET_LEVEL: {
      return { ...state, level: action.payload };
    }

    case OPEN_SECTOR_PAGE: {
      return { ...state, ...action.payload };
    }

    case SET_LEVEL_PRICE: {
      const { level, price } = action.payload;
      return { ...state, level, price: getPrice(price) };
    }

    default:
      return state ?? INIT_REDUX_SHOP;
  }
};
