import { FC } from 'react';
import styled from 'styled-components';

import { MEDIA } from 'themes';
import { isIncludeInArray } from 'services/utils';

import { Checkbox, ICheckboxComponentProps } from './checkbox';

interface ICheckboxesProps extends ICheckboxComponentProps {
  value: string | string[];
  items: IItem[];
}

const WidthCheckBox = styled(Checkbox)`
  ${MEDIA.LAPTOP} {
    width: 218px;
  }
`;

export const Checkboxes: FC<ICheckboxesProps> = (props) => {
  const { setValue, items, variant, value, isDisabled: isNotAllowed } = props;

  const isChecked = (el: string) => isIncludeInArray(value, el);

  const RenderItems = items.map((item: IItem, index: number) => {
    const isSelect = isChecked(item.value);

    return (
      <WidthCheckBox
        key={index}
        isDisabled={isNotAllowed && !isSelect}
        setValue={setValue}
        item={item}
        variant={variant}
        isChecked={isSelect}
      />
    );
  });

  return <>{RenderItems}</>;
};
