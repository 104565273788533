import { useMemo } from 'react';

import { useReduxSelector } from 'services/hooks';
import { isEmptyArray } from 'services/utils';

import { SearchWorkStatus } from './search-work.typing';
import { getContent } from './search-work.utils';

export const useSearchWork = () => {
  const job = useReduxSelector((redux) => redux.player.work?.work);
  const career = useReduxSelector((redux) => redux.player.work?.career);

  const content = useMemo(() => getContent(job || ''), [job]);

  const status =
    !career || isEmptyArray(career)
      ? SearchWorkStatus.WithoutWorkHistory
      : job
      ? SearchWorkStatus.Employed
      : SearchWorkStatus.NotEmployed;
  const sectorInformation = content[status];

  return {
    status,
    sectorInformation,
  };
};
