import { FC, memo } from 'react';
import { IAbility, ICharacterSmart, ISkillInfo } from '@avid/common';

import { spacing, Theme } from 'themes';
import { ProgressBar } from '../progress-bar';
import { SkillDisplay } from './skill-display';
import { HOME_STRINGS } from 'screens/home/home.strings';

import {
  arrayEqual,
  isEmptyArray,
  objectEqual,
  sortAlphabeticalObjectArray,
} from 'services/utils';
import { useReduxSelector } from 'services/hooks';

export interface ISkillListProps {
  className?: string;
  abilities?: IAbility[];
  align?: 'center' | 'left';
  isDisplayEmptySection?: boolean;
}

interface ISectionProps {
  title: string;
  type: TSkillType;
}

interface ICreateSkillListParams {
  abilities: IAbility[];
  smart: ICharacterSmart;
  skillInfos: ISkillInfo[];
  type: TSkillType;
}

const createSkillList = (params: ICreateSkillListParams) => {
  const { abilities, skillInfos, smart, type } = params;

  return sortAlphabeticalObjectArray(
    abilities.filter((skill) => skill.type === type),
    'name'
  ).map((ability) => {
    const skillSmart = skillInfos.find(
      (skillInfo) => skillInfo.name === ability.name
    )?.smart;

    const mp = skillSmart ? (smart[skillSmart] + 5) / 10 : 1;

    return <SkillDisplay key={ability.name} ability={ability} multi={mp} />;
  });
};

const { TSC, GSC } = HOME_STRINGS.ACHIEVEMENT;

const SkillListComponent: FC<ISkillListProps> = (props) => {
  const {
    className,
    abilities = [],
    align = 'center',
    isDisplayEmptySection,
  } = props;

  const playerSmart = useReduxSelector(
    (redux) => redux.player.createCharacter.smart,
    objectEqual
  );
  const skillInfos = useReduxSelector((redux) => redux.app.skills, arrayEqual);

  const Section = (sectionProps: ISectionProps) => {
    const skills = createSkillList({
      abilities,
      skillInfos,
      smart: playerSmart,
      type: sectionProps.type,
    });

    if (isEmptyArray(skills) && !isDisplayEmptySection) {
      return null;
    }

    return (
      <Theme.Wrapper $itemGap={{ default: spacing(1.5) }}>
        <Theme.Paragraph $textAlign={align} $fontFamily="bold">
          {sectionProps.title}
        </Theme.Paragraph>
        <ProgressBar
          itemGap="16px"
          color="pink"
          roundSize="8px"
          lineHeight="1.5px"
        >
          {skills}
        </ProgressBar>
      </Theme.Wrapper>
    );
  };

  return (
    <Theme.Wrapper className={className} $itemGap={{ default: spacing(3) }}>
      <Section title={TSC} type="TSC" />
      <Section title={GSC} type="GSC" />
    </Theme.Wrapper>
  );
};

export const SkillList = memo(SkillListComponent);
