import { memo } from 'react';
import { logsUtils } from '@avid/common';

import { IAppLog } from 'services/logs';

import { LogListStyles as Styles } from './log-list.styles';

interface ILogListProps {
  renderLogs: IAppLog[];
}

export const LogList = memo((props: ILogListProps) => {
  const { renderLogs } = props;

  const logsMap = renderLogs.map((renderLog) => {
    const { id, log } = renderLog;

    const isRoundStartLog = log.type === 'round' && log.action === 'start';

    return (
      <Styles.Item key={id} $isRoundStartLog={isRoundStartLog}>
        {logsUtils.logToString(log)}.
      </Styles.Item>
    );
  });

  return <Styles.List>{logsMap}</Styles.List>;
});
