import styled from 'styled-components';

import { COLORS, MEDIA, Theme } from 'themes';

export const ProfileStyles = {
  Root: styled.div`
    background-color: ${COLORS.white};
    padding: 16px;
    margin: 0 -16px;

    ${MEDIA.LAPTOP} {
      display: flex;
      padding: 24px 8px;
    }

    ${MEDIA.CHROMEBOOK} {
      padding: 40px 24px;
    }
  `,
  SmartsContainer: styled(Theme.Wrapper)`
    ${MEDIA.LAPTOP} {
      border-right: 1px solid ${COLORS.lightGray};
    }

    ${MEDIA.CHROMEBOOK} {
      padding: 0 16px;
    }
  `,
  CharacteristicsContainer: styled(Theme.Wrapper)`
    margin-top: 20px;

    ${MEDIA.LAPTOP} {
      margin-top: 0;
      padding-left: 10px;
    }

    ${MEDIA.CHROMEBOOK} {
      padding-left: 30px;
    }
  `,
  Ability: styled(Theme.Paragraph)`
    padding: 0 8px;
  `,
};
