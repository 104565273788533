import { FC } from 'react';
import { ICharacterSmart } from '@avid/common';

import { Tooltip } from 'components/tooltip';

import { SmartRadarChart } from './components';
import { SmartRadarStyles as Styles } from './smart-radar.styles';

interface IProps {
  characterSmart: ICharacterSmart;
  openModal?: (smartKey: keyof ICharacterSmart) => void;
}

export const SmartRadar: FC<IProps> = (props) => {
  const { characterSmart, openModal } = props;

  return (
    <Styles.Wrapper $isColumn $itemGap="0">
      <Tooltip $isHide={!openModal} $top="-9px" $left="46px">
        Click on any of the icons to assign points
      </Tooltip>

      <Styles.BoxTop
        titlePosition="top"
        image="natureSmart"
        smartKey="nature"
        smartValue={characterSmart.nature}
        openModal={openModal}
      />
      <Styles.BoxTopRight
        titlePosition="top"
        image="logicSmart"
        smartKey="logic"
        smartValue={characterSmart.logic}
        openModal={openModal}
      />
      <Styles.BoxTopLeft
        titlePosition="top"
        image="wordsSmart"
        smartKey="word"
        smartValue={characterSmart.word}
        openModal={openModal}
      />
      <Styles.BoxRight
        image="selfSmart"
        smartKey="self"
        smartValue={characterSmart.self}
        openModal={openModal}
      />
      <Styles.BoxLeft
        image="imageSmart"
        smartKey="picture"
        smartValue={characterSmart.picture}
        openModal={openModal}
      />
      <Styles.BoxBottom
        image="musicSmart"
        smartKey="music"
        smartValue={characterSmart.music}
        openModal={openModal}
      />
      <Styles.BoxBottomRight
        image="peopleSmart"
        smartKey="people"
        smartValue={characterSmart.people}
        openModal={openModal}
      />
      <Styles.BoxBottomLeft
        image="bodySmart"
        smartKey="body"
        smartValue={characterSmart.body}
        openModal={openModal}
      />

      <SmartRadarChart characterSmart={characterSmart} />
    </Styles.Wrapper>
  );
};
