import { TImageNames } from 'components/image';

export enum SearchWorkStatus {
  WithoutWorkHistory,
  Employed,
  NotEmployed,
}

export interface IPageContent {
  text: string;
  imageType: TImageNames;
}
