import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { IPossessionProperties, TPossessionItem } from '@avid/common';

import { InfoImageModal } from 'components/modal';
import { ShopModals } from 'screens/shop/shop.typings';
import { PriceInformation } from 'components/price-information';
import { useMod } from 'services/hooks';
import { getModComponent } from 'services/utils';
import { getPossessionIcon } from 'services/character';
import { Theme } from 'themes';

import { SHOP_STRINGS } from '../../shop.strings';

import { useShopModal } from './shop-modal.state';

const { POSSESSION_BUY } = SHOP_STRINGS;

const ValueWrapper = styled.div`
  margin-top: 12px;
`;

const Subtitle = styled(Theme.Paragraph)`
  margin-top: 8px;
`;

export const ShopModal: FC = () => {
  const {
    moveToStartPage,
    version,
    modal,
    level,
    sector,
    subtitle,
    energy,
    money,
  } = useShopModal();

  const GaugesHardTravelBonusMC = getModComponent(
    useMod('gaugesHard')?.components.GaugesHardTravelBonus
  );

  const Modal: Record<ShopModals, ReactElement | null> = {
    [ShopModals.BuyPossession]: (
      <>
        {sector !== 'character' && level && version && (
          <InfoImageModal
            image={getPossessionIcon(
              version,
              sector as keyof IPossessionProperties,
              level
            )}
            imageSize="150px"
            title={
              <Theme.Paragraph $fontSize="px18" $fontFamily="bold">
                {POSSESSION_BUY.TITLE}
              </Theme.Paragraph>
            }
            subtitle={<Subtitle>{subtitle}</Subtitle>}
            buttonText={POSSESSION_BUY.BUTTON}
            onCloseModal={moveToStartPage}
          >
            <ValueWrapper>
              <PriceInformation
                itemGap="16px"
                energy={-energy}
                money={money ? -money : undefined}
              />

              <GaugesHardTravelBonusMC
                item={sector as TPossessionItem}
                level={level}
              />
            </ValueWrapper>
          </InfoImageModal>
        )}
      </>
    ),
  };

  return <>{modal && Modal[modal]}</>;
};
