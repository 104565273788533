import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { IItemGap, MEDIA, Theme } from 'themes';

import { Image } from 'components/image';
import { ScreenModal } from 'components/modal';
import { Modal } from './modal';

export interface IModalWrapperProps {
  onCloseModal(): void;

  children?: ReactNode;
  className?: string;
  isClose?: boolean;
  itemGap?: IItemGap;
  isNotCenter?: boolean;
  zIndex?: number;
  isScreenModal?: boolean;
  onClick?(ev: React.MouseEvent<HTMLDivElement>): void;
}

const Wrapper = styled(Theme.Wrapper)<{ $isNotCenter?: boolean }>`
  position: relative;
  margin: 0 16px;
  padding: 32px 24px;
  border-radius: 24px;
  background-color: white;
  text-align: ${(p) => !p.$isNotCenter && 'center'};

  ${MEDIA.LAPTOP} {
    max-width: 560px;
  }
`;
const CloseTest = styled(Image)`
  position: absolute;
  top: 15px;
  right: 15px;
  margin-top: 0 !important;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: grid;
  row-gap: 16px;
`;

export const ModalWrapper = (props: IModalWrapperProps) => {
  const {
    isClose,
    onCloseModal,
    isScreenModal = true,
    children,
    className,
    isNotCenter,
    zIndex,
    onClick,
  } = props;

  const ModalComponent = isScreenModal ? ScreenModal : Modal;

  return (
    <ModalComponent className={className} isOpen={!isClose} zIndex={zIndex}>
      <Wrapper
        $itemGap={{ default: '16px' }}
        $isNotCenter={isNotCenter}
        onClick={onClick}
      >
        <ContentWrapper>
          <CloseTest type="closeModal" onClick={onCloseModal} />
          {children}
        </ContentWrapper>
      </Wrapper>
    </ModalComponent>
  );
};
