import { css } from 'styled-components';

import { FONT_FAMILIES } from 'themes/global-fonts';
import { COLORS } from 'themes/colors';
import { FONT_SIZES } from 'themes/font-sizes';

export const CSS_BUTTON_PRESET = css`
  width: 260px;
  padding: 18px;
  margin: 0px auto;
  border-radius: 20px;
  display: block;
  min-height: 56px;
  text-align: center;
  outline: none;

  transition: all 0.2s ease-in-out;
`;

export const buttonDisabledStyles = {
  default: css`
    filter: grayscale(100%);
  `,
  cancelModal: css`
    filter: grayscale(100%);
    background-color: ${COLORS.purple};
    color: ${COLORS.white};
  `,
};

export const BUTTON_THEME = {
  default: css`
    border: none;
    background-color: ${COLORS.purple};
    color: ${COLORS.white};

    &:disabled {
      ${buttonDisabledStyles.default}
    }
  `,

  blue: css`
    border: none;
    background-color: ${COLORS.blue};
    color: ${COLORS.white};

    &:disabled {
      ${buttonDisabledStyles.default}
    }
  `,

  white: css`
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    border: 1px solid ${COLORS.lightGray};
  `,

  pink: css`
    background: ${COLORS.lightPinkBackground};

    border: 1px solid ${COLORS.pink};
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.px18};

    text-align: center;

    width: 100%;
    padding: 18px;
    border-radius: 20px;
    display: block;
    min-height: 56px;
  `,

  cancelModal: css`
    font-family: ${FONT_FAMILIES.bold};
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    border: 1px solid ${COLORS.purple};

    &:disabled {
      ${buttonDisabledStyles.cancelModal}
    }
  `,

  orange: css`
    width: 165px;
    padding: 16px;
    margin: 0px auto;
    border-radius: 10px;
    display: block;
    min-height: 56px;
    color: ${COLORS.white};
    background: ${COLORS.darkYellow};
    font-family: ${FONT_FAMILIES.bold};
    font-size: ${FONT_SIZES.header};
  `,
};

export type TButtonTheme = keyof typeof BUTTON_THEME;
