import { RewardConstructor } from 'components/reward-constructor';

import { IRewardModalProps, useRewardModal } from './reward-modal.state';
import { RewardModalStyles as Styles } from './reward-modal.styles';
import { REWARD_MODAL_STRINGS } from './reward-modal.constants';
import { RewardAppearance } from './components/reward-appearance';

const { buttonGot, buttonNext, checkBadges, progress } = REWARD_MODAL_STRINGS;

export const RewardModal = (props: IRewardModalProps) => {
  const {
    isMounted,
    rewardName,
    rewardOptions,
    nextRewardName,
    nextRewardOptions,
    userPresentedRewards,
    modalWrapperRef,
    onConfirm,
  } = useRewardModal(props);

  const progressString = `${progress} ${nextRewardOptions?.badge} Badge`;

  return (
    <Styles.Root $isMounted={isMounted}>
      <Styles.ModalWrapper ref={modalWrapperRef}>
        <Styles.CloseIcon type="closeModal" onClick={onConfirm} />
        <Styles.BackgroundImage type="backgroundImage" />
        {rewardOptions && (
          <Styles.ModalContainer>
            <RewardAppearance
              rewardName={rewardName}
              rewardOptions={rewardOptions}
            />

            {nextRewardName && (
              <Styles.NextRewardContainer>
                <Styles.NextRewardTitle>
                  {progressString}
                </Styles.NextRewardTitle>
                <Styles.NextReward>
                  <RewardConstructor
                    rewardName={nextRewardName}
                    size="smallSize"
                  />
                </Styles.NextReward>
              </Styles.NextRewardContainer>
            )}
            <Styles.CheckText>{checkBadges}</Styles.CheckText>
            <Styles.FooterContainer>
              <Styles.Button
                title={userPresentedRewards > 1 ? buttonNext : buttonGot}
                onClick={onConfirm}
                isWhiteButton={true}
              />
            </Styles.FooterContainer>
          </Styles.ModalContainer>
        )}
      </Styles.ModalWrapper>
    </Styles.Root>
  );
};
