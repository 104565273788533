import { IPlayerMain, IPlayerWork } from '@avid/common';

import { ThunkAction } from 'typings';

import { gamesAPI } from '../../api';

import { INIT_PLAYER_WORK, PlayerActions } from '../reducers';
import { IReduxStore } from '../root-reducer';
import { RewardsActions } from '../../rewards';

export const PlayerThunk = {
  updateMain:
    (
      partialMain: Partial<IPlayerMain>,
      storeState?: IReduxStore
    ): ThunkAction =>
    (dispatch, getState) => {
      const { player } = storeState || getState();
      const { authInfo } = player;

      dispatch(PlayerActions.updateMain(partialMain));

      return gamesAPI.mergePlayer(authInfo, { main: partialMain });
    },

  addMoney:
    (add: number): ThunkAction =>
    (dispatch, getState) => {
      const state = getState();

      const money = state.player.main.money;
      const newMoney = money + +add;

      return dispatch(PlayerThunk.updateMain({ money: newMoney }, state));
    },

  addEnergy:
    (add: number): ThunkAction =>
    (dispatch, getState) => {
      const state = getState();

      const energy = state.player.main.energy;
      const newEnergy = Math.max(energy + +add, 0);

      return dispatch(PlayerThunk.updateMain({ energy: newEnergy }, state));
    },

  addMood:
    (add: number): ThunkAction =>
    (dispatch, getState) => {
      const state = getState();

      const {
        player: {
          main: { mood, bonusMood },
        },
        app: { gameMods },
      } = state;

      let newBonusMood = undefined;

      if (gameMods?.gauges) {
        newBonusMood = (bonusMood || 0) + +add;
      }

      const newMood = mood + +add;

      return dispatch(
        PlayerThunk.updateMain(
          { mood: newMood, bonusMood: newBonusMood },
          state
        )
      );
    },

  completeAction:
    (actionEnergy: number, actionMoney?: number): ThunkAction =>
    (dispatch, getState) => {
      const state = getState();

      const {
        player: {
          main: { money, energy },
        },
      } = state;

      const newEnergy = energy - +actionEnergy;
      const newMoney = money - +(actionMoney || 0);

      return dispatch(
        PlayerThunk.updateMain({ energy: newEnergy, money: newMoney }, state)
      );
    },

  updateWork:
    (
      partialWork: Partial<IPlayerWork>,
      reduxState?: IReduxStore
    ): ThunkAction =>
    (dispatch, getState) => {
      const { authInfo, work = INIT_PLAYER_WORK } = (reduxState || getState())
        .player;

      dispatch(PlayerActions.setWork({ ...work, ...partialWork }));

      return gamesAPI.mergePlayer(authInfo, {
        work: { ...work, ...partialWork },
      });
    },

  addStats:
    (
      params: Partial<Pick<IPlayerMain, 'energy' | 'money' | 'mood'>>
    ): ThunkAction =>
    (dispatch, getState) => {
      const { energy: addEnergy, money: addMoney, mood: addMood } = params;

      const state = getState();

      const {
        player: {
          main: { money, energy, mood, bonusMood },
        },
        app: { gameMods },
      } = state;

      const mainPayload = {
        money: money + +(addMoney || 0),
        energy: energy + (addEnergy || 0),
        mood: mood + (addMood || 0),
        bonusMood: gameMods?.gauges
          ? (bonusMood || 0) + (addMood || 0)
          : undefined,
      };

      return dispatch(PlayerThunk.updateMain(mainPayload));
    },

  presentReward:
    (reward: string, reduxState?: IReduxStore): ThunkAction =>
    (dispatch, getState) => {
      const { authInfo, rewards = [] } = (reduxState || getState()).player;

      const newRewards = [...rewards, reward];

      dispatch(PlayerActions.setRewards(newRewards));
      dispatch(RewardsActions.presentReward());

      return gamesAPI.mergePlayer(authInfo, { rewards: newRewards });
    },
};
